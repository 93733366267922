import React from 'react';
import './loader.css'; // You can create a CSS file for styling if needed
import ColorsParticles from '../particles/ColorsParticles';

const Loaders = () => {
  return (
    <div class="wrapper">
      <div class="container">
        <div class="circle">
          <span>BRINGMARK</span>
          <div class="rounded">
            <div class="round-left"></div>
            <div class="circle-t"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loaders;
