import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const FlutterDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Flutter Developers</Typography>

<Typography >Hire Flutter Developers To Built Augment Flutter Mobile Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/l5apyzlijovlearam2yv" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Flutter is an open-source UI software development toolkit created by Google. It enables developers to create natively compiled applications for mobile, web, and desktop platforms from a single codebase. Flutter uses the Dart programming language and provides a rich set of pre-designed widgets and tools to help developers build highly customizable and visually appealing user interfaces. One of the notable features of Flutter is its "hot reload" capability, which allows developers to see changes they make to the code immediately reflected in the app without the need to rebuild the entire application. This speeds up the development process and encourages rapid experimentation. Flutter is known for delivering high-performance applications with a native look and feel across different platforms, making it a popular choice for developers looking to create consistent and engaging user experiences.</Typography>

    <Typography style={{marginTop:'40px'}}> The skilled Flutter application developers at BringMark possess a deep understanding of crafting flawless and efficient cross-platform apps. Engage our team of Flutter developers to cater to your specific development needs and harness the advantages of a unified codebase. Our accomplished Flutter app development squad adeptly combines their extensive development knowledge and proficiency. Employ dedicated Flutter app developers boasting ample experience and skill to create secure, resilient software solutions that seamlessly incorporate top-tier features, enhancing your business offerings. We commit to producing top-tier solutions, ensuring a captivating user experience that maximizes the potential of Flutter's unified codebase proposition.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Flutter Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Flutter App Development & Consulting Service</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Uncertain about the potential of Flutter app development for your project? Seek guidance and development support from leading Flutter app experts to ensure your app achieves the anticipated development outcomes.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Flutter Web Development Experts </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Harness the expertise of a Flutter app development company to create intuitive, scalable web solutions that deliver a mobile-like user experience for your web and desktop applications.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Custom Widget Creation</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Secure a custom-designed widget tailored to your specific app development needs by enlisting Flutter developers, ensuring an exceptional user experience with a captivating mobile app UI.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>4. Flutter Game Development Specialists</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our top-tier Flutter app developers will assist you in owning a feature-rich 2D multiplayer gaming app, fully comprehending your development requisites.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Flutter Developer Proficient in Dart </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Hire Flutter app developers well-versed in the Dart programming language, simplifying code execution and creating stunning app UIs using Flutter development libraries.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Flutter Developers for Enterprise App Solutions</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Remote engagement of Flutter app developers unlocks the potential for optimizing your business processes through scalable and dependable enterprise applications, enabling you to achieve your business objectives in a more efficient and accessible manner.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7. Flutter Developer for UI/UX Design</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Flutter's renowned UI widgets are celebrated for their ability to craft visually stunning, engaging mobile apps that elevate the user experience.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>8. Flutter Developer for App Migration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Opt for Flutter developers to enhance your existing mobile app by migrating to the Flutter platform, attracting more app users, boosting business value, ROI, and beyond.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Flutter Developer for IoT Integration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Engage Flutter developers with extensive experience in building a centrally accessible system linked to IoT devices, enabling seamless access through cloud platforms and mobile apps.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Maintenance & Support</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Providing ongoing maintenance, updates, and technical support for Flutter applications. Ensuring app compatibility with the latest operating system versions and devices.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
 </Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default FlutterDevelopers