import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './IOT.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/imageslider/avklhh8zdxthyhgmjge5",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/imageslider/jaibvujh7vs8hig3gkik",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/imageslider/uhreevxvwlmrtlsvqfc5",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/imageslider/irfkblfixyp2fstu1xde",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/imageslider/ifhpcunw8ndbhkuzwe0h",



 

];

function IOT() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>AR/VR</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white"}}>
    At the forefront of the IoT revolution, Bringmark redefines connectivity with solutions that transcend the ordinary. Our approach to IoT is holistic, encompassing not just devices, but entire ecosystems. By seamlessly intertwining smart devices, sensors, and advanced data analytics, we enable businesses to orchestrate a symphony of insights, optimizations, and unparalleled experiences. Whether you're in manufacturing, healthcare, or logistics, Bringmark's IoT solutions empower you to harness the full potential of connected technologies, driving innovation that shapes the industries of tomorrow.
    Bringmark's IoT solutions herald a new era of connectivity, where the physical and digital worlds seamlessly intertwine. Our expertise extends beyond the realm of devices – we create ecosystems that revolutionize industries. By interconnecting smart devices, sensors, and data analytics, we empower businesses to glean insights, optimize processes, and deliver unparalleled user experiences. Whether you're in manufacturing, healthcare, or logistics, Bringmark's IoT solutions pave the way for innovation that transcends boundaries.
        </Typography>

        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/kybeekk6xt7cnrfwsqnd"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/phqodwmqqnt5xdvq6z1t"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/ja0d0qjau6zvylpiapei"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/zr4zvbzg9chyogjaoxdd" 
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/ucsebhfa58k6h5mryqub"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/hma2um1kqm1avzffdey0"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/z1uswk4y6a5omkstll5f"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/un6dq1xrjgxtti4erp6u" 
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/niuezwylz9puiruk1a6z"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/qg63zf2bfu3q83ngkeax"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/ymt9niouylgwuiiv28xz"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/marque/u2snfjpztiwcbreyorzd" 

  />
</div>

        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Navigating the IoT Landscape with Bringmark: Seize the Power of Connected Devices

</Typography>
<Typography  style={{color:"#545357"}}>

Embrace the future with Bringmark's IoT solutions, where we harness the potential of interconnected devices to drive innovation and efficiency. Our comprehensive IoT services empower businesses to collect, analyze, and derive insights from vast streams of data generated by smart devices. By seamlessly integrating sensors, data analytics, and cloud computing, we help you unlock new revenue streams, optimize operations, and deliver personalized experiences to your customers. Bringmark's IoT expertise is your compass to navigate the IoT landscape and create a smarter, more connected future </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/gif/hrj6evm4yueyvey5qdas" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
In an era where data is currency, Bringmark emerges as a trusted steward of your business's digital transformation. Our IoT services transcend mere connectivity – they are an embodiment of intelligent decision-making. By knitting together a tapestry of sensors, resilient cloud infrastructure, and predictive analytics, we empower you to decipher patterns, enhance operational efficiency, and unearth untapped opportunities. With Bringmark as your IoT partner, you gain the ability to harness the vast data landscape, steering your business towards informed, strategic leaps that elevate your competitive edge.
In the age of data-driven decision-making, Bringmark stands as your partner in harnessing the power of IoT. Our comprehensive suite of IoT services goes beyond connecting devices; it's about empowering businesses with actionable insights. By weaving together sensors, cloud infrastructure, and data analytics, we help you interpret patterns, streamline operations, and unlock new avenues for growth. With Bringmark's IoT expertise, you're equipped to make informed decisions that transform your operations and elevate your business to new heights.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/gif/zf9qmikm9kcejqz2gx64" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Connected Possibilities: Transforming Industries with Bringmark's IoT Mastery</Typography>
<Typography  style={{color:"#545357"}}>

The Internet of Things (IoT) is rewriting the rules of industry, and Bringmark is your guide to harnessing its transformative potential. Our IoT mastery extends beyond connecting devices; it's about revolutionizing industries. From manufacturing to healthcare, we design and deploy IoT solutions tailored to your business's unique needs. By integrating cutting-edge sensors, robust data analytics, and secure cloud infrastructure, we enable you to make data-driven decisions, enhance operational efficiency, and create novel customer experiences. Partner with Bringmark to embark on an IoT journey that reshapes industries and redefines success. </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Navigating the IoT landscape requires more than technical expertise – it demands a personalized, adaptive approach. Bringmark is committed to tailoring our IoT solutions to your organization's unique DNA. Collaborating closely, we delve into your challenges and aspirations, architecting solutions that address your distinct needs. From crafting intricate sensor networks to implementing real-time data analysis, we ensure that IoT serves as a catalyst for your growth. Bringmark's IoT mastery doesn't just infuse technology; it infuses agility, precision, and foresight into your operations, empowering you to anticipate industry shifts and stay ahead of the curve
Embrace a future defined by interconnected possibilities with Bringmark's IoT mastery. We understand that every business's journey is unique, and our approach reflects that. Our experts collaborate closely with you to identify pain points and opportunities, architecting IoT solutions that cater to your specific needs. From devising robust sensor networks to implementing predictive maintenance strategies, we ensure that IoT becomes a catalyst for your business's success. With Bringmark, you're not just adopting technology – you're creating a roadmap to a smarter, more efficient future
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>


  IoT Innovation Unleashed: Bringmark Pioneering Connected Futures

</Typography>
<Typography  style={{color:"#545357"}}>

Step into the era of IoT innovation with Bringmark as your trailblazer. Our IoT solutions are meticulously crafted to bring your visions to life, seamlessly blending the physical and digital realms. By architecting interconnected ecosystems of devices, sensors, and data platforms, we empower you to uncover insights, optimize processes, and deliver unmatched user experiences. Whether you're seeking to enhance your supply chain or revolutionize customer engagement, Bringmark's IoT expertise lays the foundation for your journey towards pioneering connected futures </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/gif/m9fydzwwcx3kwd8bsudo" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
The transformative power of the Internet of Things reverberates through every aspect of business, and Bringmark is your gateway to harnessing it. Our IoT solutions encapsulate this transformative potential, transcending traditional boundaries. By seamlessly integrating devices, sensors, and predictive analytics, we guide your journey from data to insights, insights to strategy, and strategy to impact. Whether you're reshaping supply chains, revolutionizing customer engagement, or reimagining user experiences, Bringmark's IoT expertise empowers you to navigate complexity with clarity. Partner with us and embark on a transformative expedition, where the convergence of digital and physical realms creates possibilities that redefine industries and reshape the future
The Internet of Things isn't just a concept; it's a transformative force that shapes industries. Bringmark's IoT solutions encapsulate this transformative power, propelling businesses into a realm of innovation and efficiency. Through seamless integration of devices, sensors, and data analytics, we help you turn raw data into actionable insights. Whether you're seeking to optimize supply chains, enhance customer engagement, or redefine user experiences, Bringmark's IoT expertise provides the tools to shape your digital destiny. Partner with us and embark on a journey where the physical and digital converge to create a future that's smarter, more connected, and boundless in potential.      </Typography>

{/* <ProjectCard/> */}

<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/IoT/gif/lfwjbqkzivofulnfecog" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unlocking IoT Potential: Elevate Your Business with Bringmark's Expertise

</Typography>
<Typography  style={{color:"#545357"}}>
The Internet of Things (IoT) is no longer a vision; it's a reality that Bringmark brings within your reach. Our IoT expertise spans from ideation to implementation, ensuring that your business fully harnesses the potential of connected devices. Through seamless integration of sensors, data analytics, and cloud solutions, we enable you to create new revenue streams, optimize operations, and enhance customer experiences. By partnering with Bringmark, you're not just adopting IoT; you're elevating your business's capabilities, embracing innovation, and future-proofing your success in the interconnected digital landscape.  </Typography>
</div>


</div>

      
    </div>
  )
}

export default IOT