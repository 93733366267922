import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './cybersecureity.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/imageslider/bt4fryytapvdqel3vd5v",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/imageslider/bmecjnaruznuvugx3ad1",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/imageslider/yuvbaipqzipxr5e05qbf",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/imageslider/wdzyagjcv7zv3f3byn5e",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/imageslider/mn5z2x3mkk1jo3tu82mf",



];

function CyberSecurity() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white"}}>
    At BringMark, cybersecurity is not just a feature; it's a fundamental commitment to safeguarding your digital assets and ensuring the integrity of your operations. With a steadfast focus on protecting your business from evolving threats, our cybersecurity solutions are designed to fortify your digital presence.

Our comprehensive cybersecurity framework encompasses proactive measures that identify vulnerabilities before they can be exploited. From advanced threat detection to real-time monitoring, we employ cutting-edge technologies to provide you with the utmost protection.

Through robust encryption, secure access controls, and continuous monitoring, BringMark ensures that your sensitive data remains confidential and out of reach from unauthorized entities. We understand that the digital landscape is ever-changing, which is why our cybersecurity strategies are adaptable and future-focused, staying one step ahead of emerging threats.

Choosing BringMark for your cybersecurity needs means making a proactive investment in your business's security. Our dedicated team of experts collaborates with you to develop customized strategies that align with your unique challenges and goals. As technology evolves, our commitment to safeguarding your digital assets remains unwavering. With BringMark as your cybersecurity partner, you can navigate the digital realm with confidence, knowing that your business is protected by the latest in cybersecurity innovation.
        </Typography>

        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/qhxgyqvm6r8s7kktydap"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/wtb7aa1scpjbhwpvfr4e"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/ynvnrndp1w5k6fzfo0ly"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/pqs2tdmmczeitpzavg8u"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/sxkq0fu352zkyhltn0fv"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/aorchxgi2ix3dexqnrxf"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/c2ua5hjcq6ooudjerujd"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/rhwmrt8grxqdwwe4zpvd"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/nspmkcmdcdwro4dlmpzr"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/kr7h6fhisgwwf92ez6f4"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/x7e7op3qdmi9kfh2ylj3"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/marque/vs4wt2ux9zen3cd5yzzt"

  />
</div>

        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Secure Connections: Safeguarding Your Network with BringMark's Expertise

</Typography>
<Typography  style={{color:"#545357"}}>
 today's interconnected digital landscape, network security is paramount. BringMark's cybersecurity expertise shines in safeguarding your network connections. Our comprehensive solutions encompass a range of measures, including robust firewall protection, sophisticated intrusion detection systems, and encrypted communications. By implementing these advanced security protocols, we ensure that your data transmissions remain shielded from unauthorized access and potential breaches. Our dedication to securing network connections helps fortify the integrity of your business operations and protect sensitive information from cyber threats, giving you the peace of mind to operate confidently in an ever-evolving digital environment </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/gif/g9tq2cksmtkaq7in48mp" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
In the digital landscape where security breaches and data vulnerabilities loom large, BringMark stands as a beacon of trust and protection. Our cutting-edge cybersecurity services are meticulously designed to safeguard your digital assets from the relentless onslaught of cyber threats. With an unwavering commitment to excellence, we provide a comprehensive suite of solutions that go beyond traditional defenses.


        </Typography>
        <Typography paragraph style={{color:"white"}}>
        At BringMark, cybersecurity isn't just a service; it's a proactive partnership to fortify your business's digital resilience. Our skilled experts employ advanced threat detection mechanisms, real-time monitoring, and stringent access controls to ensure that your sensitive data remains out of reach from unauthorized access. From encrypting data transmissions to implementing robust firewalls, we establish a multi-layered defense system that shields your digital ecosystem against evolving threats.facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/gif/ovtr3fhr1fvmhnrxwzow" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Vigilant Monitoring: 24/7 Cybersecurity Surveillance by BringMark</Typography>
<Typography  style={{color:"#545357"}}>
Cyber threats are persistent and ever-evolving, necessitating constant vigilance. BringMark takes cybersecurity surveillance to the next level with our 24/7 monitoring system. Through advanced AI-driven analytics and real-time alerts, we keep a watchful eye on your digital environment, identifying anomalies and potential breaches as they happen. Our vigilant monitoring empowers us to swiftly detect and address threats, minimizing risks and bolstering your digital defense against the ever-changing landscape of cyber adversaries. With BringMark's vigilant surveillance, you gain a proactive advantage in identifying and mitigating potential threats before they can cause significant harm, enhancing your overall cybersecurity posture  </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
What sets BringMark apart is our holistic approach to cybersecurity. We don't merely provide tools; we cultivate a culture of vigilance within your organization. Our tailored training programs empower your employees to recognize and thwart phishing attempts, adopt secure password practices, and navigate the digital landscape safely. By building a human firewall alongside our technological defenses, BringMark creates an environment where your workforce becomes an active guardian of your digital assets.
        </Typography>
        <Typography paragraph style={{color:"white"}}>
        As the cyber landscape continues to evolve, BringMark stays at the forefront of innovation, adapting our strategies to counter emerging threats. With us by your side, you're not just acquiring cybersecurity services – you're forging a dynamic partnership that bolsters your digital resilience, builds trust among your stakeholders, and ensures your business's continuity and growth in an increasingly interconnected world.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Training for Resilience: BringMark's Cybersecurity Education Empowers Employees

</Typography>
<Typography  style={{color:"#545357"}}>
A resilient cybersecurity strategy involves empowering your first line of defense: your employees. BringMark recognizes the pivotal role of an informed workforce in thwarting cyber threats. Our tailored cybersecurity education programs delve into vital areas such as phishing awareness, secure password practices, and safe browsing habits. By nurturing a culture of vigilance among your staff, they become an invaluable asset in identifying and mitigating potential threats. With BringMark's comprehensive training, your employees stand ready to tackle emerging challenges, minimizing human vulnerabilities and bolstering your organization's overall cybersecurity resilience</Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/gif/l1urzcvnjekneybgdhto" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
As the cyber landscape continues to evolve, BringMark stays at the forefront of innovation, adapting our strategies to counter emerging threats. With us by your side, you're not just acquiring cybersecurity services – you're forging a dynamic partnership that bolsters your digital resilience, builds trust among your stakeholders, and ensures your business's continuity and growth in an increasingly interconnected world.
        </Typography>
        <Typography paragraph style={{color:"white"}}>
        BringMark's cybersecurity prowess extends far beyond a mere shield – it's a proactive stance against cyber adversaries. Our arsenal encompasses sophisticated threat detection mechanisms, real-time monitoring, and robust access controls. Through meticulous data encryption and fortified firewalls, we secure your digital assets against potential breaches, ensuring that your sensitive information remains inaccessible to unauthorized parties.
        </Typography>

{/* <ProjectCard/> */}
<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Cyber%20Security/gif/wjl6umlwu6wyn9rxkebg" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Incident Response Excellence: BringMark's Swift Action in Cyber Emergencies"
</Typography>
<Typography  style={{color:"#545357"}}>

In the face of cyber emergencies, BringMark shines with swift and effective incident response capabilities. Our seasoned experts are primed to assess, contain, and mitigate the impact of breaches, no matter how complex. By swiftly identifying the root causes of incidents, we minimize downtime, preserve data integrity, and rapidly restore normalcy. Our relentless dedication ensures that your business remains operational and resilient in the wake of cyber challenges. With BringMark by your side, you're not only equipped to navigate crises but also emerge stronger, showcasing a resolute commitment to cybersecurity excellence. </Typography>
</div>


</div>

      
    </div>
  )
}

export default CyberSecurity