import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#091523',
  borderRadius: '15px'
};

const formStyle = {
  width: '100%',
  maxWidth: '400px',
  margin: '15px'
};

function ContactForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      const modalTimer = setTimeout(() => {
        closeModal();
      }, 5000);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [isModalOpen]);

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [Name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      name: Name,
      email: email,
      mobileNumber: phoneNumber,
      message: comment,
    };

    const res = await fetch("https://teal-breakable-springbok.cyclic.app/api/hireDevs/hire-contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    if( !formData.name || !formData.email || !formData.mobileNumber || !formData.message){
      alert(`Please fill all the fileds.`);
    }

    if (res.status === 200) {
      console.log("Form submitted successfully!");
  
      // Show the success message dialog
      openModal();
  
      // Reload the page after 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      console.error("Form submission failed");
    }
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };


  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };


  
  return (
    <Container style={containerStyle}>
      <form style={formStyle}>
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
          <img src={require('../assets/bg.png')} height={'70px'} width={'71'} />
        </div>
        <Typography variant="h4" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField style={{backgroundColor:"white",borderRadius:"10px"}} label="Username" fullWidth required  value={Name} onChange={handleName}/>
          </Grid>
          <Grid item xs={12}>
            <TextField style={{backgroundColor:"white",borderRadius:"10px"}} label="Email" fullWidth required value={email} onChange={handleEmail} />
          </Grid>
          <Grid item xs={12}>
            <TextField style={{backgroundColor:"white",borderRadius:"10px"}} label="Mobile Number" fullWidth required value={phoneNumber} onChange={handlePhoneNumberChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField style={{backgroundColor:"white",borderRadius:"10px"}} label="Message" multiline rows={4} fullWidth required value={comment} onChange={handleCommentChange} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          type="submit"
          style={{ marginTop: 16,backgroundColor:"#4CBB16" }}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </form>

      <Dialog open={isModalOpen} maxWidth="xs">
        <Container style={{ padding: '20px', backgroundcolor: 'green',color:"black" }}>
          <Typography variant="h6" color={"black"}>Thank you for contacting us! We are connect you soon</Typography>
          {/* <Typography color={"black"}>Your message has been sent successfully.</Typography> */}
        </Container>
      </Dialog>
    </Container>
  );
}

export default ContactForm;
