import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const CodeIgniteDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire CodeIgniter Developers</Typography>

<Typography >Engage Codeigniter developers to access speedy, dependable, and high-quality Codeigniter solutions.</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/th5hfcmbhqjwoadsbjhz" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> CodeIgniter stands as an open-source application development framework rooted in PHP. It surpasses its counterparts in various dimensions, boasting enhanced popularity and robust capabilities. Speed, streamlined configuration, security, structured file organization, seamless error handling, and an array of other advantages distinguish it within the realm of PHP frameworks. A wealth of resources including plugins, libraries, and interfaces accompany CodeIgniter, empowering developers to construct potent and fully interactive websites with remarkable efficiently.
 </Typography>

    <Typography style={{marginTop:'40px'}}> It is a renowned open-source PHP framework that simplifies and accelerates the process of developing dynamic web applications. Recognized for its lightweight design and user-friendly architecture, CodeIgniter provides developers with a versatile platform to create applications freely.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By CodeIgnite Developers:</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>1. AngularJS Mobile App Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Being a leading mobile app development company, we provide seamless AngularJS mobile app development. It will be built on a hybrid or native platform. Our AngularJS developers make it fluid and effortless, and it will be scalable and strong.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.  AngularJS Web Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>AngularJS is based on web applications, and we have specialization in that. The web developer delivers excellent and superior AngularJS web applications. AngularJS development is a manageable and great source to develop web applications.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. AngularJS UI/UX Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our AngularJS developers provide stunning and aesthetic UI/UX development. We develop AngularJS development with an outstanding UI/UX development service and deliver rapidly. In addition, we have provided a real-time user experience in AngularJS UI/UX development.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. AngularJS  Single Page Application</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our AngularJS developers provide faster Single-page applications. It helps to work efficiently and is quite flexible for web applications. In addition, we ensure that single page applications are operating accurately and securely.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.  AngularJS Web Portal</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We provide an AngularJS web portal for interactive web applications. It is easy to use and develop efficiently. Our developers obtain the best from it and gain benefits from it.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.  Custom AngularJS Plugin Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our AngularJS developers develop an excellent custom AngularJS plugin development. Our team matches the business needs. Custom AngularJS plugin development brings out new diversity by interactive and data-driven plugin development.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7.  AngularJS Ecommerce Experts</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We provide AngularJS ecommerce experts services for a faster and easier web application. Our experts provide a brilliant solution that helps you to develop your online business. Hence, AngularJS ecommerce experts transform it to the best.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. AngularJS Consultant</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Bringmark provides AngularJS consultant service. Our skilled experts give you consultancy and guide you more about the development process. In summation, we have AngularJS solutions that are impactful by the AngularJS development.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. AngularJS Game Developers</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>With AngularJS development, you can create a game application. Thus, we provide AngularJS game developers who create splendid, more agile games. Our developers make the best out of it and deliver a fun and thrilling web game.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.AngularJS Support & Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We provide AngularJS support and maintenance that goes through the entire development service to check the application is bug-free and impeccable.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default CodeIgniteDevelopers