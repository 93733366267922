import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const MagnetoDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Magneto Developers</Typography>

<Typography >Hire Magento Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/lx6ixnavwl5hlgaaphhp" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Magento is a prominent and dynamic open-source e-commerce platform that empowers businesses to create and manage online stores with remarkable efficiency and versatility. With an extensive set of tools and features, Magento caters to a diverse range of digital commerce needs. From crafting visually appealing storefronts that resonate with brand identities to orchestrating seamless transactions, Magento offers an encompassing solution. Its flexibility shines through extensive customization options, enabling businesses to tailor their online presence to their unique requirements. Moreover, Magento's scalability ensures that it caters equally well to startups and established enterprises, adapting effortlessly as businesses expand. As a comprehensive e-commerce solution, Magento remains at the forefront, offering the tools and technology needed to thrive in the ever-evolving world of online commerce.</Typography>

    <Typography style={{marginTop:'40px'}}> A compelling justification for trusting in Magento is the staggering adoption rate, with approximately 150,000 e-commerce businesses having embraced this platform. This user base spans from small boutique stores to expansive multinational corporations, showcasing Magento's scalability and versatility. Given its widespread usage, the supportive community of users associated with this open-source system boasts a comprehensive understanding of its nuances in website implementation. This proves advantageous when navigating uncommon or lesser-known issues, offering valuable assistance to both you and your Magento Developer.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Magento Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Understanding Business Requirements</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Start by thoroughly understanding your business requirements and objectives for the Magento website. This includes understanding the target audience, products or services, and unique selling points.
</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Selecting the Right Magento Version</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We help you to Choose the appropriate version of Magento (Magento 1 or Magento 2) based on the project's requirements and the client's goals.We recommended Magento 2 for new projects due to its improved features and security.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Customization and Extensions</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Customize Magento to meet specific project requirements and integrate necessary extensions and modules. Be cautious with the number of extensions to avoid conflicts and performance issues.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Regular Backups</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Team of Our Magento Developer Set up regular backups of your website and database to ensure data recovery in case of emergencies or data loss.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.Monitoring and Analytics</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Implement website monitoring and analytics tools to track website performance, user behavior, and sales data. Use this data to make informed decisions for further optimization.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Theme Integration </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Integrate and customize Magento themes to align with the client's brand identity and design guidelines.</Typography>

 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Perfomance optimization </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Optimize website performance by implementing caching strategies, code optimization, and image compression techniques.</Typography>

 
 <Typography variant='h4' marginTop={5} fontSize={22}>8.Testing and Quality Assurance </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Conduct thorough testing, including functional testing, cross-browser testing, and performance testing, to identify and resolve issues before launch.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Security</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Implement security best practices to protect the website from potential vulnerabilities and security threats. Keep Magento and extensions up-to-date with security patches.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>10. Magento E-commerce Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Customized design and development of Magento-based online stores tailored to the client's specific business requirements. Creation of visually appealing and user-friendly e-commerce websites.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default MagnetoDevelopers