import React from 'react';
import './Research.css';


export default function Research() {
  return (
    <div className='research-main'>
    <div className='provide'>
        <h1> How We Provide <br/><strong>Bringmark</strong></h1>
        <p> Take advantage of our knowledge, learn from third party experts and find solutions that address your specific business problems.</p>
    </div>
       

<div className="cardcontent4">
<div className="research">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/reasearch/u1098xbkg5ebvqcintku'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> E-books</h5>
<p>Retail Demand Forecasting in 2023 and Beyond</p>
</div> 
</div>

<div className="research">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/reasearch/jmkwjkdtueuelkaml3yv'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Report </h5>
<p>Prime Day 2: A Harbinger of the Upcoming Battle for Consumer Spend!</p>
 </div>
</div>

<div className="research">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/reasearch/tbw41frvt49zhncvt4ya'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Report </h5>
<p> The Amazon Canary in the Coal Mine</p>
</div>
</div>

<div className="research">
<img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697466190/Resources/Research/tzgqekbt6knhq3khmnwg.png" width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> White Papers 2022 </h5>
<p> How to move toward a more data-driven decision-making approach </p>
</div>
</div>

<div className="research">
<img src={'https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/aenqx2scj81toyqpup7i'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> White Papers </h5>
<p> Automated Product Attribution </p>
</div>
</div>

<div className="research">
<img src={'https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/iwb7mqwosvrlwn6dv5vt'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Report </h5>
<p> How to be an inflation winner - Pricing imperatives for fashion retailers in a post-COVID world </p>
</div>
</div>


         </div>
            </div>   
    
  );
}