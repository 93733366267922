import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './arvr.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/imageslider/gykja7g9i6nehawt34rr",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/imageslider/jv5po2zrn5dudo6ptbhu",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/imageslider/kffvkvlzyip96f3tkbpn",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/imageslider/eoddi3u1ykyfmlv18bh1",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/imageslider/ykf57q0exu0hdmkca9ul",


];

function DataAnalytics() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>Data and Analytics</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white"}}>
    At BringMark we take pride in empowering our valued customers with a comprehensive suite of cutting-edge data processing and analysis solutions. Our mission is to facilitate informed decision-making across various business aspects, enabling clients to stay ahead in a rapidly changing marketplace. Through a seamless collaboration, our clients gain transformative insights that allow them to reimagine their strategies, optimize operations, and effectively track their company's progress.

By leveraging our premium-quality data analysis services, businesses can extract valuable insights from vast datasets with minimum effort. We understand the significance of data-driven decision-making, and our cost-effective solutions ensure that our clients receive the most value from their data.

At the core of our approach lies a commitment to our clients' success and growth. With BringMark as a trusted partner, businesses can confidently navigate the challenges of the ever-evolving market landscape. We empower our clients to harness the power of data-driven strategies, enabling them to drive innovation, achieve sustainable growth, and stay competitive in their respective industries.

Our dedication to excellence, continuous innovation, and personalized approach make us more than just a service provider – we become a strategic partner, invested in our clients' success. Together, we navigate the complexities of data analysis, uncover hidden opportunities, and unlock the full potential of their business.

BringMark stands ready to support businesses of all sizes and industries in their pursuit of data-driven success. Our advanced solutions and expertise ensure that clients gain a competitive edge and a clear vision of the path to success in an ever-changing business landscape. Embrace the transformative power of data with BringMark and unlock the true potential of your business.





        </Typography>

        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/brffeurspufppyj6fosc"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/ecpwi08qf8yh1amdm1hh"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/qkhjaxhuvfubyxvqzkzy"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/c0zfgtapagnvmtyjsmaz" 
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/mmskadcsikxztawekxqp"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/lvidtrnjpuemuqhrnr2h"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/wy2ooknzjjwiohtnpp8f"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/adblpfyoe0mguuvkr0dr" 
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/zhstoxpzjbclbsn0d9fv"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/faey6qqelhcol2zbnneg"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/wrbxmmbxbh6g0wp6cjo6"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/marque/faq3uwpbkkdica4ycfdm" 

  />
</div>

        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Data-Driven Insights: Empowering Your Business with Bringmark


</Typography>
<Typography  style={{color:"#545357"}}>

At BringMark, we specialize in providing data and analytics solutions that empower your business with data-driven insights. Our comprehensive range of data processing and analysis services enables you to make informed decisions, optimize operations, and gain a deeper understanding of your business's performance. By collaborating with us, you'll gain transformative insights, reimagine strategies, and track progress, ensuring sustainable growth and enhanced competitiveness. With our expertise in data and analytics, you can harness the power of information to drive innovation and achieve success in today's data-driven world </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/gif/sfoa3ahn6y2p1cczzury" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Welcome to the world of data-driven excellence, where BringMark stands at the forefront of transformative data and analytics projects. Our extensive portfolio showcases the remarkable impact of data in unlocking the full potential of businesses across various industries. From dynamic marketing analytics that amplify brand reach to personalized customer insights that foster lasting connections, our data-driven solutions revolutionize how companies operate and thrive.

With BringMark as your trusted partner, you'll experience the seamless integration of advanced data technologies, unveiling hidden opportunities that propel your success to new heights. Our team of skilled analysts and data scientists is committed to understanding your unique business needs, ensuring that every project is meticulously tailored to deliver actionable insights and tangible results.

Embrace the power of data and analytics with BringMark, and ignite a new era of business intelligence that captivates, inspires, and drives unparalleled growth. Discover how data becomes your strategic advantage as we unleash the true potential of your business through insightful and data-driven decision-making. Join us on a transformative journey of success, where every move is guided by data, and every outcome surpasses expectations. Experience the undeniable allure of data-driven excellence with BringMark, and witness how data can transform your business into a driving force in today's dynamic marketplace.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/gif/wkh3vq2rvyngjmbh8xyv" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Seize Data's Power: Effortlessly Transition to Power BI from Legacy Software. Unlock the full potential of data-driven decisions with the transformative capabilities of Power BI.
</Typography>
<Typography  style={{color:"#545357"}}>

Seamless Migration to Power BI: Unlock the Full Potential of Your Data. Connect effortlessly to Excel, Text/CSV, Access DB, Oracle Database, IBM DB2, MySQL DB, and PostgreSQL DB through native Microsoft connectors or Power BI API integration. For bespoke data sources, our team develops custom connectors to meet your unique requirements. With its cloud-based solution, Power BI eliminates the need for infrastructure expenditure, offering scalability as your business grows.

Our dedicated team is here to guide you through every step of the ambitious migration from legacy to Power BI. Embrace the freedom from data silos and gain comprehensive insights that drive informed decisions. Power BI empowers your organization to harness data's true power, revolutionizing your analytics capabilities.

Experience the transformative impact of data-driven decision-making with our expert support throughout the migration journey. Partner with us today and unleash the future of analytics with the unmatched potential of Power BI by your side. </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Discover the synergy of data, analytics, and cutting-edge technology with BringMark. Our expert team wields advanced data processing and analytical capabilities for data-driven decision-making that fuels success.

At BringMark, staying ahead of technology trends is our priority. We embrace the latest tools and methodologies to extract valuable business intelligence, providing tailored solutions for your unique needs.

Our streamlined workflow ensures maximum efficiency, from meticulous data collection and deep analysis to intuitive visualization and strategic recommendations. Uncover hidden opportunities, optimize operations, and drive innovation through the power of data.

Experience the unmatched potential of data and technology at BringMark's site. Explore a wide range of data and analytics services, empowering your business with actionable insights for growth. Unlock the true potential of your data and transform your business into a data-driven powerhouse.

Join us on a journey of data-driven excellence, where intelligence powers every decision, and success is achievable. Embrace a future of data-driven empowerment with BringMark as your strategic partner. Unleash your business's full potential through the power of data and analytics.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unleashing Business Potential: Empowering Growth with BringMark's Data Insights

</Typography>
<Typography  style={{color:"#545357"}}>

Welcome to a world of endless possibilities with BringMark's data and analytics solutions, empowering your business to reach new heights. Our advanced data processing and analysis services enable data-driven decision-making, optimizing strategies, and revealing invaluable business insights. Partner with us to unlock the true potential of your data, redefining your business approach, and tracking progress for sustainable growth and a competitive advantage. With BringMark's expertise in data and analytics, you'll harness the power of information, driving innovation and achieving remarkable success in today's data-driven landscape. Step into the future of business intelligence, where intelligence is the key to unlocking your business's true potential. Together, we embark on a journey of data-powered growth, and with BringMark as your strategic partner, your business thrives with unmatched possibilities. Welcome to a world where data insights drive success and your business unlocks its full potential </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/gif/kcsr7n0jmvj3cinwynj5" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
At BringMark, we envision a data-powered future, where cutting-edge technology and advanced analytics converge to unlock transformative insights. Our expert team leverages the latest data processing capabilities, enabling businesses to make informed decisions that pave the way for sustainable success.

With our forward-thinking approach, BringMark stays at the forefront of technology trends, leveraging the most advanced tools and methodologies to extract invaluable business intelligence. Tailored solutions cater to your unique needs, providing a competitive edge in the dynamic data-driven landscape of the future.

Our streamlined workflow ensures efficiency, from meticulous data collection and deep analysis to intuitive visualization and strategic recommendations. Discover hidden opportunities, optimize operations, and drive innovation as we empower your business with the transformative power of data.

Experience the boundless potential of data and analytics services at BringMark's website. Explore a wide range of solutions designed to empower your business with actionable insights for growth and adaptability. Embrace a future where data becomes the cornerstone of every decision and unlocks unparalleled success.
        </Typography>

        {/* <ProjectCard/> */}

<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Data%20And%20Analytics/gif/ugyn4ekddldxq0w4jjmv" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unlocking Data Excellence: Why Choose BringMark for Cutting-Edge Data and Analytics Services
</Typography>
<Typography  style={{color:"#545357"}}>

Bringmark stands out as the ideal choice for data and analytics services due to our commitment to excellence and the unmatched value we bring to our clients. We leverage cutting-edge technology and advanced data processing capabilities to empower businesses with transformative insights. Our dedicated team of experts ensures seamless data-driven decision-making, optimization of strategies, and in-depth business intelligence.

Choosing BringMark means embracing a future of data excellence, where we unlock the full potential of your data and revolutionize your business approach. We prioritize personalized solutions, tailoring our services to your unique needs to drive sustainable growth and maintain a competitive edge. With our expertise in the latest technology trends, you'll gain a strategic advantage, driving innovation and achieving unparalleled success in today's rapidly evolving business landscape.

At BringMark, we are more than service providers; we become strategic partners invested in your success. Trust us to navigate the complexities of data and analytics, unleashing the true potential of your business. Experience the transformative power of data-driven decisions with BringMark, and propel your business towards new heights of excellence.</Typography>
</div>


</div>

      
    </div>
  )
}

export default DataAnalytics