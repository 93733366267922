import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Attributetech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Exceptionally Classified`
const featuresDescription = `Our automated product tagging software includes a library of 500+ industry-specific category tags. Moreover, the solution can be tailored to align perfectly with your unique requirements.`
const feauturesimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697369062/Solutions/AttributeTech/guddessjcjhcfabiuest.png"

const featuresTitle2 = `Comprehensive Categorization`
const featuresDescription2 = `Our patent-pending image classification system will revolutionize your marketing efforts by automatically categorizing images on your website (or other platforms) and assigning them precise values.`
const feauturesimage2 =require('../../assets/solution/demandplanningout.png')

const featuresTitle3 = `Customized Categorization`
const featuresDescription3 = `We have the capability to align our extensive tagging solution with your company's distinct taxonomy and can even propose enhancements to that taxonomy by considering the natural language expressions used by your customers.`
const feauturesimage3 =require('../../assets/solution/demandplanningout.png')

const featuresTitle4 = `Auto Learning`
const featuresDescription4 = `Algorithms that continually enhance their attribute tagging process through continuous integration of new inputs.`
const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Acquire Knowledge`
const weeksDescription = `Tailor and align product data attributes and imagery to align with your business's specific taxonomy, taking into account the natural language preferences of your customers.`
const weeksimage =require('../../assets/solution/demandplanningout.png')

const weeksTitle2 = `Develop`
const weeksDescription2 = `Construct precise automated product tagging, utilizing AI and cutting-edge Neural Network models to create internal attributes, SEO-friendly tags, and rich catalog data..`
const weeksimage2 =require('../../assets/solution/demandplanningout.png')

const weeksTitle3 = `Supervise`
const weeksDescription3 = `Persist in refining attribution by incorporating neural models and soliciting feedback from merchants to align with your business taxonomy.`
const weeksimage3 =require('../../assets/solution/demandplanningout.png')

const weeksTitle4 = `Implement`
const weeksDescription4 = `Roll out across various business systems, encompassing Planning and Allocation, Web Search and Navigation, Personalized Marketing, Store Merchandising, and more.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `Grocery.`
const message1 = `Build high-quality, product attributes instantly for quick product allocation at the right store..`
const image1 =require('../../assets/solution/supplychainout.png')

const title2 = `Jewelrys`
const message2 = `Create tailored shopping experiences for customers by ensuring they discover the perfect product at the right moment.`
const image2 =require('../../assets/solution/supplychainout.png')

const title3 = `CPG`
const message3 = `Create detail tags and metadata to automate the process of product tagging and discoverabilit`
const image3 =require('../../assets/solution/supplychainout.png')
// the end of cards




const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/jzw5t3txuzmvalajtds6")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};



const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `"Empowering Your Business with Resources"`
const message = `Explore comprehensive insights into the most recent trends in the market, cutting-edge technology developments, and how your industry peers are effectively addressing their most significant product tagging challenges through Bringmark advanced attribute tagging solutions.`;

function Attributetech() {
    const theme = useTheme();
    const navigate = useNavigate()
const request =() =>{
  navigate('/contactus')
}


  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>AttributeTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>Energize your business by leveraging automated product tagging and attribution.</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Effortlessly augment your catalog with thousands of attributes, achieving optimal product attribution accuracy and maximizing your return on investment (ROI) with minimal human involvement.          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>AttributeTech</Typography>
      </div>

      
      </Grid>

      <Grid style={{marginTop:"10vh",display:"flex",justifyContent:"flex-start" }}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>AttributeTech Advantage</h2>
 
      </Grid>
<Grid style={{width:"100%",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      Our user-friendly and intuitive retail assortment planning software empowers your team to create superior assortment plans with minimal time investment. Powered by AI, this technology allows you to analyze your data for actionable insights that drive sales and customer satisfaction.      </p>

          <p style={{marginTop:"15px",color:"white"}}>
          Furthermore, our software facilitates improved new product launches and SKU optimization, leading to successful new product introductions. AI-driven processes provide clear visibility into underperforming SKUs, enabling efficient SKU rationalization and continual assortment improvement.

</p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697365265/Solutions/AttributeTech/bpc50wlocautdgeche8i.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Steady Data</h3>

              <Typography>
Effortlessly generate precise product feeds using our automated product tagging software, reducing the expenses associated with manual tagging and eliminating the inaccuracies often associated with manual attribution processes.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Elevated Merchandise Return on Investment (ROI)</h3>
              <Typography>
Enhance your grasp of your company's performance and discover avenues to boost margins and streamline costs for data-driven decision-making. Solutions that benefit from product attribution encompass next-generation onsite or offsite search optimization, website navigation, supernavigation, facet optimization, assortment planning, pricing, allocations, forecasting, markdown strategies, and more</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/dzzmbxohg2ncypkwc8gn" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Amplify Productivity</h3>
              <Typography>Efficient activations enable result-driven product launches. AI processes lead to clear views of underperforming SKUs allowing efficient SKU rationalization, cleaning up your assortment as you go.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}> Efficiency and Accuracy for Your Business</h3>
              <Typography>Generate precise product feeds swiftly and effortlessly with our automated product tagging software, cutting down on the expenses and inaccuracies often tied to manual tagging processes.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}></h1>
        <Typography>Our revolutionary solution harnesses Computer Vision to analyze product images and autonomously generates relevant attributes for online catalogs. We also tap into all categorized or free-text information associated with each product, even down to the color level, ensuring comprehensive and accurate product descriptions.
</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697369062/Solutions/AttributeTech/guddessjcjhcfabiuest.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374225/Solutions/AttributeTech/xchempmndkvjsy3gu76m.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374321/Solutions/AttributeTech/iury19p6fev04dv4wsuu.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374374/Solutions/AttributeTech/rfebtryknnnz2hoco51d.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>AttributeTech Features</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374583/Solutions/AttributeTech/s0caghjtevgurtodrsdj.png" width={"120px"} height={"100px"} alt=''/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AttributeTech/ekq1v4i5qhxwplwaqy0j" alt=''  width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AttributeTech/aoycfc3xbmoyiy0rv5tu" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374867/Solutions/AttributeTech/ojeq7yqlhk8e4m6zrqfj.png" alt=''  width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>~60%</h2>
                </div>
                <p style={{color:"white"}}>Savings on manual attribution.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >2-3%</h2>
                </div>
                <p style={{color:"white"}}>growth in bottom-line with improved assortment optimization powered by attribute data</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>5-10%</h2>
                </div>
                <p style={{color:"white"}}>AOV uplift with better product discovery</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>5%</h2>
                </div>
                <p style={{color:"white"}}>Improvement in e-commerce conversions.</p>

        </div>
    </Box>

    <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Unlocking the Power of Resources.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Delve into comprehensive insights about current market trends, technological advancements, and how your industry peers are effectively addressing their most significant product tagging challenges with Bringmark cutting-edge attribute tagging solutions.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697375539/Solutions/AttributeTech/fna1qzn1v1krvwxevvst.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
      </Grid>
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697374867/Solutions/AttributeTech/ojeq7yqlhk8e4m6zrqfj.png" width={"120px"} height={"100px"} alt=''/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697375037/Solutions/AttributeTech/ixkxmpiyzx19i2pargln.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697375247/Solutions/AttributeTech/qwkraaeoyq9xtq8jzgoc.png" width={"140px"} height={"120px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697375368/Solutions/AttributeTech/odh7w9rbiwss16rrfucp.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Lead the Automated Product Tagging Revolution with Customized Attribute Tags.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Our automated product tagging software offers a swift, precise, and scalable method to enrich your product information significantly in just a few simple steps. We empower businesses to boost their sales by simplifying the process for shoppers to locate what they desire on your website or mobile app.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 

    </div>
  );
}

export default Attributetech;
