import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
const green = '#14C852';
const white = '#fff';

const black100 = 'rgba(0,0,0,1)';
const black90 = 'rgba(0,0,0,0.9)';
const black80 = 'rgba(0,0,0,0.8)';
const black70 = 'rgba(0,0,0,0.7)';
const black60 = 'rgba(0,0,0,0.6)';
const black50 = 'rgba(0,0,0,0.5)';
const black40 = 'rgba(0,0,0,0.4)';
const black30 = 'rgba(0,0,0,0.3)';
const black20 = 'rgba(0,0,0,0.2)';
const black10 = 'rgba(0,0,0,0.1)';

const transition = '0.8s cubic-bezier(0.165, 0.84, 0.44, 1)';

const Wrapper = styled.div`
//   position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${black10};

  margin-top:20px;
  margin-bottom:20px;
`;

const Widget = styled.div`
  width: 100%;
  max-width: 430px;
  height: 452px;
  border: 1px solid ${black20};
  background-color: ${white};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 30px 0px rgba(50, 50, 50, 0.32);
  transition: ${transition};
`;

const Photo = styled.div`
  width: 100%;
  height: 300px;
  background: url('https://media.blogto.com/articles/2018290sb-reservebar.jpg?cmd=resize_then_crop&quality=70&w=2048&height=1365') no-repeat center / cover;
  position: relative;
  transform: scale(1);
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${black60};
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
  }
`;

const Details = styled.div`
  padding: 30px;
  position: relative;
`;

const Badges = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
`;

const Badge = styled.div`
  font-size: 12px;
  display: inline-block;
  border: 1px solid ${black20};
  color: ${black50};
  width: 40px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px;
`;

const RatingBadge = styled(Badge)`
  background-color: ${green};
  border-color: ${green};
  color: ${white};
`;

const Name = styled.div`
  font-size: 18px;
  color: ${black80};
  font-weight: 600;
  width: 100%;
  padding: 0 80px 0 0;
`;

const Type = styled.div`
  font-size: 12px;
  color: ${black60};
`;

const Info = styled.div`
  span {
    color: ${black70};
    font-size: 14px;
    display: block;
    width: 100%;
    margin-bottom: 4px;
    &:first-of-type {
      margin-top: 15px;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  tr td {
    color: ${black70};
    line-height: 25px;
    &:first-child {
      color: ${black40};
    }
  }
`;

const Overlay = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 100%;
  background-color: ${black60};
`;

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  margin: 0 auto;
  padding: 10px 0;
  width: 70%;
  background-color: ${green};
  border-radius: 4px;
  color: ${white};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
  left: 0;
  right: 0;
  top: -40px;
  transition: ${transition};
`;

const WidgetHover = styled(Widget)`
  &:hover {
    ${Button} {
      top: 32px;
    }
    ${Photo} {
      height: 100px;
      transform: scale(1.3);
      &:after {
        opacity: 1;
      }
    }
    ${Overlay} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

function ProjectCard(){
    const navigate = useNavigate()

    function iot(){
        navigate('/Iotprojects')

    }
    return(
        <div style={{flexDirection:"row",display:"flex",justifyContent:"space-evenly",flexWrap:"wrap",marginBottom:"40px"}}>

        <div>
  <Wrapper>
    <WidgetHover>
      <Photo />
      <Button onClick={() => iot()}>More details</Button>
      <Details>
        <Badges>
          <Badge>$$$$</Badge>
          <RatingBadge>4.3</RatingBadge>
        </Badges>
        <Name>Prospect Restaurant</Name>
        <Type>Cafe, Bakery</Type>
        <Info>
          <span>193 Fairchild Drive, Mountain View - CA</span>
          <span>15 minute walk</span>
        </Info>
        <Table>
          <tbody>
            <tr>
              <td>Type</td>
              <td>Brunch, Lunch, Dinner</td>
            </tr>
            <tr>
              <td>Alcohol</td>
              <td>Cocktails</td>
            </tr>
            <tr>
              <td>Credit-cards</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Wi-Fi</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Outdoor Seating</td>
              <td>No</td>
            </tr>
          </tbody>
        </Table>
      </Details>
    </WidgetHover>
  </Wrapper>
  </div>

  <div>
  <Wrapper>
    <WidgetHover>
      <Photo />
      <Button onClick={() => iot()}>More details</Button>
      <Details>
        <Badges>
          <Badge>$$$$</Badge>
          <RatingBadge>4.3</RatingBadge>
        </Badges>
        <Name>Prospect Restaurant</Name>
        <Type>Cafe, Bakery</Type>
        <Info>
          <span>193 Fairchild Drive, Mountain View - CA</span>
          <span>15 minute walk</span>
        </Info>
        <Table>
          <tbody>
            <tr>
              <td>Type</td>
              <td>Brunch, Lunch, Dinner</td>
            </tr>
            <tr>
              <td>Alcohol</td>
              <td>Cocktails</td>
            </tr>
            <tr>
              <td>Credit-cards</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Wi-Fi</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Outdoor Seating</td>
              <td>No</td>
            </tr>
          </tbody>
        </Table>
      </Details>
    </WidgetHover>
  </Wrapper>
  </div>


  <div>
  <Wrapper>
    <WidgetHover>
      <Photo />
      <Button onClick={() => iot()}>More details</Button>
      <Details>
        <Badges>
          <Badge>$$$$</Badge>
          <RatingBadge>4.3</RatingBadge>
        </Badges>
        <Name>Prospect Restaurant</Name>
        <Type>Cafe, Bakery</Type>
        <Info>
          <span>193 Fairchild Drive, Mountain View - CA</span>
          <span>15 minute walk</span>
        </Info>
        <Table>
          <tbody>
            <tr>
              <td>Type</td>
              <td>Brunch, Lunch, Dinner</td>
            </tr>
            <tr>
              <td>Alcohol</td>
              <td>Cocktails</td>
            </tr>
            <tr>
              <td>Credit-cards</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Wi-Fi</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Outdoor Seating</td>
              <td>No</td>
            </tr>
          </tbody>
        </Table>
      </Details>
    </WidgetHover>
  </Wrapper>
  </div>
  </div>
)};

export default ProjectCard;
