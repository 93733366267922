import React from 'react';
import './webproject.css'
function WebProjects(){
  return (
    <>
      {/* @TODO: Move away from custom props before injecting the polyfill, as it can't handle it */}
      {/* <input type="checkbox" id="debug" /> */}
      
      <header>
        <div>
          <h2>Product</h2>
        </div>
      </header>
      
      <main>
        <ul id="services-cards">
          <li className="services-card" id="card_1">
            <div className="card__contentwebpro">
              <div>
                <h2>Card One</h2>
                <p className='main_para'>Step into the extraordinary world of 3D websites, where BringMark emerges as a true pioneer, reshaping the very essence of how we engage with products in the digital landscape. A departure from the mundanity of static images and traditional descriptions, BringMark's technological marvel introduces an entirely new realm of interactivity to the online shopping experience. With their revolutionary integration of 3D visualization, they beckon users into an immersive universe where products transcend the boundaries of the screen, offering an experience that mimics reality itself..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img  src="https://tympanus.net/codrops/wp-content/uploads/2020/11/betheme-animated-counter.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_2">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Two</h2>
                <p className='main_para'>Envision a landscape of endless possibilities, where every product becomes an odyssey of discovery. BringMark's 3D websites not only allow users to view products from diverse angles but also to manipulate and interact with them in ways that mirror real-life engagement. Whether it's the sleek contours of a state-of-the-art sports car or the intricate craftsmanship of an artisanal timepiece, these 3D representations grant an unparalleled level of insight into the intricacies that define each product's uniqueness..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://images-workbench.99static.com/01PSl8UpQVKrTMphI6qSNTQXFqw=/http://s3.amazonaws.com/projects-files/119/11912/1191246/e4466be8-f88e-40bd-b49d-ca55af53c518.gif" alt="Image description" />
              </figure>
            </div>
          </li>
     
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>Yet, BringMark's innovation doesn't stop at enhancing existing products; it extends to empowering users to be creators themselves. Acknowledging that innovation knows no bounds, their platform enables direct collaboration between users and BringMark's team to turn conceptual visions into immersive virtual realities. The 3D website becomes a canvas for these ideas, a space where imagination is brought to life with dynamic and interactive representations..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://i.pinimg.com/originals/1c/61/78/1c61788a7116f649d5f18d5730d85ba6.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>Imagine being an entrepreneurial visionary with a groundbreaking product concept. With BringMark's 3D website technology at your disposal, you're not merely showcasing a product; you're unveiling an experience. Through this platform, you can offer potential customers a journey through the heart of your creation, enabling them to truly understand the essence and potential of your innovative contribution..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://i.pinimg.com/originals/43/79/da/4379daa03042b6a9ae80b0b16a71b695.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>In the heart of BringMark's 3D websites lies the essence of interactivity, where users transition from passive observers to active participants. The platform goes beyond visualization, inviting users to "try on" virtual clothing, personalize products to their liking, and even integrate virtual furniture into their living spaces. This immersive engagement not only instills confidence in purchasing decisions but also significantly reduces the risk of post-purchase dissatisfaction..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://i.pinimg.com/originals/d2/60/4e/d2604ed9b37ddf576769a8e522a01c19.gif" alt="Image description" />
              </figure>
            </div>
          </li>


          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>For those moments when personal guidance is essential in refining your product concepts or understanding the intricacies of the 3D website experience, BringMark extends its expertise. A dedicated team of experts stands ready to engage with you, discussing ideas, providing design insights, and ensuring seamless alignment between your vision and the captivating world of 3D product representation..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://images-platform.99static.com//A0ukpgetjZNMr9lmVHzuJkk2bLs=/0x0:1000x1000/fit-in/500x500/projects-files/124/12430/1243037/a89a6600-7a42-4b63-98c7-d6a0bce8d6e4.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>In a world on the brink of e-commerce transformation, BringMark's 3D websites stand as a beacon of innovation. Conventional online shopping can often feel detached and impersonal, but BringMark bridges this gap by fostering a deep connection between customers and products. Through their platform, customers don't just view products; they immerse themselves, understanding features, exploring intricacies, and making decisions with newfound clarity..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://cdn.dribbble.com/users/533798/screenshots/2526921/cbfa.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>Embrace the vanguard of online shopping through BringMark's visionary 3D website technology. Their commitment to pushing the boundaries of interactivity and engagement sets them apart as pioneers. Whether you're a discerning shopper in search of an elevated experience or an aspirational entrepreneur ready to bring your product to life, BringMark's 3D websites unveil an uncharted expanse of possibilities waiting to be explored..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://support.eventx.io/hc/article_attachments/6356667176345/3D-capture.gif" alt="Image description" />
              </figure>
            </div>
          </li>



          <li className="services-card" id="card_4">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Four</h2>
                <p className='main_para'>If you're prepared to venture into a world where products transcend the confines of screens and imagination is given tangible form, BringMark extends a hearty invitation. Immerse yourself in their 3D website, immerse yourself in the captivating world of product experiences, and stand witness to how their innovation is redefining the way we connect with the objects that enrich our lives. The next chapter in your journey of online shopping and product creation awaits, courtesy of BringMark..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://cdn.dribbble.com/users/1468665/screenshots/4187651/dribble_shot_.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__contentwebpro">
              <div>
                <h2>Card Three</h2>
                <p className='main_para'>However, BringMark's prowess extends beyond the realm of visual appeal. Through their ingeniously designed 3D websites, they seamlessly weave comprehensive product information into the fabric of the user experience. As users navigate the platform, they're not just spectators; they're active participants, equipped with real-time specifications, user reviews, and interactive demonstrations. This dynamic approach transforms passive browsing into an engaging journey, where every feature and detail can be scrutinized and appreciated..</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img  src="https://images-workbench.99static.com/B24ZSxy2wGCbS1Lf1ySixKfb3jU=/http://s3.amazonaws.com/projects-files/144/14418/1441829/6fffab5c-3fd9-425b-8d88-84ade2e8376f.gif" alt="Image description" />
              </figure>
            </div>
          </li>
        </ul>
      </main>
      
      <aside>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem repellat non ratione eum provident quaerat obcaecati dicta
          autem voluptates sit cum quis distinctio, atque sint commodi reprehenderit perspiciatis iure velit molestiae eos molestias
          ipsa nihil quia?
        </p> */}
      </aside>
    </>
  );
};

export default WebProjects;
