import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import image1 from '../../assets/IndustryWeServe/wtc-designers.svg';
import image2 from '../../assets/IndustryWeServe/wtc-developers.svg';
import image3 from '../../assets/IndustryWeServe/customer-review.png';
import image4 from '../../assets/IndustryWeServe/wtc-project-managers.svg';
import image5 from '../../assets/IndustryWeServe/wtc-projects.svg';
import image6 from '../../assets/IndustryWeServe/wtc-product-managers.svg';
import image7 from '../../assets/IndustryWeServe/bat-hire-quickly.svg';
import image8 from '../../assets/IndustryWeServe/bat-level-above.svg';
import image9 from '../../assets/IndustryWeServe/bat-the-top.svg';
import image10 from '../../assets/IndustryWeServe/hire-dedicated-model.png';

const IndustriesWeServe = () => {
    const data = [
        { image: image1, text: 'Retail, Ecommerce',subText: 'High-level access to cluster health, features and issues.' },
        { image: image2, text: 'Education & e-learning',subText: 'High-level access to cluster health, features and issues.' },
        { image: image3, text: 'Healthcare & Fitness',subText: 'High-level access to cluster health, features and issues.' },
        { image: image4, text: 'Logistics & Distribution',subText: 'High-level access to cluster health, features and issues.' },
        { image: image5, text: 'Social Networking',subText: 'High-level access to cluster health, features and issues.' },
        { image: image6, text: 'Real Estate',subText: 'High-level access to cluster health, features and issues.' },
        { image: image7, text: 'Travel & Hospitality',subText: 'High-level access to cluster health, features and issues.' },
        { image: image8, text: 'Food & Restaurant',subText: 'High-level access to cluster health, features and issues.' },
        { image: image9, text: 'On-Demand Solutions',subText: 'High-level access to cluster health, features and issues.' },
    ];

    return (
        <Box marginBottom={30} sx={{ width: '90vw', margin: '0 auto' }}>
            <Box sx={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant='h3' fontSize={{ xs: 30, md: 50 }} sx={{  color: '#d2ff52', fontWeight: '600', letterSpacing: '3px' }}>
                    Industries We Serve
                </Typography>
                <Typography sx={{ color: 'white' }}>
                Tailored Solutions to Drive Growth and Success
                </Typography>
            </Box>
            <Grid container spacing={3} sx={{ justifyContent: 'center',marginBottom:"20px",cursor:"pointer" }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent:"flex-start", padding: 2,backgroundColor:"#262626" }}>
                            <Box sx={{ position: 'relative', width: '100%',}}>
                                <img src={item.image} alt={item.text} style={{ width: '30px', height: '30px',border:"1px solid #606060",padding:"7px",borderRadius:"5px", position: 'absolute', top: 8, left: 18 }} />
                            </Box>
                            <Typography variant='body1' sx={{ marginTop: 6, justifyContent:"flex-start",display:"flex",textAlign:"start" }}>
                                {item.text}
                            </Typography>
                            <Typography variant='p' sx={{  justifyContent:"flex-start",display:"flex",textAlign:"start",fontSize:"16px",color:"#A1A1A1",paddingRight:'20px',paddingLeft:"20px" }}>
                                {item.subText}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default IndustriesWeServe;

