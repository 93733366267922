
import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};
const formData = new FormData();

export const convertData = (data) => {
  // const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    // if (value && value.uri) {
    //   console.log(value, "datata");

    //   formData.append(key, {
    //     name: "file",
    //     type: value.type,
    //     uri: value.uri,
    //   });
    // } else {
    formData.append(key, value);

    return formData;
    // formData.append(key, value);
    // }
  });

  // const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   console.log(data[key], "data in form");
  //   formData.append(key, data[key]);
  // });
  // formData.append("demo", "123");
  // console.log(formData, "form data in conv");

  // formData.append("file", null);
  return formData;
};
