import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import './weoffer.css';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    verticalAlign: 'middle',
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#d2ff52',
    width: '90%',
    maxWidth: '1000px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '10px',
  },
  dynamicText: {
    display: 'inline-block',
    fontSize: '2.2rem',
    fontWeight: 'bold',
    animation: '$textAnimation 3s infinite',
    lineHeight: '1.2',
    margin: '0',
    padding: '0',
    overflowWrap: 'break-word',
    color:"#d2ff52"
  },
  '@keyframes textAnimation': {
    '0%': { opacity: 0.5 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.5 },
  },
}));

const keywords = [
"Web Development", "App Development", "Digital Marketing", "IT Services", "Ads Targeting",
  "Software Development", "Cloud Computing", "Cybersecurity", "Data Analytics",
  "Blockchain Development", "AI and Machine Learning", "AR/VR Development", "Network Solutions",
  "Managed IT Services", "Consulting Services", "Human Resources (HR)", "Recruitment Services",
  "Project Management", "Business Process Outsourcing (BPO)", "Market Research",
  "Business Strategy Development", "Supply Chain Management", "Quality Assurance",
  "Banking", "Insurance", "Investment Management", "Accounting Services", "Tax Preparation",
  "Financial Consulting", "Payment Processing", "Wealth Management", "Credit Card Services",
  "Mortgage Services", "General Healthcare", "Specialized Medical Care", "Dental Services",
  "Mental Health Services", "Telemedicine", "Pharmacy Services", "Nursing and Elderly Care",
  "Medical Research", "Healthcare Consulting", "Healthcare IT Services", "K-12 Education",
  "Higher Education", "Online Learning Platforms", "Tutoring Services",
  "Professional Training and Development", "Educational Consulting", "Language Learning",
  "Certification Programs", "E-Learning Development", "Legal Consulting", "Corporate Law",
  "Intellectual Property Law", "Family Law", "Criminal Defense", "Estate Planning",
  "Immigration Services", "Contract Law", "Dispute Resolution", "Notary Services",
  "Customer Support Services", "Graphic Design", "Video Production", "Photography",
  "Content Creation", "Copywriting", "Branding Services", "Animation and Motion Graphics",
  "UX/UI Design", "Print Design", "Product Design", "Logistics and Shipping", "Freight Services",
  "Courier Services", "Public Transportation", "Taxi and Ride-Sharing", "Airport Shuttle Services",
  "Vehicle Rental", "Maritime Services", "Aviation Services", "Hotel Services",
  "Event Planning", "Tourism Services", "Catering Services", "Travel Agencies",
  "Resort and Spa Services", "Restaurant Services", "Cruise Services",
  "Conference and Meeting Services", "Wedding Planning", "Waste Management", "Recycling Services",
  "Environmental Consulting", "Sustainability Services", "Water Treatment", "Air Quality Monitoring",
  "Renewable Energy Solutions", "Environmental Impact Assessment", "Conservation Services",
  "Eco-Tourism", "Residential Real Estate", "Commercial Real Estate", "Property Management",
  "Real Estate Consulting", "Real Estate Appraisal", "Mortgage Brokerage", "Real Estate Development",
  "Leasing Services", "Facility Management", "Real Estate Investment", "Physical Security Services",
  "Surveillance Systems", "Private Investigation", "Event Security", "Security Consulting",
  "Alarm Monitoring", "Access Control Systems", "Fire Safety Services", "Emergency Response Services",
  "E-Commerce Services", "Retail Consulting", "Merchandising Services", "Point of Sale Solutions",
  "Inventory Management", "Customer Loyalty Programs", "Store Design and Layout", "Product Sourcing",
  "Retail Analytics", "Product Design and Development", "Prototyping Services", "Custom Manufacturing",
  "Mass Production", "Quality Control and Testing", "Packaging Services", "Industrial Engineering",
  "Maintenance and Repair", "Manufacturing Consulting", "Telecommunications", "VoIP Services",
  "Internet Service Providers (ISP)", "Mobile Network Services", "Data Center Services",
  "Satellite Communication", "Broadcasting Services", "Media and Public Relations",
  "Corporate Communication", "Crisis Communication", "Crop Production", "Livestock Management",
  "AgriTech Solutions", "Agricultural Consulting", "Farm Management", "Irrigation Services",
  "Soil Testing", "Agricultural Research", "Organic Farming", "Agricultural Machinery Services",
  "Film Production", "Music Production", "Event Management", "Theater Production",
  "Game Development", "Streaming Services", "Talent Management", "Advertising and Media Buying",
  "Public Relations", "Concert and Festival Services", "Residential Construction", "Commercial Construction",
  "Architectural Design", "Civil Engineering", "Renovation and Remodeling", "Interior Design",
  "Construction Consulting", "Landscaping Services", "Fitness and Wellness", "Personal Training",
  "Nutrition Consulting", "Mental Wellness Programs", "Yoga and Meditation", "Spa and Beauty Treatments",
  "Childcare Services", "Elder Care", "Pet Care Services", "Home Renovation", "Cleaning Services",
  "Pest Control", "Handyman Services", "Appliance Repair", "Gardening Services", "Event Catering",
  "Party Planning", "Professional Organizing", "Career Counseling", "Public Speaking Training",
  "Language Translation", "Voiceover Services", "Cultural Consulting", "Import/Export Services",
  "Supply Chain Consulting", "Procurement Services", "Customs Brokerage", "Trade Show Services",
  "Medical Device Manufacturing", "Pharmaceutical Research", "Biotechnology Services",
  "Veterinary Services", "Sports Management", "Recreation Services", "Adventure Tourism",
  "Outdoor Activities", "Historical and Cultural Tours", "Eco-Friendly Products",
  "Sustainable Fashion", "Luxury Goods", "Art and Craft Services", "Collectibles and Antiques",
  "Furniture Design and Manufacturing", "Home Decor", "Artisanal Products", "Music Therapy",
  "Life Coaching", "Executive Coaching", "Business Coaching", "Personal Coaching"
];

const WeOffer = () => {
  const classes = useStyles();
  const [currentKeyword, setCurrentKeyword] = useState(keywords[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentKeyword(prev => {
        const currentIndex = keywords.indexOf(prev);
        const nextIndex = (currentIndex + 1) % keywords.length;
        return keywords[nextIndex];
      });
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <img
          src={require('../assets/Frame.png')}
          alt="Your Image"
          className={classes.image}
        />
        <div className={classes.overlay}>
          <p className={classes.dynamicText}>
          Generate New Customers with  {currentKeyword}
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default WeOffer;
