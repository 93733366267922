import React from 'react';
import './Videos.css';


export default function Videos() {
  return (
    <div className='videos-main'>
    <div className='videos'>
      <h1>We Leverage Our Retail Expertise <br/><strong>to Grow Your Business</strong></h1>
        <p> At BringMark, we've garnered all our retail experience to provide you the best of solutions.</p>
        <h3> Videos </h3>
    </div>
       

<div className="cardcontent6">
<div className="video">
<img src={require('../Resources/Images/videos1.gif')} width={400} height={230} alt="" />
<h5 style={{textAlign:'justify'}}> BringMark At NRF '23 Big Show</h5>
<p> Event Highlights</p>
</div> 

<div className="video">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/xizphl0dztwujnimdnkd'} width={400} height={230} alt="" />
<h5 style={{textAlign:'justify'}}> NRF '23 Big Ideas Session 1 </h5>
<p> The devil's in the data: AI-driven allocation in a world of constant change produced by BringMark </p>
 </div>


<div className="video">
<img src={require('../Resources/Images/videos3.gif')} width={400} height={230} alt="" />
<h5 style={{textAlign:'justify'}}> NRF '23 Big Ideas Session 2 </h5>
<p> Overcoming price challenges & anticipating ahead with AI produced by BringMark </p>
</div>


<div className="video">
<img src={require('../Resources/Images/videos4.gif')} width={400} height={230} alt="" />
<h5 style={{textAlign:'justify'}}> AttributeTech </h5>
<p> Create Accurate Attributes with AttributeTech </p>
</div>


<div className="video">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/f9gikn2jjhalycnqz18k'} width={400} height={230} alt="" />
<h5 style={{textAlign:'justify'}}> Merchandising</h5>
<p> A Glimpse of demistifying merchandising promotions to understand why the... </p>
</div>
   </div>
      </div>
  
    
  );
}