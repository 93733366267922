import React,{ useState , useEffect} from "react"
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
// import CheckIcon from "@mui/icons-material/check";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Phone, LocationOn, WatchLater } from '@mui/icons-material';

import { Container, Grid, Box, Card, CardHeader, IconButton, CardContent, Typography, Button, TextField } from '@mui/material';

// import { Container, Grid, Box, Card, CardContent, Typography, Button, TextField } from '@mui/material';
// import NumberCounter from './NumberCounter';

// import { Circle } from '@mui/icons-material';

import { CSSTransition } from 'react-transition-group';
import ContactInfo from "./ContactInfo";
// import './Banner.css';


const Banner = () => {

  const cardStyle = {
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  };

    const bannerStyle = {
      position: 'relative',
      width: '100%',
      height: '400px', // Adjust the height as needed
      backgroundImage: 'url("http://sudo24.online/wp-content/uploads/elementor/thumbs/andrew-neel-cckf4TsHAuw-unsplash-1-scaled-q4ribv1z8qblc7z688jwho05jh4ps9yg9rmxjzrfjk.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  
    const bannerTextStyle = {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      color: '#fff',
      fontSize: '2.5rem', // Adjust the font size as needed
      animation: 'runningText 10s linear infinite',
    };
  
    return (

      <div style={{width:"100%", margin:"auto"}}> 
      
      
      
      <Box  component="div" style={bannerStyle} >


        <Typography variant="h2" style={{color:"white", textAlign:"center"}} >
          Contact Us
        </Typography>
        <style>
          {`
          @keyframes runningText {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
        </style>

     
      </Box>

      <ContactInfo/>


      <Container
      sx={{
        backgroundImage: `url("https://t3.ftcdn.net/jpg/05/91/51/92/240_F_591519280_sLFvZTyBkK5qlBTnDWBW3U32ZjbDThSE.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '50px',
        paddingBottom: '50px',
        marginTop:'30px',
        // marginBottom:"60px"
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Contact Information
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <TextField label="Name" fullWidth />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField label="Email" fullWidth />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField label="Phone Number" fullWidth />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField label="Write your message here" fullWidth multiline rows={6} />
                </Box>
                <Box sx={{ mt: 2 }}>
                <Button
               variant="contained"
            sx={{ backgroundColor: '#ff0000', color: '#ffffff' }}
            >
           Send Message
           </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <img
                src="https://media.istockphoto.com/id/1453599596/photo/blue-envelope-with-document-paper-newsletter-mailbox-web-icon-3d-illustration.webp?b=1&s=170667a&w=0&k=20&c=MWnIdJly1qAyXDe5KXfxGn4IWS1-P4tlwhJF8LMt6ZY="
                alt="Image"
                style={{ width: '100%', height: '530px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Box
      // sx={{
      //   backgroundImage: `url("https://t3.ftcdn.net/jpg/06/05/56/80/240_F_605568059_m4ei46ov06bqtNWyUtrBzVeJBDvWoevh.jpg")`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      //   paddingTop: '50px',
      //   paddingBottom: '80px',
      //   marginTop:'30px',
      //   height:'444px',
      //   marginBottom:"40px"
      // }}
    >

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '30px',
          maxWidth: '1200px',
          marginBottom:'10px',
          marginRight:'4px',
          marginLeft:'4px',
          margin: '0 auto',
          marginTop:'90px',
        }}
      >


        <Card sx={{  backgroundColor:"#f6d4a3",flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': {cursor:"pointer", transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Phone"
            avatar={
              <IconButton sx={{ color: '#ff0000' }}>
                <Phone />
              </IconButton>
            }
          />

          <CardContent>
            <Typography variant="h6">Contact With Details </Typography>
            <Typography variant="body2" color="text.secondary">
            +918750753590,

            </Typography>
            <Typography variant="body2" color="text.secondary">
            +919997668959
            </Typography>
            <Typography variant="body2" color="text.secondary">
            info@sudo24.com
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{  backgroundColor:"#f6d4a3",flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': {cursor:"pointer", transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Address"
            avatar={
              <IconButton sx={{ color: '#00ff00' }}>
                <LocationOn />
              </IconButton>
            }
          />

          <CardContent>
            <Typography variant="h6">Our Address</Typography>
            <Typography variant="body2" color="text.secondary">
            H- 666, 
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Noida,
            </Typography>
            <Typography variant="body2" color="text.secondary">
           Uttar Pradesh,
            </Typography>
            <Typography variant="body2" color="text.secondary">
            India 201301
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{   backgroundColor:"#f6d4a3", flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': { cursor:"pointer", transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Work Hour"
            avatar={
              <IconButton sx={{ color: '#0000ff' }}>
                <WatchLater />
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="h6">Our Work Time</Typography>
            <Typography variant="body2" color="text.secondary">
            Monday - Sunday:



 
            </Typography>
            <Typography variant="body2" color="text.secondary">
            

           09.00 - 23.00

 
            </Typography>
            
          </CardContent>
        </Card>
      </Box>
    </Box>


      </div>
      
    );
  };
  
  
  

  
  
  export default Banner;