import React from 'react';
import './News.css';

export default function News() {
    return (
      <div className='news'>
      <div className='letter'>
          <h1> Newsletters </h1>
          <p> A regular review of published content, recent awards and upcoming events, product updates, and late-breaking news.</p>
      </div>

      <div className="cardcontent3">
<div className="News">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/news/pyyve9xo9ukgcfsvr1oj'} width={350} height={230} alt="" />
<p> The BringMark Newsletter: Q1-Q2 2023</p>
<button> View More</button>
</div> 
  </div>  
    </div>

    );
  }  