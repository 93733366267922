import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Racktech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Automate Planogram Audits`
const featuresDescription = `Utilize our retail shelf-monitoring solution to conduct planogram audits at store level automatically, comparing the execution against predefined benchmarks. It quickly identifies any deviations from planned items or incorrect SKU/Category placements across all categories at every location!`
const feauturesimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/ptescpfuo15yqnv85po9"

const featuresTitle2 = `Keen-Eyed Detection`
const featuresDescription2 = `The computer-vision-based ShelfWise automatically recognizes product assortments, delivering real-time insights on shelf placements and out-of-stock items through its mobile app for retailers.`
const feauturesimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/dtx4ntt5ul70auvwtnzw"

const featuresTitle3 = `In-Depth Insights and Enhanced Sales`
const featuresDescription3 = `An AI-driven retail shelf monitoring system that automatically gathers real-time data about items on your shelves to enhance product availability, rectify pricing inaccuracies, and optimize sales – all supported by tailored dashboards and reports tailored to the specific needs of retailers.`
const feauturesimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/neqgxejegscyvugrrlif"

// const featuresTitle4 = `this is a 4st cards`
// const featuresDescription4 = `this is my 4st last cards of below the page you can check now.`
// const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Explore`
const weeksDescription = `Examine current field inspections to determine the aspects you currently monitor and their significance to your team. Capture data on products and shelves to establish a baseline.`
const weeksimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/hbvwpedp71vedny5hhtb"

const weeksTitle2 = `Develop`
const weeksDescription2 = `Educate our ML and AI tools to transform shelf scans into concise shelf reports, providing real-time inventory status and product disposition.`
const weeksimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/zhhfoinegt87ns8dfixd"

const weeksTitle3 = `Supervise`
const weeksDescription3 = `Receive instant notifications for any incorrect SKU/Category placements or alterations in planned items across all categories at all levels.`
const weeksimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/yydvhxrkqvs3q3zufjvq"

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Distribute the tool to sales representatives and field teams and begin reaping the benefits immediately by gaining real-time insights into retail shelves across all channels.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `CPG`
const message1 = `Acquire real-time awareness of your retail shelves and ensure precisely stocked shelves for the highest level of customer interaction and product appeal.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/jtc9gvw13musyn48zkeg"


/*const title2 = `this is a 2nd cards`
const message2 = `this is my 2nd last cards of below the page you can check now.`
const image2 =require('../../assets/solution/supplychainout.png')

const title3 = `this is a last cards`
const message3 = `this is my last cards of below the page you can check now.`
const image3 =require('../../assets/solution/supplychainout.png')
the end of cards*/






const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Sectors that can gain from RackTech`
const message = `Guarantee precise shelf planogramming at a detailed level for each location and platform.`;

const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/ocl5mwolqj18mvpt1i85")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};
function Racktech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>RackTech AI</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>AI-driven image recognition offers the Top Retail Shelf Monitoring Technology.
           </h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Your layout designs are solely as effective as your implementation. Our pioneering AI-based image recognition discloses the activity within the shelving units, allowing you to refine processes. Maximize sales through our state-of-the-art, live, retail shelf-monitoring system.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color:"black"}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color:"black"}}>RackTech</Typography>
      </div>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "black" }}>RackTech Advantage</h2>
 
      </Grid>
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      ShelfWise is an innovative retail shelf observation tool that converts retail shelves into digital data and provides insights using a smartphone-based image processing application. It offers real-time suggestions for operational adjustments and sales promotions, featuring peak Racktech precision to trim your trade promotion expenses.        
      </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/y0e3rhtqrw1cq0pergch"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3>User Friendly</h3>

              <Typography>Our application permits you to utilize your smartphone camera to scan any product within your retail environment and acquire instant details about it – such as the price, size, and availability in other stores. You can also generate sales reports using our comprehensive data analysis tools through simple integration.
                </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Optimal Stock Level in Stores </h3>
              <Typography>Empower sales representatives to scan store shelves and obtain real-time inventory information to prevent stock shortages of high-demand items.            
                  </Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/lek1mubkawkr1hluvljg"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Compare with Competitors</h3>
              <Typography>This monitoring tool for retail shelves supplies actionable insights regarding competitors' presence on the shelves. This helps users comprehend what influences their performance compared to competitors within a specific category or location, along with trustworthy suggestions for competitive shelf positioning.
                  </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Enhance Field Productivity</h3>
              <Typography>Rapidly grasp in-store actions and decrease the audit duration of field representatives, thus accelerating field productivity.
                </Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>RackTech </h1>
        <Typography>Conventional retail shelf-monitoring systems are costly, rigid, and sluggish in their responsiveness. This leads to a shortage of inventory visibility, ultimately resulting in revenue losses. Whether it's Sales, Category Management, or Supply Chain and Operations, ShelfWise provides real-time analytics for any retail store or warehouse location. This enables you to RackTech sales based on your inventory's movement, facilitating operational optimization and profit growth.
           </Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

     {/* <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={require("../../assets/Home.png")} width={"80px"} height={"80px"}/>
          </Grid>
           <Grid item xs> 
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>*/}
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>RackTech Features</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
              AI-driven Racktech analytics benefitting retailers with</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>75%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in time for determining share of shelf</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >100%</h2>
                </div>
                <p style={{color:"white"}}>Evaluation of planogram compliance</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>40%</h2>
                </div>
                <p style={{color:"white"}}>Boost in precision of calculation.</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>50%</h2>
                </div>
                <p style={{color:"white"}}>Enhancement in productivity</p>

        </div>
    </Box>

    {/* <Box className='forcast_cardContainer3'>
      <div  >
      <h2 style={{color:"white",fontWeight:"bold"}}>See RackTech in Action</h2>
      <p style={{color:"white",flexWrap:"wrap",marginTop:"50px"}}>
      Discover how ShelfWise assists our clients in comprehending how their products appear, perform, and compete on the shelf, consequently eradicating stock shortages, leading to annual savings exceeding $10 million.</p>
    <Button variant='contained' style={{marginTop:"50px" , marginBottom:"40px"}}>Request Demo</Button>
      </div>
      <div>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/zaqtkt9xhro2qxjhyco3"} alt="" width={"500px"} height={"400px"}/>
      </div>
     
    </Box> */}
        <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore RactTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover how ShelfWise assists our clients in comprehending how their products appear, perform, and compete on the shelf, consequently eradicating stock shortages, leading to annual savings exceeding $10 million.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/zaqtkt9xhro2qxjhyco3"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
      </Grid>
     
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

     {/* <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
         <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper> */}

      </div>
     
    </Box>

   


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Racktech/o7pin91xnzbed7uocqf7"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Reveal comprehensive insights into the most recent trends in the market, technology, and how your colleagues are addressing their most significant shelf planning challenges using Bringmark cutting-edge planogram solutions.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 
    

    </div>
  );
}

export default Racktech;
