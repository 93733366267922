import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const IconicDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Ionic Developers</Typography>

<Typography >Hire Ionic Developers To Built Augment Ionic Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/omqwmhhn9pz8e08pvrfj" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Iconic is a freely available, open-source framework tailored for the development of mobile applications. It leverages HTML5 for crafting mobile apps, making it especially well-suited for building hybrid applications. Positioned as an excellent choice for front-end user interfaces, Ionic excels at managing layout and various aspects of mobile apps. It serves as an optimal solution for creating visually captivating and engaging mobile apps, offering flexibility and ease of use. The Ionic Framework boasts robust components, and effectively harnessing its capabilities necessitates the expertise of a skilled Ionic developer to navigate and orchestrate its functionalities.</Typography>

    <Typography style={{marginTop:'40px'}}> Iconic developers help develop angular-based applications, and the code written with Ionic is used by other developers like Progressive Web Applications. The code is extensive so that the Ionic developer can reuse it in another development. The Ionic developers help to create a UI grid for mobile-based devices such as tablets and smartphones. The Ionic developers assist in creating and deploying cloud-based platforms. Ionic developers use an Ionic plugin to enable access for the device and its file system. The Ionic developer can use the third-party plugin written in native code and select binary cases only in the project. </Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Iconic Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1.Progressive Web App Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our team of Ionic developers boasts expertise in Progressive Web App (PWA) development. When you hire our Ionic developers, you'll elevate your business by enhancing PWAs, delivering an offline mode experience, and optimizing various aspects of PWA development.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.Hybrid Iconic Developement:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Iconic development is renowned for its hybrid capabilities, and our team excels in harnessing its power. This approach accelerates performance through native language integration. Our Ionic developers ensure seamless compatibility across critical platforms, ensuring top-notch server application performance. The difference in service quality is discernible, and our developers thoroughly comprehend it.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Iconic Angular Mobile Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Iconic and Angular are a perfect match. Our Ionic developers offer services to bolster your app or web platform with highly functional capabilities. They leverage the synergies between Ionic and Angular to create mobile apps and web solutions that provide an edge.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>4.Iconic Debugging & Testing </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Effective debugging and testing are pivotal for any application or web project. Our team possesses a profound understanding of Ionic debugging and testing practices, employing a specialized toolkit for this purpose. They ensure meticulous implementation before the project launch, displaying a keen interest in Ionic testing.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.Offshore Ionic App Development:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Offshore Ionic App Development stands as one of our prominent services within the realm of Ionic development. Our skilled professionals deliver seamless and tailored solutions that empower businesses to grow and explore new horizons with different frameworks.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.Iconic Apps 3rd Party Integration:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We offer Ionic application development services enriched with 3rd party integration. Our Ionic developers grasp the concept, adapt it seamlessly, and craft exceptional hybrid mobile apps. We leverage cutting-edge tools and technologies to create mobile applications with Ionic, seamlessly integrating 3rd party components.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7.Iconic App Maintenance:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>From development to deployment and beyond, our Ionic developers cover all aspects. We ensure comprehensive maintenance, regular updates, and unwavering support. Our team manages every facet of your business, providing ingenious solutions that fuel your business growth.</Typography>



    
 <Typography variant='h4' marginTop={5} fontSize={22}>8.Agile Expansion </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our commitment to transparency involves continuous communication with our clients, whether it's about ongoing or completed development processes. We expand our capabilities with an agile approach, ensuring that tasks are completed within the required timeframe. Our Ionic developers harness cutting-edge technologies to deliver projects that drive rapid business growth. When it comes to project quality, BringMark remains unparalleled in excellence.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9.Efficient Resolution</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our team of seasoned Ionic developers brings years of experience to the table, ensuring an efficient resolution for your business needs. Furthermore, they deliver solutions that are both high-quality and cost-effective. These efficient solutions lay the foundation for strategic planning and execution. Our Ionic developers craft a comprehensive strategy that ultimately culminates in a successful mobile application or web solution.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.Unique Approach</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>With over a decade of Ionic development experience under their belts, our developers employ a unique and compelling methodology. We guarantee that your app, built with the Ionic Framework, not only stands out but also functions seamlessly. The approaches adopted by our Ionic developers are distinctive, marked by a long-term vision that sets them apart.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default IconicDevelopers