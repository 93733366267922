import React from 'react';
import './Awards.css';

export default function Awards(){
    return(
      <div className='award-main'>
        <div className='award'>
                <h1>BringMark Innovation Awards </h1>
                <h3>2022</h3>
                <p>BringMark is thrilled to announce its first annual BringMark Innovation Awards. We are honored to recognize outstanding customer and partner achievements leveraging AI/ML innovation to transform business processes and performance through tech data-based decisions to achieve substantial ROI.</p>
        </div>

        <div className='recognize'> 
          <h3> Recognizing Success with BringMark End-to-End Suite of Solutions</h3>        
        <p> We are proud of the customers we serve as we continue to help data-driven organizations innovate and create new opportunities with the right technologies and data strategies that truly creates an effect. Our suite of AI-powered products for planning, forecasting, and merchandising empower leading retailers to make tech data-based decisions, transform their businesses, and achieve substantial business benefits.</p>
        </div>
                    
            <div className='winner'>
            <h3 > Congratulations to this Year's Winners!</h3>
         <p> The BringMark Innovation Awards are designed to recognize Bringmark's customers and partners for innovation in the use of deployed solutions.
             This year's customer and partner recipients are:</p>
            </div>

            <div className='carter'>
            <h1> carter's</h1>
            <div className='carterdata'>
            <h4> Carter's PriceTech</h4>
            <p>Pricing in any environment is not easy, but today's world with inflation makes it the most difficult. As Carter's merchandising team analyzed their processes, they identified gaps in their pricing operations and took the challenge head-on to automate and optimize. They selected Bringmark PriceTech because it had all the features they needed to build their ideal pricing system, including the ability to manage pricing across the entire product lifecycle – through pre-season, in-season, and clearance pricing modules, which are all managed at different levels, yet are seamlessly integrated. With PriceTech, Carter’s teams now have a system that provides optimized pricing recommendations along with all the information they need to evaluate and accept or modify them. In addition to considering Carter’s financial targets, the recommendations are designed to maximize margin, units, etc. during various lifecycle phases, ensuring they meet all the objectives. In addition, all processes, from recommendation generation to final push to the POS system, are fully automated, allowing the teams to dedicate their time to other valuable tasks.</p>
            </div>
            </div>
      </div>
    );
}

