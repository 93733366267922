import React from 'react';
import './Companynews.css';



export default function Companynews() {
  return (
    <div className='company-main'>
    <div className='News'>
        <h1> In The News</h1>
    </div>
       

<div className="news-card">

<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/o7keaijxdzdpzfn2must'} width={350} height={230} alt="" />
<p>BringMark enhances its AI-native product portfolio with generative AI technologies</p>
</div>

<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/uyhv19ps6eespug24vnw'} width={350} height={230} alt="" />
 <p>Prime Day 2: A Harbinger of the Upcoming Battle for Consumer Spend!</p>
 </div>


<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/rib9qaygxfg99qwipkeq'} width={350} height={230} alt="" />
 <p> The Amazon Canary in the Coal Mine</p>
</div>

<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/enbeq4gmkxerbmcxo7up'} width={350} height={230} alt="" />
<p> How to move toward a more data-driven decision-making approach </p>
</div>

<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/q4wzkjwrev5zxxtg9ooy'} width={350} height={230} alt="" />
<p> How to be an inflation winner - Pricing imperatives for fashion retailers in a post-COVID world </p>
</div>


<div className="company">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/newsletter/ombfhuonj9hakmasbrkn'} width={350} height={230} alt="" />
<p> How to be an inflation winner - Pricing imperatives for fashion retailers in a post-COVID world </p>
</div>
</div>
   </div>

    
  );
}