import React from 'react';
import './Industry1.css'
import Button from '@mui/material/Button';


export default function Industry1() {
  return (
   
    <div className='game'>
        <div className='insights'>
            <h1> Game-Changing Insights</h1>
            <p> Get Our Latest Insights</p>
            <Button> Amazon Prime Day 2023 Analysis </Button>
        </div>
           

<div className="industrycard">
<div className="industry">
  <img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/TRENDINGINSITE/xzgcdncngtmhqbg1ezru'} width={350} height={230} alt="" />
  <h3 style={{textAlign:'start'}}>Amazon Prime Day 2023 Promotions: A Holiday Bellwether </h3>
  <p>We analyzed Amazon Prime Day promotions across 11 categories over the last five years. What we learned will help you prepare for the 2023 holiday season.</p>
   {/* <button>Learn more</button>   */}
   </div> 


<div className="industry">
  <img src={'https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/a2bzfv3jnpux9upclz09'} width={350} height={230} alt="" />
  <h3 style={{textAlign:'start'}}> Fashion Promotions Index </h3>
  <p>BM's Fashion Promotions Index tracks the breadth and depth of promotions at 20+ leading retailers across departments, categories, and price points on a bi-weekly basis.</p>
   {/* <button>Learn more</button>    */}
     </div>


<div className="industry">
  <img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/TRENDINGINSITE/omhxt3nlddasrxm48rtq'} width={350} height={230} alt="" />
  <h3 style={{textAlign:'start'}}>Insights on Pricing & Promotions - Athleisure & Footwear </h3>
  <p>BM's Pricing and Promotions Analysis is designed to identify the trends and changes in pricing and promotions across various categories by tracking and analyzing publicly available data.</p>
   {/* <button>Learn more</button>    */}
</div>


</div>
</div>

  );
}