import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { Dialog } from '@mui/material';
import './Contacts.css';

function ContactPage() {
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [budgetDollars, setBudgetDollars] = useState(120.217); // Initial value
  const [comment, setComment] = React.useState('');
  const [Name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      const modalTimer = setTimeout(() => {
        closeModal();
      }, 5000);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [isModalOpen]);

  const handleSubmit = async (event) => {

    event.preventDefault();
    const formData = {
      name: Name,
      email: email,
      mobileNumber: phoneNumber,
      message: comment,
      budget: budgetDollars,
    };

    const res = await fetch("https://teal-breakable-springbok.cyclic.app/api/contactus/contactus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    // if(re)
    if( !formData.name || !formData.email || !formData.mobileNumber || !formData.message ||  !formData.budget){
      alert(`Please fill all the fileds.`);
    }

    if (res.status === 200) {
      console.log("Form submitted successfully!");
  
      // Show the success message dialog
      openModal();
  
      // Reload the page after 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      console.error("Form submission failed");
    }
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };


  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleBudgetDollarsChange = (event, newValue) => {
    setBudgetDollars(newValue);
  };

  const getColor = () => {
    if (budgetDollars < 105000) {
      return 'red';
    } else if (budgetDollars < 235000) {
      return 'orange';
    } else {
      return 'green';
    }
  };

  return (
    <ThemeProvider theme={createTheme()} style={{backgroundColor:"rgb(9,21,35) "}}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url(https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/landingpage/digcw4cdixsvd1pl0ghl)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={{ backgroundColor: "rgb(9,21,35)", marginTop: "20px" }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Contact us
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={Name}
                autoComplete="name"
                autoFocus
                onChange={handleName}
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={handleEmail}
                autoComplete="email"
                autoFocus
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              />
              <TextField
                type="number"
                margin="normal"
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="tel"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              />
              <TextareaAutosize
                aria-label="Comment"
                minRows={7}
                placeholder="Write message"
                name="comment"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                style={{ color: "black", width: "100%", backgroundColor: "white", borderRadius: "10px", fontSize: "20px" }}
              />
              <Typography variant="h6" style={{ color: "white" }}>Budget: ${budgetDollars}</Typography>
              <Slider
                value={budgetDollars}
                onChange={(event, newValue) => setBudgetDollars(newValue)}
                min={120.217}
                max={479636.80}
                step={1000}
              />
              <div
                style={{
                  backgroundColor: getColor(),
                  width: '100%',
                  height: '20px',
                  marginTop: '10px',
                }}
              ></div>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} style={{ backgroundColor: "#4CBB16" }} onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="xs" fullWidth>
        <Box p={2} style={{ backgroundColor: 'green', color: 'black' }}>
          <Typography variant="h6" align="center">Thank you for reaching out to Bringmark. We'll be in touch soon!</Typography>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

export default ContactPage;
