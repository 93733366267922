import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './mobileapp.css'
import MovingCards from '../card/MovingCards';



const images = [

"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/imageslider/r8jtpfc7b5usqkuty51k",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/imageslider/zydhbmnlvfdgi1j90wzu",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/imageslider/ff2llp1smskrasdmgkgy",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/imageslider/aba18ppteketcw4yun0x",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/imageslider/jakqf3ownctqv29ewcob",


];

function MobileAppDev() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>Mobile App Development</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
      
    </div> 

    <Typography paragraph style={{color:"white"}}>
    In today's fast-paced world, smartphone apps have revolutionized the way we go about our daily activities, making them an integral part of our lives. They have become essential tools for communication, entertainment, productivity, and much more, shaping the way we interact with the world around us.

In this technologically advanced era, Bringmark stands at the forefront of crafting exceptional mobile applications for businesses and startups. As a leading tailor-made mobile app development company, we pride ourselves on delivering top-notch apps that cater to diverse needs and industries across the globe.

Our team of highly skilled and continuously updated mobile app developers brings together a wealth of expertise in various domains. Collaborating with global firms, we leverage our insights and creativity to transform innovative ideas into stunning and inventive mobile apps. We believe that each project is unique, and we work closely with our clients to understand their goals, preferences, and target audience, ensuring the end product aligns perfectly with their vision.

With a proven track record and extensive experience, we have successfully produced remarkable mobile apps for clients from various industries and geographical locations. Our solutions are designed to be user-friendly, visually appealing, and technologically advanced, providing a seamless and engaging user experience.

Embrace the mobile revolution with Bringmark as your reliable partner, and watch your ideas come to life in the digital world. Whether it's an intuitive mobile app for your business or an innovative solution to disrupt the market, we are committed to helping you achieve success and stay ahead in today's competitive landscape. Trust us to be your technology partner and witness the transformation of your app concept into a tangible reality that captivates users and empowers your business.
        </Typography>


        <div>
  <MovingCards
  
  
  image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/zq5m5iijpnpla6qypwpc"

  image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/zyrp4tqy20mjmjfdqv4x"
  
  image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/kroanaqgzvzdnu4bwmty"
  
  image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/rbbl1bddygncwcmlkmap"
  
  image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/eaiifslxp8hirurmmvq5"
  
  image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/u7nezewzlkfoz2mdonno"
  
  image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/r2gtl2p1ifcggywkf87u"
  
  image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/ngdfvbiqfuu65euk7t4l"
  
  image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/tlutlrqd0dqoi9pnhhvp"
  
  image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/marque/jiec6r7g7nxububcczkt"
  
  image11="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/landingpage/appdev/rggzvwyzgyzs1qbdmdsp"
  
  image12="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/landingpage/appdev/o4ht4s1uxyieoofukong"

  />
</div>
<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Transforming Ideas into Reality: Crafting Exceptional Mobile Apps for Today's World. Embrace the Mobile Revolution with Bringmark- Your Trusted Technology Partner for Top-Notch Solutions Globally


</Typography>

<Typography  style={{color:"#545357"}}>
Experience the Perks of Hiring App Developers from Bringmark:

Dedicated Teams,
Comprehensive Reporting,
NDA Security,
24x7 Support,
Resilient Environment,
Security and IP Protection,
Quick Team Scaling,
QA Tested & Bug-Free Solution,
Source Code Authorization,
No Hidden Costs,
Technical Support,
On-Time Delivery,
 </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/gif/gob0yke9ckdubdovecxg" alt=""  style={{borderRadius:20}}/>
  
  
</div>


</div>

<Typography paragraph style={{color:"white"}}>
In the ever-changing landscape of innovation, Bringmark takes the lead in turning visionary concepts into tangible reality through exceptional mobile app development. Our passion lies in crafting cutting-edge apps that cater to the dynamic needs of today's tech-savvy world.

As staunch advocates of the mobile revolution, we proudly stand by your side as your trusted technology partner, delivering top-notch solutions worldwide. Our highly skilled developers, equipped with continuous updates on emerging trends, harness their expertise to create stunning and inventive mobile apps.

With a customer-centric focus, we work closely with you to grasp your goals, preferences, and target audience, ensuring the final product aligns seamlessly with your vision. Our distinguished track record and extensive experience bear testimony to our ability to produce remarkable mobile apps across diverse industries and locations.

At the heart of our solutions lie user-friendliness, visual allure, and technological prowess, culminating in a seamless and captivating user experience. Whether it's an intuitive mobile app for your business or a groundbreaking innovation, we are fully devoted to helping you achieve success and stay ahead in today's fiercely competitive landscape.

Embrace the boundless opportunities with Bringmark, your reliable partner, as we witness the metamorphosis of your ideas into powerful mobile applications that captivate users and elevate your business globally. Trust us as your technology ally, and together, we'll carve a path towards revolutionary success in the digital realm.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/gif/mnw4cxpurk5sbehtew0u" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Revolutionizing Mobile App Development: Your Trusted Technology Partner for Global Success
</Typography>
<Typography  style={{color:"#545357"}}>


Embrace Versatility with Bringmark: Hire Skilled Developers for Diverse Services:

Hire iPhone App Developers,
Hire Unity3D App Developers,
Hire Android App Developers,
Hire Game Developers,
Hire Flutter Developers,
Hire AR/VR App Developers</Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Cognitive enterprises are at the forefront of digital transformation, where they continuously enhance their digital focus while simultaneously rationalizing and adapting their processes. At the heart of their digital focus lies a well-defined mobile experience strategy and a commitment to providing consistent functional experiences to their users.

The mobile experience strategy adopted by these enterprises is centered around a data-first approach, leveraging valuable insights and analytics to inform their decisions and drive actions. They understand that data-driven strategies lead to better user engagement and overall business success. These actions, in turn, aim to delight users by offering personalized and intuitive experiences that meet their specific needs and preferences.

At our company, we play a crucial role in empowering cognitive enterprises to translate complex requirements into tangible solutions. We specialize in developing custom mobile applications that prioritize the human-centric aspect of user interactions. By carefully understanding the unique challenges and goals of each enterprise, we craft bespoke mobile apps that align perfectly with their vision.

Our approach to custom mobile app development encompasses a fusion of mobile experience strategy and innovative design principles. We believe that a well-designed app not only enhances usability but also contributes to measurable achievements for the enterprise. Through meticulous planning and execution, we help our clients achieve quantifiable success in their digital endeavors.

By collaborating closely with cognitive enterprises, we ensure that our custom mobile apps align with their broader digital focus and strategic goals. Our services go beyond mere development; we become partners in their journey towards digital transformation, offering continuous support and enhancements to stay ahead of the curve.

In conclusion, the integration of mobile experience strategy, consistent functional experiences, and data-driven actions is the key to success for cognitive enterprises. As pioneers in delivering human-centric custom mobile apps, we are dedicated to empowering these enterprises and helping them thrive in the ever-evolving digital landscape. Together, we strive to create a future where user delight and business prosperity go hand in hand.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>


  Unlock the Potential of Mobile App Development: Benefit from Our Decades of Experience to Create Robust, Scalable, and User-Friendly Apps on Android and iOS Platforms for Your Business.


</Typography>
<Typography  style={{color:"#545357"}}>

Leverage our decades of experience in mobile app development to unlock your business's potential. Our expertise ensures robust and user-friendly apps on Android and iOS platforms, catering to a broad audience. We prioritize reliability, building apps that handle heavy user loads flawlessly. With scalability in mind, your app will effortlessly accommodate growing user demand. User interface and experience design expertise guarantee intuitive and engaging apps, boosting customer satisfaction and retention rates. Collaborating closely with you throughout the process, we align the final product with your business goals and vision. Partner with us to create a powerful mobile app that propels your business forward in the digital landscape </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/gif/bjru8onwzhglwkc5jzzr" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>

In today's highly interconnected global economy, the success of a business idea is influenced by various complex factors. To navigate this landscape, businesses can strategically initiate a pilot program to test their ideas in the market. By adopting an iterative agile methodology, they can rapidly adapt and refine their concepts based on real-world feedback. At our company, we employ an agile mobile application development process that enhances the quality of each granular task.

Through our iterative approach, we ensure a continuous and steady stream of improvements to the mobile app. This approach allows us to respond to user feedback promptly and make necessary adjustments, driving the app's transformational evolution. By diligently executing this process, we achieve a mobile application that not only meets user expectations but also exceeds them, ultimately leading to its success in the competitive market.

Our agile methodology fosters collaboration and transparent communication with clients, enabling us to stay aligned with their evolving needs and preferences throughout the development journey. We understand that adaptability is crucial in an ever-changing digital landscape, and our iterative approach empowers businesses to stay ahead of the curve, delivering cutting-edge solutions that resonate with their target audience.

In conclusion, in an era where global interconnectedness defines the business landscape, piloting ideas and employing an agile mobile application development process are key drivers of success. Our commitment to continuous improvement and transformational results ensures that your mobile app remains relevant and impactful, propelling your business towards long-term prosperity and growth.
        </Typography>

<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/app%20development/gif/bdjbzanxgsfaly1x2sqd" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Why Should You Hire App Developers From Bringmark?
</Typography>
<Typography  style={{color:"#545357"}}>

Fulfill Your Dreams with Our Core Expertise and Dedicated Developer Services. Expect the Best Experience with Our Skilled Mobile App Developers. Bringmark Delivers Top-notch Customer Service through Innovative and Collaborative Project Management Systems.

Perks of Hiring Resources from Bringmark:

Highly Cost-effective Solutions,
Well-established Infrastructure,
Quick Turnaround Time,
Skilled Developers for Every Platform,
Guaranteed Results,
Reliable Support,
Hire App Developers from Bringmark to Develop Your Mobile App in Your Way. Choose from a Vast Pool of Resources and Customized Hiring Packages. Contact Us to Hire Dedicated App Developers within 24 Hours. Your Mobile App Success is Just a Step Away! </Typography>
</div>


</div>

      
    </div>
  )
}

export default MobileAppDev