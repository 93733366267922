import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import './WhyChoose.css'; // Import the CSS file

const useStyles = makeStyles({
  // You can still define additional styles here if needed
});

const WhyChoose = ({ name, readOnly }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(5);



  return (
    <div className='rews'>
   
    <div className='reviewContainer11'>
        <div>
            <h2>Why Choose Bringmark?</h2>


        <div className='card6'>
            <div className='cardcol'>
                <div className='card1time'>
                <img src={require('../../assets/whybringmark/Vector.png')} alt="" height={'40px'} width={'50px'} />
                    <p>Fast Delivery time</p>

                </div>

                <div className='card1time'>
                <img src={require('../../assets/whybringmark/Group.png')} alt="" height={'40px'} width={'50px'} />
                    <p>Affordable Pricing</p>

                </div>

                <div className='card1time'>
                <img src={require('../../assets/whybringmark/people.png')} alt="" height={'40px'} width={'50px'} />
                    <p>Complete Transparency</p>

                </div>

            </div>
{/* card */}
<div className='cardcol'>
                <div className='card1time'>
                    <img src={require('../../assets/whybringmark/proactive.png')} alt="" height={'40px'} width={'50px'} />
                    <p style={{fontSize:"12px"}}>Proactive Communication</p>

                </div>

                <div className='card1time'>
                <img src={require('../../assets/whybringmark/file.png')} alt="" height={'40px'} width={'50px'} />
                    <p>Proactive Communication</p>

                </div>

                <div className='card1time'>
                <img src={require('../../assets/whybringmark/face.png')} alt="" height={'40px'} width={'50px'} />
                    <p>Professional Team</p>

                </div>

            </div>
        </div>
        </div>
   
  
<div className='img2'>

          <div className='imgCont'>

      <Card className="card22"> 
     
    </Card>


    <Card className="card23"> 
     
    </Card>

      </div>

<div className='imgshadowcont'>
<Card className="card33"> 
      
      </Card>
      

      </div>

</div>

    </div>
    </div>

   
  );
};

export default WhyChoose;
