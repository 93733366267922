import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './cloudService.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/imageslider/cpg55a82hecpova4ielq",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/imageslider/ltdijgbewsgguwgkothw",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/imageslider/wycretjity24snscyco8",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/imageslider/bpyyxlpw6qqlbxdygxe3",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/imageslider/fwrl0t14zr7xt8jx3vtn",
 

];

function CloudService() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };



    
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 
<div className="LandingPage-container">
    <Typography paragraph style={{color:"white"}} 
>
Step into a new era of business management with BringMark – a revolutionary, cost-effective cloud-based platform accessible via web browsers and vehicle-mounted tablets. This advanced ecosystem redefines operational efficiency, ensuring unparalleled safety, performance enhancement, and compliance across your entire operation.

BringMark leverages the power of cutting-edge cloud service technology to reshape how businesses optimize their workflows. Seamlessly accessible via web browsers and tablets, this platform empowers you to transcend traditional limitations, streamlining management processes like never before. The fusion of cloud technology and user-friendly interfaces offers a novel approach to handling safety, performance, and compliance, paving the way for a more efficient future.

Witness unparalleled benefits as BringMark becomes your trusted partner in elevating business performance. Embrace a solution that embodies innovation, enabling you to optimize operations like never before. BringMark forward-looking approach ensures that your business is prepared for the future, where cloud technology is the key to unlocking limitless potential. Welcome to the dawn of optimized operations, where BringMark
        </Typography>

        </div>

        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/erdn94avu8xoqgx5uwre"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/u1hfkz86ju7efxnftd0f"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/y4h92ivczoxymnwlbpcm"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/zfmfyks0fr3a3zotgrvc"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/gpvj6sn0uegucorp94se"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/yr8bapabrrfjxy9tcktz"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/sw9ntfxtrksso6a4jzh0"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/n5vnhcp899ctbz542muw"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/troo0nnh44bfooyicrwj"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/q2ozfah3np54h333lbbz"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/quhknnh4jhoz43kj5get"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/marque/bg5notrus1oh2nbzdvao"
  />
</div>

        <div className='LandingPage-thirdBox'>

<div >
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unleash Cloud Potential with BringMark: Elevate Your Business



</Typography>
<Typography  style={{color:"#545357"}}>

BringMark is your gateway to unlocking the full potential of cloud services, revolutionizing the way you do business. Our comprehensive suite of cloud solutions offers scalability, agility, and cost-effectiveness, propelling your operations to new heights. By harnessing the power of cloud technology, you streamline processes, enhance collaboration, and foster innovation, while we manage the complexities behind the scenes. Embrace the future with BringMark's cloud services, where limitless possibilities await your business </Typography>
</div>
<div style={{marginBottom:"50px"}} className='content-slide1'>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/gif/ppyscr5s6yvbgkqhzbli" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Step into a realm of limitless possibilities with BringMark's cloud-powered solutions that are designed to elevate your business to new heights. With a profound understanding of the transformative potential of cloud technology, BringMark empowers businesses to harness the full spectrum of benefits that the cloud offers.

As you embark on your journey with BringMark's cloud services, you're embarking on a path of seamless integration and optimization. From the moment businesses initiate their requests for specialized cloud services, a meticulous process begins. Rigorous authentication ensures that only authorized users access the tailored solutions, setting the foundation for a secure and efficient interaction.

Resource allocation is the cornerstone of the cloud experience. BringMark's expertise ensures that the computing resources you need for your specific requirements are readily available. Whether it's immersive experiences, data analytics, or customized solutions, the cloud infrastructure seamlessly adapts to your needs.

The heart of BringMark's cloud solutions lies in data processing and secure storage. Your data is transformed into actionable insights within a safe and protected environment. Networking and communication are facilitated with precision, ensuring smooth data transfer between your devices and the cloud data centers.

Scalability and flexibility are in the DNA of BringMark's cloud services. As your business evolves, the cloud effortlessly scales resources up or down based on demand. This adaptability enables you to manage resources efficiently and focus on your core operations.

Moreover, BringMark's commitment extends to monitoring and support. The cloud infrastructure is under constant vigilance to ensure its reliability and performance. Transparent billing mechanisms provide a clear view of your resource consumption, enabling you to manage costs effectively.

With an unwavering focus on data security and industry compliance, BringMark ensures that your valuable information remains safeguarded throughout the cloud journey. The culmination of this process is the seamless delivery of the outcomes you've requested, whether it's advanced analytics insights, immersive experiences, or innovative applications.

In a rapidly evolving digital landscape, BringMark stands as a trailblazer, constantly introducing new cloud-based solutions aligned with emerging trends and evolving needs. By weaving cloud technology into its services, BringMark invites you to elevate your business, optimize your operations, and explore new horizons of growth and innovation.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}} className='content-slide'>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/gif/yo9iyhy29mc13bcqsy4z" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Cloud Excellence by BringMark: Empower Your Digital Transformation

</Typography>
<Typography  style={{color:"#545357"}}>
BringMark empowers your business to embark on a transformative journey through our cutting-edge cloud services. Seamlessly integrating advanced cloud technologies, we provide the infrastructure for your digital transformation. Experience unparalleled flexibility as you scale resources on demand, optimize operations, and drive innovation. Our expert team ensures that your transition to the cloud is smooth and secure, allowing you to focus on what truly matters – achieving your business objectives with the support of cloud excellence.
</Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
BringMark leverages cutting-edge cloud service technology, introducing unmatched management features that redefine business optimization. Seamlessly integrated into your workflow, this platform offers a fresh perspective on overseeing every aspect of your operation, enhancing safety, optimizing performance, and ensuring unwavering compliance.

Embrace a future where technology and operations intertwine seamlessly. BringMark isn't just a system; it's a strategic shift in how you manage your business. Bid adieu to traditional methods and welcome a realm where your operation thrives within the cloud, steering your business toward endless possibilities. Experience the future with BringMark where operational excellence and cloud technology converge, setting a new standard for success. 
        </Typography>

<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Elevate Your Business with BringMark's Cloud Expertise

</Typography>
<Typography  style={{color:"#545357"}}>

Beyond revolutionizing management practices, this visionary ecosystem heralds a new era of operational efficiency, safety, performance, and compliance.
Elevate your business to new heights with BringMark's cloud expertise, where innovation and efficiency converge. Our cloud services are designed to amplify your capabilities, providing a flexible, scalable, and secure foundation for your digital journey. By partnering with BringMark, you tap into a wealth of experience and knowledge, ensuring a seamless migration to the cloud. Embrace the future of technology-enabled growth and operational excellence, driven by the comprehensive cloud services offered by BringMark. </Typography>
</div>
<div style={{marginBottom:"50px"}} className='content-slide1'>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/gif/uxaby22k7sx2tcnft9ue" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
BringMark's cloud services offer a seamless workflow that empowers businesses with cutting-edge technology and data-driven capabilities. As businesses request specific cloud services, such as immersive experiences, data analytics, or AR/VR development, BringMark ensures secure authentication and authorized access. Once authenticated, BringMark's cloud infrastructure efficiently allocates tailored computing resources.

For services involving data analysis or immersive technologies, BringMark's cloud platform excels in data processing and secure storage, enabling valuable insights and captivating experiences. The robust networking infrastructure facilitates communication between users' devices and cloud data centers, ensuring seamless data transfer.

Scalability and flexibility are hallmarks of BringMark's cloud solutions, allowing businesses to scale resources based on demand. This feature is especially beneficial for fluctuating workloads or increased computational needs.

BringMark meticulously monitors cloud infrastructure performance, ensuring reliability. Transparent usage tracking and billing mechanisms provide insights for cost management. Stringent security measures safeguard data and ensure compliance, fostering confidence in cloud services utilization.

Ultimately, BringMark's cloud services deliver requested outcomes, from advanced analytics insights to immersive experiences. By staying ahead of technological trends, BringMark provides businesses access to the latest solutions that align with evolving needs. Through seamless technology integration and a robust workflow, BringMark's cloud services drive operational efficiency, informed decisions, and sustained growth.
        </Typography>

{/* <ProjectCard/> */}
<div className='LandingPage-thirdBox'>
<div style={{ marginBottom: "50px" }}>
  <img src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/cloud%20Service/gif/kgzomxt24e0gqgywfcg3" alt="" style={{ borderRadius: 20 }} />
</div>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Why should I opt for BringMark's cloud services?</Typography>
<Typography  style={{color:"#545357"}} className='content-slide'>

Choose BringMark for unparalleled seamless integration, tailored solutions, top-notch data security, effortless scalability, constant innovation, reliable performance, cost-effective solutions, technical prowess, and a future-ready approach. BringMark is your strategic partner for optimizing operations, ensuring growth, and embracing the evolving possibilities of cloud technology.t </Typography>
</div>


</div>

      
    </div>
  )
}

export default CloudService