// import logo from './logo.svg';
// import { Box } from '@mui/system';
import React,{ useState } from "react";
// import { Box, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
// import CheckIcon from "@mui/icons-material/check";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Container, Grid, Typography, Box,  useScrollTrigger,CardMedia, List, ListItem, ListItemText } from "@mui/material";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';

function Aboutus() {
    
    const theme = useTheme();
    
  
    return (

        
        <>
        <Box>
        <img
          src="https://t3.ftcdn.net/jpg/06/02/10/72/240_F_602107267_7We178PIPreCFS8ZZF5KZiCWvH4b8bFA.jpg"
          alt="Banner"
          style={{
            width: '100%',
            height: '450px',
            [theme.breakpoints.down('sm')]: {
              height: 100, // Adjust the height for mobile devices
            },
          }}
        />
      </Box>
      <Grid container spacing={2} marginTop={10}>
      {/* First Box */}
      <Grid item xs={12} sm={6}>
        <Box sx={{ backgroundColor: 'white', padding: 2 }}>
        <Typography variant="h8" color="orange" marginBottom={2}component="h2">
        OUR HISTORY
          </Typography>
          <Typography variant="h4" marginBottom={2} component="h2">
          Who We Are And What We Can Do For Your Business?
          </Typography>
          <Typography variant="body1" component="div">
      We can help you develop a <span style={{color:"blue"}}>customized strategy</span> for growing your business. Our team of experts can work with you to identify opportunities for expansion and develop a plan to achieve your growth goals. <span style={{color:"blue"}}>Contact us</span> today to learn more.
      <List sx={{ marginTop: '16px' , marginLeft:"14%" }}>
        <ListItem disablePadding>
          <CheckIcon sx={{ marginRight: '8px' }} />
          <ListItemText primary="Develop A Strong Brand Identity" />
        </ListItem>
        <ListItem disablePadding>
          <CheckIcon sx={{ marginRight: '8px' }} />
          <ListItemText primary="Expand Your Offerings" />
        </ListItem>
        <ListItem disablePadding>
          <CheckIcon sx={{ marginRight: '8px' }} />
          <ListItemText primary="Invest In Marketing" />
        </ListItem>
        <ListItem disablePadding>
          <CheckIcon sx={{ marginRight: '8px' }} />
          <ListItemText primary="Build Strategic Partnerships" />
        </ListItem>
      </List>
    </Typography>
        </Box>
      </Grid>
      {/* Second Box */}
      <Grid item xs={12} sm={6}>
        <Box sx={{ backgroundColor: '#e0e0e0', height: '100%' }}>
          <CardMedia
            component="img"
            src="https://t4.ftcdn.net/jpg/06/03/23/95/240_F_603239501_8HbTZsqORNHxgohTgLKFsWoDfsVE0hAR.jpg"
            alt="Image"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Grid>
    </Grid>
   
<Grid container spacing={2} marginTop={10}>
      {/* First Box */}
      <Grid item xs={12} sm={6}>
        <Box sx={{ backgroundColor: '#e0e0e0', height: '100%' }}>
          <CardMedia
            component="img"
            src="https://t3.ftcdn.net/jpg/02/55/22/68/360_F_255226859_Rhqr5hflr2esVXHQE1sS1bWxmZxs0gWI.jpg"
            alt="Image"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Grid>
    
      {/* Second Box */}
      <Grid item xs={12} sm={6}>
        <Box sx={{ backgroundColor: 'white', padding: 2 }}>
          <Typography variant="h4" component="h2">
          Building digital solutions for a seamless user experience.
          </Typography>
          <Typography variant="body1" component="p">
          “<span style={{color:"blue"}}>Website development</span>  and  <span style={{color:"blue"}}>app development</span> are essential components of a successful digital strategy. A well-designed and developed website can create a positive first impression, engage with the target audience, and drive conversions, while a well-designed and developed app can improve the user experience and offer unique features and functionality. When deciding whether to invest in website development, app development, or both, it’s important to consider the goals and needs of the business and the target audience. Both website and app development require a focus on user experience, mobile responsiveness, search engine optimization, branding, security, and analytics. Create a  <span style={{color:"blue"}}>digital presence</span>  that supports their growth goals and delivers a seamless and enjoyable experience to their customers”
          </Typography>
        </Box>
      </Grid>
    </Grid>
      </>
    );

}

export default Aboutus;
