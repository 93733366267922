import React from "react";
import Slider from "react-slick";
import './AnimatedCard.css';

// Utility function to generate random unique numbers
const generateUniqueNumbers = (count, min) => {
  const numbers = new Set();
  while (numbers.size < count) {
    numbers.add(Math.floor(Math.random() * (10000 - min + 1)) + min);
  }
  return Array.from(numbers);
};

function AnimatedCard() {
  // Unique service names
  const services = [
    "Web Development", "App Development", "Digital Marketing", "IT Services", "Ads Targeting",
    "Software Development", "Cloud Computing", "Cybersecurity", "Data Analytics", "Blockchain Development",
    "AI and Machine Learning", "AR/VR Development", "Network Solutions", "Managed IT Services", "Banking",
    "Insurance", "Investment Management", "Accounting Services", "Tax Preparation", "Financial Consulting",
    "Payment Processing", "Wealth Management", "Credit Card Services", "Mortgage Services", "General Healthcare",
    "Specialized Medical Care", "Dental Services", "Mental Health Services", "Telemedicine", "Pharmacy Services",
    "Nursing and Elderly Care", "Medical Research", "Healthcare Consulting", "Healthcare IT Services", "K-12 Education",
    "Higher Education", "Online Learning Platforms", "Tutoring Services", "Professional Training and Development",
    "Educational Consulting", "Language Learning", "Certification Programs", "E-Learning Development", "Legal Consulting",
    "Corporate Law", "Intellectual Property Law", "Family Law", "Criminal Defense", "Estate Planning",
    "Immigration Services", "Contract Law", "Dispute Resolution", "Notary Services", "Consulting Services",
    "Human Resources (HR)", "Recruitment Services", "Project Management", "Business Process Outsourcing (BPO)",
    "Market Research", "Business Strategy Development", "Supply Chain Management", "Quality Assurance",
    "Customer Support Services", "Graphic Design", "Video Production", "Photography", "Content Creation",
    "Copywriting", "Branding Services", "Animation and Motion Graphics", "UX/UI Design", "Print Design",
    "Product Design", "Logistics and Shipping", "Freight Services", "Courier Services", "Public Transportation",
    "Taxi and Ride-Sharing", "Airport Shuttle Services", "Vehicle Rental", "Maritime Services", "Aviation Services",
    "Hotel Services", "Event Planning", "Tourism Services", "Catering Services", "Travel Agencies",
    "Resort and Spa Services", "Restaurant Services", "Cruise Services", "Conference and Meeting Services",
    "Wedding Planning", "Waste Management", "Recycling Services", "Environmental Consulting", "Sustainability Services",
    "Water Treatment", "Air Quality Monitoring", "Renewable Energy Solutions", "Environmental Impact Assessment",
    "Conservation Services", "Eco-Tourism", "Residential Real Estate", "Commercial Real Estate", "Property Management",
    "Real Estate Consulting", "Real Estate Appraisal", "Mortgage Brokerage", "Real Estate Development",
    "Leasing Services", "Facility Management", "Real Estate Investment", "Physical Security Services",
    "Cybersecurity Services", "Surveillance Systems", "Private Investigation", "Event Security", "Security Consulting",
    "Alarm Monitoring", "Access Control Systems", "Fire Safety Services", "Emergency Response Services",
    "E-Commerce Services", "Retail Consulting", "Merchandising Services", "Point of Sale Solutions", "Inventory Management",
    "Customer Loyalty Programs", "Store Design and Layout", "Product Sourcing", "Retail Analytics", "Product Design and Development",
    "Prototyping Services", "Custom Manufacturing", "Mass Production", "Quality Control and Testing", "Packaging Services",
    "Industrial Engineering", "Maintenance and Repair", "Manufacturing Consulting", "Telecommunications", "VoIP Services",
    "Internet Service Providers (ISP)", "Mobile Network Services", "Data Center Services", "Satellite Communication",
    "Broadcasting Services", "Media and Public Relations", "Corporate Communication", "Crisis Communication",
    "Crop Production", "Livestock Management", "AgriTech Solutions", "Agricultural Consulting", "Farm Management",
    "Irrigation Services", "Soil Testing", "Agricultural Research", "Organic Farming", "Agricultural Machinery Services",
    "Film Production", "Music Production", "Event Management", "Theater Production", "Game Development",
    "Streaming Services", "Talent Management", "Advertising and Media Buying", "Public Relations",
    "Concert and Festival Services", "Residential Construction", "Commercial Construction", "Architectural Design",
    "Civil Engineering", "Project Management", "Renovation and Remodeling", "Interior Design", "Construction Consulting",
    "Landscaping Services", "Real Estate Development"
  ];

  // Generate unique numbers greater than 500
  const numbers = generateUniqueNumbers(services.length, 500);

  // Map services and numbers to data
  const data = services.map((service, index) => ({
    number: numbers[index],
    name: service
  }));

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data.map((item, index) => (
          <div key={index} className="noContainer">
            <h2 style={{ color: "#EEF35F" }}>{item.number}+</h2>
            <h5 style={{ marginTop: "10px" }}>{item.name}</h5>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AnimatedCard;
