
import React, { useState, useEffect } from 'react';
import './AnimatedText.css';
import ForumIcon from '@mui/icons-material/Forum';
const AnimatedText = () => {
  const [text, setText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const animatedText = 'Boost your business online Expand reach and attract new customers now.';

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % animatedText.length);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setText(animatedText.slice(0, currentIndex + 1));
  }, [currentIndex, animatedText]);

  return (
    <div className="animated-text-overlay-container">
      <h1 className="animated-text"> <ForumIcon style={{marginRight:"10px"}}/> {text}</h1>
    </div>
  );
};

export default AnimatedText;
