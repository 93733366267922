import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './arvr.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/imageslider/pdbckragdtianq1rjuxf",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/imageslider/e0r0ksiryuj5b5sw9y6g",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/imageslider/zxqkcmdc7gy3d0oqgh7v",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/imageslider/a6qxmhaj3gk8sfhwzipv",
  "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/imageslider/ib2ekj0q2fzsqkbzjxsc",

  


 

];

function ArVr() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false

    };
  


  
  return (
    <div>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>AR/VR</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white"}}>
    Experience the awakening of your senses as you step into the captivating world of our breathtaking VR games. Get ready to feel the rush of adrenaline with every step and action, as our cutting-edge virtual reality technology takes gaming to unprecedented heights. At BringMark, we are at the forefront of the gaming industry's evolution, pushing the boundaries of what's possible in immersive experiences.

Our team of talented developers and designers is dedicated to crafting unforgettable VR games that transport players to new dimensions of excitement and wonder. Through stunning visuals, compelling storytelling, and intuitive gameplay mechanics, we create experiences that will leave you spellbound and yearning for more.

As virtual reality rapidly becomes the preferred medium for immersive entertainment, now is the perfect time to seize the opportunity and make your mark in the gaming world. Whether you're an avid gamer seeking thrills or a visionary entrepreneur looking to make a statement, our VR game development services offer a gateway to a world of endless possibilities.

Join the revolution and embark on thrilling adventures with our cutting-edge VR games. Experience the future of gaming and get ready to be captivated like never before. BringMark is your gateway to a universe where imagination meets reality, and the possibilities are limited only by your own dreams. Get ready to step into the extraordinary world of virtual reality and unleash your potential for greatness..
        </Typography>


<div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/abpcjh09we3zmy0racre"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/lol7qb1gagoglonjbdls"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/p1uaebb3osbt80ntud5a"
image4= "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/famawbjp5xxj1uio8uiw"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/rqhvrcchldxrfhtuojmu"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/xpitwp6irscrpf2xgb1v"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/derod0hs0e7su3nwvo8t"
image8= "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/ohzlghhsf0tjtznlgusa"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/okkglenigfjnhgmznodv"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/buypnu8dq068yyadbksk"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/kl4fechj0kgfwkqqufh4"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/marque/rjs05szcv3uhae5uqwcz"
  />
</div>
        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Embark on a journey of limitless creativity with AR/VR (Augmented Reality/Virtual Reality)


</Typography>
<Typography  style={{color:"#545357"}}>

Step into the world of BringMark, your ultimate destination for crafting professional and industry-standard web-based AR/VR filters and interactive experiences. Similar to Meta's Spark and Snap's Lens Studio, our platform empowers digital designers to unleash their creativity and effortlessly create captivating augmented reality content.

With our intuitive drag-and-drop tools, your creative vision comes to life in real-time. Whether you're designing immersive 360 experiences or crafting dynamic face filters, BringMark provides the perfect canvas to explore the endless possibilities of augmented reality. Join our community of innovative creators and let your imagination run wild as you shape the future of interactive AR/VR experiences </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/gif/cuj5mbwur7h83et1arkl" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
      
BringMark stands at the forefront of cutting-edge webAR technologies, uniting their powers to deliver exceptional results. By seamlessly integrating renowned technologies such as Three.js, Google Media Pipe, and 8th Wall, we provide you with a robust toolkit for crafting immersive and interactive AR experiences like never before. Benefit from the advancements and expertise of these industry-leading technologies, all conveniently accessible within the intuitive bringMark platform.

But our commitment to excellence doesn't end there. Our platform goes beyond merely bringing together the best-in-class webAR technologies; it optimizes them under the hood to ensure seamless performance across a wide range of mobile devices, spanning from older models to the latest innovations. You can focus entirely on unleashing your creativity and building extraordinary AR content while we take care of the technical complexities, ensuring your creations come to life flawlessly for every user.

With BringMark, we empower creators like you to push the boundaries of augmented reality, making it accessible, engaging, and delightful for your audience. Embrace the power of webAR, harness the expertise of our integrated technologies, and watch your imagination soar as you create captivating AR experiences that captivate the world. Welcome to the future of webAR, where possibilities are limitless, and every idea can be transformed into an awe-inspiring reality.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/gif/xpnbq20yybl37hw0ypsb" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unlock boundless creativity and immerse yourself in the transformative Power of Real-Time webAR Creation.
</Typography>
<Typography  style={{color:"#545357"}}>


Experience the seamless magic of BringMark, where simplicity and speed converge to redefine AR content creation. Our platform enables you to effortlessly craft stunning web-based AR experiences, akin to filling out a form or creating a social media post - no coding knowledge required! With our user-friendly interface, unleash your creativity and captivate your audience with interactive experiences that leave them spellbound. Embrace the future of augmented reality with BringMark, and let your imagination take flight in the immersive world of web-based AR </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Awaken your senses in the captivating world of our breathtaking VR games. Feel the rush of adrenaline with each step and action, as our cutting-edge virtual reality technology elevates gaming to new heights. At BringMark, we lead the gaming industry's evolution, pushing the boundaries of immersive experiences.

Our dedicated team of developers and designers crafts unforgettable VR games, transporting players to new dimensions of excitement and wonder. Through stunning visuals, compelling storytelling, and intuitive gameplay mechanics, our experiences leave you spellbound and yearning for more.

As virtual reality becomes the preferred medium for immersive entertainment, seize the opportunity and make your mark in the gaming world. Whether an avid gamer seeking thrills or a visionary entrepreneur looking to make a statement, our VR game development services open a world of endless possibilities.

Join the revolution and embark on thrilling adventures with our cutting-edge VR games. Experience the future of gaming and be captivated like never before. BringMark invites you to a universe where imagination meets reality, and possibilities are boundless. Step into the extraordinary world of virtual reality and unleash your potential for greatness. Let your dreams shape the next era of gaming excellence.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>


  Step into the future of "Futuristic Real-Time VR/AR" and unleash boundless creativity as you craft captivating experiences. Immerse audiences worldwide in the next frontier of augmented reality.


</Typography>
<Typography  style={{color:"#545357"}}>

Embark on a visionary expedition into the dynamic AR/VR software product market, where you'll unlock boundless possibilities and experience the seamless fusion of cutting-edge technology and captivating creativity. Prepare to shape a future of unparalleled innovation and immersive experiences beyond imagination  </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/gif/oznetxrfe6phf6twxpup" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Welcome to the cutting-edge world of immersive technology, where our Video Wall and AR/VR product line redefine visual experiences like never before. Just as our breathtaking VR games push the boundaries of interactive entertainment, our Video Wall products captivate audiences with stunning visual displays.

With our Video Wall solutions, you gain the power to create seamless, larger-than-life displays that leave a lasting impact in various settings, from retail environments to corporate boardrooms. Effortlessly manage content in real-time and unleash your creativity with intuitive controls that bring your visions to life.

Similarly, our AR/VR product line empowers you to craft captivating augmented reality experiences that captivate users and boost engagement. Seamlessly integrate AR/VR technology into your marketing campaigns, training programs, or product demonstrations to immerse your audience in a world of interactive wonders.

Whether you seek to impress customers with immersive retail displays or engage employees with dynamic presentations, our Video Wall and AR/VR product line offer a gateway to a world of endless possibilities. Embrace the future of immersive technologies and elevate your business to new heights with our transformative solutions. Welcome to a universe where imagination meets reality, revolutionizing how you connect with your audience and set new standards of excellence.        </Typography>


{/* <ProjectCard/> */}
<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/arvr/gif/yupwrpwenxts1jeenzoz" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Why did BringMark choose to incorporate AR/VR products into its offerings?


</Typography>
<Typography  style={{color:"#545357"}}>

BringMark chose to integrate AR/VR products into its offerings because these technologies represent the future of immersive experiences. By leveraging cutting-edge AR/VR capabilities, BringMark can provide captivating and interactive solutions to its clients, taking their engagements to new dimensions of excitement and wonder. Embracing AR/VR allows BringMark to stay at the forefront of the industry's evolution, unlocking limitless creative possibilities and offering a gateway to a world where imagination meets reality.  </Typography>
</div>


</div>

      
    </div>
  )
}

export default ArVr