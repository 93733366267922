import { Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import image1 from '../../assets/WhoWeWorkWith/1.png'
import image2 from '../../assets/WhoWeWorkWith/2.png'
import image3 from '../../assets/WhoWeWorkWith/3.png'
import image4 from '../../assets/WhoWeWorkWith/4.png'
const StepperStep = () => {
  return (
    <Box style={{padding:'50px', backgroundColor:'black', marginBottom:'100px',borderRadius:"20px",border:"1px solid #47494B"}}>

        <Grid container>

             <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} style={{backgroundColor: 'black',}}>
<Card style={{display:'flex',boxShadow:'none', flexDirection:'column',height:'100%',  justifyContent:'center', margin:'auto', backgroundColor: 'black', padding:'30px'}}> 
 {/* <Typography variant='h4' style={{color:'whitesmoke', fontWeight:'bold',marginBottom:'15px' }}>Who We Work With
</Typography>  */}

<Typography>
    Bringmark, a top business growth partner in India, has empowered over 2,000 businesses—from startups to enterprises—by delivering innovative solutions that drive success and scalability. We specialize in accelerating growth across all sectors, offering tailored strategies that meet diverse business needs and ensure long-term success.
</Typography>
</Card>
             </Grid>

             <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} >

       
       <Grid container spacing={2}>

        <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} style={{width:'100%'}} >
            <Card style={{backgroundColor:'#151616', borderRadius:'15px', padding:'20px',border: '1px solid #2D2E2E' }}> 
                
                <img src={image1} alt="" />
                <Typography style={{marginTop:'10px'}} >Start Up Business
</Typography></Card>
        </Grid>

        <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} style={{width:'100%'}} >
            <Card style={{backgroundColor:'#151616', borderRadius:'15px', padding:'20px',border: '1px solid #2D2E2E'}}> 
                
                
            <img src={image2} alt="" />
                <Typography style={{marginTop:'10px',fontSize:"14px"}} >Small & Medium Business
</Typography></Card>
        </Grid>

        <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} style={{width:'100%'}}>
            <Card style={{backgroundColor:'#151616', borderRadius:'15px', padding:'20px',border: '1px solid #2D2E2E'}}>  
                
            <img src={image3} alt="" />
                 <Typography style={{marginTop:'10px'}} >Enterprise
</Typography></Card>
        </Grid>

        <Grid item xsm={12} sm={12} md={6} lg={6} xl={6} style={{width:'100%'}}>
            <Card style={{backgroundColor:'#151616', borderRadius:'15px', padding:'20px',border: '1px solid #2D2E2E'}}>   
                
                
            <img src={image4} alt="" />
                <Typography style={{marginTop:'10px'}} >Agencies
</Typography></Card>
        </Grid>
       </Grid>
                
                </Grid>
        </Grid>

    </Box>
  )
}

export default StepperStep