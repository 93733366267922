import React,{ useState , useEffect} from "react"
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
// import CheckIcon from "@mui/icons-material/check";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Container, Grid, Box, Card, CardContent, Typography,useMediaQuery, Button, TextField } from '@mui/material';
// import NumberCounter from './NumberCounter';
// import HeadingWithParagraph from './HeadingWithParagraph';
// import { Circle } from '@mui/icons-material';

import { CSSTransition } from 'react-transition-group';
// import './Banner.css';
const Banner = () => {
    const bannerStyle = {
      position: 'relative',
      width: '100%',
      height: '400px', // Adjust the height as needed
      backgroundImage: 'url("https://t4.ftcdn.net/jpg/01/25/33/97/240_F_125339765_rkLwixYPwBIC4SoXJ3OqXXlzGv4BQ5W7.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  
    const bannerTextStyle = {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      color: '#fff',
      fontSize: '2.5rem', // Adjust the font size as needed
      animation: 'runningText 10s linear infinite',
    };
  
    return (
      <Box component="div" style={bannerStyle}>
        <Typography variant="h1" style={bannerTextStyle}>
          Blog
        </Typography>
        <style>
          {`
          @keyframes runningText {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
        </style>
      </Box>
    );
  };
  
  
  
const Blog = () => {
const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <>
        <div>
          {/* Other components */}
          <Banner />
          
          {/* Other components */}
        </div>
        <Box display="flex" alignItems="center" justifyContent="center" marginTop ={10}mb={2}>
            {/* <Circle sx={{ fontSize: 40, color: '#F44336', marginRight: 10 }} /> */}
            <Typography variant="h4" align="center" color="orange"fontSize={20} gutterBottom>
            RECENT POSTS
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            {/* <Circle sx={{ fontSize: 40, color: '#F44336', marginRight: 10 }} /> */}
            <Typography variant="h4" align="center" fontSize={40} fontWeight="bold"gutterBottom>
            Recent News In Business
            </Typography>
            
          </Box>
          <Button
      variant="contained"
      sx={{
        backgroundColor: '#FF9800',
        color: '#FFFFFF',
        borderRadius:'30px',marginBottom:'20px',
        '@media (max-width: 600px)': {
          width: '50%',
          marginBottom: '30px',
        },
      }}
    >
      Read More
    </Button>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <img
          src="https://t4.ftcdn.net/jpg/02/07/53/83/240_F_207538366_r6yerLIhPIU5uRkk66T1QUzTcpI9rtzZ.jpg"
          alt="Image"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
        Welcome to our guide on <span style={{color:"blue"}}>website design, development</span>, and <span style={{color:"blue"}}>SEO</span>! We know that creating a successful online presence can be a challenge, which is why we’re here to help you understand the key elements that go into building a website that works for your business.
        </Typography>
        <Typography variant="body1">
        Your website is the online face of your business, and it needs to be designed, developed, and optimized for search engines in order to be effective. In this post, we’ll explore what you need to know about website design, development, and SEO to create a successful online presence.
        </Typography>
        <Typography variant="body1">
        In today’s <span style={{color:"blue"}}>digital world</span>, having a strong online presence is essential for businesses of all sizes. Whether you’re looking to build an app, create a website, or improve your search engine rankings, there are a few key things you need to know. In this post, we’ll explore the basics of app development, website design and development, and SEO to help you create a successful online presence.
        </Typography>
        
      </Grid>
      
    </Grid>
    <Grid item xs={12} sm={6} marginTop={10}>
        <Typography variant="h4" gutterBottom>
        WEBSITE DESIGN
        </Typography>
        <Typography variant="body1">
        When it comes to website design, our focus is on creating a website that looks great, is easy to navigate, and represents your brand identity. We make sure that your website’s layout is organized and easy to understand, with high-quality images that are optimized for fast loading. We also make sure that your website is responsive, so that it looks great on any device, whether it’s a desktop computer, tablet, or smartphone.
        </Typography>
        <Typography variant="h4" gutterBottom marginTop={5}>
        WEBSITE DEVELOPMENT
        </Typography>
        <Typography variant="body1">
        Our website development services are all about taking your<span style={{color:"blue"}}> website’s design</span> and turning it into a functional website that works the way it should. We use a content management system (CMS) to make it easy for you to update your website’s content, and we implement security measures to make sure that your website is protected against hacking and malware. We also make sure that your website is optimized for speed and accessibility, so that your visitors can find what they’re looking for quickly and easily.
        </Typography>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" marginTop={5}>
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
        Navigation:
      {/* </Typography> */}
      {/* <Typography variant={isMobile ? 'body2' : 'body1'} component="p"> */}
        <span style={{fontSize:"15px" }}> Your <span style={{color:"blue"}}>website’s navigation</span> should be easy to use and intuitive. Visitors should be able to find what they’re looking for quickly and easily.</span>
      </Typography>
      
    </Box>
     <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
        Layout:
      {/* </Typography> */}
      {/* <Typography variant={isMobile ? 'body2' : 'body1'} component="p"> */}
        <span style={{fontSize:"15px" }}>Your website’s layout should be clean and organized, with content grouped logically and clearly labeled.</span>
      </Typography>
      
    </Box>
     <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h2'} component="h2" sx={{ marginTop:"5px",fontSize:"18px" }}>
        Images:
      {/* </Typography> */}
      {/* <Typography variant={isMobile ? 'body2' : 'body1'} component="p" sx={{ marginTop:"20px" }}> */}
      <span style={{ color:"blue",fontSize:"15px"}}>High-quality images</span><span style={{fontSize:"15px" }}> can make your website more engaging and visually appealing. Make sure your images are optimized for web use and don’t slow down your website’s loading speed.</span> 
      </Typography>
      
    </Box>
      </Grid>
      <Grid container spacing={2} marginTop={10}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" component="h2" marginTop={1} gutterBottom>
        SEARCH ENGINE OPTIMIZATION 
        </Typography>
        <Typography variant="body1">
        <span style={{color:"blue"}}>Search engine optimization </span>(SEO) is an important part of any website’s success, and we’re here to help you get the most out of your website’s visibility in search engine results pages (SERPs). We use keyword research to identify the keywords and phrases that your  target audience is searching for, and we optimize your website’s pages for those keywords. We also work to build backlinks to your website from other websites, which can help increase your website’s authority and visibility in search engines.
        </Typography>
        
     <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Keyword Research:
     
        <span style={{fontSize:"15px"  }}> Identify the keywords and phrases that your <span style={{color:"blue"}}>target audience</span> is searching for, and include them in your website’s content.</span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      On-Page Optimization: 
     
        <span style={{fontSize:"15px" }}>Optimize your website’s pages for <span style={{color:"blue"}}>specific keywords </span>, including title tags, meta descriptions, and content.</span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Backlinks: 
     
        <span style={{fontSize:"15px" }}>Backlinks are links from other websites to your website. They can help increase your <span style={{color:"blue"}}> website’s authority</span> and visibility in search engines.</span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Local SEO: 
     
        <span style={{fontSize:"15px" }}>If you have a physical location, make sure your website is optimized for local search. This includes creating a <span style={{color:"blue"}}> Google My Business</span> page and including your address and contact information on your website.</span>
      </Typography>
      
    </Box>
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <img
          src="https://media.istockphoto.com/id/1093508248/photo/modern-work-table-with-computer-laptop-and-cityscapes-view-from-window-business-concepts-ideas.webp?b=1&s=170667a&w=0&k=20&c=tE2s9RgqmXEzaKl08g8O95CBb4mrptJspmnqNJ1YSn8="
          alt="Image"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
      
    </Grid>
    <Grid container spacing={2} marginTop={7}>
      <Grid item xs={12} sm={6}>
        <img
          src="https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxvZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
          alt="Image"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
     
      <Grid item xs={12} sm={6}marginTop={8}>
        <Typography variant="h4" component="h2" gutterBottom>
        APPLICATION DEVELOPMENT
        </Typography>
        <Typography variant="body1">
        <span style={{color:"blue"}}>Mobile applications </span>have become a major part of our daily lives, and they’re an essential tool for businesses looking to reach their customers on the go. Here are a few things to keep in mind when developing an app:
        </Typography>
        
     <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Purpose: 
     
        <span style={{fontSize:"15px"  }}>Your app should have a clear purpose that solves a problem or provides value to your users. </span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Platform: 
     
        <span style={{fontSize:"15px"  }}>Decide which platform(s) you want your app to be available on (e.g., iOS, Android). </span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Design:
     
        <span style={{fontSize:"15px"  }}>Your app’s design should be user-friendly and consistent with your brand identity. </span>
      </Typography>
      
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
      <Typography variant={isMobile ? 'h6' : 'h4'} component="h2" sx={{ marginRight: '8px',marginTop:"5px",fontSize:"18px" }}>
      Functionality:  
     
        <span style={{fontSize:"15px"  }}>Your app should be easy to use and bug-free, with  updates to fix any issues. </span>
      </Typography>
      
    </Box>
    </Grid> 
      
    </Grid>
          </>
      );
      
      
  };
  
  
  export default Blog;