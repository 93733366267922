import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const DartDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Dart Developers</Typography>

<Typography > Do you have an idea? Bring it to life by hiring your dedicated Dart developers for a seamless user experience.</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/zb3m0byorejmnqwwyarc" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Dart is an open-source, object-oriented, class-based programming language web and mobile app development. It was invented by Google in 2011. A Dart developer may create native software apps for a variety of platforms, including iOS, Android, Windows, and others, using the Dart language and accompanying Flutter SDK. A Dart developer can create full-featured mobile and web apps that work both on the client and server sides.
 </Typography>

    <Typography style={{marginTop:'40px'}}> Dart is a rapidly growing language in terms of developer demand, particularly amongst mobile and web app developers. According to the market study 2020, Dart has climbed to the seventh most widely used programming language. The increasing popularity of the Dart programming language and the Flutter platform has developed a growing market for Flutter and Dart developers.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Dart Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Expertise: </Typography>
    <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Bringmark has amassed extensive experience over the past few years in web and mobile app development. Our team comprises top-tier experts who harness the full potential of Dart to deliver the advantages that your project demands.</Typography>

   <Typography variant='h4' marginTop={5} fontSize={22}>2. Timely Delivery of Quality: </Typography>
   <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> It's a universally acknowledged truth that clients expect their projects to be completed promptly. Therefore, we prioritize and execute projects with efficiency and punctuality. Each project we undertake is developed and rigorously tested without any flaws or errors. Clients receive a final product characterized by exceptional functionality, a delightful user experience, and a high level of professionalism. </Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Strategic Dart Solutions:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> At Bringmark, our clients encounter work of the utmost caliber, executed with precision. Every Dart development project we undertake undergoes meticulous testing to ensure top-notch quality. Your project undergoes a series of well-thought-out strategies and implementations before it reaches your hands. </Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Expert Dart Developers:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our team of Dart development experts consists of dependable professionals with extensive industry knowledge. They remain prepared to tackle new challenges at all times. At Bringmark, our experts approach tasks in a manner that propels you ahead of your competitors.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Version Control and Collaboration: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>  Our Dart Developers have Proficiency in version control systems like Git and the ability to work collaboratively with clients.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Deployment: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Prepare your application for deployment to the desired platforms (web, mobile, or desktop). We Ensure that the deployment process is well-documented and reproducible.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7. Compliance and Regulations: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Ensure that the application complies with relevant industry regulations and standards, such as data privacy regulations.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>8. Post-Launch Support:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Offer post-launch support to address any unforeseen issues and to assist with future enhancements or expansions.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Feedback and Iteration:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Gather feedback from users and the client after the application is in use. We Use feedback to plan and implement iterative improvements and updates.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Project Planning:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Create a detailed project plan that includes milestones, timelines, and deliverables. Identify the technologies, tools, and frameworks that will be used, particularly Dart and any related libraries or frameworks like Flutter.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default DartDevelopers