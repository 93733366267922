import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const IOTSolutionArchitecture = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire IOT Solution Arch Developers</Typography>

<Typography >Hire IOT Developers To Built Augment IOT Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/nsojuvnwt6m5hi6iknxp" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> The Internet of Things (IoT) represents a contemporary technological breakthrough that has unlocked a multitude of previously unexplored capabilities. In its essence, IoT simplifies the remote accessibility of virtually any physical device. Consider this scenario: as you embark on your daily commute, recalling whether you switched off the fan, air conditioner, TV, music system, and even secured the front door can be a mental strain. Enter IoT, which empowers you to effortlessly engage with your residential and commercial appliances remotely. It extends the capability to control and monitor your premises' equipment, enabling you to conveniently lock and unlock doors from any location.</Typography>

    <Typography style={{marginTop:'40px'}}> a combination of business models, fresh technologies, partnerships, and innovative market approaches is imperative. To cater to the varied needs of customers and businesses, a multifaceted and intricate structure is essential. Within this structure, a pivotal role is held by the IoT Architect. This professional shoulders the responsibility of formulating the comprehensive IoT solutions strategy, overseeing product execution capabilities, and orchestrating the alignment of network, IT infrastructure, and client requirements. </Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By IoT Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1.IoT Wearable Technology Development:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Wearable technology heavily relies on IoT technology, and our adept IoT developers employ cutting-edge technologies to create IoT applications for wearable devices compatible with Android and iOS platforms.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.Cloud-Based Solutions & IoT Integration:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our IoT developers harness the power of cloud support to enhance the features, scalability, efficiency, and connectivity of your IoT applications. We seamlessly integrate IoT technology with your existing systems to meet your business strategy's objectives.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3.Tailored IoT Solutions:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Prior to commencing any project, we invest time in comprehensively understanding each client's needs and objectives. Subsequently, we deliver IoT solutions meticulously crafted to align with your company's specific requirements, drawing from both your specifications and our IoT expertise.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4.IoT Sensor Innovation: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Enhancing end-users' lives through sensor development is our specialty. Our team of IoT developers facilitates this connectivity, enabling applications such as vehicle tracking, advanced locking systems, weather monitoring, and much more.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.IoT-Driven Data Analytics: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>The amalgamation of IoT with applications and other systems generates copious amounts of data. Our IoT developers leverage this data to provide insights into customer behavior, enabling you to achieve better outcomes.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.Cost Optimization:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Help our client to optimize costs associated with IoT implementation, including managing data storage costs and identifying areas for efficiency improvement. </Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7.Technology Stack Selection:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We guide our client to choose the right IoT hardware and software components based on their requirements. Consider factors such as scalability, security, compatibility, and cost-effectiveness.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>8.Client Needs Assessment: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We Start by thoroughly understanding your business objectives and challenges. What problems are you trying to solve with IoT? What are your goals for implementing IoT technology?</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9.Architecture Design:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We Create a detailed IoT architecture that outlines the overall system design, data flow, and integration points. Consider issues such as connectivity protocols, data transmission, and edge computing.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.IoT Support & Maintenance:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Implementing advanced technologies like the Internet of Things is a multifaceted process that necessitates ongoing maintenance to sustain functionality. Our team of IoT developers offers these essential services to uphold the efficiency of your existing systems.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default IOTSolutionArchitecture