import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const ShopifyDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Shopify Developers</Typography>

<Typography >Hire Shopify Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/dd0k4qlvdqausgz7bvme" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Shopify is an all-in-one e-commerce platform that empowers individuals and businesses to create their own online stores and effectively sell products and services over the internet. With a user-friendly interface and a comprehensive set of tools, Shopify makes it remarkably straightforward to establish and manage an online business. This platform facilitates store design, product listing, secure payment processing, inventory management, and more, offering a seamless end-to-end solution for launching and running successful e-commerce ventures. Whether you're a beginner or an experienced entrepreneur, Shopify provides the necessary resources to transform your ideas into a fully functional and visually appealing online store. Crafting an online platform for selling products has been revolutionized by the expansive growth of the E-commerce landscape. Within this flourishing environment, Shopify emerges as a prime contender, especially for those aspiring to engage in the realm of international online commerce. Gradually evolving, Shopify has firmly established itself as one of the foremost e-commerce solutions available. </Typography>

    <Typography style={{marginTop:'40px'}}> Our adept team of Shopify eCommerce developers transform Shopify into a fully functional website by seamlessly blending captivating elements with search engine optimized features. Our experience spans various industries worldwide, with a track record of crafting eCommerce websites that deliver results. Choose BringMark to hire Shopify programmers and gain access to a comprehensive eCommerce solution. Additionally, we provide diverse and flexible hiring models for Shopify store development. Our range of Shopify solutions is specifically designed to align with the unique requirements of business owners. We take great care to ensure that each project isn't deemed complete until our client's satisfaction is achieved. Our commitment lies in delivering a project that not only meets, but surpasses your expectations, propelling you towards your business objectives. </Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Shopify Developers</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Consultive Approach</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our team prioritizes delivering your projects within specified timeframes without compromising quality. Our goal is to ensure client satisfaction through competitive pricing and excellent service.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Shopify Expert Developers</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Following rigorous interview processes, we onboard Shopify experts with extensive experience and the skills needed to handle a variety of projects.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Code Quality</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Shopify developers adhere rigorously to coding standards, striving to provide high-quality, scalable, reliable, and visually appealing solutions.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Transparent Workflow</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Throughout the entire development process, we maintain complete transparency, ensuring continuous communication while delivering services and solutions.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Data Security </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Safeguarding the confidentiality of our clients' private information remains our utmost priority. We continually update our practices to comply with the latest data security standards and management protocols.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Effective Online Marketing</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our team of SEO specialists has crafted a Shopify solution that simplifies web marketing, encompassing straightforward Google product submission and the creation of XML sitemaps.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Training and Education</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We provide training and resources to clients and their teams to help them effectively manage and grow their Shopify stores.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Payment Gateway Integration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We integrate secure payment gateways to facilitate smooth and secure online transactions.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Order Processing and Fulfillment</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We streamline order processing and fulfillment workflows to ensure timely and accurate deliveries.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Product Upload and Management</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We help clients efficiently upload and manage their product listings, including product descriptions, images, and pricing.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default ShopifyDevelopers