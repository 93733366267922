import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const VuejsDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Vuejs Developers</Typography>

<Typography >Hire Vuejs Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/fpgoszmgacf4xvjh9jdk" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>    

    <Typography> VueJS stands as a notable JavaScript framework, known for its adaptability and dynamism. Its primary purpose is to craft an array of advanced web applications, particularly for mobile devices, while also creating engaging user interfaces. What sets Vue JS development apart is its speed, leading to time and cost savings. Designed with flexibility in mind, VueJS is geared toward seamless integration with other view layer frameworks, emphasizing interoperability.VueJS has rapidly gained traction as a favored front-end technology. Major players in the IT industry, including Adobe, have embraced its HTML extensions and JavaScript foundation. Compared to other static frameworks in the market, Vue JS exhibits exceptional flexibility, reducing the necessity for comprehensive understanding of all its principles. This flexibility has garnered significant attention from web developers in a relatively short span of time. </Typography>

    <Typography style={{marginTop:'40px'}}> Vue.js developers are skilled professionals specializing in utilizing the Vue.js framework to build dynamic and responsive web applications. With expertise in Vue.js, these developers are adept at creating interactive user interfaces and engaging user experiences that cater to a wide range of devices, including mobile phones. Leveraging the framework's flexibility and efficiency, Vue.js developers are capable of streamlining development processes, leading to quicker turnaround times and cost-effective solutions. Their proficiency extends to integrating Vue.js with other view layer frameworks, enhancing the interoperability of applications. As Vue.js gains prominence in the front-end technology landscape, these developers find themselves at the forefront of innovation, utilizing its HTML extensions and JavaScript foundation to create cutting-edge solutions. Their ability to harness Vue.js's flexibility and simplicity has garnered attention from both established and emerging web developers, making them instrumental in driving the growth of this dynamic framework.</Typography>

    <Grid className='sticky'> 

<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Vuejs Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Vuejs Expertise </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> At BringMark, we understand the fast-paced nature of your enterprise and the ever-evolving marketplace. Our team of Vue.js experts is dedicated to delivering top-tier solutions and services to organizations of all sizes and industries, regardless of their location.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Single Page App Development </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Stand out from the competition with our Vue.js developers. We excel in crafting single-page web applications that will captivate and engage your users, providing a seamless and interactive experience.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Custom Vuejs Web Apps</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Leverage our Vue.js app development expertise to bring your unique vision to life within your desired timeframe, all while maintaining uncompromising quality. Our developers have a proven track record in delivering customized solutions across a wide spectrum of industries.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Native Mobile App Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> BringMark offers proficient Vue.js developers to assist you in creating cross-platform native mobile applications. Our Vue.js developers leverage Vue Native CLI and Vue Native Coding Language to develop highly interactive Vue Native apps that meet your business needs.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Real Time App Development </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Harness the power of Vue.js to create robust real-time applications, including cloud solutions, chat apps, and messengers. Our Vue.js developers bring extensive knowledge and expertise to the table, ensuring you benefit from cutting-edge Vue.js development services.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.  Vuejs Consulting Services</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Before you embark on your Vue.js development journey, consult our experts. They will help you gain a deeper understanding of your project's requirements and guide you towards a manageable and practical strategy. Contact us today to initiate this essential conversation.</Typography>

 
 <Typography variant='h4' marginTop={5} fontSize={22}>7.  Vuejs Migration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Trust our Vue.js migration specialists to efficiently and securely transform your existing applications and websites from other technologies to Vue.js. Whether it's for app modernization or any other purpose, our Vue.js developers follow a systematic approach to ensure a smooth transition.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>8. Years of Proven Expertise</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Many clients have entrusted our Vue.js developers for years to deliver outstanding software solutions. Our teams have been recognized with awards and honors, reflecting their dedication and experience in providing tailored web application services that align with your unique needs.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Effortless Project Management</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our specialists work diligently to keep your projects fresh and hassle-free. They excel in tackling challenges with ease and innovating on the fly for superior outcomes.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>10. Ensuring Complete Client Satisfaction</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We go the extra mile to guarantee our clients' satisfaction. We offer round-the-clock post-development support and maintenance, backed by booking solutions and cost-effective maintenance packages.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>

</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default VuejsDevelopers