import React,{ useState , useEffect} from "react"
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
// import CheckIcon from "@mui/icons-material/check";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Container, Grid, Box, Card, CardHeader, IconButton, CardContent, Typography, Button, TextField } from '@mui/material';
// import NumberCounter from './NumberCounter';
// import { Favorite, Share, MoreVert } from '@mui/icons-material';
import { Phone, LocationOn, WatchLater } from '@mui/icons-material';

// import { Circle } from '@mui/icons-material';

import { CSSTransition } from 'react-transition-group';
import Contact from "./Banner";
import Banner from "./Banner";
// import './Banner.css';
const ContactUs = () => {
    const cardStyle = {
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    };
  

     return(
      <Banner/>
     )

  };
  
  
  
  
const Contac = () => {
    return (
        <div>
          {/* Other components */}
     
  
          
          <Box
      sx={{
        backgroundImage: `url("https://t3.ftcdn.net/jpg/06/05/56/80/240_F_605568059_m4ei46ov06bqtNWyUtrBzVeJBDvWoevh.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '50px',
        paddingBottom: '80px',
        marginTop:'30px',
        height:'444px',
        marginBottom:"40px"
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '20px',
          maxWidth: '1200px',
          marginBottom:'10px',
          marginRight:'4px',
          marginLeft:'4px',
          margin: '0 auto',
          marginTop:'90px',
        }}
      >
        <Card sx={{ flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Phone"
            avatar={
              <IconButton sx={{ color: '#ff0000' }}>
                <Phone />
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="h6">Contact With Details </Typography>
            <Typography variant="body2" color="text.secondary">
            +918750753590,

            </Typography>
            <Typography variant="body2" color="text.secondary">
            +919997668959
            </Typography>
            <Typography variant="body2" color="text.secondary">
            info@sudo24.com
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Address"
            avatar={
              <IconButton sx={{ color: '#00ff00' }}>
                <LocationOn />
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="h6">Our Address</Typography>
            <Typography variant="body2" color="text.secondary">
            H- 666, 
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Noida,
            </Typography>
            <Typography variant="body2" color="text.secondary">
           Uttar Pradesh,
            </Typography>
            <Typography variant="body2" color="text.secondary">
            India 201301
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ flex: '1 1 300px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
          <CardHeader
            title="Work Hour"
            avatar={
              <IconButton sx={{ color: '#0000ff' }}>
                <WatchLater />
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="h6">Our Work Time</Typography>
            <Typography variant="body2" color="text.secondary">
            Monday - Sunday:



 
            </Typography>
            <Typography variant="body2" color="text.secondary">
            

           09.00 - 23.00

 
            </Typography>
            
          </CardContent>
        </Card>
      </Box>
    </Box>
  
  
          {/* Other components */}
        </div>
        
      );
   
      
  };
  
  
  export default ContactUs;