import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Planetech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `What-if Scenarios`
const featuresDescription = `Formulate various what-if situations and utilize straightforward comparison of schemes to conclude the optimal plan.`
const feauturesimage = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/dvu4mkz6eplleyhwmerg"

const featuresTitle2 = `Early & Mid-Season Planning`
const featuresDescription2 = `Plan well ahead of a season and also plan during the season with a robust module for in-season planning that auto-updates with the latest PlanTech data.`
const feauturesimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/q5zejc3qgc2anispjvtr"

const featuresTitle3 = `Unified Scheme`
const featuresDescription3 = `Maintain one trustworthy data source throughout the organization to boost transparency and precision.`
const feauturesimage3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/g7zpxwlhlshzhksajyyt"

const featuresTitle4 = `Flexible Planning`
const featuresDescription4 = `Adaptability in planning across channels, areas, and product hierarchy elevates the quality and efficiency of planning.`
const feauturesimage4 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/tcowak2lwyymjekiyrli"


// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Gather current schemes, stock, orders, external demand factors to produce an AI/ML based extended-term demand projection.`
const weeksimage = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/c1lxngsxxnrcax6huipu"

const weeksTitle2 = `Build`
const weeksDescription2 = `Harness AI-pushed demand predictions to create financial strategies for merchandising at numerous product hierarchy tiers to realize strategic goals.`
const weeksimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/jvo1gh9hdk2ftumtzseu"

const weeksTitle3 = `Monitor`
const weeksDescription3 = `Vigilantly track sales in comparison to schemes, and dynamically adapt using ML models and overlays. Efficiently oversee open-to-buy budgets.`
const weeksimage3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/l6ugk1hu16gdtstw4z7r"

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Usual rollout occurs category by category, encompassing instruction in new tools and workflows.`
const weeksimage4 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/xukb3mo4mubksdz2weij"

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Empowering sellers to promptly assess, enhance, and reimagine intricate resolutions for trade.`
const image1 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/lswfnen0p8vhwahzysdk"

const title2 = `Fashion`
const message2 = `Discern rapidly evolving client desires and unmatched ambiguities for more rapid expansion and client contentment.`
const image2 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/adh9gfy5tthz4xnqkpsj"

const title3 = `Hardgoods`
const message3 = `Secure efficiency and robustness by recognizing trade hazards and chances.`
const image3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/landingpage/nnuiszxc1v6qakr967ir"
// the end of cards

const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/vdejtoz4dp5v1scb9utq")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};



const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Sectors that can benefit from PlanTech.`
const message = `With efficient trade merchandise financial planning software, achieve full-spectrum insight, back digital evolution, and function as a trigger for info-guided choice-formation to steer enhanced results throughout the enterprise.`


function Planetech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
      
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>PlanTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>Tech and automatic product economic planning system</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Create wise, prediction-based retail economic strategies and efficiently oversee Open-to-Buy budgets.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>PlanTech</Typography>
      </div>

      
      </Grid>
      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>PlanTech Advantage</h2>
 
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
       SmartTech, a machine learning-driven retail financial planning solution, employs extended-term predictions to create enhanced strategies considering crucial limits and strategic goals across various tiers of the product structure.          
      </p>
</Grid>
     

      
  

 
    </div>
    <Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/lugdbrutg15nat7cdzdd" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Simple Assessment </h3>

              <Typography>Evaluations with OP, LY, and different schemes to evaluate the difference and strategize following the Go-To-Market approach.
               </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Enhance Efficiency</h3>
              <Typography>Transition from unchanging spreadsheets to scenario modeling, budgeting, forecasting, and financial planning for retailers with a system that exploits boundless data and AI.
                </Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/pi4dikjsibsjcykoslbo"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3> Improved Schemes</h3>
              <Typography>Formulate Enhanced strategies considering essential constraints and strategic objectives (Goal GM, EOP/BOP, ST, Decision Counts, etc.).
                </Typography>
            </div>
            {/* <div className='laptop_container1'> 
              <h3 style={{marginTop:"35px"}}>Reduce Overstocks and Stockouts</h3>
              <Typography>Identify lost sales through intelligent insights, and increase your sales margin through reduced inventory overstocks.</Typography>
            </div>*/}
          </div>
        </Grid>
      </Grid>  

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>PlanTech Features</h1>
        <Typography>We optimize our users' capabilities by automating recurrent calculations, utilizing machine learning to uncover trends.
           </Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>Implementation of PlanTech Takes Less than 8 Weeks </h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    {/* <Box className='forcast_cardContainer2'> 
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>10%</h2>
                </div>
                <p style={{color:"white"}}>dans djsjda kshdof the total sales of a brand.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >4-6%</h2>
                </div>
                <p style={{color:"white"}}>dans djsjda kshdof the total sales of a brand.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>80%</h2>
                </div>
                <p style={{color:"white"}}>dans djsjda kshdof the total sales of a brand.</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>~5-10%</h2>
                </div>
                <p style={{color:"white"}}>dans djsjda kshdof the total sales of a brand.</p>

        </div>
    </Box>
*/}


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore PlanTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Investigate how our customers steer their retail financial planning and budget allocation to attain margin expansion and inventory investment objectives.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/vm8hokujpjmjrnrkhdx7"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
      </Grid>
     

    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>



<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Planetech/xlf5bitohbxv8mcpfqh9"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Obtain comprehensive insights into the most recent developments in the market, technology, and how your colleagues are addressing their major financial planning issues using BM's cutting-edge merchandise planning solutions.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 




    

    </div>
  );
}

export default Planetech;
