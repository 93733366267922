import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const SalesforecDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire SalesForce Developers</Typography>

<Typography >Hire Salesforce Developers To Built Augment  Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/osfkxhtb5bxvnp2gjzsd" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Salesforce is a powerful CRM solution designed to facilitate seamless interactions between companies and their customers. Operating in the cloud, Salesforce eliminates the need for extensive IT expertise during setup. It has established a pioneering approach to customer engagement, revolutionizing the way businesses connect with their clientele.Central to its functionality is the prioritization of customer success. Salesforce empowers businesses by enabling them to monitor customer engagements comprehensively. This encompasses tracking customer interactions, executing targeted marketing campaigns, and offering a host of additional services that foster stronger customer relationships. By providing these capabilities, Salesforce plays a pivotal role in enhancing business performance and customer satisfaction. </Typography>

    <Typography style={{marginTop:'40px'}}> Our dedicated team of Salesforce experts specializes in crafting tailored solutions to address your distinct business requirements. With our extensive proficiency in harnessing the potential of Apex and Visualforce, we enhance and customize Salesforce to align perfectly with your needs. Our comprehensive suite of Salesforce Development services encompasses a wide array of offerings, including but not limited to Salesforce consulting, leveraging the Lightning framework, seamless data migration, personalized customization, intricate integration, and a multitude of other cutting-edge CRM services. </Typography>

    <Grid className='sticky'> 

<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Salesforce Developers:</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>1. Custom Salesforce Application Development </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>At BringMark involves our team of experts creating tailored Salesforce applications to meet your specific business and market needs, prioritizing security and scalability.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Salesforce Migration </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our adept Salesforce developers handle data migration, facilitating the smooth transition of your sales and opportunity data from one platform to another within Salesforce CRM.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3.Salesforce AppExchange </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Leveraging our experienced Salesforce programmers, we provide error-free AppExchange development solutions, resulting in highly functional and easily manageable Salesforce applications.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Salesforce Integration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>When integrating Salesforce with other systems is necessary, our proficient Salesforce developers ensure a secure and seamless integration using AppExchange tools and mechanisms.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Salesforce Customization </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We offer personalized Salesforce CRM software solutions that align with your requirements. Our dedicated Salesforce developers bring your vision to life on the digital platform.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Salesforce Marketing Services </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Salesforce offers marketing automation tools and services to help clients create and execute targeted marketing campaigns, track customer interactions, and analyze marketing performance.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Salesforce Implementation </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Salesforce assists clients in the actual implementation of Salesforce solutions, ensuring that the platform is configured to meet their specific requirements. This may involve data migration, custom development, and integration with other systems.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Salesforce Analytics and Reporting</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We Provide Salesforce's analytics and reporting tools to gain insights into your data and make informed business decisions. Salesforce offers services to help clients set up and optimize these capabilities.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Salesforce Security and Compliance: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Salesforce helps you to ensure the security and compliance of your Salesforce deployments by providing tools and services for data protection, user access control, and regulatory compliance.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.Salesforce Support & Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We offer ongoing support and maintenance services to ensure the reliability and performance of Salesforce solutions. This includes troubleshooting issues, applying updates, and addressing any technical challenges..</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default SalesforecDevelopers