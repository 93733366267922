import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import './hireweb.css'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const WordPressDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire WordPress Developers</Typography>

<Typography >Hire Wordpress Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/ts38jl0rw74rmkciu5cg" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> WordPress is a widely-used content management system that empowers individuals, businesses, and organizations to create and manage websites with ease. Its user-friendly interface and customizable features make it an accessible option for people with varying levels of technical expertise. Originally designed for blogging, WordPress has evolved into a versatile platform capable of supporting various types of websites, from personal blogs and portfolios to corporate sites and online stores. With a vast library of themes and plugins, users can tailor their websites to suit their unique needs, enhancing functionality and visual appeal. Its open-source nature encourages a collaborative community of developers, constantly contributing to its improvement and expansion. Whether you're a beginner or an experienced developer, WordPress offers the tools to bring your online presence to life.  </Typography>

    <Typography style={{marginTop:'40px'}}> A compelling justification for enlisting the services of a WordPress Developer arises from the potential necessity of having a proficient PHP coder to manage your WordPress site. Given that WordPress is constructed upon PHP and MySQL, the quest for an adept WordPress programmer is further compounded. The rationale behind this requisite expertise pertains to PHP's extensive usage as a well-supported web application language. Consequently, it becomes imperative to ensure the accessibility of your WordPress site across diverse servers and operating systems that cater to PHP. The adept skills of the appropriate WordPress Developer, exemplified by BringMark, can adeptly address this demand. </Typography>

    <Grid className='sticky'> 

<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By WordPress Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Multilingual and Internationalization</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Offer Implementation of multilingual features and internationalization for global audiences.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Social Media Integration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Integration of social media platforms for sharing content and improving brand visibility.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Content Management</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Assistance with content creation, including writing, editing, and uploading posts and pages. Training for clients on how to manage and update their content.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Custom Theme Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Customization of existing themes to match the client's branding and design requirements.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Plugin Installation and Configuration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Installation and setup of essential WordPress plugins for SEO, security, performance, and more.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. WordPress Website Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Custom website design and development using WordPress. Creation of responsive and mobile-friendly WordPress themes.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7. E-commerce Integration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Integration of E-commerce solutions like WooCommerce to enable online selling. Setup of product listings, shopping carts, and payment gateways.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>8. Backup and Recovery</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Regular backups of website data to ensure data recovery in case of issues or data loss. Implementation of disaster recovery plans.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Perfomance Optimization</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Regular performance audits and optimization to identify and resolve issues impacting website speed and user experience.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>10. Security & Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Implementation of security measures to protect the website from threats and vulnerabilities. Ongoing maintenance and updates for WordPress core, themes, and plugins.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>

</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>

    
<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default WordPressDevelopers