import React from 'react';
import './Casestudy.css';


export default function Casestudy() {
  return (
    <div className='customer'>
    <div className='case'>
        <h1> Customer Case Studies <br/> <strong> BringMark Stories</strong></h1>
        <p> Real-life examples of how we met our customer's needs and helped them accomplish their business goals.</p>
    </div>
       

<div className="cardcontent2">
<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/t1qtiovxdudiszge0yzc'} width={350} height={230} alt="" />
<p>Global, multibrand luxury lifestyle house cuts lost sales 50% with InventoryTech</p>
</div> 


<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/az4z9guhiqw1pv6z9qeo'} width={350} height={230} alt="" />
<p>A leading kids apparel retailer improves planning efficiency 50% with PriceTech </p>
 </div>


<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/jqt0jcm2yjnlfd31ucrn'} width={350} height={230} alt="" />
<p> BJ's Wholesale Club boosts revenues $19M by optimizing pricing with PriceTech</p>
</div>


<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/hbztfdzq5saaq2lxl9al'} width={350} height={230} alt="" />
<p> P&G improves process efficiency by 75% with RackTech </p>
</div>


<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/sirm98y4evxk0edlrlaw'} width={350} height={230} alt="" />
<p> A restaurant chain with 390 stores enables higher margins with TestTech </p>
</div>


<div className="study">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/CASESTUDY/c26dcpwggtnknvclpce9'} width={350} height={230} alt="" />
<p> Pet Supplies Plus reduces toxic promotions by 40% with PromoTech </p>
</div>

</div>
</div>
  
    
  );
}