import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  

import './hireweb.css'
import ContactForm from '../ContactForm'
const WebDevelopers = () => {

    const width=useWindowSize()


  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
    <Grid>
    <Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire web Developers</Typography>

<Typography >Hire Web Developers To Built Augment Web Applications

</Typography>
    </Grid>



</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/ynuehuqmmq0tngcozwoq" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Web development encompasses the creation of websites and online applications accessible via the internet or intranet. It encompasses a wide spectrum, ranging from building simple single-page sites to intricate web applications and social networking services. For the execution of various functionalities and applications, the engagement of a skilled web developer is essential. These experts possess the competencies needed to oversee and manage web development tasks. In today's landscape, establishing an online presence is imperative for businesses. Having a website is almost a prerequisite, irrespective of whether the enterprise is a fledgling startup or a corporate giant. The significance of a website should be comprehended universally. It functions as a virtual reflection of a company's endeavors, conveying its essence and offerings. Should a company intend to expand its reach globally, a website is a pivotal tool for achieving that ambition. This platform facilitates meaningful interactions with both existing and prospective customers. Modern web applications have ushered in superior user experiences on the web, marked by speedier and enhanced service. Engaging web developers is a multi-faceted process, encompassing options such as freelancers, part-time hires, and full-time professionals. </Typography>

    <Typography style={{marginTop:'40px'}}> Bringmark offer web development services aimed at enhancing web applications and optimizing decision-making processes. Our skilled web developers deliver professional work and innovative designs for your website needs. Our development services elevate the quality of your web presence and provide exceptional solutions. Our team of experts specializes in creating growth-oriented web applications, encompassing e-commerce portals, PWAs, extensive websites, tailor-made web applications, as well as SaaS solutions, all designed to cater to user requirements and align with business objectives.</Typography>


<Grid className='sticky'> 
<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}>
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Web Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. SEO optimized Solutions</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>At BringMark, our developers specialize in crafting web solutions that are finely tuned for optimal search engine performance. Recognizing the paramount importance of Search Engine Optimization (SEO) in web development, our professionals are well-versed in seamlessly integrating websites with search ranking strategies. Our experts are dedicated to delivering top-tier search engine rankings while meticulously adhering to SEO best practices. We leverage the latest tools, technologies, and techniques to ensure your web development project is SEO-friendly.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Efficient Execution</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our seasoned professionals possess profound insights into the art of web development and execute projects with the utmost efficiency. Drawing upon their extensive expertise, our web developers employ cutting-edge tools and techniques to deliver excellence. We extend unwavering support and maintenance services throughout the web development journey. By enlisting the services of BringMark web developers, you gain access to a distinctive and efficient approach to web development that consistently delivers on time with unmatched proficiency.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Timely Deleiveries</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our web developers are committed to delivering your web development projects within the stipulated timeframes. We prioritize the delivery of quality solutions, ensuring that your web development project is both distinct and punctual within the market. Our professionals hold your business in high regard, treating it as if it were their own, and invest unwavering effort into ensuring its success.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>4. Distinguishing Branding</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Partnering BringMark web developers equips your business with the means to stand out conspicuously amidst competitors. We equip you with exceptional tools and technologies, unwaveringly committed to maintaining the highest standards of quality. Our unique and effective methodology empowers businesses to effectively engage with a broad spectrum of target audiences. We place a premium on transparency, ensuring that business stakeholders are well-informed and comprehend every facet of the development process.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Adaptability </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Team of Web Developers have Willingness to learn and adapt to new technologies and frameworks. Keeping up with industry trends and best practices.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Reaponsive Web Design</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We Ensure that your websites and web applications are mobile-friendly and responsive across various devices and screen sizes.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>7.  Versatility</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Team Of our Web Developers have the Ability to work on both frontend and backend development. Proficiency in various web development tools and libraries.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Security Awareness</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Web Developers have the depth Knowledge of web security best practices to prevent vulnerabilities and data breaches. They Implement secure coding practices.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Attention to Detail </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We Provide Careful code review to ensure accuracy and quality. Thorough testing to catch and fix bugs.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Web Accessbility</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Making websites and web applications accessible to individuals with disabilities, following WCAG guidelines.</Typography>
 </Grid>

 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>

</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{ width: '100%' ,flexDirection:"row",display:"flex",flexWrap:"wrap"}} className="center-cards">

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default WebDevelopers