import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import './hireweb.css'

import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const YIIDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire YII Developers</Typography>

<Typography >Hire YII Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/vzw7ee3mihoy9kkppcg8" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Yii stands as a comprehensive open-source solution for web application development, boasting an MVC framework that provides an optimal platform for developers. Moreover, the secure PHP framework of Yii enables the creation of engaging interactive web applications. Over time, Yii has evolved into a favored choice among developers, allowing them to construct highly resilient, secure, and exceptionally scalable web applications within tight timeframes. Furthermore, this uncomplicated object-oriented programming (OOP) framework harnesses advanced PHP features, incorporating elements such as SPL classes and interfaces, late static binding, and confidential functions.</Typography>

    <Typography style={{marginTop:'40px'}}> A YII developer is a skilled professional specializing in leveraging the Yii PHP framework to design, develop, and maintain web applications. With expertise in Yii's architecture and features, a YII developer possesses the ability to create robust and high-performing applications. They are proficient in implementing the Model-View-Controller (MVC) pattern, which enhances the organization and scalability of projects. YII developers are adept at utilizing Yii's built-in tools for caching, security, authentication, and internationalization, streamlining the development process and ensuring the creation of secure and feature-rich applications. Their familiarity with object-oriented programming and Yii's advanced PHP features allows them to optimize code structure and efficiency. Whether building interactive web applications, e-commerce platforms, or complex systems, a YII developer plays a pivotal role in crafting dynamic solutions tailored to clients' requirements.</Typography>

    <Grid className='sticky'> 
<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By YII Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Skilled Professional</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We provide Yii developers who possess the expertise and experience necessary for your project's success.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Commitment to Quality</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We pledge to uphold the highest quality standards, guaranteeing 100% client satisfaction as a cornerstone of our promise when delivering solutions.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Flexible Billing Options</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We offer a range of billing models to suit your hiring preferences. Whether it's monthly payments, pay-as-you-go, or fixed amounts over specific periods, we provide flexibility in payment arrangements.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Consultive Methodology</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We embrace a consultative approach, first understanding your specific needs and then presenting tailored solutions accordingly.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Transparency</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Transparency is paramount in our services and solutions. We maintain open lines of communication, offer user testing options for clients, and encourage regular reviews to ensure your complete satisfaction.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. On-Time Delievery</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Renowned for our punctual deliveries, we adopt a proactive approach to identify and mitigate any potential issues that could impede the process.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Lifetime Support</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Beyond project delivery, we extend our commitment to you by providing lifetime support. This encompasses bug fixes, issue resolution, and system training.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Effective Communication</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our Yii developers communicate effectively in your language, ensuring clear and precise interactions that facilitate the attainment and delivery of your exact requirements.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Security Audits</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Conducting security audits to identify and resolve vulnerabilities in Yii applications. Implementing best practices for web security and data protection.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.YII Support & Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Ongoing maintenance, updates, and technical support for Yii applications. Regular monitoring, security patches, and performance optimization.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
 </Grid>

</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default YIIDevelopers