

import React from 'react'
import './MovingCards.css'
import AOS from "aos";
import "aos/dist/aos.css";
const MovingCards = ({image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12}) => {

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  
  const images=[
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},
    {source: image1},
    {source: image2},
    {source: image3},
    {source: image4},
    {source: image5},
    {source: image6},
    {source: image7},
    {source: image8},
    {source: image9},
    {source: image10},
    {source: image11},
    {source: image12},

    
    
  ]



  return (
    <div  
   style={{backgroundColor:"rgba(21, 22, 25, 1)"}}
    >




<div class="slider">
  <div class="slide-track">
   {images.map((el)=>(
    <div class='slide'>
            
            <img src={el.source} alt="" />
         
          </div>

   ))}
        

  </div>
</div>

    </div>
    
  )
}

export default MovingCards