import React from 'react';
import './CyberCproject.css'
function CyberCproject(){
  return (
    <>
      {/* @TODO: Move away from custom props before injecting the polyfill, as it can't handle it */}
      {/* <input type="checkbox" id="debug" /> */}
      
      <header>
        <div>
          <h2>Product</h2>
        </div>
      </header>
      
      <main>
        <ul id="services-cards">
          <li className="services-card" id="card_1">
            <div className="card__content">
              <div>
                <h2>Card One</h2>
                <p>Prepare for a shopping experience like no other with BringMark's meticulously crafted Android and iOS apps. These digital gateways don't merely facilitate transactions; they usher you into a realm where products become captivating stories waiting to be explored. With the seamless integration of cutting-edge 3D technology, the Android and iOS apps redefine the very essence of online shopping, offering a tantalizing glimpse into the future of retail engagement.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://uploads.quarkly.io/5f844e532e3a2a001faf55b8/images/091.gif?v=2021-02-03T08:33:47.248Z" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_2">
            <div className="card__content">
              <div>
                <h2>Card Two</h2>
                <p>The Android and iOS apps engineered by BringMark aren't just functional tools; they are the epitome of innovation meeting user-centric design. These apps stand as portals to a new dimension of shopping, where the lines between physical and virtual blur, granting you unprecedented control over the products you encounter. It's a convergence of reality and technology that unfolds in the palm of your hand.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://itsfoss.com/content/images/2023/01/bottom.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Venturing into the immersive world of BringMark's Android and iOS apps reveals a treasure trove of real-time insights at your disposal. Diving into comprehensive specifications, authentic user reviews, and interactive demonstrations isn't a mundane task; it's a journey of exploration that transforms you from a passive observer to an active participant. This dynamic engagement reshapes the way you understand and appreciate each product's unique qualities.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://1.bp.blogspot.com/-m3xIQvY22qs/XeEG9V8V_5I/AAAAAAAAAYg/utu3WG2fJQARUiF3Vg99KQBhFba0sWyhACLcBGAsYHQ/s1600/Linux-Process-Monitoring-with-Top.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>The true brilliance of BringMark's Android and iOS apps emerges as they empower you to transcend the role of a mere consumer and embrace that of a creator. Beyond conventional shopping, these apps foster collaboration with BringMark's experts, propelling your product ideas into the digital realm. These apps become your canvas, where concepts flourish into captivating virtual realities.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://i.pinimg.com/originals/eb/6e/93/eb6e93dc338288b7d54d218a3c3b9e04.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Envision a scenario where your creation takes center stage through BringMark's Android and iOS apps. Your product transcends the confines of static visuals and traditional descriptions, metamorphosing into a captivating experience. Empowered by these apps, prospective customers don't just see; they engage deeply, forging a connection that extends beyond the digital realm.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img src="https://thumbs.gfycat.com/AnxiousGleefulGermanspitz-size_restricted.gif" alt="Image description" />
              </figure>
            </div>
          </li>
        </ul>
      </main>
      
      <aside>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem repellat non ratione eum provident quaerat obcaecati dicta
          autem voluptates sit cum quis distinctio, atque sint commodi reprehenderit perspiciatis iure velit molestiae eos molestias
          ipsa nihil quia?
        </p> */}
      </aside>
    </>
  );
};

export default CyberCproject;
  