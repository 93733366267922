import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import './Review.css'; // Import the CSS file
import Rating from '@mui/material/Rating';

const useStyles = makeStyles({
  // You can still define additional styles here if needed
});

const ReviewCards = ({ name, readOnly }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(5);



  return (
    <div className='rew'>
    <h2 style={{textAlign:"center",color:"#FFC727"}}>Review Our clients</h2>
    <p style={{textAlign:"center",color:"#EBEBEB",marginBottom:"20px",}}>Assess clients' feedback to enhance their experience and boost our services.</p>
    <div className='reviewContainer'>
      <div>
      <Card className="card1"> {/* Use the class names from CSS file */}
      <CardContent className="content1"> {/* Use the class names from CSS file */}
      <Typography gutterBottom variant="h5" component="h2" className='title' >
          CRM solution
        </Typography>

     
    <Typography variant="body2" className='subtitle'>
    Bringmark's CRM solution significantly improved our customer management. Their team's professionalism and dedication were impressive.</Typography>
   
    <div >
      <Rating name={name} value={value} readOnly={readOnly} />
    </div>
      </CardContent>
    </Card>

      </div>
{/* ----------------- */}
      <div style={{flexDirection:"column",display:"flex",justifyContent:"space-evenly"}}>

      <Card className="card2"> {/* Use the class names from CSS file */}
      <CardContent className="content2"> {/* Use the class names from CSS file */}
      <Typography gutterBottom variant="h5" component="h2" className='title'>
      HRM Integration
        </Typography>    
   
    <Typography variant="body2" className='subtitle'>
    Bringmark made our HRM project seamless with their expertise and support. </Typography>
  
        <div>
      <Rating name={name} value={value} readOnly={readOnly} style={{fontSize:"13px"}}/>
    </div>
   
      </CardContent>
    </Card>


    <Card className="card24"> 
      <CardContent className="content2"> 
      <Typography gutterBottom variant="h5" className='title' >
          Website Design
        </Typography>
     
    <Typography variant="body2" className='subtitle'>
    Bringmark developed an excellent website for us.   </Typography>
 
    <div>
      <Rating name={name} value={value} readOnly={readOnly} style={{fontSize:"13px"}} />
    </div>
      </CardContent>
    </Card>

      </div>

{/* ------------------------ */}
<div >
      <Card className="card3"> {/* Use the class names from CSS file */}
      <CardContent className="content3"> {/* Use the class names from CSS file */}
      
      <Typography gutterBottom variant="h5" component="h2" className='title' >
      App Development
        </Typography>
       
    <Typography variant="body2" className='subtitle'>
    Bringmark created an outstanding app for us. Their attention to detail and support throughout the project was commendable   </Typography>

    <div >
      <Rating name={name} value={value} readOnly={readOnly} />
    </div>
      </CardContent>
    </Card>

      </div>
    </div>
    </div>

   
  );
};

export default ReviewCards;
