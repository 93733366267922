
import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import image1 from '../../assets/WhoWeWorkWith/AssortSmart-1.webp';

import FolderIcon from '@mui/icons-material/Folder';
// import image1 from '../../assets/IndustryWeServe/wtc-designers.svg';
import image2 from '../../assets/ourwork/2.png';
import image3 from '../../assets/ourwork/1.png';
import image4 from '../../assets/ourwork/3.png';
import image5 from '../../assets/ourwork/4.png';
import image6 from '../../assets/ourwork/5.png';
import image7 from '../../assets/ourwork/6.png';
import image8 from '../../assets/IndustryWeServe/bat-level-above.svg';
import './whoworkwith.css'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    color: 'red',
  },
});

const data = [
    { image: image2, text: 'Web Development', subText: 'Click here', route: '/web-development' },
    { image: image3, text: 'App Development', subText: 'Click here', route: '/mobile-development' },
    { image: image4, text: 'AR/VR & Distribution', subText: 'Click here', route: '/arVr' },
    { image: image5, text: 'Cyber Security', subText: 'Click here', route: '/cyber-security' },
    { image: image6, text: 'Data Analytics', subText: 'Click here', route: '/data-analytics' },
    { image: image7, text: 'Cloud Services', subText: 'Click here', route: '/cloud-service' },
];

const WhoWeWorkWith = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Box className='mainwho'>
      <Grid container spacing={2} className='whoContainer'>
        <Card xs={12} md={6} lg={6} xl={6} className='ourwork'>
          <FolderIcon className='folder'  />
          <Typography className='h3'>
            Our Work
          </Typography>
          <Typography className='h4'>
          Grow with Affordable Tech
          </Typography>
          <Typography className='p'>
          Maximize productivity and grow your business with our affordable tech services.
          </Typography>
        </Card>
      </Grid>

      <Grid container spacing={3} sx={{ justifyContent: 'space-between', marginBottom: "20px", cursor: "pointer" }}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start", padding: 2, backgroundColor: "#151616", border: '1px solid #2D2E2E' }}
              onClick={() => handleNavigation(item.route)}
            >
              <Box sx={{ width: '100%' }}>
                <img src={item.image} alt={item.text} style={{ width: '83px', height: '83px', padding: "7px" }} />
              </Box>
              <Typography variant='p' sx={{ justifyContent: "center", display: "flex", fontSize: "13px" }}>
                {item.text}
              </Typography>
              <Typography variant='p' sx={{ justifyContent: "center", display: "flex", fontSize: "12px", color: "#FFC727", paddingRight: '10px', paddingLeft: "10px", border: "2px solid #FFC727", borderRadius: "20px", marginTop: "10px", alignContent: "center", alignItems: "center", alignSelf: "center",padding:"8px 21px",fontFamily:"Lato",fontWeight:"bold" }}>
                {item.subText}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default WhoWeWorkWith;
