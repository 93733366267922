import React from "react";
import './Careers.css';

export default function Careers(){
    return(
           
        <div className="career-main">
          <div className="team">
            <h1> Join our team of <strong>Creators & <br/>Innovators</strong></h1>
            <p>Innovation is our DNA and everyday is an opportunity to learn and grow at Bringmark. Join our team of creators, engineers & innovators, and work on the most cutting-edge, cloud-based SaaS solutions in the market, to solve the toughest retail challenges through automation and AI.</p>
            <button>VIEW ALL JOBS</button>
          </div>

          <div className="whyus"> 
           <h1> Why Us ?</h1>
           <p> Our values are what define us and it applies to everyone who we interact with. These key pillars of our organization defined by our founders, make us the company we are today.</p>
          </div>

       <div className="whypoint">
          <div className="career">
          <img src="https://res.cloudinary.com/self-taken/image/upload/v1697210706/Company/Careers/ff3eudkspsujxwpai0hq.png" width={100} height={80} alt="" />
            <h3> Innovation is our DNA</h3>
            <p>Problem solving is at the heart of it</p>
            </div>

<div className="career">
<img src="https://res.cloudinary.com/self-taken/image/upload/v1697212081/Company/Careers/fwhlau5uvde4mrvihetl.png" width={100} height={80} alt="" />
<h3 style={{textAlign:"justify"}}>Fail Fast & Experiment</h3>
<p>Failure Assures Invaluable Learning</p>
</div>

<div className="career">
<img src="https://res.cloudinary.com/self-taken/image/upload/v1697212419/Company/Careers/tt1blfyujibxrgezp8j9.png" width={100} height={80} alt="" />
<h3>Collaboration & Learning</h3>
<p>Knowledge gains through collaboration</p>
</div>

<div className="career">
<img src="https://res.cloudinary.com/self-taken/image/upload/v1697212702/Company/Careers/ydilrqwmgwoi9s7fbgck.png" width={100} height={80} alt="" />
<h3>Customer Service</h3>
<p>Happy customers is our mantra</p>
</div>

<div className="career">
  <img src="https://res.cloudinary.com/self-taken/image/upload/v1697216404/Company/Careers/ncqbuefphxzvl4ekfd6e.png" width={100} height={80} alt=""/>
  <h3>Ownership & Work Ethics</h3>
  <p> You're your own boss! </p>
</div>   

<div className="career">
<img src="https://res.cloudinary.com/self-taken/image/upload/v1697217332/Company/Careers/eudiyjpfnro6vcdykazk.png" width={100} height={80} alt=""/>
  <h3>Honesty & Transparency</h3>
<p> Honesty is the expressway to building trust</p>
</div>

<div className="career">
<img src="https://res.cloudinary.com/self-taken/image/upload/v1697217640/Company/Careers/wnkqkp6t9klke5188lzq.png" width={100} height={80} alt=""/>
  <h3>Agility & Efficiency</h3>
<p>  Think fast, plan faster, finish fastest</p>
</div>

<div className="career">
  <img src="https://res.cloudinary.com/self-taken/image/upload/v1697217944/Company/Careers/j8epwq4iyhymondt0cfb.png" width={100} height={80} alt=""/>
  <h3>Empathy & Giving Back</h3>
<p> Giving back is a privilege and duty</p>
</div>
    </div>

    <div className="join">
      <h1> Join Our team</h1>
      <p>Life in BringMark is full of opportunities, choices, and the ability to shape your unique journey while having a lot of fun. Join us to explore yourself </p>
     {/* <button>VIEW ALL JOBS</button> */}
    </div>
        </div>
    );
}