import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const FrontEndDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire  FrontEnd Developers</Typography>

<Typography >Hire Frontend Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/cs5pqxdtfo4gik7pvpl0" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Whenever you visit a website and observe its design, fonts, layout, colors, text, and all the visual elements, you're experiencing the work of front-end developers. Front-end development involves managing the outward appearance of a website while handling the technical aspects behind the scenes. These developers play a crucial role within teams, as they oversee and coordinate all the aspects of website and web application functionality. If you aim to create exceptional and engaging web and mobile applications, enlisting the expertise of front-end developers is essential.</Typography>
    <Typography style={{marginTop:'40px'}}> Front-end development services play a pivotal role in helping you connect with your target audience and gaining a competitive edge in your industry. The execution of front-end development is no simple task, yet it remains indispensable for both websites and mobile applications. This process involves working with technologies like HTML, CSS, JavaScript, among others. Additionally, contemporary frameworks such as Angular, Express, ReactJS, and VueJS, among others, are integral to the development process. These practices are applicable across various devices, including mobile, web, and tablets. Front-end developers need to meticulously consider every detail, as the layout, user interface (UI), and user experience (UX) constitute the initial components that users and consumers encounter.</Typography>

    <Grid className='sticky'> 

<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By FrontEnd Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Front-end Architecture & Design:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Front-end development services aim to construct dynamic and sustainable applications using front-end architecture, a set of tools and processes designed to enhance the quality of front-end code.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.  UI/UX App Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>UI/UX development constitutes a vital component of website and web application creation. UI/UX development services deliver highly scalable, data-driven applications with an intuitive user interface. The design must be captivating.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. PWA App Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Progressive Web Application Development is a potent service for both websites and mobile applications. It offers a hybrid approach suitable for native websites and mobile apps, blending ease of use with a rich user experience. </Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. HTML5/CSS Development:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>
This service is efficient for enhancing applications and websites. HTML/CSS3 service provides an outstanding tool that elevates quality. It is user-friendly and serves multiple purposes in front-end development.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Single Page Application Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Many websites opt for single-page application development services due to their rapid scalability. The front-end web tools are invaluable for development, relying on JavaScript as a foundation. </Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.  AMP App Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Front-end development services are sought for web browsing and optimization, facilitating faster page loading and improved functionality. AMP app development is highly effective and user-friendly.</Typography>




 <Typography variant='h4' marginTop={5} fontSize={22}>7.  Full-stack Web Development:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Full-stack development is a pivotal aspect of front-end design. The development service we offer is interactive with front-end users, trustworthy, and encompasses the essence of both front-end and back-end development.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. New Technologies And Tools:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> BringMark is known for using the most trustworthy and latest technology and tools. Our developers use the same for the front-end development, and our front-end developer strives to deliver highly optimized web applications and websites. The front-end developers write codes without any bugs and deliver the best.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Boosting Efficiency:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our team of front-end developers maintains a strong focus on productivity while staying abreast of the latest releases and updates. We are committed to delivering exceptional services that meet high standards. Our front-end developers actively engage in the entire development cycle, consistently generating innovative concepts. We take pride in delivering well-structured layouts that offer a seamless user experience.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Seamless experience</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>In addition to delivering top-notch quality, we place a strong emphasis on ensuring a smooth user journey. Our front-end developers adhere to all guidelines, ensuring that the development process runs without a hitch. Should any minor bugs or errors arise, our team is promptly prepared to rectify them. With their effective communication skills, our front-end developers contribute to creating a seamless experience in front-end development.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our Frontend developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default FrontEndDevelopers