import React, { useEffect, useState } from 'react';
import './pagenotfound.css';
import { useNavigate } from 'react-router-dom';

const Pagenotfound = () => {
  const [text, setText] = useState(['', '', '']);
  const navigate = useNavigate();

  function home() {
    navigate('/');
  }

  useEffect(() => {
    const codes = [
      '<span>this_page</span>.<em>not_found</em> = true;',
      '<span>if</span> (<b>you_spelt_it_wrong</b>) {<span>try_again()</span>;}',
      '<span>else if (<b>we_screwed_up</b>)</span> {<em>alert</em>(<i>"We\'re really sorry about that."</i>); <span>window</span>.<em>location</em> = home;}',
    ];

    const type = (n, t) => {
      setTimeout(() => {
        let i = 0;
        const se = setInterval(() => {
          i++;
          const newText = codes[n].slice(0, i);
          setText((prevState) => {
            prevState[n] = newText;
            return [...prevState];
          });

          if (i === codes[n].length) {
            clearInterval(se);
            setText((prevState) => {
              prevState[n] = codes[n];
              return [...prevState];
            });
          }
        }, 10);
      }, t);
    };

    type(0, 0);
    type(1, 600);
    type(2, 1300);
  }, []);

  return (
    <div className='ccn'>
      <p className='ps'>HTTP: <span className='ps'> 404</span></p>
      {text.map((line, i) => (
        <code key={i} dangerouslySetInnerHTML={{ __html: line }}></code>
      ))}
      <center><a className='as' onClick={home}>HOME</a></center>
    </div>
  );
};

export default Pagenotfound;
