// import React, { useState, useEffect } from 'react';
// import { AppBar, Toolbar, Typography, Modal, Box } from '@mui/material';
// import './gst.css'; // Import the CSS file
// import AddIcCallIcon from '@mui/icons-material/AddIcCall';
// import ReactFlagsSelect from 'react-flags-select';


// const flags = [
//    "IN", "US","CN", "ID", "PK", "BR", "NG", "BD", "RU", "MX",];


// const Gst = () => {
//   const [open, setOpen] = useState(false);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setOpen(false); // Close the modal after 2 minutes
//     }, 120000); // 2 minutes

//     return () => clearTimeout(timer);
//   }, [open]);

//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '80%',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

//   return (
//     <>
//       <AppBar position="static" style={{ backgroundColor: "#D2FF52", height: '40px' }}>
//         <Toolbar style={{ display: 'flex', justifyContent: 'space-between', minHeight: '40px', width: "100%" }}>
//           <div className='container123'>
//             <div className='inners'>
//               <p onClick={handleOpen} className='parasss'>
//                 GST No: 07GGGPD7428R1ZP
//               </p>
//             </div>
//           </div>

//           <Box sx={{ flexGrow: 2 }} />
//           <p className='mobile'>
//             Learn More: +917303482908
//           </p>
//           <div className='flags'>
//             <div className='flag-scroll-container'>
//               {flags.map((country, index) => (
//                 <ReactFlagsSelect
//                   key={country}
//                   countries={[country]}
//                   customLabels={{ [country]: "" }}
//                   showSelectedLabel={false}
//                   showOptionLabel={false}
//                   selected={country}
//                   selectButtonClassName="flag-button"
//                   selectButtonStyle={{
//                     margin: "0 5px",
//                     padding: "0",
//                     border: "none",
//                     backgroundColor: "transparent",
//                     height: '100%', // Ensure flags are centered vertically
//                     boxShadow: 'none', // Remove the border shadow
//                   }}
//                 />
//               ))}
//             </div>
//           </div>
//         </Toolbar>
//       </AppBar>

//       <Modal open={open} onClose={handleClose}>
//         <Box sx={modalStyle}>
//           <Typography variant="h6" component="h2">
//             GST Details
//           </Typography>
//           <Box>
//             <object data={require('../gst/GST Certificate.pdf')} type="application/pdf" width="100%" height="500px">
//               <p>PDF cannot be displayed.</p>
//             </object>
//           </Box>
//         </Box>
//       </Modal>

//       {/* Add CSS for scrolling animation */}
//       <style jsx global>{`
//         .flags {
//           overflow-x: auto;
//           white-space: nowrap;
//         }
        
//         .flag-scroll-container {
//           display: flex;
//           flex-direction: row;
//           padding: 5px;
         
//           animation: scroll 10S linear infinite; /* Adjusted to 2 minutes */
//         }

//         .flag-scroll-container > :not(:last-child) {
//           margin-right: 10px;
//         }

//         @keyframes scroll {
//           0% {
//             transform: translateX(0);
//           }
//           100% {
//             transform: translateX(-100%);
//           }
//         }
//       `}</style>
//     </>
//   );
// };

// export default Gst;

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Modal, Box } from '@mui/material';
import './gst.css'; // Import the CSS file
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import ReactFlagsSelect from 'react-flags-select';

const flags = [
  "IN", "US", "CN", "ID", "PK", "BR", "NG", "BD", "RU", "MX",
];

const Gst = () => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 768) { // Assuming 768px as the mobile threshold
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const handleOpen = () => {
    if (isMobile) {
      alert("Sorry, we can't open this PDF. Please use the site on a desktop to view the PDF.");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!isMobile) {
      const timer = setTimeout(() => {
        setOpen(false); // Close the modal after 2 minutes
      }, 120000); // 2 minutes

      return () => clearTimeout(timer);
    }
  }, [open, isMobile]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted for better mobile view
    maxWidth: '600px', // Ensures it doesn't get too wide on larger screens
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2, // Adjusted padding for mobile view
    overflowY: 'auto', // Ensure content is scrollable on smaller screens
  };

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: "#D2FF52", height: '40px' }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', minHeight: '40px', width: "100%" }}>
          <div className='container123'>
            <div className='inners'>
              <p onClick={handleOpen} className={`parasss ${isMobile ? 'disabled' : ''}`}>
                GST No: 07GGGPD7428R1ZP
              </p>
            </div>
          </div>

          <Box sx={{ flexGrow: 2 }} />
          <p className='mobile'>
            Learn More: +919654570253
          </p>
          <div className='flags'>
            <div className='flag-scroll-container'>
              {flags.map((country, index) => (
                <ReactFlagsSelect
                  key={country}
                  countries={[country]}
                  customLabels={{ [country]: "" }}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                  selected={country}
                  selectButtonClassName="flag-button"
                  selectButtonStyle={{
                    margin: "0 5px",
                    padding: "0",
                    border: "none",
                    backgroundColor: "transparent",
                    height: '100%', // Ensure flags are centered vertically
                    boxShadow: 'none', // Remove the border shadow
                  }}
                />
              ))}
            </div>
          </div>
        </Toolbar>
      </AppBar>
{/* 
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            GST Details
          </Typography>
          <Box>
            <iframe 
              src={require('../gst/GST Certificate.pdf')} 
              type="application/pdf" 
              width="100%" 
              height="500px"
              style={{ border: 'none' }}
            >
              <p>PDF cannot be displayed.</p>
            </iframe>
          </Box>
        </Box>
      </Modal> */}

      {/* Add CSS for scrolling animation */}
      <style jsx global>{`
        .flags {
          overflow-x: auto;
          white-space: nowrap;
        }

        .flag-scroll-container {
          display: flex;
          flex-direction: row;
          padding: 5px;
          animation: scroll 120s linear infinite; /* Adjusted to 2 minutes */
        }

        .flag-scroll-container > :not(:last-child) {
          margin-right: 10px;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @media (max-width: 600px) {
          .flag-scroll-container {
            animation-duration: 60s; /* Faster scroll on smaller screens */
          }
        }

        .parasss.disabled {
          pointer-events: none;
          color: gray;
        }
      `}</style>
    </>
  );
};

export default Gst;
