import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './webdev.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

  "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/WEBDESIGN/rdeybc8fb1besxayrhhd",
  "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/WEBDESIGN/qvq8zzi1efouzwh5ho2x",
  "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/WEBDESIGN/ez9m34rw9kbyz1azitcq",
"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/WEBDESIGN/gkbcpukepwmihahbywwj"  ,
"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/WEBDESIGN/n98pa26ny2zhrzr8lygs"

];

function WebDev() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
        
    };
  
// danish
// 
  
  return (
    <div className='web_container'>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>Web Development</Typography>  */}
<div>
<Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>

    </div> 

    <Typography paragraph style={{color:"white",flexWrap:"wrap",marginTop:"30px"}}>
    In today's digital era, a website has become an indispensable and integral part of human life. Every business, regardless of its scale, seeks a compelling online presence to showcase its skills, products, talents, and more. Embracing this profound need, Bringmark excels in crafting exquisite websites and CMS systems for startups and established enterprises alike. As a leading tailor-made web development company, we take pride in delivering the finest web and CMS solutions across the globe.
        </Typography>
        <Typography paragraph style={{color:"white",flexWrap:"wrap"}}>
        Our team of highly skilled developers possesses a deep understanding of the latest technologies and trends. Collaborating with global firms, we have transformed innovative ideas into stunning and inventive websites. Our rich experience and boundless creativity have enabled us to produce phenomenal websites and CMS solutions for diverse clients worldwide. Embracing a different and unique perspective, our experts tailor web development with unparalleled speed and precision.
        </Typography>

        <Typography paragraph style={{color:"white"}}>
        With our web development expertise, businesses thrive with enhanced functionality and efficiency. We create platforms that empower businesses to grow, evolve, and connect with their audiences seamlessly. Our solutions are meticulously designed to drive success and exceed expectations.
        </Typography>

        <Typography paragraph style={{color:"white",flexWrap:"wrap"}}>
        Our commitment to excellence is reflected in every project we undertake. From conceptualization to implementation, we ensure our clients' visions materialize into exceptional digital realities. Partner with Bringmark, and take your business to new heights through cutting-edge web development and CMS solutions that leave a lasting impact on the online landscape.
        </Typography>

        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/nq3e93mcgiywtfbm8jgp"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/gqmkgqp1x95onepyvjo8"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/gif/bro20pjjeavgwixlkl6h"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/dmwpswqqlgr8xvowu6r7"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/fngnvqiaagtfb1kww1zk"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/q7cco1seuzgaqi3wowa1"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/ayouakqynn0aey3zrbf4"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/wsoni7hcabw1nb5z3a2c"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/min1imuivi2y8rrdy2we"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/d48lroak6yslyfajqiip"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/ekjpnzcxnxhrjulj6huz"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/marque/f3zd6xgx7qe1rxmud9ks"

  />
</div>



        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unleash the potential of your online presence through Bringmark tailored web and CMS solutions


</Typography>
<Typography  style={{color:"white"}}>

Bringmark web and CMS solutions create a global impact by expanding businesses beyond local limits, engaging a diverse international audience. With their well-optimized and responsive websites, language barriers are surmounted, forging meaningful connections with customers from various regions and cultures. </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/gif/bro20pjjeavgwixlkl6h" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Process We Follow:<br/>
How Our Experts Handle The Project Efficiently,<br/>

1) Planning
Gather all the information and plan the project, craft the idea for the website and CMS development.<br/>
2) Web Designing<br/>
Our front-end designers will craft a stunning and striking responsive website following the wireframe, UI/UX design to prototype.<br/>
3) Define Content<br/>
A website is incomplete without content. Thus, we will put informative content on the website and make it SEO-friendly.<br/>
4) Development<br/>
After deciding the website's objectives, it is time for development. The process is the pre-final stage of the website.<br/>
5) Testing<br/>
It is essential to conduct a test to ensure that the website is 100% bug-free and you get a seamless, user-friendly website.<br/>
6) Deployment<br/>
After everything is covered, from design to deployment, it is time to launch the website.<br/>
7) Post Deployment & Maintenance<br/>
After the website's launch, we will provide all support and answer every query after the deployment.<br/>
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/gif/xmgjd3k9y2hyen7xbupd" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Our Cutting-Edge Technologies and Services for Website & CMS Development





</Typography>
<Typography  style={{color:"white"}}>

Explore the forefront of technology with our website and CMS development services. Embracing innovation, we deploy cutting-edge technologies to craft high-performance and user-centric digital solutions. Our expert team leverages the latest tools and frameworks to create visually captivating and responsive websites while ensuring seamless content management through our advanced CMS platforms. From dynamic web applications to feature-rich e-commerce sites, we stay ahead of the curve, delivering unparalleled experiences to clients worldwide. Unleash the power of innovation and propel your online presence with our exceptional website and CMS development expertise. </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Unparalleled IT Services for Your Web Needs!<br/>
Delivering Excellence On Time<br/>

At Bringmark, we pride ourselves on delivering exceptional quality swiftly. Our punctuality and discipline ensure timely project delivery, yielding optimal outcomes.<br/>

Effective & Transparent Communication<br/>

We foster warm and effective communication with our clients, keeping them informed throughout the entire process. Our openness helps clients stay in the loop about their projects.<br/>

Utmost Confidentiality<br/>

Respecting privacy is paramount to us. We maintain full confidentiality of information and data, instilling trust and confidence in our clients.<br/>

Friendly & Supportive<br/>

Our web & CMS developers are friendly and approachable, always ready to assist with any client issues. We are passionately committed to supporting our clients and their projects.<br/>

Years of Experience<br/>

With our team's extensive experience in Web & CMS development, we excel in utilizing cutting-edge techniques, tools, and technologies.<br/>

Complete Technical Competence<br/>

We stay updated with the latest tools and technologies, providing clients with complete technical expertise and knowledge.<br/>

Unwavering Support<br/>

Our experts offer unwavering support in resolving technical glitches, bugs, and project doubts, ensuring smooth development and client satisfaction.<br/>             

        </Typography>

<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unleashing Extraordinary Websites: Captivate Users and Achieve Results. Our web development services blend creativity and functionality, elevating your online presence to new heights.


</Typography>
<Typography  style={{color:"white"}}>

Embark Empower your online presence with our premier website services. Our team excels in crafting user-centric experiences that captivate, engage, and yield meaningful results. Trust us as your partner in creating a website that leaves a lasting impact on your audience. </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/gif/rhginqhsjnpz3jriq9u6" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Welcome to Bringmark, a hub of innovation and top-notch solutions. Our seasoned developers excel in web development, UI/UX design, and cutting-edge technologies.

Our mission is to be your trusted digital partner, transforming your ideas into reality. Leveraging technology, we drive growth and elevate user experiences.

Our customer-centric approach sets us apart. Valuing strong client relationships, we prioritize effective communication. Understanding your unique goals, we deliver solutions that align perfectly with your vision.
        </Typography>


        {/* project here */}

<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/web%20development/gif/olcwkbtcx97co9dv6cfl" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Ignite Your Digital Presence: Unleash Exceptional Websites That Captivate and Deliver Outstanding Results.
</Typography>
<Typography  style={{color:"white"}}>
Ignite Your Digital Presence: Unleash Exceptional Websites That Captivate and Deliver Outstanding Results.
Indulge in the pinnacle of website excellence where captivating user experiences meet remarkable results. With our web development services, we skillfully harmonize creativity and cutting-edge functionality, working in perfect synergy to elevate your online presence to unprecedented heights. Your website will stand out as a masterpiece of design, user engagement, and performance, leaving an indelible impact on your audience. Trust us as your partner in this exhilarating journey, and together, we'll redefine the boundaries of online success. </Typography>
</div>


</div>

      
    </div>
  )
}

export default WebDev