import React from 'react'
import { Typography } from "@mui/material"
import "./Services.css"

import TripOriginIcon from '@mui/icons-material/TripOrigin';



const Services = () => {
    return (
        <div className='Services-container'>

            <div className='Services-firstBox'>

                <img style={{width:"100%"}} src="http://sudo24.online/wp-content/uploads/elementor/thumbs/christina-wocintechchat-com-UcZcsHSp8o4-unsplash-1-scaled-q4rjafkaldnik8flj7u6ghvg8jysih6nnm4pytro84.jpg" alt="" />

            </div>


            <div className='Services-secondBox'>

                <div style={{display:"grid", gap:"20px"}} > 


                    <Typography  style={{ color: '#ff7f50' }}  variant='h6'>

                        < TripOriginIcon fontSize='small'/>
                        PROCESS OF WORK
                    </Typography>
                    <Typography variant='h4' style={{ fontWeight: "bold" }}>Our Team Includes  <br /> Experts To Do Their Best  Job</Typography>

                    <Typography  style={{color:"#545357"}}>Our website development team comprises a group of experienced professionals who are passionate about creating visually stunning, user-friendly, and high-performing websites. Our team consists of front-end and back-end developers, full-stack developers, UX designers, content specialists, project managers, and quality assurance testers.</Typography>
                </div>

                <div style={{display:"flex", alignItems:"center", justifyContent:"center",flexDirection:"column"}}>


<Typography variant='h5' style={{fontWeight:"bold",marginBottom:"40px"}} >

    
Our Front End Team
</Typography>


<Typography  style={{color:"#545357"}}>Our front-end developers specialize in HTML, CSS, JavaScript, and popular front-end frameworks such as React, Angular, and Vue. They focus on creating visually appealing and user-friendly websites that provide a seamless user experience across all devices are delivering high-quality work.</Typography>
</div>



<div style={{display:"flex", alignItems:"center", justifyContent:"center",flexDirection:"column"}}>


<Typography variant='h5' style={{fontWeight:"bold",marginBottom:"40px"}} >

Our Back End Team
</Typography>


<Typography  style={{color:"#545357"}}>Our back-end developers specialize in server-side programming languages such as PHP, Ruby, Python, and Node.js, and database technologies such as MySQL, PostgreSQL, and MongoDB. They focus on building robust and scalable web applications that are secure and performant.</Typography>
</div>

               


               

            </div>



            <div className='Services-thirdBox'>
 <div style={{textAlign:"center"}}>
    <img src="http://sudo24.online/wp-content/uploads/2023/04/headway-5QgIuuBxKwM-unsplash-1024x1024.jpg" alt="" />
 </div>
 <div style={{display:"flex", justifyContent:"center",flexDirection:"column",  gap:"10px"}} > 


<Typography  style={{ color: '#ff7f50' }}  variant='h6'>

    < TripOriginIcon fontSize='small'/>
    OUR SERVICES
</Typography>
<Typography variant='h4' style={{ fontWeight: "bold" }}>Our Team Includes  <br /> Experts To Do Their Best  Job</Typography>

<Typography  style={{color:"#545357"}}>We offer expert website development and SEO services to help businesses of all sizes achieve their online goals. Our team of experienced professionals is passionate about creating high-performing websites and optimizing them for search engines to attract more traffic, leads, and sales.Whether you are looking for website development or SEO services, we have the expertise and experience to help you achieve your online objectives. Contact us today to learn more about how we can help your business grow!</Typography>
</div>
            </div>

            <div className='Services-fourthBox'>

                <Typography style={{color:"#FF7F50",textAlign:"center",marginBottom:"20px"}}> <TripOriginIcon/> <br/>MAIN SERVICES
                   </Typography>


                <Typography variant='h3' style={{fontWeight:"bold",textAlign:"center"}}>
                We Give Your Business <br/> Everything Its Needs
                </Typography>

                <div className='Services-fourthBox-secondSection'>

                    <div>
                        <img src="http://sudo24.online/wp-content/uploads/2020/03/Group_4071.png" alt=""/>

                        <Typography  variant='h5' style={{fontWeight:"bold"}}>Digital Marketing
</Typography>
 <Typography  style={{color:"#545357"}}> Digital marketing refers to the use of digital channels, such as search engines, social media, email, and mobile apps, to promote products or services and engage with customers. </Typography>
                    </div>

                    
                    <div>
                        <img src="http://sudo24.online/wp-content/uploads/2020/02/Group_4068.png" alt=""/>

                        <Typography  variant='h5' style={{fontWeight:"bold"}}>Safe Estate

</Typography>
 <Typography  style={{color:"#545357"}}> In design and development, safety is an essential consideration to protect user data and prevent security breaches. Important safety features include SSL encryption to protect sensitive information, two-factor authentication! </Typography>
                    </div>


                    
                    <div>
                        <img src="http://sudo24.online/wp-content/uploads/2020/02/Group_4069.png" alt=""/>

                        <Typography  variant='h5' style={{fontWeight:"bold"}}>Fully Responsive

</Typography>
 <Typography  style={{color:"#545357"}}> We are committed to providing excellent customer service and responding to our customers in a timely and professional manner. Our team of customer service experts has the skills and experience to handle any customer inquiry or concern, and we are dedicated to ensuring customer satisfaction and loyalty. </Typography>
                    </div>


                    
                    <div>
                        <img src="http://sudo24.online/wp-content/uploads/2020/02/Group_4067.png" alt=""/>

                        <Typography  variant='h5' style={{fontWeight:"bold"}}>Plans To Success

</Typography>
 <Typography  style={{color:"#545357"}}>Developing a plan for success is essential if you want to achieve your goals and live the life of your dreams. By defining your goals, creating a roadmap, taking action, monitoring your progress, and celebrating your success, you can develop a plan for success that works for you! </Typography>
                    </div>
                    
                </div>
                
            </div>

        </div>
    )
}

export default Services