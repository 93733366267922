import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const AndroidDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Android Developers</Typography>

<Typography >Hire Android Developers To Built Augment Android Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/ltmaho6x2nbn0zinuosb" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography>In this rapidly evolving world, Mobile app development is a necessity without prerequisites. The Android app development team at Bringmark possesses the prowess to transform any concept into an innovative and functional android app solution. Our dedicated team of android app developers exhibits adaptability to cater to businesses of various sizes, spanning from small-scale enterprises to large-scale corporations across diverse industry verticals. </Typography>

    <Typography style={{marginTop:'40px'}}> Our extensive experience in Mobile app development positions us as a preferred partner for global enterprises. If you're contemplating hiring dedicated android developers from Bringmark, here's a glimpse into the proficiency our app developers bring to the table. Their capabilities encompass crafting tailored solutions perfectly aligned with your app development requisites. These Android development offerings position us as the premier android development company catering to virtually every industry.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    {/* <Typography variant='h4' marginTop={10} fontSize={27}>What Android App Development Services Await You?
:</Typography> */}

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Android App Design</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>At Bringmark, you can enlist Android app designers and acquire aesthetically pleasing mobile applications enriched with the latest UI/UX design trends..</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.  Custom Android App Development
</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our dedicated Android developers possess the experience and skill to engage with contemporary trends and technologies, crafting user-friendly and navigable mobile applications.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Android Testing</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our dedicated android app testing team ensures the delivery of bug-free, high-performance mobile applications by meticulously troubleshooting challenges prior to the app deployment phase</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Android App Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>By hiring Android developers from Bringmark, you're not only obtaining exceptional mobile app development but also seamless mobile app maintenance. This guarantees the flawless functionality of app features across diverse Android devices.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.  Android App Upgrade</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our dedicated Android developers specialize in revamping and enhancing your existing Android mobile applications, rendering them compatible with various versions of the Android operating system..</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.  Application Migration
</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>If you intend to migrate an existing application to the Android operating system, our app development team can provide skilled Android developers. They'll ensure you have a feature-rich android app, leveraging the latest technological trends.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7.  Mobile App Development Consultation</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>If uncertainties persist about the necessity of android app development or the feasibility of your app concept, our Android developers offer comprehensive answers and insights.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Experienced App Developers
</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>As a leading app development company, our Android development team consists of seasoned developers who deliver exceptional results for both Native and cross-platform app development.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Quality Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Hire dedicated Android developers who harness state-of-the-art tech stacks to ensure top-quality development results. Bringmark is renowned for delivering premium app development outputs at a pocket-friendly pricing model.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.Continuous Knowledge Enhancement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>With a history of pioneering Android app development following our establishment as a top iPhone app development company, our developers remain updated with evolving trends and tech stacks. This ensures they can cater to your development needs and deliver top-tier outcomes..</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default AndroidDevelopers