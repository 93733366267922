import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Testtech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of TestTech features cards
const featuresTitle = `Facilitated Test Generation`
const featuresDescription = `Predefined templates assist users in crafting tests from the ground up, structuring test and control groups with statistical precision, and selecting test parameters that yield accurate test results, thereby driving value across the organization.`
const feauturesimage =require('../../assets/solution/demandplanningout.png')

const featuresTitle2 = `Comprehensive Investigation and Root Cause Analysis`
const featuresDescription2 = `Our test and learn platform scrutinizes test results at multiple levels, encompassing geography, product categories, inventory, and all other key performance indicators, in order to unearth the primary drivers of your business. This provides you with an effective means of interpreting test outcomes within a single unified platform.`
const feauturesimage2 =require('../../assets/solution/demandplanningout.png')

const featuresTitle3 = `Robust Test and Control Equivalence`
const featuresDescription3 = `Our advanced testing methods and frameworks establish dependable tests, offering substantial statistical confidence.`
const feauturesimage3 =require('../../assets/solution/demandplanningout.png')

const featuresTitle4 = `Conduct Simultaneous Customer and Store Tests`
const featuresDescription4 = `Our user-friendly platform offers a unified interface for concurrently executing multiple independent tests and learning campaigns at various levels, including stores, departments, customer segments, and more. Some organizations conduct hundreds of tests throughout the year, with dozens running concurrently. In fact, our TestTech platform can manage over 1,000 simultaneous tests if ever necessary.`
const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of TestTech takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Examine the previous year's testing program to grasp the standard parameters and measurements applied. Ingest data streams encompassing test and control groups, as well as input variables, and subject previous tests to the TestTech platform to assess the platform's performance within your operational context.`
const weeksimage =require('../../assets/solution/demandplanningout.png')

const weeksTitle2 = `Build `
const weeksDescription2 = `Tailor the TestTech platform to meet specific measurement requirements and enable the creation of a comprehensive array of test structures and configurations. Adapt the modules for test creation, monitoring, and analysis to align with business needs.`
const weeksimage2 =require('../../assets/solution/demandplanningout.png')

const weeksTitle3 = `Monitor `
const weeksDescription3 = `Organize new tests on the platform and meticulously oversee test results to calibrate and refine the outcomes. Investigate how various factors impact each other at multiple levels, including distinct products, locations, and key performance indicators.`
const weeksimage3 =require('../../assets/solution/demandplanningout.png')

const weeksTitle4 = `Deploy `
const weeksDescription4 = `The usual deployment of the test platform encompasses all testing initiatives once the AI structures are validated, ensuring high comprehensibility throughout every facet of the business. This minimizes the time required for action and reduces errors in crafting and evaluating in-store, merchandising, and marketing tests.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Empower retailers to design robust experiments spanning various aspects of their business, encompassing stores, merchandising, marketing, real estate, and more, to boost revenue and profitability.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390496/Solutions/testtech/mbvwqzujza97kfq3xanr.png"

const title2 = `CPG`
const message2 = `Identify opportunities with suppliers, vendors, and customers by utilizing insights derived from our innovative algorithms. Assess inventory, shipping, promotions, and more to amplify your outcomes and enhance ROI across the business.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390495/Solutions/testtech/mt73yhg3vn3wtjo0lrrp.png"

const title3 = `Hospitality`
const message3 = `Uncover the potential of your business data to make informed choices in promotions, pricing, and marketing, considering factors such as geographies, property types, and staffing levels.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390495/Solutions/testtech/ilhkgxgfsiahhccq9l0s.png"
// the end of cards






const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Industries that can gain from TestTech`
const message = `Seize control of your business and enhance decision-making! TestTech is well-suited for application in a broad range of contexts that can experience the advantages of adopting test and learn methodologies.`;
const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/n92ib27lhkvkizhfx45x")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};
function Testtech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>TestTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>Cutting-edge test and experimentation platform</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Bringmark robust ExperimentWise platform equips your business with quick and straightforward test configuration, enhanced statistical testing and experimentation methods, more comprehensive in-depth analyses to grasp results, and, most importantly, superior decision-making and enhanced business results.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>TestTech</Typography>
      </div>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "black" }}>TestTech Advantage</h2>
 
      </Grid>
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      TestTech utilizes artificial intelligence and machine learning to execute numerous concurrent hypothesis-driven tests spanning across stores, merchandising, marketing, promotions, and additional areas. Eliminate guesswork from business management and adopt statistically informed decisions to fuel business growth.          
      </p>

          <p style={{marginTop:"15px",color:"white"}}>
          Our test and learn approach enables you to swiftly establish tests via our guided processes and promptly assess outcomes, resulting in immediate margin improvements and increased conversion rates. Create robust hypothesis testing initiatives while maintaining cost-effectiveness through our state-of-the-art, patented test and experimentation platform.
          </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent: 'center',}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389731/Solutions/testtech/hq97pwhykph7eojujd3a.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3>Enhanced Effectiveness</h3>

              <Typography>Streamlined test creation and analysis processes, the capacity for concurrent execution of multiple tests, and in-depth machine learning investigations for uncovering concealed insights all contribute to a shift in focus towards extracting actionable insights for real-time decision-making.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Diverse Applications</h3>
              <Typography>Bringmark TestTech platform supports an extensive array of use cases. The platform adeptly employs various testing methods, enabling tests across store operations and customer interactions, encompassing store layout and adjacencies, fixtures, signage, inventory distribution, product assortment planning, price sensitivity, promotional impact, campaign evaluation, and numerous other pre-designed test types, while also accommodating limitless custom test configurations.
                  </Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389731/Solutions/testtech/fdh8l4c4lprkp4l18fxe.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Unified and Accurate Data</h3>
              <Typography>Through our industry-leading test and learning approach, the TestTech platform consolidates all company-wide tests into a single repository, providing a comprehensive overview of ongoing experiments throughout various departments.
               </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Exceptional Returns on Investments (ROI)</h3>
              <Typography>Access actionable insights that surpass binary outcomes. The IA TestTech platform leverages machine learning to provide in-depth insights into the reasons behind test success or failure, enabling business users to make well-informed decisions. For instance, a test may yield positive results in certain geographic areas or store types but not in others. This heightened level of visibility significantly enhances the value of test results, leading to improved ROI.
                 </Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>TestTech Features</h1>
        <Typography>Empower businesses to pinpoint opportunities for new product development, marketing initiatives, and merchandising strategies with our state-of-the-art 'test and learn' platform.
          </Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389729/Solutions/testtech/hqp7eglosjddhjapa4kh.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389729/Solutions/testtech/brlyjpu4eyc1m3xe0ad1.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389729/Solutions/testtech/h9oyzotgmp3ex75nba92.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697389729/Solutions/testtech/ij7xvmlgehegla4h5oj7.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold",}}>Implementation of TestTech Takes less than <span style={{color:"#15dfed"}}>8 Weeks.</span></h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390098/Solutions/testtech/x1xj6hvuyiy6bbs3vgr6.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390098/Solutions/testtech/oosyuruozit9gjabv7vw.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390098/Solutions/testtech/odolk05yvnjetvuxn9bn.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390098/Solutions/testtech/xijg1pqzq77l1kuh04as.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
              AI-driven predictive analytics providing advantages to retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>25%</h2>
                </div>
                <p style={{color:"white"}}>Boost in the quantity of experiments yielding statistically significant outcomes</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >80%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in the time required to formulate and evaluate experiments</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>20-30%</h2>
                </div>
                <p style={{color:"white"}}>Enhancement in the matching score of Test and control groups</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>30%</h2>
                </div>
                <p style={{color:"white"}}>Rise in the number of conducted experiments
                  </p>

        </div>
    </Box>

    {/* <Box className='forcast_cardContainer3'>
      <div  >
      <h2 style={{color:"white",fontWeight:"bold"}}>See TestTech in Action</h2>
      <p style={{color:"white",flexWrap:"wrap",marginTop:"50px"}}>
      Discover how our clients have utilized TestSmart to execute numerous experiments, ascertain the actual influence, and implement profit-boosting concepts.
            </p>
    <Button variant='contained' style={{marginTop:"50px" , marginBottom:"40px"}}>Request Demo</Button>
      </div>
      <div>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390307/Solutions/testtech/utirrujrmqfkez0dx4va.png" alt="" width={"600px"} height={"300px"}/>
      </div>
     
    </Box> */}
            <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>See TestTech in Achtion</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover how our clients have utilized TestTech to execute numerous experiments, ascertain the actual influence, and implement profit-boosting concepts.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390307/Solutions/testtech/utirrujrmqfkez0dx4va.png" alt="" width={"600px"} height={"400px"}/>
        </Grid>
      </Grid>  
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697390606/Solutions/testtech/m4ixh9icakllrtendsw5.png" alt="" width={"600px"} height={"400px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Reveal data-driven insights into the most recent market trends, technological advancements, and how your colleagues are addressing their prominent testing obstacles with Bringmark cutting-edge next-generation solutions.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid>  


    

    </div>
  );
}

export default Testtech;
