import React, { useState } from 'react'
import styles from "./navs.module.css"
import { RiArrowDropDownLine } from "react-icons/ri"
import { GiHamburgerMenu } from "react-icons/gi"
import { Box, Typography } from '@mui/material'
import TempDrower from './TempDrower/TempDrower'
import { NavLink, useNavigate } from 'react-router-dom'
import ColorsParticles from '../particles/ColorsParticles'
import Gst from './gst/Gst'
const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate()
    const toggleMenu = () => {
        console.log("asdf")
        setMenuOpen(!menuOpen);
    };
    function load(){
        setTimeout(() => {
            window.location.reload();
          }, 100);
        }
    
    // solutions path
    function forecast() {
        navigate('/ForeCarstTech')
        load()
    }

    function assortech() {
        navigate('/Assorttech')
        load()

    }

    function inventory() {
        navigate('/Inventorytech')
        load()

    }
    function spaceTech() {
        navigate('/Spacetech')
        load()

    }

    function attribute() {
        navigate('/Attributetech')
        load()

    }
    function audit() {
        navigate('/Audittech')
        load()

    }
    function monday() {
        navigate('/Mondaytech')
        load()
        
    }
    function planet() {
        navigate('/Planetech')
        load()

    }

    function Pricetech() {
        navigate('/Pricetech')
        load()

    }
    function Racktech() {
        navigate('/Racktech')
        load()

    }
    function Testtech() {
        navigate('/Testtech')
        load()

    }
    function Tradetech() {
        navigate('/Tradetech')
        load()

    }

    function home() {
        navigate('/')
      

    }

    // end
    // services
    function webDev() {
        navigate('/web-development')
        load()

        scrollToTop()
    }
    function arvr() {
        navigate('/arVr')
        scrollToTop()
        load()

    }

    function mobileApp() {
        navigate('/mobile-development')
        load()

        scrollToTop()
    }

    function iot() {
        navigate('/iot')
        scrollToTop()

        load()

    }
    function dataAnalytics() {
        navigate('/data-analytics')
        scrollToTop()
        load()

    }
    function cloudservices() {
        navigate('/cloud-service')
        scrollToTop()
        load()

    }
    function cyber() {
        navigate('/cyber-security')
        scrollToTop()
        load()

    }
    function aiml() {
        navigate('/aiml')
        scrollToTop()
        load()

    }
    function devops() {
        navigate('/devops')
        scrollToTop()
        load()

    }
    // sevives end
    // hire developers
    function angular() {
        navigate('/AngularjsDevelopers')
        scrollToTop()
        load()

    }
    function AndroidDevelop() {
        navigate('/AndroidDevelopers')
        scrollToTop()
        load()

    }
    function appDev() {
        navigate('/AppDevelopers')
        scrollToTop()
        load()

    }
    function BackEndDevelop() {
        navigate('/BackEndDevelopers')
        scrollToTop()
        load()

    }
    function BlockChainDeve() {
        navigate('/BlockChainDevelopers')
        scrollToTop()
        load()

    }
    function react() {
        navigate('/ReactjsDevelopers')
        scrollToTop()
        load()

    }
    function node() {
        navigate('/NodejsDevelopers')
        scrollToTop()
        load()

    }
    function wordpress() {
        navigate('/WordPressDevelopers')
        scrollToTop()
        load()

    }

    function vue() {
        navigate('/VuejsDevelopers')
        scrollToTop()
        load()

    }
    function php() {
        navigate('/PhpDevelopers')
        scrollToTop()
        load()

    }
    function webDevelopers() {
        navigate('/WebDevelopers')
        scrollToTop()
        load()

    }
    function fullStack() {
        navigate('/FullStackDevelopers')
        scrollToTop()
        load()

    }
    function frontend() {
        navigate('/FrontEndDevelopers')
        scrollToTop()
        load()

    }
    function software() {
        navigate('/SoftwareDevelopers')
        scrollToTop()
        load()

    }
    function uiux() {
        navigate('/UIUXDesigner');
        scrollToTop()
        load()

    }
    function iotSolution() {
        navigate('/IOTSolutionArchitecture');
        scrollToTop()
        load()

    }
    function ios() {
        navigate('/IosDevelopers')
        scrollToTop()
        load()

    }
    function flutter() {
        navigate('/FlutterDevelopers')
        scrollToTop()
        load()

    }
    function reactNative() {

        navigate('/ReactNativeDevelopers')
        scrollToTop()
        load()

    }
    function swift() {
        navigate('/SwiftDevelopers');
        scrollToTop()
        load()

    }
    function iconic() {
        navigate('/IconicDevelopers');
        scrollToTop()
        load()

    }
    function kotlin() {
        navigate('/KotlinDevelopers');
        scrollToTop()
        load()

    }
    function cakephp() {
        navigate('/CakephpDevelopers');
        scrollToTop()
        load()
    }
    function codeignitor() {
        navigate('/CodeIgniteDevelopers');
        scrollToTop()
        load()

    }
    function magento() {
        navigate('/MagnetoDevelopers');
        scrollToTop()
        load()

    }
    function shopify() {
        navigate('/ShopifyDevelopers');
        scrollToTop()
        load()

    }
    function drupal() {
        navigate('/DrupalDevelopers');
        scrollToTop()
        load()

    }
    function woo() {
        navigate('/WooCommerceDeveloper');
        scrollToTop()
        load()

    }
    function python() {
        navigate('/PythonDevelopers');
        scrollToTop()
        load()

    }
    function golang() {
        navigate('/GolangDevelopers');
        scrollToTop()
        load()

    }
    function solidity() {
        navigate('/SolidityDevelopers');
        scrollToTop()
        load()

    }

    function dart() {
        navigate('/DartDevelopers')
        scrollToTop()
        load()

    }

    function spring() {
        navigate('/SpringBootDevelopers');
        scrollToTop()
        load()

    }
    function django() {
        navigate('/DjangoDevelopers');
        scrollToTop()
        load()

    }
    function laravel() {
        navigate('/LaravelDevelopers');
        scrollToTop()
        load()

    }
    function yii() {
        navigate('/YIIDevelopers');
        scrollToTop()
        load()

    }
    function java() {
        navigate('/JavaDevelopers')
        scrollToTop()
        load()

    }

    function iphone() {
        navigate('/IphoneDevelopers');
        scrollToTop()
        load()

    }


    function data() {
        navigate('/DataScientist');
        scrollToTop();
        load()

    }
    function sales() {
        navigate('/SalesforecDevelopers');
        scrollToTop();
        load()

    }
    function contact() {
        navigate('/contactus');
        scrollToTop()
    }
    function blogs() {
        navigate('/blogs');
        scrollToTop()
    }
    function awards() {
        navigate('/Awards');
        scrollToTop()
    }
    function career() {
        navigate('/Careers');
        scrollToTop()
    }

    function  Casestudy() {
        navigate('/Casestudy');
        scrollToTop()
    }

    function Research() {
        navigate('/Research');
        scrollToTop()
    }


    function news() {
        navigate('/News');
        scrollToTop()
    }

    function report() {
        navigate('/Report');
        scrollToTop()
    }

    function videos() {
        navigate('/Videos');
        scrollToTop()
    }

    function Industry1() {
        navigate('/Industry1');
        scrollToTop()
    }


    // hire ends

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smooth scrolling (optional)
        });
    }
    
    return (
        <>
            <Box className="rr">
                <nav>
<Gst/>
                    <div className={`${styles.wrapper}`}>
                        <div className={`${styles.logo}`} >
                            <img src={require("../../assets/real.png")} width={"65px"} height={"66px"} onClick={() => {
                                home();
                                scrollToTop();
                            }} />
                        </div>
                        <label htmlFor="btn" className={`${styles.icon}`} onClick={toggleMenu}>
                            <GiHamburgerMenu />
                        </label>
                        <input type="checkbox" id='btn' checked={menuOpen} />
                        <ul className={`${styles['nav-links']} ${menuOpen ? `${styles.show}` : ''}`}>

                            {/* hire developer */}

                            <li>

                                <label htmlFor="btn-1" className={`${styles.show}`}>Solutins <RiArrowDropDownLine className={`${styles['drop-down-icon']}`} /></label>
                                <input type="checkbox" id='btn-1' />
                                <a href="" style={{ color: "white",fontWeight:"bold" }}>Hire Developers</a><RiArrowDropDownLine className={`${styles['drop-down-icon']}`} />
                                <div className={`${styles['mega-box']}`}>


                                    <div className={`${styles.content}`}>
                                        {/* <div>
                                        <h4
                                         style={{
                                                color:"#7254EF",top:0,right:0,justifyContent:"center",fontSize:"34px",textAlign:"center",fontWeight:"bold",marginBottom:"50px"

                                        }}
                                        >Hire Dedicated Developers</h4>
                                    </div> */}
                                        <div className={`${styles.row}`}>

                                            {/* <header >Demand Planning</header> */}
                                            <ul className={`${styles['mega-links']}`}>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/androiddeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={appDev}>
                                                        {/* <li><a href="">App Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>App Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/web.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={webDevelopers}>
                                                        {/* <li><a href="">Web Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Web Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/softwaredeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={software}>
                                                        {/* <li><a href="">Software Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Software Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/fullstackdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={fullStack}>
                                                        {/* <li><a href="">Full-stack Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Full-Stack Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/frontenddeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={frontend}>
                                                        {/* <li><a href="">Frontend Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Frontend Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/backenddeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={BackEndDevelop}>
                                                        {/* <li><a href="">Backend Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Backend Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/uiuxdeveveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={uiux}>
                                                        {/* <li><a href="">UI/UX Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>UI/UX Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/iotsolution.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={iotSolution}>
                                                        {/* <li><a href="">IOT Solution Architect</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>IOT Solution Architect</li>
                                            </NavLink>
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Supply Chain</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/blockdesign.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={BlockChainDeve}>
                                                        {/* <li><a href="">Blockchain Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Blockchain Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/androiddeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={AndroidDevelop}>
                                                        {/* <li><a href="">Android Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Android Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/iosdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={ios}>
                                                        {/* <li><a href="">IOS Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>IOS Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/flutterdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={flutter}>
                                                        {/* <li><a href="">Flutter Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Flutter Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/reactnative developer.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={reactNative}>
                                                        {/* <li><a href="">React Native Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>React Native Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/swiftdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={swift}>
                                                        {/* <li><a href="">Swift Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Swift Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/ionicdevelopment.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={iconic}>
                                                        {/* <li><a href="">Ionic Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Ionic Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/kotlindeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={kotlin}>
                                                        {/* <li><a href="">Kotlin Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Kotlin Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>


                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Merchandising</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/angularjsdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={angular}>
                                                        {/* <li><a href="">Angularjs Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Angularjs Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/reactjsdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={react}>
                                                        {/* <li><a href="">Reactjs Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Reactjs Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/nodejsdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={node}>
                                                        {/* <li><a href="">Nodejs Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Nodejs Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/vuejsdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={vue}>
                                                        {/* <li><a href="">Vuejs Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Vuejs Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/phpdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={php}>
                                                        {/* <li><a href="">PHP Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>PHP Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/wordjsdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={wordpress}>
                                                        {/* <li><a href="">Wordpress Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>WordPress Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/cakephpdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={cakephp}>
                                                        {/* <li><a href="">CakePhp Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>CakePhp Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/codeignitedeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={codeignitor}>
                                                        {/* <li><a href="">Codeigniter Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>CodeIgnitor Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>


                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Pricing & Promotiions</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/magnetodeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={magento}>
                                                        {/* <li><a href="">Magento Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Magento Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/shopifudeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={shopify}>
                                                        {/* <li><a href="">Shopify Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Shopify Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/drupaldeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={drupal}>
                                                        {/* <li><a href="">Drupal Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Drupal Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/woocommercedeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={woo}>
                                                        {/* <li><a href="">WooCommerce Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>WooCommerce Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/pythondeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={python}>
                                                        {/* <li><a href="">Python Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Python Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/golangdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={golang}>
                                                        {/* <li><a href="">Golang Developers</a></li> */}

                                                        <NavLink>
                                            <li className='ww'>Golang Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/soliditydeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={solidity}>
                                                        {/* <li><a href="">Solidity Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Solidity Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/Pastel Colorful Marketing Analytics Infographic Instagram Post.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={dart}>
                                                        {/* <li><a href="">Dart Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Dart Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>


                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Business Intelligence</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/springbootdeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={spring}>  
                                                        {/* <li><a href="">Spring boot Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Spring Boot Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/djangodeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={django}>
                                                        {/* <li><a href="">Django Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Django Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/lareveldeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={laravel}>
                                                        {/* <li><a href="">Laravel Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Laravel Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/yiideveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={yii}>
                                                        {/* <li><a href="">YII Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>YII Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/javadeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={java}>
                                                        {/* <li><a href="">Java Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Java Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/iphonedeveloper.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={iphone}>
                                                        {/* <li><a href="">Iphone Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Iphone Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/datascienctist.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={data}>
                                                        {/* <li><a href="">Data Scientists</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Data Scientists</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/hiredevs/sales.png")} width={"70px"} height={"60px"} />
                                                    </div>
                                                    <div onClick={sales}>
                                                        {/* <li><a href="">Salesforce Developers</a></li> */}
                                                        <NavLink>
                                            <li className='ww'>Salesforce Developers</li>
                                            </NavLink>
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <label htmlFor="btn-1" className={`${styles.show}`}>Solutins <RiArrowDropDownLine className={`${styles['drop-down-icon']}`} /></label>
                                <input type="checkbox" id='btn-1' />
                                <a href="" style={{ color: "white",fontWeight:"bold" }}>Solutions</a><RiArrowDropDownLine className={`${styles['drop-down-icon']}`} />
                                <div className={`${styles['mega-box']}`}>
                                    <div className={`${styles.content}`}>
                                        {/* <div className="row">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF3drOnN-jfYOxiPLNRXaYA0Ezg22jzhq7DLZglaprvImmUUdP52QDOkeUTeKlYpyqxYk&usqp=CAU" alt="" />
                                    </div> */}
                                        <div className={`${styles.row}`}>
                                            <header style={{ color: "#7254EF", fontWeight: "bold", justifyContent: "center", color: "rgb(254,196,0)" }} >Demand Planning</header>
                                            <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div>
                                                    <img src={require("../../assets/solution/demandplanning/forecasttech.png")} width={"100px"} height={"90px"} />
                                                </div>
                                                <div>
                                                    <ul className={`${styles['mega-links']}`} onClick={() => { forecast(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>ForecastTech</a></li> */}
                                                        <NavLink  >
                                            <li className='ww'>ForecastTech</li>
                                            </NavLink>
                                                        
                                                        <li style={{ fontSize: 12, color: "white", width: "180px" }}>Accurate ForeCast with state of the forcasting engine</li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`${styles.row}`}>
                                            <header style={{ color: "#7254EF", fontWeight: "bold", justifyContent: "center", color: "rgb(254,196,0)" }}>Supply Chain</header>
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/supplychain/inventarytech.png")} width={"100px"} height={"90px"} />
                                                    </div>

                                                    <div onClick={() => { inventory(); scrollToTop() }}>
                                                        {/* <li onClick={() => { inventory(); scrollToTop() }} > InventoryTech */}
                                                        <NavLink  >
                                            <li className='ww'>InventoryTech</li>
                                            </NavLink>
                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Simplify, Optimise & automate allocation process.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/demandplanningout.png")} width={"100px"} height={"90px"} />
                                                    </div>

                                                    <div onClick={() => { spaceTech(); scrollToTop() }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0" }}>SpaceTech</a> */}
                                                        <NavLink >
                                            <li className='ww' style={{fontWeight:"bold"}}>SpaceTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Space Planning & Optimization solution.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/supplychain/racktech.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => {Racktech(); scrollToTop() }}>
                                                        <NavLink>

                                                            <li  style={{ fontWeight: "bold" }}>RackTech


                                                            </li>
                                                        </NavLink>
                                                        <li style={{ fontSize: 12, color: "white", width: "180px" }}>AI Powered shelf monitoring solution.</li>

                                                        

                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            <header style={{ color: "#7254EF", fontWeight: "bold", justifyContent: "center", color: "rgb(254,196,0)" }}>Merchandising</header>
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/merchandising/plantech.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { planet(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>PlaneTech</a> */}
                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>PlanTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Better manage financial plans and open-to-buy budgets.</li>

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/merchandising/assortech.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { assortech(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>AssortTech</a> */}

                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>AssortTech</li>
                                            </NavLink>
                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Build Localized store & channels assortments.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/merchandising/attributetech.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { attribute(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>AttributeTech</a> */}
                                                        
                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>AttributeTech</li>
                                            </NavLink>
                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>AI backed automated and accurate attribute tagging.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>



                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            <header style={{ color: "#7254EF", fontWeight: "bold", justifyContent: "center", color: "rgb(254,196,0)" }}>Pricing & Promotiions</header>
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/priceandpermoting/price.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { Pricetech(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>PriceTech</a> */}
                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>PriceTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Suite of pricing solutions that Optimize business strategies.</li>

                                                    </div>
                                                </div>



                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/priceandpermoting/tradetech.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { Tradetech(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>TradeTech</a> */}
                                                        
                                                        <NavLink>
                                            <li className='ww'>TradeTech</li>
                                            </NavLink>      
                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>AI led trade promo plannning and Optimization.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/priceandpermoting/audit.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { audit(); scrollToTop(); }}>
                                                        {/* <li><a href="" style={{ color: "#6D5DF0", fontWeight: "bold" }}>AuditTech</a> */}
                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>AuditTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Advance RPA bot to deliver high-quality-audits.</li>

                                                        {/* </li> */}

                                                    </div>
                                                </div>



                                            </ul>
                                        </div>
                                        {/* <div className={`${styles.row}`}>
                                            <header style={{ color: "#7254EF", fontWeight: "bold", justifyContent: "center", color: "rgb(254,196,0)" }}>Business Intelligence</header>
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/bujnessintelligence/test.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { Testtech(); scrollToTop(); }}>
                                                        <NavLink>
                                            <li className='ww'>TestTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>Automated Testing platform to test new initiatives.</li>

                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div>
                                                        <img src={require("../../assets/solution/bujnessintelligence/monday.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    <div onClick={() => { monday(); scrollToTop(); }}>
                                                        <NavLink>
                                            <li className='ww'>MondayTech</li>
                                            </NavLink>

                                                            <li style={{ fontSize: 12, color: "white", width: "180px" }}>BI solution to diagnose problem areas.</li>


                                                    </div>
                                                </div>



                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </li>



                            {/* danish */}
                            <li>
                                <label htmlFor="btn-1" className={`${styles.show}`}>Solutins <RiArrowDropDownLine className={`${styles['drop-down-icon']}`} /></label>
                                <input type="checkbox" id='btn-1' />
                                <a href="" style={{ color: "white",fontWeight:"bold" }}>Services</a><RiArrowDropDownLine className={`${styles['drop-down-icon']}`} />

                                <div className={`${styles['mega-box']}`}>

                                    <div className={`${styles.content}`}>
                                     
                                        <div className={`${styles.row}`}>
                                            {/* <header >Demand Planning</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={() => { webDev(); scrollToTop(); }}>

                                                    <div>
                                                        <img src={require("../../assets/services/webdev.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div > */}
                                                        {/* <li><a href="">Web Development</a></li> */}
                                                        <NavLink>
                                            <li className='ww' style={{fontWeight:"bold"}}>Web Development</li>
                                            </NavLink>
                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={() => { mobileApp(); scrollToTop(); }}>

                                                    <div>
                                                        <img src={require("../../assets/services/apppdev.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={() => { mobileApp(); scrollToTop(); }}> */}
                                                        {/* <li><a href="">Mobile App Development</a></li> */}
                                                        <NavLink >
                                            <li className='ww'style={{fontWeight:"bold"}}>Mobile App Development</li>
                                            </NavLink>
                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={() => { arvr(); scrollToTop(); }}>

                                                    <div>
                                                        <img src={require("../../assets/services/arvr.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={() => { arvr(); scrollToTop(); }}> */}
                                                        {/* <li><a href="">AR VR</a></li> */}
                                                        <NavLink to="/services/arvr">
                                            <li className='ww' style={{fontWeight:"bold"}}>AR VR</li>
                                            </NavLink>
                                                    </div>
                                                </div>


                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Supply Chain</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>

                                                <div style={{display:"flex",alignItems:"center"}} onClick={iot}>

                                                    <div>
                                                        <img src={require("../../assets/services/iot.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={iot}> */}
                                                        {/* <li><a href="">IOT</a></li> */}
                                                        <NavLink to="/iot">
                                            <li className='ww'style={{fontWeight:"bold"}}>IOT</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={dataAnalytics}>

                                                    <div>
                                                        <img src={require("../../assets/services/dataanaylytics.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={dataAnalytics}> */}
                                                        {/* <li><a href="">Data and Analytics</a></li> */}
                                                        <NavLink to="/data-analytics">
                                            <li className='ww'style={{fontWeight:"bold"}}>Data and Analytics</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={cloudservices}>
                                                    
                                                    <div>
                                                        <img src={require("../../assets/services/cloudservices.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={cloudservices}> */}
                                                        {/* <li><a href="">Cloud Services</a></li> */}
                                                        <NavLink to="/cloud-service">
                                            <li className='ww'style={{fontWeight:"bold"}}>Cloud Services</li>
                                            </NavLink>
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>
                                        <div className={`${styles.row}`}>
                                            {/* <header>Merchandising</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={devops}>

                                                    <div>
                                                        <img src={require("../../assets/services/devops.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={devops}> */}
                                                        {/* <li><a href="">Devops</a></li> */}
                                                        <NavLink to="/devops">
                                            <li className='ww'style={{fontWeight:"bold"}}>Devops</li>
                                            </NavLink>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={aiml}>

                                                    <div>
                                                        <img src={require("../../assets/services/aiml.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={aiml}> */}
                                                        {/* <li><a href="">AI/ML</a></li> */}
                                                        <NavLink to="/aiml">
                                            <li className='ww'style={{fontWeight:"bold"}}>AI ML</li>
                                            </NavLink>
                                                        
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}}  onClick={cyber}>

                                                    <div>
                                                        <img src={require("../../assets/services/cybersecurity.png")} width={"100px"} height={"90px"} />
                                                    </div>
                                                    {/* <div onClick={cyber}> */}
                                                        {/* <li><a href="">Cyber Security</a></li> */}
                                                        <NavLink >
                                            <li className='ww'style={{fontWeight:"bold"}}>Cyber Security</li>
                                            </NavLink>

                                            
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>


                                        

                                        <div className={`${styles.row}`}>
                                            {/* <header>Merchandising</header> */}
                                            <ul className={`${styles['mega-links']}`}>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={devops}>

                                                    <div>
                                                        <img src={require("../../assets/services/offer.png")} width={"400px"} height={"300px"} />
                                                    </div>
                                                    {/* <div onClick={devops}> */}
                                                        {/* <li><a href="">Devops</a></li> */}
                                                        {/* <NavLink to="/devops">
                                            <li className='ww'style={{fontWeight:"bold"}}>Digital Product Engineering</li>
                                            </NavLink> */}
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}} onClick={aiml}>

                                                    {/* <div>
                                                        <img src={require("../../assets/services/consultingservices.png")} width={"100px"} height={"90px"} />
                                                    </div> */}
                                                    {/* <div onClick={aiml}> */}
                                                        {/* <li><a href="">AI/ML</a></li> */}
                                                        {/* <NavLink to="/aiml">
                                            <li className='ww'style={{fontWeight:"bold"}}>Consulting services</li>
                                            </NavLink> */}
                                                        
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                <div style={{display:"flex",alignItems:"center"}}  onClick={cyber}>

                                                    {/* <div>
                                                        <img src={require("../../assets/services/itmanagement.png")} width={"100px"} height={"90px"} />
                                                    </div> */}
                                                    {/* <div onClick={cyber}> */}
                                                        {/* <li><a href="">Cyber Security</a></li> */}
                                                        {/* <NavLink >
                                            <li className='ww'style={{fontWeight:"bold"}}>IT management services</li>
                                            </NavLink> */}

                                            
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>
                                      
                                        <div className={`${styles.row}`}>
                                            {/* <header>Business Intelligence</header> */}
                                     
                                        </div>
                                    </div>
                                </div>
                            </li>


                            {/* resources */}
                            <li>
                                <label htmlFor="btn-3" className={`${styles['show']}`}>Resources <RiArrowDropDownLine className={`${styles['drop-down-icon']}`} /></label>
                                <input type="checkbox" id='btn-3' />
                                <a href="" style={{ color: "white",fontWeight:"bold" }}>Resources</a><RiArrowDropDownLine className={`${styles['drop-down-icon']}`} />
                                <ul className={`${styles['drop-menu']}`}>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/trendinginsight.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={Industry1}>TRENDING insight</a></li> */}
                                            <NavLink to="/Industry1">
                                            <li className='ww'style={{fontWeight:"bold"}}>TRENDING insight</li>
                                            </NavLink>

                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/blogs.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={blogs}>Blogs</a></li> */}
                                            <NavLink to="/Blogs">
                                            <li className='ww'style={{fontWeight:"bold"}}>BLogs</li>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/casestudy.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={Casestudy}>Case Studies</a></li> */}
                                            <NavLink to="/Casestudy">
                                            <li className='ww'style={{fontWeight:"bold"}}>Case Studies</li>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/research.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={Research}>Research</a></li> */}
                                            <NavLink to="/Research">
                                            <li className='ww'style={{fontWeight:"bold"}}>Research</li>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/inthenews.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={news}>In the news</a></li> */}
                                            <NavLink to="/news">
                                            <li className='ww'style={{fontWeight:"bold"}}>News Letter</li>
                                            </NavLink>
                                        </div>
                                    </div>
             
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/resources/reports.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            <NavLink to="/Report">
                                            <li className='ww'style={{fontWeight:"bold"}}>Reports</li>
                                            </NavLink>
                                        </div>
                                    </div>
                                    {/* <div style={{flexDirection:"row",display:"flex"}}>
                                                <div>
                                                <img src={require("../../assets/resources/newsletter.png")} width={"70px"} height={"60px"}/>
                                                    </div>
                                                    <div>
                                                <li style={{marginRight:"10px"}}><a href="">Newsletter</a></li>
                                                    </div>
                                                    </div> */}
                                </ul>
                            </li>
                            {/* danish */}


                            <li>
                                {/* <label htmlFor="btn-4" className='show'>Company <RiArrowDropDownLine className={`${styles['drop-down-icon']}`} /></label> */}
                                <input type="checkbox" id='btn-4' />
                                <a href="" style={{ color: "white",fontWeight:"bold" }}>Company</a><RiArrowDropDownLine className={`${styles['drop-down-icon']}`} />
                                <ul className={`${styles['drop-menu']}`}>
                                    <div style={{ flexDirection: "row", display: "flex" }}>

                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/drawerCompany/career.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            <NavLink to="/Careers">
                                            <li className='ww'style={{fontWeight:"bold"}}>Careers</li>

                                            </NavLink>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/drawerCompany/innovation.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={awards}>Innovation</a></li> */}
                                            <NavLink to="/Awards">
                                            <li className='ww'style={{fontWeight:"bold"}}>Innovation</li>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/drawerCompany/contactus.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={contact}>Contactus</a></li> */}
                                            <NavLink to="/contactus">
                                            <li className='ww'style={{fontWeight:"bold"}}>Contactus</li>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: "row", display: "flex" }}>
                                        <div>
                                            <img src={require("../../assets/drawerCompany/contactus.png")} width={"70px"} height={"60px"} />
                                        </div>
                                        <div>
                                            {/* <li><a href="" onClick={videos}>Videos</a></li> */}
                                            <NavLink to="/videos">
                                            <li className='ww'style={{fontWeight:"bold"}}>Videos</li>
                                            </NavLink>
                                        </div>
                                    </div>

                                   
                                </ul>
                            </li>
                            <Box sx={{ flexGrow: 2 }} />
                            <NavLink  to='/contactus' className='ww'>
                            <li  style={{ color: "#EEF35F",fontWeight:"bold" }}> Contact Us</li>
                            </NavLink>

                        </ul>
                    </div>
                </nav>
            </Box >

            <TempDrower toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </>
    )
}

export default Navbar
