import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Spacetech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of SpaceTech features cards
const featuresTitle = `Store-Specific Planograms`
const featuresDescription = `Curate store-tailored planograms based on localized store size and demand. Generate precise floor plans for detailed store-level macro & micro planning to enhance productivity per selling sq. ft.`
const feauturesimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/xyiequomzg9ggarkdicc"

const featuresTitle2 = `Space Elasticity Analysis`
const featuresDescription2 = `Enable assortment tier and clustering for the recognition of similar stores and assess the revolution of space change on store performance (at tier-cluster level).`
const feauturesimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/e0kygkiv1clg8mr1uxkw"

const featuresTitle3 = `Assortment Performance Reports`
const featuresDescription3 = `Utilize easily generated micro performance reports at a store-SKU level. Customize reporting to meet your requirements with our cutting-edge retail space planning software.`
const feauturesimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/ezuvp3f0zif2lznmzcc4"

const featuresTitle4 = `Affinity and Adjacency Analysis`
const featuresDescription4 = `First-rate visual merchandising system to steer assortment efforts by automatically reviewing, analyzing, and providing AI/ML-based recommendations for localized floor plans.`
const feauturesimage4 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/lkdtrcqfrdxzjuoyihfz"

// the end

// Implementation of RackTech takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Develop and assess scenarios for implementing assortment adjustments at the store/cluster levels, using cutting-edge visual merchandising driven by AI and ML.`
const weeksimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/yzifhppjjtuha393lj1g"

const weeksTitle2 = `Build`
const weeksDescription2 = `Enable direct-to-shelf restocking, reduce storage expenses through localized replenishment, and acquire real-time planogram insights based on local customer behaviors.`
const weeksimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/k0pclgjyyokuwaxc28nu"

const weeksTitle3 = `Monitor`
const weeksDescription3 = `Ensure assortment tier and clustering provide an advantage and evaluate the influence of space changes on overall store performance at all levels.`
const weeksimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/rmldbegbasvxxovwkcyi"

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Provide precise floor plans for detailed store-level macro & micro planning to ensure enhanced product allocation per selling sq. ft.`
const weeksimage4 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/iar0ilq6b6tube1wgbzd"

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Construct efficient planograms that match evolving customer choices.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/m98zrd9o6uexbugvwd9b"

const title2 = `Fashion`
const message2 = `Establish customized shopping adventures for shoppers by making sure they locate the perfect product at the correct moment.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/hadhfctoyva24yluh0gn"

const title3 = `CPG`
const message3 = `Enhance your return on investment for your assigned store area to guarantee the best location for top-selling goods.`
const image3 ="https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/l0qhwlcmhpkxy8b16ojl"
// the end of cards




const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/mguqwtvovmv0uivysjqf")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Businesses that have profited from SpaceTech`
const message = `Adopt state-of-the-art automatic software for arranging store layouts, changing the retail landscape worldwide.`;


function Spacetech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>SpaceTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>Automated store layout planning software</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Enhance allocation choices for floor plans and shelf space to create customer-focused, space-conscious planograms and SpaceTech.
          </p>
          </div>
          <div style={{marginTop:"85px",}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon style={{color:"black",}}/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>Solutions</Typography>
        <FastForwardIcon color='black'/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>SpaceTech</Typography>
      </div>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "black" }}>SpaceTech Advantage</h2>
 
      </Grid>
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      Reduce manual efforts and guarantee superior precision in planning retail spaces, including floor plans and store layouts, using automated and localized space plans and planograms. Utilize artificial intelligence and machine learning to produce enhanced space planning results, resulting in improved top and bottom-line outcomes for your business.
       </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/tjixsonijw2ptmlzi7au"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >planogram Enhancement</h3>

              <Typography>Formulate and assess scenarios for implementing assortment modifications at the store/cluster level, utilizing state-of-the-art visual merchandising driven by AI.
               </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Cut Operational Expanses </h3>
              <Typography>Guarantee direct-to-shelf restocking and decrease storage expenses by employing localized restocking and real-time planogram suggestions.
               </Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/kieisrn34v9j9ddwg9yw"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Avert Inventory Shortages</h3>
              <Typography>Examine your store layout and adapt product SpaceTech efficiently in accordance with your store space. Prevent overstocking or stock-out situations with our advanced demand SpaceTech technology and retail space planning software.
                </Typography>
            </div>
            {/* <div className='laptop_container1'> 
              <h3 style={{marginTop:"35px"}}>Reduce Overstocks and Stockouts</h3>
              <Typography>Identify lost sales through intelligent insights, and increase your sales margin through reduced inventory overstocks.</Typography>
            </div>*/}
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>SpaceTech Features</h1>
        <Typography>We unlock the capabilities of our users by automating repetitive computations and harnessing machine learning to uncover patterns.
           </Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold",}}> Implementation of SpaceTech takes less than <span style={{color:"#15DFED"}}>8 weeks.</span></h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
              AI-driven
SpaceTech analytics
benefitting retailers with</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>3-8%</h2>
                </div>
                <p style={{color:"white"}}>Boost in revenue
</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >20-30%</h2>
                </div>
                <p style={{color:"white"}}>Cut in inventory cost</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>20%</h2>
                </div>
                <p style={{color:"white"}}>Enhancement in operational efficiency</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>5%</h2>
                </div>
                <p style={{color:"white"}}>Raise in availability</p>

        </div>
    </Box>


    <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore SpaceTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover how our data-driven AI-powered software for retail floor planning employs insights to enhance space allocation for retailers globally.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/o6nl5sutmujksqpi1cpf"} height={"400px"} width={"500px"} alt="Laptop" />
        </Grid>
      </Grid>  
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/spacetech/zfs38rajkfdwgclmdlxr"} height={"400px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Equip yourself with data-driven insights into current market trends, technology advancements, and how your industry colleagues are addressing their space planning dilemmas using Bringmark state-of-the-art floor planning solution.   
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid>  

    

    </div>
  );
}

export default Spacetech;
  