import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./forcastTech.css";
import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of ForecastTech features cards
const featuresTitle = `
Acquire Knowledge`
const featuresDescription = `Assess market conditions and swiftly detect events, price fluctuations, promotional activities, external influences, and unexpected disruptions to enhance your forecasting capabilities effectively.`

const featuresTitle2 = `Construct`
const featuresDescription2 = `Create customized models for your company that incorporate seasonality, current trends, and external factors. Adapt these models in real-time to respond to exceptions, ensuring a more agile approach to retail demand planning`

const featuresTitle3 = `Supervise`
const featuresDescription3 = `Utilize powerful machine learning, trained on an extensive two petabytes of data enriched with deep retail context. This technology identifies missed sales opportunities and offers data-driven insights to enhance your upcoming demand planning strategies.`

const featuresTitle4 = `Implement`
const featuresDescription4 = `Implement a rollout strategy with context-adaptive variables to address seasonal ForecastTech variations, inventory fluctuations, abrupt economic shifts such as inflation, changing demand patterns, and new product launches..`

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Grocery`
const weeksDescription = `Uncover the genuine demand potential across every sales avenue and enhance your bottom-line profitability`

const weeksTitle2 = `Consumer Packaged Goods`
const weeksDescription2 = `Harness the fastest demand forecasting tool, capable of executing millions of computations in mere minutes, to gain insights into SKU-specific demand.`

const weeksTitle3 = `Commercial Printing`
const weeksDescription3 = `Optimize inventory allocation closer to your customers and revolutionize demand and inventory planning in ways you've never imagined.`

const weeksTitle4 = `Deploy `
const weeksDescription4 = `Rollout with context-adaptive variables to tackle seasonal ForecastTech, inventory changes, sudden economic changes like inflation, shifting demand, and new launches`
// the end

// seconsd last card of below the page 
const title1 = `Quick and accurate prediction of emerging trends`
const message1 = `Demand forecasting tools facilitate the adjustment of coefficients to incorporate evolving trends.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/repyiiv7p7evoag25kv1"

const title2 = `Hierarchical Forecasting`
const message2 = `Provide remarkably precise demand forecasts, right down to the SKU-Store level, at a regional scale. These forecasts consider the distinct behaviors of different customer segments.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/zf4765mmptp2vmtlilis"

const title3 = `
Quantify Elasticity`
const message3 = `Thoroughly apply contextual treatments to the data, both before and after modeling, guided by informed business judgments and retrospective simulations.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/khyixbutl5apxr6kwt4x"
// the end of cards




const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/wvhfbsvzrvsxtiq2ju7p")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};


const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Attaining over 90% accuracy in demand forecasting`
const message = `Our exclusive AI-driven demand forecasting tool, ForecastTech, incorporates a multitude of variables to consider current demand trends and drivers. It evaluates store data, including foot traffic, resulting in the delivery of exceptionally accurate location-specific forecasts, surpassing 90%, a level of precision that's challenging to find elsewhere.`;

// 2nd

function ForeCarstTech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >

      <Grid  style={containerStyle}>
        {/* Left Column */}
        <Grid  xs={12} sm={6} style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: '#15DFED' }}>ForecastTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px",flexWrap:"wrap"}}>
          <h2 style={{ fontWeight: "bold", color: "white" }}>AI-powered Retail Assortment Planning Software</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px"}}>
            Improve margins and turns through better retail ForecastTech planning software using our industry-leading ForecastTech solution to drive your process.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>ForecastTech</Typography>
      </div>

      
      </Grid>
      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>ForecastTech Advantage</h2>
 
      </Grid>
<Grid style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"flex-start",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      With easy-to-use, intuitive interfaces and tools our intelligent retail ForecastTech planning software will have your team turning out better ForecastTech plans with fewer invested hours of their valuable time. Our AI-powered retail ForecastTech planning technology lets you analyze your data to gain actionable insights that allow you to plan ForecastTechs that drive sales and customer delight.          
      </p>

          <p style={{marginTop:"15px",color:"white"}}>
          Improved new product introductions and SKU rationalization efficient activations enable result-driven new product introductions. AI-driven processes lead to clear views of underperforming SKUs allowing efficient SKU rationalization and improving your ForecastTechs as you go.

</p>
</Grid>
     

      
  

<Grid container spacing={2} className='laptop_container' >
        {/* For desktop view */}
        <Grid item xs={12} sm={6}  className='imgtt'>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697347550/Solutions/Forecasttech/kzlcvgurypaeje3zr9ph.png" height={"50%"} width={"50%"} alt="Laptop"  />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Reduce Overstocks and Stockouts</h3>

              <Typography>Efficient activations enable result-driven product launches. AI processes lead to clear views of underperforming SKUs allowing efficient SKU rationalization, cleaning up your ForecastTech as you go.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Reduce Overstocks and Stockouts</h3>
              <Typography>Identify lost sales through intelligent insights, and increase your sales margin through reduced inventory overstocks.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='laptop_container'>
        {/* For desktop view */}
        <Grid item xs={12} sm={6} className='imgtt'>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697347558/Solutions/Forecasttech/rnojvqvbsglriubyjl9t.png" height={"50%"} width={"50%%"} alt="Laptop" />
        </Grid>
   
        <Grid item xs={12} sm={6}>
          
          <div >
            <div className='laptop_container1'>
              <h3>One-stop solution for new products and SKU rationalization</h3>
              <Typography>Efficient activations enable result-driven product launches. AI processes lead to clear views of underperforming SKUs allowing efficient SKU rationalization, cleaning up your ForecastTech as you go.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Reduce Overstocks and Stockouts</h3>
              <Typography>Identify lost sales through intelligent insights, and increase your sales margin through reduced inventory overstocks.</Typography>
            </div>
          </div>
        </Grid>
        
      </Grid>   
    </div>

    <div style={{marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>ForecastTech Features</h1>
        <Typography>We maximize the potential of our users by automating repetitive calculations, leveraging machine learning to identify patterns, and enabling a truly different retail ForecastTech planning software process.

</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/errpa4bhisppzcpfvc10" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/bjnp3whtzzok5lgqzyxw" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/mape93xvgomfuns3qljy" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/cseeioygeertxss8gwqr" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>ForecastTech Features</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/r8fj0rpeyodfhvyqn4au" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/mr9ynky05ku3grivxlxi" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/adhx6fqnw5hzw5rufemm" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Forecasttech/hf4nqv7wvdtfsk1hysvc" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
         </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>10%</h2>
                </div>
                <p style={{color:"white"}}>Improved Forecast Accuracy</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >4-6%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in Unfulfilled Sales.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>80%</h2>
                </div>
                <p style={{color:"white"}}>Streamlined Forecast Creation and Management Time</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>~5-10%</h2>
                </div>
                <p style={{color:"white"}}>Improved Business Response Times to Events</p>

        </div>
    </Box>

    {/* <Box className='forcast_cardContainer3'>
      <div  >
      <h2 style={{color:"white",fontWeight:"bold"}}>Elements</h2>
      <p style={{color:"white",flexWrap:"wrap",marginTop:"50px"}}>
      Access extensive insights into current market trends, technology advancements, and learn how your industry peers are addressing their most significant demand planning and forecasting obstacles through Bringmark predictive analytics.</p>
    <Button variant='contained' style={{marginTop:"50px" , marginBottom:"40px"}}>Request Demo</Button>
      </div>
      <div>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697394057/Solutions/Forecasttech/rs0wdnfwcyrhbn3grgxj.png" alt="" width={"600px"} height={"300px"}/>
      </div>
     
    </Box> */}
           <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Elements</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Access extensive insights into current market trends, technology advancements, and learn how your industry peers are addressing their most significant demand planning and forecasting obstacles through BM's predictive analysation.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697394057/Solutions/Forecasttech/rs0wdnfwcyrhbn3grgxj.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
      </Grid>
    
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697394447/Solutions/Forecasttech/xzdzgccwtvfaj7d3b3wk.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Enhance precision through Retail Demand Forecasting Software.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Effective decision-making relies on real-world data sets, and achieving accurate demand forecasts with such data can be challenging. ForecasTech was designed to address this challenge by operating effectively with sparse data, swiftly identifying trends, and bridging gaps for improved demand forecasting.

Our customers have reported that our retail demand and supply chain forecasting software models deliver forecasts that are 10 times more accurate and yield faster results compared to using Excel spreadsheets and traditional demand forecasting tools. Elevate your planning efficiency today with ForecastTech!.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid>    

    </div>
  );
}

export default ForeCarstTech;
