

import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Assorttech.css";
import "./forcastTech.css";
import FastForwardIcon from '@mui/icons-material/FastForward';
// import ForecastReviews from './ForecastReviews';
import { useNavigate } from 'react-router-dom';
// data of assortech features cards
const featuresTitle = `Smart Clustering`
const featuresDescription = `Retail assortment planning and management software, driven by intelligent insights, resonates with customers by ensuring precise product placement at the right location, at the right time, on the right shelf.`
const feauturesimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/jj44k480vkbdcel5tjta"

const featuresTitle2 = `
Line Planning`
const featuresDescription2 = `Efficient assortment data management with instantaneous updates. Flexibility to monitor, handle, and modify at every stage of the planning process.`
const feauturesimage2="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/jj44k480vkbdcel5tjta"

const featuresTitle3 = `Granular SKU-level Visibility`
const featuresDescription3 = `Decrease the time required for merchandise procurement and elevate your planning with detailed SKU-level visibility, serving as the foundation for intelligent groupings across various planning levels.`
const feauturesimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/jj44k480vkbdcel5tjta"

const featuresTitle4 = `Customer-Focused Assortment`
const featuresDescription4 = `Enhance shopper satisfaction by creating tailored assortments using highly accurate predictions of customer behavior, powered by our NextGen AI-led Forecasting.`
const feauturesimage4 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/zjra4t4sccizbligcrud"

// the end

// Implementation of AssortTech takes less than 8 weeks.

const weeksTitle = `Acquire Knowledge`
const weeksDescription = `Evaluate current inventory levels, inventory aging, selling velocity, and historical sales data at various levels, including by item, class, category, and department. Additionally, review your existing assortment philosophy and processes to inform future decisions and improvements.`
const weeksimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/j1nuagzwoh5bpbbvzrre"

const weeksTitle2 = `Develop`
const weeksDescription2 = `
Implement machine learning models to create optimizations specific to categories, geographies, and channels. Guarantee localized assortments through intelligent clustering for enhanced relevance.`
const weeksimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/AssortTech/mlf9iiznwofc68apcrbp"

const weeksTitle3 = `Supervise`
const weeksDescription3 = `Assess customer experience by tracking shopper demand, in-stock availability, sell-through rates, selling velocity, and markdowns at the store level. Ongoing adjustments to core AI models will be made to enhance overall performance based on these metrics.`
const weeksimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697360245/Solutions/AssortTech/u5rvwlbjnxjfuyuh4tr0.png"

const weeksTitle4 = `Implement`
const weeksDescription4 = `
Introduce new assortment processes by category, including team training. Establish goals and budgets while preventing stockouts and overstocking through the use of predictive analytics based on demand and the latest trends`
const weeksimage4 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697360969/Solutions/AssortTech/csrzug7g8zrevuwe7hli.png"

// the end

// seconsd last card of below the page 
const title1 = `Grocery`
const message1 = `Implement transparent and localized assortments using AI/ML-powered assortment management software to cater to your customers' specific needs.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697364365/Solutions/AssortTech/befj9ctqhpnp0rkdhiyl.png"

const title2 = `Jewelry`
const message2 = `Gain advantages from AI-driven assortments that consider the styles, tastes, and preferences of customers in all locations and channels.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697364431/Solutions/AssortTech/l4fpksxrw8xd0h2jnnnb.png"

const title3 = `Softgoods`
const message3 = `Address the dynamic lifestyle requirements of customers using a forward-thinking assortment planning solution.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697364466/Solutions/AssortTech/ndcssxo5tzejwwnygde9.png"
// the end of cards






const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `AssortTech has provided benefits to numerous industries,`
const message = `Our solutions have been successfully implemented across diverse industries, and our reach continues to expand. Empower your planning team with our top-tier assortment planning solution to enhance customer service and fuel business growth `;


const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/i4tx6uoktt7d1byzp0hy")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};


function Assorttech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }
  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid   style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'black' }}>AssortTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "black" }}>AI-Enhanced Retail Assortment Planning Software

</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",color:"black"}}>
          Boost profitability and inventory turnover with our cutting-edge AssortTech solution, the industry leader in retail assortment planning software. Let AssortTech drive your retail planning process for improved results.   </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" style={{backgroundColor:"black"}} onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'black'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'black'}}>AssortTech</Typography>
      </div>

     
      </Grid>
      <Grid style={{marginTop:"10vh",display:"flex",justifyContent:"flex-start"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>The AssortTech Advantage</h2>
 
      </Grid>
<Grid style={{width:"100%",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      Our intelligent retail assortment planning software, equipped with user-friendly interfaces and intuitive tools, empowers your team to create superior assortment plans with minimal time investment. Powered by AI, this technology enables data analysis, providing actionable insights to craft assortments that boost sales and enhance customer satisfaction       
      </p>

          <p style={{marginTop:"15px",color:"white"}}>

          Experience enhanced new product launches and efficient SKU optimization that drive tangible results. Our AI-driven processes provide clear visibility into underperforming SKUs, facilitating efficient SKU rationalization and continuous assortment improvement.
</p>
</Grid>
     

      
  

<Grid  container spacing={2} className='img-container'>
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697352924/Solutions/AssortTech/lvezq1ecnmugders0wu7.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >A comprehensive solution for both new product management and SKU rationalization.




</h3>

              <Typography>
Efficient activations pave the way for successful product launches that yield results. AI-driven processes offer transparent insights into underperforming SKUs, enabling efficient SKU rationalization and the continuous enhancement of your assortment as you proceed.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Minimize Overstocks and Eliminate Stockouts.




</h3>
              <Typography>Identify missed sales opportunities through intelligent insights and boost your sales margin by reducing excess inventory overstocks.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
    
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Achieve superior accuracy and exceptional efficiency.




</h3>
              <Typography>Leverage machine learning and AI-assisted tools to streamline data entry, minimizing manual efforts and reducing the risk of error. This enables your team to dedicate more time to interacting with actionable insights, enhancing productivity and decision-making.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Efficient Control and Seamless Management




</h3>
              <Typography>Achieve efficient management of business budgets, enhanced visibility at division, category, and class levels, and optimize your merchandise assortment planning for improved overall performance.</Typography>
            </div>
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697352926/Solutions/AssortTech/v4aslsnipwtueibf84zw.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>AssortTech Features</h1>
        <Typography>We maximize the potential of our users by automating repetitive calculations, leveraging machine learning to identify patterns, and enabling a truly different retail assortment planning software process.

</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold"}}>The implementation of AssortTech can be completed in as little as <span style={{color:"#15dfed"}}>8 weeks.</span>
</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>10%</h2>
                </div>
                <p style={{color:"white"}}>Increase in Average Inventory Turn</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >4-6%</h2>
                </div>
                <p style={{color:"white"}}>Increase in Gross Margin</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>80%</h2>
                </div>
                <p style={{color:"white"}}>Reduction in time spent on assortment planning process</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>~5-10%</h2>
                </div>
                <p style={{color:"white"}}>Reduction in non-productive SKUs</p>

        </div>
    </Box>


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
       
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Experience AssortTech in practice.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover a platform designed to provide extensive data access, robust analytics, and actionable insights. Witness how we are revolutionizing assortment planning and optimization for our valued customers.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/v1697363790/Solutions/AssortTech/wnthpblhmaizucizbegr.png"} alt="" width={"600px"} height={"300px"}/>
        </Grid>
      </Grid> 
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"100px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"100px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"100px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   




<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697364733/Solutions/AssortTech/c2bxa8ejmrnlosngorua.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Enumerate the sectors that have profited from AssortTech.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Our solutions have found applications in a variety of industries, and our presence continues to grow across different sectors. Utilize our top-tier assortment planning solution to empower your planning team, enhance customer service, and foster business expansion.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 

    

    </div>
  );
}

export default Assorttech;