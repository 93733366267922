import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const SoftwareDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Software Developers</Typography>

<Typography >Hire Software Developers To Built Augment Web and App Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/fslph4d0i1w3axwxtkqf" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Software development is the process of creating computer programs or applications using a special language that computers understand. It involves designing, testing, writing code, fixing mistakes, and more. The software that's made can include things like apps, tools, and other computer stuff. It's like building a puzzle with different pieces of code that make the software work. When the software is done, it can do different things based on what it was designed for. Hiring software developers is really important because they know how to write the specific instructions that computers need to follow. There are many ways to do software development, and it can be customized to fit what a business needs. In today's fast-paced world, businesses need to come up with new and efficient ideas quickly to do well. That's why getting a software developer to help is a smart move. </Typography>

    <Typography style={{marginTop:'40px'}}> Software developers are like the architects of computer stuff. They're in charge of designing, testing, and setting up software systems that businesses use. They help businesses work better by creating special computer programs that make things run smoother. When the computer stuff needs updates or fixing, software developers step in. They also make sure everything is safe and can make new databases for businesses. Software developers do their work on phones, computers, and other devices to build these systems. They're the ones who keep everything organized and take care of making new things and keeping old things working. Think of them as the brains behind computer programs, making other programs work together or on their own. There are two main types of software developers: those who work on specific applications and those who work on the big systems that these applications run on. They handle both big and small projects, making sure computer programs do what they're supposed to do.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Software Developers:</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>1. Reliable Resources</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our software developers prioritize reliability and scalability for your business projects. In software development, every detail matters, and we keep this in mind throughout the development process. We understand the critical importance of reliable deliverables for your business, reducing risk management compared to other providers.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Proficient Consultation</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our skilled software developers offer exceptional consulting services, tailoring effective strategies that are both cost-effective and time-efficient. We assist in identifying the right technology and methods for your needs, addressing challenges, and providing relevant information and data. Our developers specialize in niche projects, ensuring the most suitable development solutions for your business.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. High Quality Experience</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Delivering a high-quality experience is the cornerstone of BringMark. We uphold unwavering standards of quality, a reputation validated by our satisfied clients. Quality assessments are conducted before presenting the final product or project to our clients. Our software developers consistently deliver excellence in every project, product, and application we undertake.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Unique Approach</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> BringMark software developers adopt a distinctive approach to development. They possess deep insights into software development and know precisely when and how to apply it in applications. Managing projects from various angles sets them apart from others, and their approaches are highly relevant and impactful in the market.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Cloud Services </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our cloud experts assist in selecting and implementing cloud solutions such as AWS, Azure, or Google Cloud to optimize application scalability, performance, and cost-efficiency.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Database Design & Management</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We offer database design, optimization, and management services to ensure data integrity and efficiency in software applications.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Code Review & Quality Assurance </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our team conducts thorough code reviews and quality assurance assessments to identify and rectify issues, ensuring software quality and reliability.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Agile Project Management</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Agile project management services assist software developers in planning, executing, and delivering projects efficiently, enabling flexibility and adaptability in development processes.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Software Licensing and Compliance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Assistance with software licensing and compliance to ensure adherence to legal and regulatory requirements.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Cloud Computing and Hosting</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>  Deployment and management of software on cloud platforms like AWS, Azure, or Google Cloud for scalability and reliability.

</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default SoftwareDevelopers