

import "./App.css";
import Loaders from './components/loader/Loaders';
import React, { useState, useEffect } from 'react';
import RoutesAll from './mainframes/RoutesAll';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  // const handleContextMenu = (e) => {
  //   e.preventDefault(); // Prevent the default right-click context menu
  // };

  // // Add event listeners to prevent inspect element and common shortcuts
  // window.addEventListener('keydown', (event) => {
  //   if (
  //     (event.key === 'F12') || // F12
  //     (event.metaKey && event.altKey && event.key === 'C') || // Command + Option + C
  //     (event.key === 'U') || // View Page Source (Cmd + Option + U in Safari)
  //     (event.ctrlKey && event.shiftKey && event.key === 'C') || // Ctrl + Shift + C
  //     (event.metaKey && event.altKey && event.key === 'C')
  //   ) {
  //     event.preventDefault();
  //   }
  // });

  // // // Disable right-click context menu
  // document.addEventListener('contextmenu', (e) => {
  //   e.preventDefault();
  // });

  // // // Disable the 'Inspect Element' context menu option
  // document.addEventListener('contextmenu', (e) => {
  //   e.preventDefault();
  // });

  // // Disable the 'Ctrl+Shift+I' and 'F12' key combinations
  // window.addEventListener('keydown', (event) => {
  //   if ((event.ctrlKey && event.shiftKey && event.key === 'I') || (event.key === 'F12')) {
  //     event.preventDefault();
  //   }
  // });

  // // Disable 'Ctrl+U' to view page source
  // window.addEventListener('keydown', (event) => {
  //   if (event.ctrlKey && event.key === 'U') {
  //     event.preventDefault();
  //   }
  // });

  // // Prevent various other keyboard shortcuts and key combinations
  // window.addEventListener('keydown', (event) => {
  //   if (
  //     (event.ctrlKey && event.key === 'S') || // Ctrl + S (Save)
  //     (event.ctrlKey && event.key === 'J') || // Ctrl + J (Downloads)
  //     (event.ctrlKey && event.key === 'K') || // Ctrl + K (Search)
  //     (event.ctrlKey && event.key === 'E') || // Ctrl + E (Search)
  //     (event.ctrlKey && event.key === 'U') || // Ctrl + U (View page source)
  //     (event.ctrlKey && event.key === 'F')    // Ctrl + F (Find)
  //   ) {
  //     event.preventDefault();
  //   }
  // });

  // // Disable common macOS keyboard shortcuts for developer tools
  // window.addEventListener('keydown', (event) => {
  //   if (
  //     (event.metaKey && event.key === ']') || // Cmd + ] (Next tab)
  //     (event.metaKey && event.key === '[')  // Cmd + [ (Previous tab)
  //   ) {
  //     event.preventDefault();
  //   }
  // });

  // useEffect(() => {
  //   // Simulate a 3-second delay for loading (you can adjust this)
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500);
  // }, []);

  return (
    <div className="App" 
    // onContextMenu={handleContextMenu}
    >
      {/* {isLoading ? ( */}
        {/* // <Loaders /> */}
      {/* // ) : ( */}
        <div>
          <RoutesAll />
        </div>
      {/* // )} */}
    </div>
  );
}

export default App;