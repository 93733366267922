import React from 'react';
import './Blogs.css';


export default function Blogs() {
  return (
    <div className='blog'>
    <div className='impact'>
        <h1> The BringMark Blog</h1>
        <p> Guidance and viewpoints from our in-house SMEs as well as third-party industry experts and influencers.</p>
    </div>
       

<div className="cardcontent1">
<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/nmxifvnws0z4yjc01bbv'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'start'}}> Retail</h5>
<p style={{textAlign:"start"}}>Retail Assortment, Inventory, Pricing Planning for Halloween: Tradition!</p>
</div> 
</div>

<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/tzepnktyykrkshn8hcvc'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Retail </h5>
<p>5 Ways You Will Boost Profit with AI-Driven Retail Assortment Planning </p>
 </div>
</div>

<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/ukpdufskvupco9jqcyfx'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Retail </h5>
<p> Improve Retail Assortment Planning With Size Curves-Based Demand Forecasting</p>
</div>
</div>

<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/rii5xbebwvmyhwwdwnmg'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Retail </h5>
<p> When Did Physical Stores Become the New Retaiil Marketing Media Channel?  </p>
</div>
</div>

<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/rpe8kqva5z1ihlp8j5bl'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Software </h5>
<p> Markdown Optimization Part 3: How to Succeed if You're not Using Pricing Software </p>
</div>
</div>

<div className="retail">
<img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/BLOG/as9arjbnbcgqsjaqcy7o'} width={350} height={230} alt="" />
<div className='description1'>
<h5 style={{textAlign:'justify'}}> Software </h5>
<p> Markdown Optimization Part 2: How to Succeed if You're not Using Pricing Software </p>
</div>
</div>


</div>
</div>   
    
  );
}