import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const SpringBootDevelopers = () => {

    const width=useWindowSize()
  return (
<Grid  style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire SpringBoot Developers</Typography>

<Typography >Hire Springboot Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/o9zt5xsbbebsjadxkbct" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Established back in 2002, Spring Boot stands as a widely recognized Java-based framework utilized for crafting business web and mobile applications. This Spring framework offers an extensive array of features and functionalities meticulously designed to align with the most cutting-edge requisites of businesses. Engaging a Spring Boot developer enables the transformation of your project into a Spring framework, characterized by distinctive structures all converging around a specific focal point. A multitude of fully operational applications have embraced Java Spring Boot due to its exceptional speed and security attributes. Developers opt for Spring Boot owing to its streamlined nature, which sets it apart from other frameworks in terms of rapidity and simplicity. Java adheres to the principle of writing code once and running it universally, obviating the need for vendor-specific installation descriptors when migrating applications between different servers. </Typography>

    <Typography style={{marginTop:'40px'}}> Spring Boot developers possess the know-how to harness the capabilities of this framework, renowned for its efficiency in creating business web and mobile applications. These developers excel in leveraging Spring Boot's extensive feature set, tailored to meet the dynamic requirements of modern companies. With a keen focus on specific areas, they craft projects within the Spring framework, resulting in unique and effective structures. Many operational applications owe their functionality to Java Spring Boot's quick and secure attributes, making it a preferred choice. The simplicity and speed of Spring Boot set it apart, appealing to developers for its user-friendly approach compared to other frameworks. Additionally, Java's "write once, run anywhere" philosophy eliminates the need for vendor-specific installation descriptors when transferring applications across servers, further enhancing the appeal of Spring Boot developers in the development process.</Typography>

    <Grid className='sticky'> 

<Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By SpringBoot Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Spring App Enhancement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Engage one of our skilled Spring developers to enhance the usability of your application.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Spring App Consulting Services </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Benefit from the extensive expertise of our Spring developers in application maintenance and consulting.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Custom Application Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our full-stack Spring software developers deliver tailor-made solutions rapidly, catering to businesses of all scales.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Timely Delievery</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>The developers at BringMark have undergone rigorous training to ensure adherence to project timelines without deviation.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Progressive Roadmap</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We adopt a results-oriented approach in all our projects, investing significant effort in comprehensively understanding every aspect of the projects before delivering solutions.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Non-Disclosure Agreement (NDA)</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We highly value your ideas and recognize their importance to you. Therefore, we require all of our clients to sign a non-disclosure agreement to safeguard their intellectual property.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Microservices Development</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Design and implementation of microservices architectures using Spring Boot for improved scalability and flexibility.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Feedback and Survey Services</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>  Our Team Develop services to collect customer feedback, conduct surveys, and analyze customer sentiment. We integrate with tools like Spring Data to store and manage feedback data.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Strong Client Retention Rate</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>With our outstanding code quality and exceptional user experience, we've successfully retained the majority of our clients.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.Spring App Support & Maintenance</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our Spring developers provide product maintenance and support services to continuously monitor and assist clients with their applications.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>
<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 
 </Grid>
  )
}

export default SpringBootDevelopers