import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useWindowSize } from "../../commonFunctions";
import ContactForm from "../ContactForm";

const AppDevelopers = () => {
  const width = useWindowSize();
  return (
    <Grid
      container
      style={{ width: "80%", margin: "auto", paddingBottom: "200px" ,marginTop:"90px"}}
    >
      <Grid
        item
        xsm={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: "600", marginBottom: "20px" }}
        >
          Hire App Developers Developers
        </Typography>

        <Typography>
          Recruit app developers to obtain a sturdy solution for your project's
          mobile app development requirements. Elevate your business on a global
          scale with a professionally crafted mobile app brought to life by our
          expertise!
        </Typography>
      </Grid>

      <Grid
        item
        xsm={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: "80%" }}
          src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/mgggwwmmcnhr6ggbfmya"
          alt=""
        />
      </Grid>

      <Grid item style={{ marginTop: "100px" }}>
        <Typography> The demand for mobile app development has surged across businesses of all types! In the past, information was primarily accessed through computers and desktops. However, technological advancements have led to a shift in user behavior towards mobile devices, encompassing activities from browsing websites to ordering food and booking taxis as part of their daily routines. This situation presents a dual role for businesses: an opportunity to excel and a responsibility to craft exceptional experiences for their most valued clients—often through smartphones. Brinkmark offers prime opportunities to engage skilled and seasoned mobile app developers. Our team comprises certified and professional app developers who specialize in creating feature-rich and highly functional mobile applications tailored to diverse business needs. Whether you're a well-established enterprise or a fledgling startup,We can bring your mobile app to life at a cost-effective price point. With a track record of your applications spanning a wide array of industries.</Typography>

        <Typography style={{ marginTop: "40px" }}> Our skilled app developers are deeply enthusiastic about exploring novel ideas to enhance user experience during the creation of your distinctive application. Opt for hiring app developers from BringMark, and unlock an entirely new realm of boundless possibilities with unique mobile apps that elevate your business's competitiveness and user-friendliness.</Typography>
        <Grid className="sticky"> 

        <Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}>
          <Typography variant="h4" marginTop={10} fontSize={27}>Service We Offer By App Developers:   </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}> 1. Clear Concept and Purpose </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> We follow well-defined and clear concept which is essential to Understand the purpose of your app.</Typography>


          <Typography variant="h4" marginTop={5} fontSize={22}> 2.Market research </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> Conduct thorough research to identify your target audience, their needs, and preferences. This helps tailor your app to your users. </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}> 3.Better UI/UX Development </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>Create a user-friendly interface with intuitive navigation. Prioritize user experience (UX) and user interface (UI) design.         </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>4. App Store Optimization</Typography> 
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> We Optimize your app's title, description, and keywords to improve its visibility in app store search results.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>5. Leverage Social Media</Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> Utilize platforms like Facebook, Instagram, Twitter, and LinkedIn to promote your app. Engage with your audience through posts, stories, and targeted ads.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>6. Backend Development </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> Creating the server-side logic, databases, and APIs that enable seamless communication between the user interface and the data storage.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>7. Quality Assurance and Testing </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}> Thoroughly testing applications to identify and rectify any bugs, glitches, or performance issues before release.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>8. Deployment and Integration</Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>Managing the deployment process to make the application accessible to users and ensuring proper integration with relevant platforms and systems.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}> 9. Maintenance and Support</Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>Providing ongoing support, updates, and maintenance to ensure the app remains functional, secure, and up-to-date.</Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>10. Emerging Technologies</Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>Staying updated with emerging technologies like artificial intelligence, augmented reality, and blockchain to incorporate innovative features into your application.</Typography>
        </Grid>
        <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
      </Grid>

      <Grid container style={{ marginTop: "200px", justifyContent: "center" }}>
        <Typography variant={width < 700 ? "h6" : "h2"}>
          Leverage World-Class Talent
        </Typography>
        <Typography>
          We have a team of experts who have a pool of expertise in their
          respective fields. Their approach is out-of-box, dynamic, and unique
          in the market.
        </Typography>
      </Grid>

      <Grid  spacing={4} marginTop={5} style={{ width: "100%",flexDirection:"row",display:"flex",flexWrap:"wrap" }}>
        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xlg={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent", padding: "10px" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Junior Developers
            </Typography>
            <Typography>
              Our Junior Developers with 1 to 2 years of experience understand
              the client's needs and ensure that the entire process matches
              requirements. They have insightful knowledge and try their best
              input to develop outstanding and unique development.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Senior Developers
            </Typography>
            <Typography>
              Senior Developers having 2 to 8 years of experience are highly
              skilled and proficient throughout the development process. They
              bring the best in the development and get successful bug-free
              solutions.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Web Developers
            </Typography>
            <Typography>
              Web Developers of Bringmark are outstanding and
              dedicated. We have a team of skilled web developers with several
              years of experience in the market. The Web Developers have a
              futuristic vision of web development.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            Project Managers
          </Typography>
          <Typography>
            Our project managers are well aware of how to handle and execute
            projects. Bringmarkhas expertise in IT development, and
            our project managers keep an eye on every minor detail in the
            development process with client satisfaction.
          </Typography>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            UI/UX Designers
          </Typography>
          <Typography>
            Our web developers know the importance of a website for businesses
            in this competitive era. They have expertise in all the latest web
            technologies and deliver exceptional web design and development
            services as per client requirements.
          </Typography>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            Testers
          </Typography>
          <Typography>
            Quality Assurance is one of the top aspects of any successful
            solution. We believe in delivering solutions with the best quality
            in the market, and our QA team checks every project we work on and
            helps us deliver bug-free solutions to our clients.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDevelopers;
