import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const DjangoDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Django Developers</Typography>

<Typography >Hire Django Developers To Built Augment Django web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/qi4zxxejnhh2s2gnoz8c" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Django is a famous and widely used tool made with Python. It's like a speedy, strong, and expandable tool that helps programmers create lots of different things like IoT devices, online stores, Machine Learning (ML), and data analysis projects.
Many Python developers like using the Django Web Framework because it helps them make apps quickly using less code. And because it has clear and simple coding features, it makes the whole process of making websites work better.</Typography>

    <Typography style={{marginTop:'40px'}}> Moreover, the Django framework offers a dynamic combination of create, read, update, and delete functions through its excellent admin models. This combination defines the fundamental database actions, making it easier to view, edit, and search for any data using the interface.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Django Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. High Speed</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>The Django framework empowers Django developers to swiftly bring projects from ideas to deployment with remarkable speed.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Great Scalibilty </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Businesses evolve over time, necessitating an advanced-level framework for apps that can scale accordingly. With the Django framework, apps and websites can be delivered in a manner that aligns with future business requirements.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Top tier Django developement services</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Choose from our top-notch services and benefit from our extensive experience in crafting performance-driven web apps.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Highly reliable</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Django has been meticulously designed to ensure robust security, incorporating comprehensive security functionalities. Websites and apps developed using Django are guaranteed to be exceptionally reliable.

</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Rich in features </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>The Django Framework is equipped with numerous add-ons that enable the creation of distinctive features for both web and mobile applications.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Adaptability: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Out Team of Django Developer have ability to adapt to changing project requirements, technology stacks, and development methodologies.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>7. Security Awareness:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our Django Developers Understand  web application security principles and practices, including how to protect against common security vulnerabilities like SQL injection and cross-site scripting (XSS).</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>8. Proficiency in Django: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Django developer have a strong command of the Django web framework, including its core components like models, views, templates, and URL routing.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Django Migration and Upgrades:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Assisting clients in migrating their existing web applications to Django or upgrading their current Django applications to the latest version to leverage new features and security improvements.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Maintenance and Support:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Providing ongoing maintenance, updates, and technical support to ensure that Django applications run smoothly and stay up to date with security patches and feature enhancements.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default DjangoDevelopers