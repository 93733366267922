import React from 'react';
import './Appprojects.css'
function Arvrprojects(){
  return (
    <>
      {/* @TODO: Move away from custom props before injecting the polyfill, as it can't handle it */}
      {/* <input type="checkbox" id="debug" /> */}
      
      <header>
        <div>
          <h2>Product</h2>
        </div>
      </header>
      
      <main>
        <ul id="services-cards">
          <li className="services-card" id="card_1">
            <div className="card__content">
              <div>
                <h2>Card One</h2>
                <p>Prepare for a shopping experience like no other with BringMark's meticulously crafted Android and iOS apps. These digital gateways don't merely facilitate transactions; they usher you into a realm where products become captivating stories waiting to be explored. With the seamless integration of cutting-edge 3D technology, the Android and iOS apps redefine the very essence of online shopping, offering a tantalizing glimpse into the future of retail engagement.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img  className='img_cantainer' src="https://cms.ultraleap.com/app/uploads/2019/12/ultraleap-stems-pinch-scroll-1.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_2">
            <div className="card__content">
              <div>
                <h2>Card Two</h2>
                <p>The Android and iOS apps engineered by BringMark aren't just functional tools; they are the epitome of innovation meeting user-centric design. These apps stand as portals to a new dimension of shopping, where the lines between physical and virtual blur, granting you unprecedented control over the products you encounter. It's a convergence of reality and technology that unfolds in the palm of your hand.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://miro.medium.com/v2/resize:fit:920/0*t173G0ULbpKUfKuf.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Venturing into the immersive world of BringMark's Android and iOS apps reveals a treasure trove of real-time insights at your disposal. Diving into comprehensive specifications, authentic user reviews, and interactive demonstrations isn't a mundane task; it's a journey of exploration that transforms you from a passive observer to an active participant. This dynamic engagement reshapes the way you understand and appreciate each product's unique qualities.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img  className='img_cantainer' src="https://uploads.toptal.io/blog/image/124559/toptal-blog-image-1509618028103-92545ba5595a43f04369647452dceb84.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>The true brilliance of BringMark's Android and iOS apps emerges as they empower you to transcend the role of a mere consumer and embrace that of a creator. Beyond conventional shopping, these apps foster collaboration with BringMark's experts, propelling your product ideas into the digital realm. These apps become your canvas, where concepts flourish into captivating virtual realities.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://assets.arpost.co/wp-content/uploads/2019/05/28210722/39.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Envision a scenario where your creation takes center stage through BringMark's Android and iOS apps. Your product transcends the confines of static visuals and traditional descriptions, metamorphosing into a captivating experience. Empowered by these apps, prospective customers don't just see; they engage deeply, forging a connection that extends beyond the digital realm.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://uploads.toptal.io/blog/image/124558/toptal-blog-image-1509617985735-ab78c09150100459e3c4dcc1f81a7e03.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>At the core of BringMark's Android and iOS apps lies a symphony of interactivity that resonates with users. This isn't a one-sided interaction; it's an immersive dialogue. Whether it's virtually trying on clothing, personalizing products, or envisioning furniture placements, the apps provide a playground for exploration, boosting your confidence in every choice you make.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                {/* <img src="https://i.pinimg.com/originals/27/8d/8d/278d8d98b386c8564f5d108a549641b9.gif" alt="Image description" /> */}
                <img className='img_cantainer' src="https://www.motortrend.com/uploads/sites/5/2019/01/Ford-VR-car-design-animation.gif" alt="Image description" />

              </figure>
            </div>
          </li>


          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>When the horizons of your visionary concepts need to be refined, BringMark's personalized consultations become invaluable. The experts at BringMark aren't just consultants; they're collaborators invested in nurturing your success. Together, you'll ensure that your product vision harmoniously melds with the endless possibilities that the Android and iOS apps offer.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                {/* <img src="https://www.motortrend.com/uploads/sites/5/2019/01/Ford-VR-car-design-animation.gif" alt="Image description" /> */}
                <img className='img_cantainer' src="https://i.pinimg.com/originals/27/8d/8d/278d8d98b386c8564f5d108a549641b9.gif" alt="Image description" />

              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Amidst the ever-evolving landscape of mobile shopping, BringMark's Android and iOS apps redefine the very art of customer engagement. In a world where standard apps often fall short, BringMark's approach fosters an immersive journey, empowering customers to truly comprehend and bond with products before committing to purchase.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://media.giphy.com/media/WRHj9A4ZVEFmV8khAN/giphy.gif" alt="Image description" />
              </figure>
            </div>
          </li>
          <li className="services-card" id="card_3">
            <div className="card__content">
              <div>
                <h2>Card Three</h2>
                <p>Embark on a transformative shopping experience through BringMark's visionary Android and iOS apps. Their unwavering dedication to interactivity cements their position as pioneers in the domain of mobile product engagement. Whether you're an enthusiast seeking enriched experiences or a visionary yearning to breathe life into your concept, BringMark's apps promise a canvas of boundless potential.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://miro.medium.com/v2/resize:fit:1200/0*gPyzMthXW-LXeUP4.gif" alt="Image description" />
              </figure>
            </div>
          </li>



          <li className="services-card" id="card_4">
            <div className="card__content">
              <div>
                <h2>Card Four</h2>
                <p>As you stand on the threshold of transcending conventional app boundaries, BringMark's Android and iOS applications beckon. Immerse yourself in a realm where products cease to be static and instead come alive in immersive experiences. Engage in captivating encounters, and observe firsthand how innovation bridges the divide between users and the products that enrich their lives. Your odyssey through the realms of mobile shopping and product creation ignites with the luminance of BringMark.</p>
                <p><a href="#top" className="btn btn--accent">Read more</a></p>
              </div>
              <figure>
                <img className='img_cantainer' src="https://media.tenor.com/V8nftWjCR74AAAAd/augmented-reality-glasses.gif" alt="Image description" />
              </figure>
            </div>
          </li>
        </ul>
      </main>
      
      <aside>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem repellat non ratione eum provident quaerat obcaecati dicta
          autem voluptates sit cum quis distinctio, atque sint commodi reprehenderit perspiciatis iure velit molestiae eos molestias
          ipsa nihil quia?
        </p> */}
      </aside>
    </>
  );
};

export default Arvrprojects;
