import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const FullStackDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire FullStack Developers</Typography>

<Typography >Hire Full-Stack Developers To Built Augment Full-Stack web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/gmicppg9kvfcnoxfxpb9" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Full-stack development refers to the practice of creating both the front-end and back-end components of a web application or software. A full-stack developer possesses the skills to work on all layers of an application's architecture, from designing and implementing user interfaces (UI) to managing databases and server-side logic. Front-end development involves crafting the visible aspects of the application that users interact with, including the design, layout, and user experience (UX). This often involves technologies like HTML, CSS, JavaScript, and various front-end frameworks. On the other hand, back-end development deals with the server-side of the application. This includes handling data storage, managing user authentication, and processing requests. Back-end development commonly utilizes server-side languages like Python, Ruby, PHP, Node.js, and frameworks like Django, Ruby on Rails, or Express. </Typography>

    <Typography style={{marginTop:'40px'}}> Full-stack developers bridge these two realms, ensuring that the front-end and back-end components work harmoniously together. They possess a comprehensive understanding of the entire development process, allowing them to create functional and cohesive applications from start to finish. This breadth of knowledge also enables full-stack developers to effectively collaborate with specialized front-end and back-end teams.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By FullStack Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1.Full Stack Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our full-stack developer specializes in creating dynamic and dependable web solutions, leveraging the latest front-end and back-end technologies. We also offer personalized design options for full-stack web development.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2.Database Management: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We excel in precise real-time database management. Our team adheres to data flow diagram principles when delivering databases. Our experts are adept at handling and managing databases effectively. </Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3.Full Stack Front-End Developement</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our developers utilize HTML, JavaScript, MEAN stack, and CSS for front-end development. We are dedicated to delivering top-quality results, following best practices in front-end development. Our developers pay meticulous attention to details like fonts, text, colors, and more to ensure website coherence.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4.Full Stack Back-End Developement:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our full-stack back-end development employs JavaScript, Laravel, Node.js, PHP, Ruby, Python, and more. We ensure accurate and efficient code execution, providing secure and reliable full-stack back-end development services.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5.Full Stack Software Developement:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>We offer scalable and high-quality software development services. Our developers rigorously test and verify applications until they achieve seamlessness. Our primary objective is to align with our clients' business requirements, and we achieve this through the use of cutting-edge technology.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6.Full Stack Application Developement:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Full-stack application development is one of our renowned services. Our experts craft cross-platform, native, and hybrid mobile applications. We harness technologies like Flutter, Ionic, React Native, and more for comprehensive mobile app development.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7.Full Stack Javascript Developement: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our full-stack developers are proficient in JavaScript. They create dynamic web applications, interactive web pages, mobile apps, and software applications, showcasing their versatility and exceptional functionality.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8.API Development and Integration: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our full-stack developers excel in implementing API integration services, connecting various applications such as shipping tracking apps, IoT devices, third-party payment gateways, B2B integrations, and SaaS platforms. We provide high-quality and scalable API development solutions.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9.Full-Stack PHP Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> BringMark leverages PHP development for website and web app creation. Our developers operate on Linux-based operating systems, ensuring error-free operation. We also offer third-party API development for seamless functionality.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10.Economical and Time-Efficient:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>With our skilled developers,we have a clear understanding of project deliverables. Their expertise significantly streamlines the development process, making it both cost-effective and time-efficient. We have a range of factors and robust elements in place to align with our clients' business requirements. BringMark stands as a prominent leader in the realm of full-stack development. Our track record includes successful project deliveries by our skilled full-stack developers. We are renowned for our commitment to quality, punctual project completion, efficiency, and many more attributes. If you are in search of a full-stack developer for your project, BringMark emerges as the ideal choice.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px',}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' }}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default FullStackDevelopers