import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Inventorytech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Exception Management Focused`
const featuresDescription = `Guides the user to focus their efforts where they are most needed, facilitating an optimized and automated process.`
const feauturesimage =require('../../assets/solution/demandplanningout.png')

const featuresTitle2 = `Automated Style Linking`
const featuresDescription2 = `Enhance new product inventory planning, allocation, and replenishment through automated style chaining and supersessions.`
const feauturesimage2 =require('../../assets/solution/demandplanningout.png')

const featuresTitle3 = `"What-If" Scenario Simulation Tool`
const featuresDescription3 = `The capability to evaluate different inventory strategies and select the one that maximizes service levels and delivers the best business outcomes.`
const feauturesimage3 =require('../../assets/solution/demandplanningout.png')

const featuresTitle4 = `Enhanced End-to-End Inventory Planning`
const featuresDescription4 = `Advanced machine learning models accurately forecast demand and store allocation or replenishment requirements, facilitating optimized distribution center purchasing and replenishment processes.`
const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Acquire Knowledge`
const weeksDescription = `Retrieve data directly from your systems to initiate preliminary analyses, all while gathering input from key stakeholders.`
const weeksimage =require('../../assets/solution/demandplanningout.png')

const weeksTitle2 = `Construct`
const weeksDescription2 = `Develop machine learning models grounded in critical insights to fine-tune complex allocation and replenishment solutions`
const weeksimage2 =require('../../assets/solution/demandplanningout.png')

const weeksTitle3 = `Supervise`
const weeksDescription3 = `Guarantee that the applied models function effectively and evolve to enhance accuracy over multiple time periods.`
const weeksimage3 =require('../../assets/solution/demandplanningout.png')

const weeksTitle4 = `Implement`
const weeksDescription4 = `Deployment and Go-Live, along with continuous support to ensure the solution continually adds value to your business.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Optimized inventory planning and retail allocation and replenishment powered by AI-driven inventory planning software in the retail sector.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Inventorytech/ixgjntpdnbzptwee8l7v"

const title2 = `Fashion`
const message2 = `Harness machine learning for precise SKU-Store level forecasts, utilizing style chaining to automate new product introductions and ensure accurate retail inventory allocation in the fashion industry.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697387510/Solutions/Inventorytech/ozvsdm3kww9tfezzj8qw.png"

const title3 = `CPG`
const message3 = `Mitigate stockouts and inventory overflows by employing inventory planning and replenishment software.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697387509/Solutions/Inventorytech/jleafuhjsfhzwv3vyl8y.png"
// the end of cards


const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/bota1c6oisklconk2hsh")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};



const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Industries that can benefit from InventoryTech`
const message = `
Gather comprehensive insights into the latest trends in inventory planning, the utilization of artificial intelligence and machine learning in the merchandising process, and how leading companies in your industry are addressing their inventory challenges through inventory optimization software..`;


function Inventorytech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid  style={containerStyle}>
        {/* Left Column */}
        <Grid  xs={12} sm={6} style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: 'white'}}>InventoryTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "white"}}> Advanced inventory planning software guarantees that the correct product is delivered to the right location precisely when it's needed. .</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px", color: "white" ,fontSize:"20px"}}>
   AI-powered inventory planning software ensures that service levels are consistently met while simultaneously minimizing inventory levels, thus optimizing operational efficiency.     </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: 'white'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'white'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: 'white'}}>InventoryTech</Typography>
      </div>

   
      </Grid>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>Inventory Advantage</h2>
 
      </Grid>
<Grid style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      Optimize your inventories through cutting-edge inventory planning, allocation, and replenishment software that harnesses predictive analytics to provide unparalleled accuracy, even in intricate inventory scenarios. Our state-of-the-art machine learning models enable swift "what-if" simulations, making our inventory planning solution exceptionally flexible, dynamic, and precise. This ensures businesses can make informed decisions that drive bottom-line results.      </p>

          <p style={{marginTop:"15px",color:"white"}}>
          Lower inventory costs, release working capital, reduce markdowns, and boost margin dollars with the assistance of inventory optimization software.</p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386129/Solutions/Inventorytech/kpbaouxqu73wmhxfaawe.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Growth in Margin Dollars</h3>

              <Typography>Efficient activations enable result-driven product launches. AI processes lead to clear views of underperforming SKUs allowing efficient SKU rationalization, cleaning up your InventoryTech as you go.</Typography>
            </div>
            <div className='laptop_container1'>
               <h3 style={{marginTop:"35px"}}>Precision</h3>
              <Typography>AI-based algorithms deliver remarkably precise InventoryTech down to the most detailed level, enhancing return on investment in end-to-end inventory planning and optimization.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386128/Solutions/Inventorytech/gxg9p4bpnjjdij3iqzf5.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Enhanced Efficiency</h3>
              <Typography>Decrease the time dedicated to product inventory allocation and replenishment, all the while sidestepping common inventory challenges such as stockouts, overstocking, and backorders..</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Streamlined Inventory Management</h3>
              <Typography>Streamline your inventory management process, reducing the time spent on product allocation and replenishment while minimizing inventory issues like stockouts, overstocking, and backorders.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>InventoryTech</h1>
        <Typography>Inventory planning software maximizes the potential of your inventory by automating repetitive calculations and harnessing machine learning to recognize patterns.

</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Inventorytech/wzqqw97qny1ls2bed2s4" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386364/Solutions/Inventorytech/tfmdcuzyuezqjfbsrofo.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386363/Solutions/Inventorytech/hhsumizc9egk0bcsd0vo.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386363/Solutions/Inventorytech/t52cmwbiazoh7dwxua2c.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>Inventory Features</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386982/Solutions/Inventorytech/bmcnnjvu3qqexzosjzh5.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386982/Solutions/Inventorytech/ozlvik3dfpkgfwz4gmtd.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386982/Solutions/Inventorytech/ycbclwzedhyqema2wezi.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697386982/Solutions/Inventorytech/xouon5fagvroxsyf2kww.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>10%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in Allocation Planning Time</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >4-6%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in Clearance</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>80%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in Lost Sales</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>~5-10%</h2>
                </div>
                <p style={{color:"white"}}>Reduction in Wastage</p>

        </div>
    </Box>

        <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore InventoryTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Gather comprehensive insights into the latest trends in inventory planning, the utilization of artificial intelligence and machine learning in the merchandising process, and how leading companies in your industry are addressing their inventory challenges through inventory optimization software.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697387352/Solutions/Inventorytech/urmvcd0hntxgnipk6shc.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
      </Grid>
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   

    
<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697387712/Solutions/Inventorytech/mnfjderee5lf9jexadwr.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Boost bottom-line growth with Bringmark industry-leading inventory planning, allocation, and replenishment software.
      
      Your retail store is a labyrinth of products and displays. With the assistance of our AI-powered inventory allocation software, you can enhance business performance by ensuring you provide the products your customers desire.
      
      Retailers require the most accurate insights, and InventoryTech delivers just that. Our resilient inventory optimization solution boasts constantly evolving InventoryTech algorithms that cater to your supply chain needs, spanning from promotions to overstocks, seasonal allocations, and much more.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 
    

    </div>
  );
}

export default Inventorytech;
