import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { Divider,Button,TextField, Box,Snackbar } from '@mui/material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import BusinessIcon from '@mui/icons-material/Business';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import './footer.css'
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { Link, useNavigate,NavLink } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast, ToastContainer } from 'react-toastify';


const useStyles = {

  logo: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  socialIcons: {
    '& > *': {
      color: 'white',
      marginRight: '8px', // Adjust the spacing as needed
    },
  },
  footerText: {
    marginTop: '8px', // Adjust the spacing as needed
    textAlign:"center"
  },
  tableContainer: {
    marginTop: '24px', // Adjust the spacing as needed
  },
  
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    mobile: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setEmail(e.target.value);
    setEmailError(false); // Reset the error when the user starts typing again
  };

  const handleSubmit = (e) => {
    if (email.trim() === '') {
      setEmailError(true);
    } else {
      setOpenSnackbar(true);
    }
    e.preventDefault();
    toast.success('Thank you for connecting with Bringmark!');
    setTimeout(() => {
      window.location.reload();
    }, 3000); // Refresh the page after 3 seconds
    console.log(formData);
  };


  function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // for smooth scrolling (optional)
    });
}
const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};



  return (
    <div style={useStyles.root} className='foot'>
      
      <Container maxWidth="lg" style={{marginTop:"30px"}}>

        <Grid container className='imgl'>

          
        <Box sx={{ flexGrow: 1 }} />


          <Grid item>
            <div style={useStyles.socialIcons}>
              <Link to={"https://www.facebook.com/profile.php?id=61550021712429&sk=following"}>
              <IconButton>
                <FacebookIcon />
              </IconButton>
              </Link>
              <Link to={"https://twitter.com/BringMark_"}>
              <IconButton>
                <TwitterIcon />
              </IconButton>
              </Link>
              
              <Link to={"https://www.instagram.com/bringmark_/"}>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              </Link>
             <Link to={"https://www.linkedin.com/company/bringmark"}>
             <IconButton>
                <LinkedInIcon />
              </IconButton>
             </Link>
              <Link to={"https://wa.me/message/Q2FPHIXUDAZFB1"}>
              <IconButton>
                < WhatsAppIcon/>
              </IconButton>
              </Link>
              <Link to={"https://www.threads.net/@bringmark_"}>
              <IconButton>
                < FingerprintIcon/>
              </IconButton>
              </Link>
              <Link to={"https://www.pinterest.com/14rf7749xiibn8jqbf0p5jop8z4rup/  "}>
              <IconButton>
                < PinterestIcon/>
              </IconButton>
              </Link>
             
              <Link to={"https://www.youtube.com/results?search_query=bringmark"}>
              <IconButton>
                < YouTubeIcon/>
              </IconButton>
              </Link>
             
            </div>
          </Grid>
        </Grid>
      
      </Container>

 

     <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
     <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
     <Grid style={{width:"100%",justifyContent:"center",alignContent:"center",display:"flex"}}>
          <NavLink to='/' onClick={scrollToTop} >
            <img src={require("../assets/real.png")} height={"70px"} width={"70px"} />
            </NavLink>
          </Grid>
    {/* <li style={{ fontSize: "35px",color:"rgb(254,196,0)",textAlign:"center",fontFamily:"fantasy",fontWeight:"bold",letterSpacing:"3px" }}>Bringmark</li> */}
    <li style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
    Utilize Bringmark's innovative platform to seamlessly connect with your target audience, expand your brand's reach, and supercharge your startup's growth</li>

  </ul>

  <div style={{display:"flex",alignContent:"center",justifyContent:"center",alignItems:"center",alignSelf:"center",margin:"20px"}}>
 
 
  <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px'
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleChange}
          error={emailError}
          helperText={emailError ? "Email is required" : ""}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
              '& input': {
                color: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white',
            },
            color: 'wheat',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <ArrowForwardIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#282828',
                      borderRadius: '20px',
                      fontSize: '38px',
                      padding: '10px'
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
        />
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Thank you for contacting us. Our employee will call you."
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />

  </div>

  <div style={{justifyContent:"center",display:"flex",flexWrap:"wrap" }}>
    <img src={require('../../src/assets/iso.png')} width={'90px'} height={'100px'} />
    <img src={require('../../src/assets/truste.png')} width={'120px'} height={'50px'} style={{marginLeft:"10px"}}/>
    <img src={require('../../src/assets/great.png')} width={'70px'} height={'100px'} style={{marginLeft:"10px"}}/>
  </div>

     </div>
  <Divider style={{backgroundColor:"white", marginTop:"15px"}}/>

      <div className='table-row'>

<div style={{justifyContent:"center"}}>
<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
    <li style={{ fontSize: "28px",marginTop:"10px",color:"rgb(254,196,0)",fontFamily:"serif" }}>Services</li>
    <li style={{marginTop:"10px" }}>Web Development</li>
    <li style={{marginTop:"10px" }}>Mobile App Development</li>
    <li style={{marginTop:"10px" }}>AR/VR</li>
    <li style={{marginTop:"10px" }}>Data Analytics</li>
  </ul>

</div>

<div style={{justifyContent:"center"}}>
<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
    <li style={{ fontSize: "28px",marginTop:"10px",color:"rgb(254,196,0)" ,fontFamily:"serif" }}>Others</li>
    <li style={{marginTop:"10px" }}>Demands Planning</li>
    <li style={{marginTop:"10px" }}>Supply chain</li>
    <li style={{marginTop:"10px" }}>Merchandising</li>
    <li style={{marginTop:"10px" }}>Pricing and Promotion</li>

  </ul>
  
</div>

<div style={{justifyContent:"center"}}>
  
<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
    <li style={{ fontSize: "28px",marginTop:"10px",color:"rgb(254,196,0)" ,fontFamily:"serif"}}>Connect</li>
    <li style={{marginTop:"10px",flexDirection:"row",display:"flex" }}><AddIcCallIcon style={{marginRight:"10px"}}/>+919654570253</li>
    <li style={{marginTop:"10px" ,flexDirection:"row",display:"flex"}}><BusinessIcon style={{marginRight:"10px"}}/>Qutub Vihar I, Najafgarh, New Delhi, Delhi, 110071 </li>
    <li style={{marginTop:"10px" ,flexDirection:"row",display:"flex"}}><MarkEmailReadIcon style={{marginRight:"10px"}}/>info@bringmark.com</li>
  </ul>
</div>


</div>
<Divider style={{backgroundColor:"white",marginTop:"15px"}}/>

<Typography variant="body2" style={useStyles.footerText}>
          © {new Date().getFullYear()} Bringmark. All rights reserved.
        </Typography>

    </div>
  );
};

export default Footer;
