import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Audittech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Define Business Rules`
const featuresDescription = `Tailor rules and conditions that can adapt to your evolving business requirements in real-time, enabling a 100% RPA-based audit and automated risk analysis..`
const feauturesimage =require('../../assets/solution/demandplanningout.png')

const featuresTitle2 = `Engage with Interactive Dashboards`
const featuresDescription2 = `AuditTech simplifies KPI analysis by offering an easy-to-read dashboard that enables users to delve into specific areas for more detailed information.`
const feauturesimage2 =require('../../assets/solution/demandplanningout.png')

const featuresTitle3 = `Monitor Metrics and Generate Reports`
const featuresDescription3 = `Provide comprehensive RPA-based audits by collecting data from various sources to generate daily, weekly, and monthly automated performance reports..`
const feauturesimage3 =require('../../assets/solution/demandplanningout.png')

const featuresTitle4 = `Enhanced Audit Insights`
const featuresDescription4 = `Gain enhanced audit insights by harnessing the power of our RPA-based solutions, which extract data from multiple sources and deliver detailed performance reports on a daily, weekly, and monthly basis.`
const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Acquire Knowledge`
const weeksDescription = `Collect your existing audits and gain insights into your audit procedures, including the key performance indicators (KPIs) that are relevant to your business. Understand the layout and functionality of your current dashboards.`
const weeksimage =require('../../assets/solution/demandplanningout.png')

const weeksTitle2 = `Develop`
const weeksDescription2 = `Create comprehensive audit pathways based on robotic process automation (RPA) and analyze your overall accounts, including fraud detection and financial risks, if present..`
const weeksimage2 =require('../../assets/solution/demandplanningout.png')

const weeksTitle3 = `Supervise`
const weeksDescription3 = `Verify that the system is promptly detecting anomalies as intended, providing real-time alerts within seconds, and diminishing financial risks and compliance costs.`
const weeksimage3 =require('../../assets/solution/demandplanningout.png')

const weeksTitle4 = `Implement`
const weeksDescription4 = `Deployment usually runs parallel to your current systems, overlapping by 1-2 months. This approach ensures that we can demonstrate our ability to identify significantly more issues than your previous methods, while also streamlining and reducing the workload of your processes.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `Define Business Rules`
const message1 = `Tailor rules and conditions that can adapt to your evolving business needs in real-time, enabling a 100% RPA-based audit alongside automated risk analysis.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697379917/Solutions/Audittech/fwrsmsuo8ebqndrwzxre.png"

const title2 = `Engage with Interactive Dashboards`
const message2 = `AuditTech simplifies the analysis of your Key Performance Indicators (KPIs) by offering an easily comprehensible dashboard. This dashboard allows users to delve into specific areas to access more detailed information.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697380588/Solutions/Audittech/f3hqorsyvim4hwwwbk6q.png"

const title3 = `Monitor Metrics and Generate Reports`
const message3 = `Deliver comprehensive RPA based audits by capturing data from multiple sources to drive daily, weekly, and monthly automated performance reports.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697379918/Solutions/Audittech/uy4pol3s65a3xeavol3a.png"
// the end of cards



const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/nrdz5v0vhnok0qpnep2i")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};


const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `range of industries`
const message = `
AuditTech allows you to address routine audits with an all-in-one RPA software that accommodates various business structures and audit types, from risk reduction to industry-specific rule-based demands.`;

function Audittech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid  style={containerStyle}>
        {/* Left Column */}
        <Grid  xs={12} sm={6} style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: '#15DFED' }}>AuditTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "white" }}>Attain complete audit coverage through Robotic Process Automation (RPA).</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px"}}>

          Empower your audit team with industry-leading robotic process automation tools, enabling them to create, manage, and execute rules for near real-time audits          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>AudittTech</Typography>
      </div>

     
      </Grid>
      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>AuditTech Advantage</h2>
 
      </Grid>
<Grid style={{width:"100%",display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"50px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      Harness the power of advanced Robotic Process Automation to save both time and money for your business. By eliminating human errors, enhancing fraud detection, and achieving 100% audit coverage, you can optimize your audit processes.      </p>

          <p style={{marginTop:"15px",color:"white"}}>
          Enhance your operational efficiency with our state-of-the-art Robotic Process Automation solutions, reducing errors and enabling comprehensive audit coverage
</p>
</Grid>
     

      
  

<Grid  container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378089/Solutions/Audittech/v4whpvnqlvevsm73gl4j.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Logistics</h3>

              <Typography>AuditTech's rule-based bot delivers intelligent automation capabilities for capturing multiple entities, handling complex data, managing real-time complexities, implementing multiple congruent rules, and much more.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Fraud Detection</h3>
              <Typography>Our comprehensive suite of RPA-based audit tools includes automated data collection powered by machine learning algorithms, robust analysis and reporting capabilities, anomaly detection, and real-time alerts that trigger when potential issues or red flags are identified.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center",marginTop:"30px"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378089/Solutions/Audittech/zkkxess31nfpr2clgifj.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Seamless Integration</h3>
              <Typography>Our highly customized and intuitive user interface (UI) is designed to meet your specific business requirements. It's fast, easy to implement, and seamlessly integrates with your existing systems for a smooth transition.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Sophisticated Reporting</h3>
              <Typography>AuditTech streamlines your audit processes through our user-friendly interface and Robotic Process Automation (RPA). Our RPA audits provide all the advanced outputs required to achieve 100% coverage, eliminating the need for manual error-trapping calculations in Excel or Access. This ensures pinpoint accuracy in your audit procedures.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>AuditTech Features</h1>
        <Typography>Utilize our groundbreaking SaaS audit solution to automate any laborious audit process, leveraging streamlined data collection, rules-based audit data analysis, exception-based dashboards, and our advanced Robotic Process Automation (RPA) capabilities.

</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378200/Solutions/Audittech/gthckmbwcfmtpabb5xg1.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378200/Solutions/Audittech/tzrhrtvhwx2lu8rusggf.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378199/Solutions/Audittech/dntajfcisuyijkodggfb.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378199/Solutions/Audittech/aphxnemverf1pk2qjgoq.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>AuditTech Features</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378668/Solutions/Audittech/lhi5wbqcuwjs4dgj6p7l.png" width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378667/Solutions/Audittech/h4vjwtineskfcffy2jaj.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378667/Solutions/Audittech/brarssko1oi0atgpdlxv.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697378667/Solutions/Audittech/encld2emavzqlbmkaqxq.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
 AI-powered
predictive analytics
benefitting retailers in</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>5M+</h2>
                </div>
                <p style={{color:"white"}}>Savings annually</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>95%</h2>
                </div>
                <p style={{color:"white"}}>Reduction in auditing time.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>100%</h2>
                </div>
                <p style={{color:"white"}}>Compliance to audits.</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>60%</h2>
                </div>
                <p style={{color:"white"}}>Improved efficiency.</p>

        </div>
    </Box>



<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Witness AuditTech in operation.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              AuditTech clients have experienced a 100% audit success rate and substantial cost savings. With features that enhance governance and streamline processes, this RPA-based solution reduces the reliance on employees for audits, allowing them to divert their attention towards other strategic initiatives.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697379644/Solutions/Audittech/xday1d8f9oqf73iwsyhw.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
      </Grid>
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>


<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697380741/Solutions/Audittech/krho69im8cr0vcprquac.png" alt="" width={"600px"} height={"300px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Implement RPA-based audits throughout your business.</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              AuditTech is Bringmark RPA-based audit solution that streamlines routine audits with minimal human involvement. It analyzes and validates all transactions, offering clear reports on findings. By leveraging AuditTech, you can achieve substantial cost savings compared to manual audits, achieve a 100% audit coverage, and enhance accuracy through our rules-based approach. The Machine Learning algorithms underpinning AuditTech provide heightened precision and reliability compared to conventional methods.
                                 </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 

    

    </div>
  );
}

export default Audittech;
