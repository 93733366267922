import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Pricetech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Flexible Forecasting`
const featuresDescription = `Adaptable forecasting takes historical data and current shifts in consumer behavior into account to make more precise forecasts about future sales at every store location.`
const feauturesimage = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/fdytttfcfhatx5xkh73d"

const featuresTitle2 = `Examine and Command`
const featuresDescription2 = `Capability to establish experiments at both store and customer levels using various essential performance indicators and metrics that deliver practical insights through retail price optimization software.`
const feauturesimage2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/qt125s9maeqixftdq21v"

const featuresTitle3 = `Customization`
const featuresDescription3 = `Store-specific pricing strategies can be fine-tuned by store, store group, channel, region, and customer segments. Group your stores based on similarities for tailored retail price optimization programs.`
const feauturesimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/cvxrvnsrathvj2rm1bg3"

const featuresTitle4 = `Personalized Discounts`
const featuresDescription4 = `Enhance through markdown optimization software employing advanced tech to predict demand and formulate unique markdown schemes for inventory clearance.`
const feauturesimage4 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/zxqysqfczj3vsldrsrql"

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Evaluate price sensitivities for each item, at each phase of its existence, customized by area, platform, client, and more`
const weeksimage ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/t7qi7kcabmcknixwnfts"

const weeksTitle2 = `Build`
const weeksDescription2 = `Confirm ML models are progressing. The platform should be providing practical insights and presenting adaptable pricing approaches`
const weeksimage2 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/jf4o0jz8ybirajuckgt8"

const weeksTitle3 = `Monitor`
const weeksDescription3 = `Confirm ML models are progressing. The platform should be providing practical insights and presenting adaptable pricing approaches`
const weeksimage3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/pewvq24weaa47ordj8j4"

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Gradual introduction across regions, categories, and corporate units. Ongoing assistance to guarantee the solution is enhancing value for your enterprise`
const weeksimage4 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/edpgrwwzy46x2b1ztxji"

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Strategize pricing and promotions throughout every product's lifecycle to boost sales, profits, and enhance customer interaction.`
const image1 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/fqxqwqj7joyc7xbkho5o"

const title2 = `Hospitality`
const message2 = `Dynamic pricing structure supported by advanced demand predictions and clever insights.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/srooofse3twyi5pafkln"
const title3 = `HardGoods`
const message3 = `Guarantee location-specific pricing for lucrative, customer-oriented pricing and promotions.`
const image3 ="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/xqkomznkf4e9cjyvnusl"
// the end of cards






const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Industries that have profited from PriceTech`
const message = `Your data, coupled with our advanced AI-driven retail pricing model, results in a unified pricing and promotions planning solution tailored to your field.`
const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/rlfssyt24hq0jmeh4fzi")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};
function Pricetech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  xs={12} sm={6}>
          <h1 style={{ fontWeight: "bold", color: '#15DFED' }}>PriceTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "white" }}>Automate end-to-end pricing with merchandising price strategy software.</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px"}}>
          Harness Artificial Intelligence and Machine Learning-fueled knowledge to provide precise pricing approaches. Eradicate human mistakes with our top-tier retail price strategy software.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify' }}>
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>PriceTech</Typography>
      </div>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>PriceTech Advantage</h2>
 
      </Grid>
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      PriceTech utilizes AI to guide choices regarding initial, promotional, discount, and clearance pricing in order to reach your corporate goals. Enhance pricing, promotions, and end-of-life markdowns with pricing optimization software."
      </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/xopaxqwujlf3xemfroxr"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Enhanced Retail Pricing</h3>

              <Typography>Guarantee real-time enhanced prices for every item, at every stage of its existence, personalized by area, platform, client, and more through Pricing and sales campaign planning software.
                  </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Effortless Integration</h3>
              <Typography>Streamlined workflow integration with your data repository, promotional schedules, and other tools, facilitates real-time early-season, in-season, outlet, price reduction, and clearance pricing suggestions.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/vm2sxawox7k5ivvut2h5"} height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Clearance Approach</h3>
              <Typography>Our automated price reduction optimization software removes merchant and planner sentiments from the markdown formula. We employ seasonal timing and sales trends, price sensitivity, price tiers/markdown thresholds, and markdown frequency to establish an optimized markdown strategy. We then provide you with the capacity to adapt that output to fit your markdown budget using scenario planning.
              </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Outlet Pricing and Secondary Avenues</h3>
              <Typography>For enterprises with outlets and additional sales platforms, our retail pricing improvement software identifies the suitable timing for retiring products and transferring them to secondary channels. Machine learning and artificial intelligence then function to optimize initial pricing, discounts, and markdowns during the life cycle of the product.
                </Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>PriceTech Features</h1>
        <Typography>Discover how our AI-driven pricing software for retail considers all factors, enhances your store's sales with practical insights, and reveals adaptable pricing tactics.
</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold"}}>Implementation of PriceTech is accomplished in under <span style={{color:"#15DFED"}}>8 weeks.</span>
           </h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>- AI-driven forecast tech benefitting retailers with
              </h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>10-15%</h2>
                </div>
                <p style={{color:"white"}}>Boost in EBITDA.</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >75%</h2>
                </div>
                <p style={{color:"white"}}>Cut in promotion planning time</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>8-12%</h2>
                </div>
                <p style={{color:"white"}}>Decrease in wastage (due to price-basedoptimal early-season planning)
                 </p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>100%</h2>
                </div>
                <p style={{color:"white"}}>Conformity to pricing compliance policies (via rules and automated integration)</p>

        </div>
    </Box>

 
       <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore PriceTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover how our clients are determining the ideal rhythm to attain the maximum Gross Margin while adhering to clearance and exceeding expected Return on Investment, through lucrative, customer-focused pricing and promotions.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/ywamp7rbmwsksm5tt3mr"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
      </Grid>
     
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>

   

<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Pricetech/gdnxxxld6s96cu0gru6c"} alt="" width={"500px"} height={"400px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Obtain comprehensive insights into the most recent industry trends, technology, and how your colleagues are addressing the most significant challenges related to promotions and markdowns using Bringmark cutting-edge, next-generation pricing and promotions planning software solution.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 
    
    

    </div>
  );
}

export default Pricetech;
