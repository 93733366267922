import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Tradetech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Campaign Surveillance`
const featuresDescription = `Generate comprehensive demand plan reports and forecast key performance indicators, including actuals for each product line. Access a highly interactive analysis of trade promotion management data.`
const feauturesimage =require('../../assets/solution/demandplanningout.png')

const featuresTitle2 = `Anticipate Promotional Results`
const featuresDescription2 = `Utilize robust trade promotion optimization algorithms that recommend effective trade promotion strategies to enhance ROI across the product lifecycle.`
const feauturesimage2 =require('../../assets/solution/demandplanningout.png')

const featuresTitle3 = `Master Data Standardization`
const featuresDescription3 = `Tailor your master data and standardize it across multiple trade systems. Set up new promotions and products with ease.`
const feauturesimage3 =require('../../assets/solution/demandplanningout.png')

const featuresTitle4 = `Comparison of Multiple Strategies`
const featuresDescription4 = `Assess various scenarios and anticipate the return on investment at the tactic level through our user-friendly and intuitive user interface.`
const feauturesimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Ingest existing promotions, promotion plans, past partner-level promotional performance, and the complete trade economics landscape. Gain insights into your approach to promotion management, encompassing the fundamental promotion philosophy and retailer strategy.`
const weeksimage =require('../../assets/solution/demandplanningout.png')

const weeksTitle2 = `Build`
const weeksDescription2 = `Implement machine learning models to discern the areas where your trade expenditure yields positive results and where resources are squandered. Superimpose AI modeling to craft optimized plans at the partner level, inclusive of demand plan reports and forecasted KPIs broken down by product line.`
const weeksimage2 =require('../../assets/solution/demandplanningout.png')

const weeksTitle3 = `Monitor`
const weeksDescription3 = `Assess the overall performance and the time savings achieved (typically resulting in a 70% reduction in time allocated to promotion planning).`
const weeksimage3 =require('../../assets/solution/demandplanningout.png')

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Introduce the platform progressively through strategic account groups over several months, closely monitoring the transition to ensure a seamless process. Facilitate easy setup for the instant introduction of new promotions and products in real-time with zero error risk.`
const weeksimage4 =require('../../assets/solution/demandplanningout.png')

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Achieve seamless, AI-driven merchandising that enhances decision-making across the entire spectrum, from planning and allocation to promotions, and more.`
const image1 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697393178/Solutions/tradetech/uv01lszxqiabtyj5q0jl.png"

const title2 = `CPG`
const message2 = `Enhance the effectiveness of your trade spending by optimizing product placements. Move away from zero-sum negotiations in promotion deployment to win-win solutions that boost sales for both you and your channel partners.`
const image2 ="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697393178/Solutions/tradetech/xpedl5np2zfbem6a2b9t.png"

// const title3 = `this is a last cards`
// const message3 = `this is my last cards of below the page you can check now.`
// const image3 =require('../../assets/solution/supplychainout.png')
// the end of cards



const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/z6uxlnildtaharvsltni")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};


const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Fields that can gain from TradeTech`
const message = `Harness our forward-thinking trade promotion optimization solution to significantly enhance your promotional results.`;


function Tradetech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: '#15DFED' }}>TradeTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "white" }}>The essential trade promotion management platform for every business</h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px"}}>
          Our advanced trade promotion management platform empowers you to enhance the returns on your promotional investments. TradeTech provides real-time insights into your promotional expenses, optimizes the performance of your trade promotions, and enhances your budgeting and forecasting visibility.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'start',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>TestTech</Typography>
      </div>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>TestTech Advantage</h2>
 
      </Grid>
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      A cutting-edge trade promotion management solution for enhancing the efficiency of your promotional investments. Through our TradeTech platform, you can monitor and enhance the performance of each dollar you allocate.          
      </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697391040/Solutions/tradetech/e9ba2w6zdeabfqnivrak.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3>Wiser Trade Spending</h3>

              <Typography>Enhance the efficiency of trade investments within minutes, increasing your ROI by more than 10% without raising your spending to drive additional margin growth.
                  </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Harmonize Cross-Enterprise Promotions</h3>
              <Typography>Our analytics dashboard presents a comprehensive performance overview spanning various campaigns by brand and category, facilitating data-driven decision-making with actionable insights.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697391040/Solutions/tradetech/mzdpozqxjilmgtevfdbl.png" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Comprehensive Automated Intelligence</h3>
              <Typography>Achieve a reduction of over 70% in the time required for promotion planning, encompassing performance reporting, forecasting, and trade promotion optimization.
              </Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Streamlined Retail-Partner Negotiation</h3>
              <Typography>Evaluate past promotional performance at the partner level and craft data-driven scenarios for trade promotion planning, fostering increased partner engagement.
               </Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>TradeTech Features</h1>
        <Typography>TradeTech is a user-friendly trade promotion optimization solution designed for retailers, manufacturers, and brands to assess various promotional strategies and formulate promotional plans at the brand and category levels.
          </Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392381/Solutions/tradetech/rku8nal45jmukgbrjiib.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392381/Solutions/tradetech/uxcbhk2155tbblsbxzox.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/tradetech/gxqslczqzpymlwheqg9e" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392381/Solutions/tradetech/txs3jzjhtr8m4fseq0r1.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold"}}>Implementation of TradeTech takes less than <span style={{color: '#15DFED'}}>8 weeks.</span></h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392635/Solutions/tradetech/d2hv9mchqs6oojmpwmqi.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392634/Solutions/tradetech/p2jfiwwzn3j7euosgivv.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392635/Solutions/tradetech/mnzychk5uiznibtofyfk.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392634/Solutions/tradetech/rl87bsjknyzvrchvs2uf.png" width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>Bringmark Tech</span>-
              AI-Powered Intelligent Predictive Analytics</h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>5%</h2>
                </div>
                <p style={{color:"white"}}> Boost in promotional revenue</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >10%</h2>
                </div>
                <p style={{color:"white"}}>Reduction in promotional spending</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>20%</h2>
                </div>
                <p style={{color:"white"}}>Enhancement in ROI</p>

        </div>
        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>60</h2>
                </div>
                <p style={{color:"white"}}> Seconds to generate an optimized trade plan</p>

        </div>
    </Box>


        <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>See TradeTech in Achtion</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              TradeTech users experience a 10-15% enhancement in their ROI on promotional investments, thanks to this comprehensive trade solution that covers planning, forecasting, monitoring, and reporting.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/v1697392837/Solutions/tradetech/f0znnwitq5quslrsyzkk.png"} height={"400px"} width={"500px"} alt="Laptop" />
        </Grid>
      </Grid>  
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"120px"} height={"100px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
        {/*<StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
       <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} > 
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>*/}

      </div>
     
    </Box>


    <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src={"https://res.cloudinary.com/dn3pfxep6/image/upload/v1697393180/Solutions/tradetech/avu9ff560x3unslrseoe.png"} height={"400px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Access concrete data about the most recent industry trends, technological advancements, and how your colleagues are addressing their most significant trade promotion planning challenges through Bringmark cutting-edge trade promotion optimization solution.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid>  


    

    </div>
  );
}

export default Tradetech;
