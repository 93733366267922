import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const BlockChainDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire BlockChain Developers</Typography>

<Typography >Hire Consecrated Blockchain Developers from Bringmark</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/wdnpx1e2zxk4hiobaq6p" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography>  A blockchain functions as a data framework designed to establish a digital ledger for transactions. Instead of being centralized under one entity, this ledger is distributed across a diverse network of computers. It consists of growing and interlinked data blocks, fortified by cryptography. Within a blockchain, each block is tied to the preceding one through a hash pointer. This technology stands as a pioneering and exceptionally secure method for storing and transmitting sensitive data. Blockchain technology finds applications in various industries, including finance, supply chain, healthcare, real estate, and more. It provides a secure and transparent way to store and exchange data, reducing the need for intermediaries and enhancing efficiency and trust in transactions. </Typography>

    <Typography style={{marginTop:'40px'}}> A significant advantage lies in the fact that the database contained within the blockchain network benefits from the safeguarding of an extensive community. As a result, your data finds secure storage across multiple sites, leading to a reduction in the overall expenses related to data retention. This proves particularly valuable for enterprises harboring substantial volumes of crucial data, prompting them to enlist the expertise of blockchain developers to innovate fresh and more efficient business workflows. Through this approach, they guarantee the meticulous execution and documentation of all transactions while keeping costs minimal.
</Typography>

<Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Services We Offer By BlockChain Developers:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Blockchain Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Creating custom blockchain solutions tailored to the client's requirements. This includes designing the blockchain architecture, developing smart contracts, and implementing the necessary consensus mechanisms.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Smart Contract Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Designing and coding smart contracts for specific use cases, such as supply chain management, decentralized finance (DeFi), non-fungible tokens (NFTs), or any other application requiring automated, self-executing agreements. </Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>3. Consultation and Strategy:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Providing expertise and guidance on blockchain adoption, including assessing the feasibility of blockchain for a particular use case, defining a blockchain strategy, and helping clients choose the right blockchain platform.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>4. Wallet and App Development: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Creating secure cryptocurrency wallets and user-friendly blockchain applications for desktop and mobile platforms.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Blockchain Integration: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Integrating blockchain technology into existing systems and applications to leverage its benefits, such as enhanced security, transparency, and traceability.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>6. Security Audits:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Conducting security audits and code reviews to identify vulnerabilities in smart contracts and blockchain applications. This helps ensure the integrity and safety of the blockchain ecosystem.</Typography>

 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Blockchain Analytics:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Implementing blockchain analytics tools to help clients gain insights into transactions, user behavior, and network performance.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Legal and Compliance Guidance: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Assisting clients in navigating the legal and regulatory aspects of blockchain technology, especially in industries with strict compliance requirements.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Tokenization Services: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Tokenizing real-world assets, such as real estate, art, or commodities, to make them tradable on blockchain platforms.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Maintenance and Support: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Offering ongoing maintenance and support services to ensure the smooth operation of blockchain networks and applications, including updates and bug fixes.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default BlockChainDevelopers