import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useWindowSize } from "../../commonFunctions";
import ContactForm from "../ContactForm";

const BackEndDevelopers = () => {
  const width = useWindowSize();
  return (
    <Grid
      container
      style={{ width: "80%", margin: "auto", paddingBottom: "200px",marginTop:"90px" }}
    >
      <Grid
        item
        xsm={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: "600", marginBottom: "20px" }}
        >
          Hire Backend Developers 
        </Typography>

        <Typography>
          {" "}
          Enhance operational seamlessness through effective backend
          development.{" "}
        </Typography>
      </Grid>

      <Grid
        item
        xsm={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: "80%" }}
          src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/kdjn59nimgdgh8kfmgua"
          alt=""
        />
      </Grid>

      <Grid item style={{ marginTop: "100px" }}>
        <Typography>
          {" "}
          Backend development revolves around the web development of websites
          and web applications, ensuring the seamless functionality of these
          digital platforms. It is responsible for verifying the proper
          operation of website features and functionalities. This vital work
          takes place behind the curtain, powering all the functions of the
          website. However, accomplishing this task is no easy feat; it
          necessitates the engagement of adept backend developers. These
          developers primarily focus on aspects like application programming
          interfaces, server management, and database handling. Proficiency in
          various programming languages is a prerequisite for backend
          developers. They are tasked with managing both mobile applications and
          websites. The toolkit of backend developers encompasses languages like
          Structured Query Language, PHP, Java, Python, ROR, .NET, Clojure, and
          Haskell. Without a solid grasp of these languages, effective
          development is unattainable.{" "}
        </Typography>

        <Typography style={{ marginTop: "40px" }}>
          {" "}
          Backend developers tackle intricate challenges arising during mobile
          application or website development. They possess the capacity to
          effect changes from the backend, preventing errors from being exposed
          externally. Their purview encompasses meticulous management of both
          minor and major development intricacies, resulting in a bug-free
          outcome. The onus of delivering seamless user experiences for apps and
          websites falls on the shoulders of backend developers, alongside the
          crucial task of optimizing page loading speed. Continual code updates
          to enhance websites or mobile apps also fall under the purview of
          backend developers. They wield the power to modify existing elements
          or forge new ones to drive development. Depending on your business
          needs, you have the flexibility to hire full-time in-house, part-time,
          or freelance backend developers.
        </Typography>
        <Grid className="sticky"> 

        <Grid item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}>
          <Typography variant="h4" marginTop={10} fontSize={27}>
            Service We Offer By BackEnd Developers:
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            1.Database Programming:{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            {" "}
            Our team of database developers has extensive experience with a
            variety of database management systems, including Microsoft SQL
            Server, MongoDB, DB2, PostgreSQL, MariaDB, SAP HANA, MySQL, and
            more.{" "}
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            2. Secvice Scripting:{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            {" "}
            Our backend developers offer a seamless scripting service,
            recognizing that the right script is paramount for ensuring
            successful outcomes. We are committed to preventing any mishaps by
            ensuring the accuracy of scripts. The work of our project's backend
            developers is instrumental in meeting business requirements with
            improved server speed.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            3. API Programming and Integration:
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            {" "}
            By enlisting the expertise of our backend developers, you gain the
            advantage of effortless integration with third-party applications.
            We specialize in seamlessly integrating new applications with your
            existing backend, eliminating any potential complications.{" "}
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            4. Application Re-Engineering{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            Our proficient backend developers excel in achieving supreme
            customization through efficient utilization of existing application
            methods. We employ cutting-edge re-engineering techniques to enhance
            aspects, screens, and processes while maintaining seamless
            functionality.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            5. Refactoring Backend:{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            {" "}
            Our backend developers specialize in refactoring backend systems,
            allowing them to rejuvenate web application backends without
            compromising frontend elements. This approach is invaluable for
            rectifying long-standing errors or implementing changes without an
            overhaul of the backend code. Notably, it also contributes to
            performance enhancement by targeting only the necessary backend
            modifications.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            6. Cloud Migration:{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            Our backend developers offer comprehensive cloud migration services,
            facilitating the seamless transition of backend systems to the
            cloud. Our expertise spans private, public, and hybrid cloud
            migration, with a clear understanding that the success of such
            migration hinges on well-structured backend systems.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            7. proficient user experience{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            Our adept backend developer offers a skillful user experience. Our
            web development services are of top-notch quality, guaranteeing a
            seamless encounter. The backend developers execute each project with
            effectiveness.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            8.Exceptional Functionality{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            Backend developers play a pivotal role in delivering crucial
            functionality during the development process. This functionality is
            essential for the operation of websites or apps. Our backend
            developers take meticulous care to ensure the seamless operation of
            these functions, addressing any potential technical issues that may
            arise. The success of the project's functionality rests in the hands
            of our developers, and our backend team takes every measure to
            ensure each functionality is outstandingly executed.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            9. Dependably Security
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            With a focus on security and reliability, our backend developers
            construct your website or app using trustworthy tools and
            technology. We prioritize safeguarding your platform to prevent any
            form of misuse or incidents. The integrity of the internal code
            plays a pivotal role in app and web development, and at BringMark,
            we diligently uphold its invulnerability. Our commitment extends to
            meticulous attention to detail and every aspect of updates.
          </Typography>

          <Typography variant="h4" marginTop={5} fontSize={22}>
            10. Seamless Icorporation{" "}
          </Typography>
          <Typography style={{ color: "#E1D9D1", marginTop: "15px" }}>
            {" "}
            Our backend developers shoulder the responsibility for optimizing
            various website components, spanning from individual features to
            third-party integrations. We construct highly functional websites
            and web applications that seamlessly incorporate these elements.
            These integrations serve to enhance the performance of web and
            mobile applications progressively.
          </Typography>
        </Grid>
        <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
      </Grid>
      </Grid>

      <Grid container style={{ marginTop: "200px", justifyContent: "center" }}>
        <Typography variant={width < 700 ? "h6" : "h2"}>
          Leverage World-Class Talent
        </Typography>
        <Typography>
          We have a team of experts who have a pool of expertise in their
          respective fields. Their approach is out-of-box, dynamic, and unique
          in the market.
        </Typography>
      </Grid>

      <Grid  spacing={4} marginTop={5} style={{ width: "100%",flexDirection:"row",display:"flex",flexWrap:"wrap" }}>
        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xlg={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent", padding: "10px" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Junior Developers
            </Typography>
            <Typography>
              Our Junior Developers with 1 to 2 years of experience understand
              the client's needs and ensure that the entire process matches
              requirements. They have insightful knowledge and try their best
              input to develop outstanding and unique development.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Senior Developers
            </Typography>
            <Typography>
              Senior Developers having 2 to 8 years of experience are highly
              skilled and proficient throughout the development process. They
              bring the best in the development and get successful bug-free
              solutions.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <Card style={{ backgroundColor: "transparent" }}>
            <img
              src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg"
              alt=""
              height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
            />
            <Typography
              fontSize={25}
              color={"#E1D9D1"}
              marginTop={2}
              marginBottom={1}
            >
              Web Developers
            </Typography>
            <Typography>
              Web Developers of Bringmark are outstanding and
              dedicated. We have a team of skilled web developers with several
              years of experience in the market. The Web Developers have a
              futuristic vision of web development.
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            Project Managers
          </Typography>
          <Typography>
            Our project managers are well aware of how to handle and execute
            projects. Bringmark has expertise in IT development, and
            our project managers keep an eye on every minor detail in the
            development process with client satisfaction.
          </Typography>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            UI/UX Designers
          </Typography>
          <Typography>
            Our web developers know the importance of a website for businesses
            in this competitive era. They have expertise in all the latest web
            technologies and deliver exceptional web design and development
            services as per client requirements.
          </Typography>
        </Grid>

        <Grid
          item
          xsm={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{ boxShadow: " 0 8px 8px -4px lightblue" }}
        >
          <img
            src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg"
            alt=""
            height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}
          />
          <Typography
            fontSize={25}
            color={"#E1D9D1"}
            marginTop={2}
            marginBottom={1}
          >
            Testers
          </Typography>
          <Typography>
            Quality Assurance is one of the top aspects of any successful
            solution. We believe in delivering solutions with the best quality
            in the market, and our QA team checks every project we work on and
            helps us deliver bug-free solutions to our clients.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BackEndDevelopers;
