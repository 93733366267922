import React from 'react';
import './Report.css';
import { Color } from 'three';

export default function Report() {
  return (
    <div className='report-main'>
      <div className='Report'>
        <h1>Reports</h1>
        <h3>Get the latest BringMark reports featured around the globe for transforming retail with next-gen AI technology</h3>
        <p>Explore our reports to gain deeper insights about our solutions and their outcome on the business.</p>
        <button>DISCOVER NOW</button>
      </div>

      <div className="cardcontent5">
        <div className="report">
          <img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/REPORT/tttnmmydgazbrc96ewqu'} width={350} height={230} alt="" />
          <div className='description2'>
            <h5>Gartner</h5>
            <p>Market Guide for E-Commerce Merchandising Solutions</p>
          </div>
        </div>

        <div className="report">
          <img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/REPORT/epwzifekztcrcsfwjlin'} width={350} height={230} alt="" />
          <div className='description2'>
            <h5>E-Book</h5>
            <p>Retail Demand Forecasting in 2023 and Beyond</p>
          </div>
        </div>

        <div className="report">
          <img src={'https://res.cloudinary.com/dpr1ii8jh/image/upload/f_auto,q_auto/v1/RESOURCES/REPORT/ynpgqhgf0mplxyyibwhi'} width={350} height={230} alt="" />
          <div className='description2'>
            <h5>BringMark Report</h5>
            <p>Prime Day 2: A Harbinger of the Upcoming Battle for Consumer Spend!</p>
          </div>
        </div>

        <div className="report">
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/v1697465805/Resources/Report/q3iza5vqshwenkxuvypi.png" width={350} height={230} alt="" />
          <div className='description2'>
            <h5>BringMark Report</h5>
            <p>The Amazon Canary in the Coal Mine</p>
          </div>
        </div>

        <div className="report">
          <img src={'https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/aenqx2scj81toyqpup7i'} width={350} height={230} alt="" />
          <div className='description2'>
            <h5>BringMark Report</h5>
            <p>How to move toward a more data-driven decision-making approach</p>
          </div>
        </div>

        <div className="report">
          <img src={'https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/company%20and%20resourve/iwb7mqwosvrlwn6dv5vt'} width={350} height={230} alt="" />
          <div className='description2'>
            <h5>BringMark</h5>
            <p>Automated Product Attribution</p>
          </div>
        </div>
      </div>
    </div>
  );
}
