import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Devops.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/imageslider/sojgythoui1exy8pamfg",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/imageslider/xrghdc9v1ne1qvzpyihv",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/imageslider/wt3qrz5kobnjq55lor2y",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/imageslider/ssql9cnxs5pazltwcouj",
"https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/imageslider/fmqxejxtdfxe3jfd1qsj",



 

];

function Devops() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false
    };
  


  
  return (
    <div>
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>AR/VR</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white",marginTop:"40px"}}>
    At Bringmark, we understand that efficient software delivery is pivotal in today's fast-paced business environment. Our DevOps expertise is designed to revolutionize the way you develop and deploy applications. By seamlessly integrating development and operations, we create a collaborative ecosystem where automation and continuous integration reign supreme. Through streamlined processes and agile methodologies, we reduce development cycles and enhance product quality, allowing your teams to focus on innovation rather than grappling with manual tasks. With Bringmark's DevOps mastery, you're equipped to respond to market demands swiftly and deliver solutions that meet the highest standards.
        </Typography>
        <div>
  <MovingCards
  
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/k9xjldvp2cbdt6gaouhc"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/y42ek7znusowcf1ej9nh"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/glhnsqkffttniffdsu8m"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/k2va1r0dzegkxz5xjo2z"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/h3ekh5h6usgme9oix5pd"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/fihuoov6mgdxcfx9mkqq"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/ccs5hmeh4jzegkpuj8gg"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/odt48fjcofdm5onozlcw"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/imnsyduszibupjr4klhh"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/xdaml55vnjnl2wf1cbm2"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/ktl5zpxl3mhrvr7hfy8g"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/marque/rxrmnwkbh30lgm1scmfg"

  />
</div>


        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Revolutionize Operations with DevOps by Bringmark: Unleash Efficiency

</Typography>
<Typography  style={{color:"white"}}>

Transform your operations with Bringmark's DevOps expertise, a game-changing approach that maximizes efficiency across your software development and IT teams. Our comprehensive DevOps solutions break down silos, fostering collaboration, automation, and continuous integration. By streamlining processes, we accelerate your time-to-market and enhance product quality, giving you a competitive edge in the digital landscape. Bringmark's DevOps services are your gateway to a future of agile, innovative, and streamlined operations </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/gif/iy2ye8cj5jywearliv1y" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
      
Efficiency takes center stage in the realm of DevOps, and at Bringmark, we orchestrate operations to maximize productivity. By automating repetitive tasks, provisioning infrastructure on-demand, and enabling continuous monitoring, we streamline processes and reduce manual intervention, resulting in faster time-to-market. Our holistic approach empowers development and IT teams to collaborate seamlessly, eliminating bottlenecks and driving cross-functional communication. Bringmark's DevOps prowess cultivates a culture of rapid iteration and innovation, propelling your business forward in an ever-evolving digital landscape.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/gif/q0noam6h7fowgb0m9s3t" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Accelerate Innovation with Bringmark's DevOps Excellence</Typography>
<Typography  style={{color:"white"}}>

Embrace innovation's fast lane with Bringmark's DevOps excellence. Our holistic approach seamlessly integrates development and operations, paving the way for rapid and efficient software delivery. By automating processes, embracing continuous monitoring, and encouraging collaboration, we enable your teams to focus on creativity and value creation. Join hands with Bringmark to accelerate your innovation journey and stay ahead in a dynamic and demanding marke</Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
DevOps isn't just a methodology; it's a transformative journey, and Bringmark guides you every step of the way. Our seasoned experts tailor DevOps practices to suit your organization's unique needs, ensuring a seamless adoption process. Through meticulous planning, comprehensive training, and hands-on support, we equip your teams with the skills to leverage the power of DevOps effectively. By aligning technology, processes, and people, Bringmark empowers you to deliver value to customers faster, respond to market changes promptly, and foster a culture of continuous improvement.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>


  Empower Your Business with Bringmark's DevOps Solutions

</Typography>
<Typography  style={{color:"white"}}>
Empower your business to thrive in the digital age with Bringmark's comprehensive DevOps solutions. Through the seamless fusion of development and operations, we enable a harmonious workflow that accelerates deployment cycles and enhances product quality. Our expert team ensures the smooth adoption of DevOps practices, transforming your IT landscape into a well-oiled engine of innovation and efficiency. With Bringmark's DevOps expertise, you're equipped to navigate the complexities of modern software development with confidence </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/gif/puajizhpb263n6mgqzjv" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Collaboration, innovation, and speed converge under the banner of Bringmark's DevOps mastery. We recognize that each business's journey is distinct, and our approach is flexible and adaptable. Whether you're transitioning to DevOps for the first time or seeking to enhance your existing practices, Bringmark's DevOps solutions cater to your aspirations. We empower you to transcend traditional silos, embrace automation, and transform your IT landscape into a well-coordinated engine of growth. With Bringmark as your DevOps partner, you're not just embracing a methodology – you're embarking on a transformative path to elevate your organization's capabilities and thrive in the digital era.      </Typography>

{/* <ProjectCard/> */}

<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Devops/gif/lfwtawcf0hbuuzkai7ak" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Unleash Efficiency and Collaboration with Bringmark's DevOps Mastery

</Typography>
<Typography  style={{color:"white"}}>

Unleash a new era of efficiency and collaboration with Bringmark's DevOps mastery. Our integrated approach brings development and operations together, nurturing a culture of continuous improvement. By automating processes, streamlining workflows, and facilitating cross-functional teamwork, we empower your business to achieve faster, higher-quality software delivery. Embrace the power of DevOps with Bringmark as your partner, and witness a transformation that redefines how you create and deliver value to your customers. </Typography>
</div>


</div>

      
    </div>
  )
}

export default  Devops