import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Navigate, useNavigate } from 'react-router-dom';
const ListComp = ({ anchor,toggleMenu }) => {
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [subDropdownOpen, setSubDropdownOpen] = useState({});

  const [ServicesOpen, setServicesOpen] = useState(false);
  const [subDropdownOpen1, setSubDropdownOpen1] = useState({});

  const [hireDevelopers, setHireDevelopers] = useState(false);
  const [subDropdownOpen2, setSubDropdownOpen2] = useState({});

  const [company, setCompany] = useState(false);
  const [subDropdownOpen3, setSubDropdownOpen3] = useState({});

  const [resources, setResources] = useState(false);
  const [subDropdownOpen4, setSubDropdownOpen4] = useState({});
  const navigate = useNavigate()

  const toggleSolutionsOpen = () => {
    setSolutionsOpen(!solutionsOpen);
  };

  const toggleSubDropdownOpen = (key) => {
    setSubDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleResources = () => {
    setResources(!resources);
  };


  const toggleServicesOpen = () => {
    setServicesOpen(!ServicesOpen);
  };



  const toggleHireDevelopers = () => {
    setHireDevelopers(!hireDevelopers);
  };


  const toggleCompany = () => {
    setCompany(!company);
  };


      // solutions path
      function forecast(){
        toggleMenu()
        navigate('/ForeCarstTech')
    scrollToTop()

    }

    function assortech(){
      toggleMenu()
        navigate('/Assortsolutionstech')
    scrollToTop()

    }

    function inventory(){
      toggleMenu()

        navigate('/Inventorytech')
    scrollToTop()

    }   
    function spaceTech(){
      toggleMenu()

        navigate('/Spacetech')
    scrollToTop()

    }

    function attribute(){
      toggleMenu()

        navigate('/Attributetech')
    scrollToTop()

    }
    function audit(){
      toggleMenu()

        navigate('/Audittech')
    scrollToTop()

    }
    function monday(){
      toggleMenu()

        navigate('/Mondaytech')
    scrollToTop()

    }
    function planet(){
      toggleMenu()

        navigate('/Planetech')
    scrollToTop()

    }

    function Pricetech(){
      toggleMenu()

        navigate('/Pricetech')
    scrollToTop()
    }
    function Racktech(){
      toggleMenu()

        navigate('/Racktech')
    scrollToTop()

    }
    function Testtech(){
      toggleMenu()

        navigate('/Testtech')
    scrollToTop()

    }
    function Tradetech(){
      toggleMenu()

        navigate('/Tradetech')
    scrollToTop()

    }

    function home(){
      toggleMenu()

        navigate('/')
    scrollToTop()

    }

    // end
    // services
    function webDev(){
      toggleMenu()

        navigate('/web-development')
    scrollToTop()

    }
    function arvr(){
      toggleMenu()

        navigate('/arVr')
    scrollToTop()

    }
    function mobileApp(){
      toggleMenu()

        navigate('/mobile-development')
    scrollToTop()

    }

    function iot(){
      toggleMenu()

        navigate('/iot')
    scrollToTop()

    }
    function dataAnalytics(){
      toggleMenu()
      scrollToTop()

        navigate('/data-analytics')
    }
    function cloudservices(){
      toggleMenu()
        navigate('/cloud-service')
    scrollToTop()

    }
    function cyber(){
      toggleMenu()
    scrollToTop()

        navigate('/cyber-security')
    }
    function aiml(){
        navigate('/aiml')
      toggleMenu()
    scrollToTop()


    }
    function devops(){
      toggleMenu()
      scrollToTop()

        navigate('/devops')
    }
    // sevives end
    // hire developers
    function angular(){
      toggleMenu()
        navigate('/AngularjsDevelopers')
    scrollToTop()

    }
    function AndroidDevelop(){
      toggleMenu()

        navigate('/AndroidDevelopers')
    scrollToTop()

    }
    function appDev(){
      toggleMenu()
        navigate('/AppDevelopers')
    scrollToTop()

    }
    function BackEndDevelop(){
      toggleMenu()
        navigate('/BackEndDevelopers')
    scrollToTop()

    }
    function BlockChainDeve(){
      toggleMenu()
        navigate('/BlockChainDevelopers')
    scrollToTop()

    }
    function react(){
      toggleMenu()
        navigate('/ReactjsDevelopers')
    scrollToTop()

    }
    function node(){
      toggleMenu()
        navigate('/NodejsDevelopers')
    scrollToTop()

    }
    function wordpress(){
      toggleMenu()
        navigate('/WordPressDevelopers')
    scrollToTop()

    }

    function vue(){
      toggleMenu()
        navigate('/VuejsDevelopers')
    scrollToTop()

    }
    function php(){
      toggleMenu()
        navigate('/PhpDevelopers')
    scrollToTop()

    }
    function webDevelopers(){
      toggleMenu()
        navigate('/WebDevelopers')
    scrollToTop()

    }
    function fullStack(){
      toggleMenu()
        navigate('/FullStackDevelopers')
    scrollToTop()

    }
    function frontend(){
      toggleMenu()
        navigate('/FrontEndDevelopers')
    scrollToTop()

    }
    function software(){
      toggleMenu()
        navigate('/SoftwareDevelopers')
    scrollToTop()

    }
    function uiux(){
      toggleMenu()
        navigate('/UIUXDesigner')
    scrollToTop()

    }
    function iotSolution(){
      toggleMenu()
    scrollToTop()

        navigate('/IOTSolutionArchitecture')
    }
    function ios(){
      toggleMenu()
        navigate('/IosDevelopers')
    scrollToTop()

    }
    function flutter(){
      toggleMenu()
        navigate('/FlutterDevelopers')
    scrollToTop()

    }
    function reactNative(){
      toggleMenu()
        navigate('/ReactNativeDevelopers')
    scrollToTop()

    }
    function swift(){
      toggleMenu()
        navigate('/SwiftDevelopers')
    scrollToTop()

    }
    function iconic(){
      toggleMenu()
        navigate('/IconicDevelopers')
    scrollToTop()

    }
    function kotlin(){
      toggleMenu()
        navigate('/KotlinDevelopers')
    scrollToTop()

    }
    function cakephp(){
      toggleMenu()
        navigate('/CakephpDevelopers')
    scrollToTop()

    }
    function codeignitor(){
      toggleMenu()
        navigate('/CodeIgniteDevelopers')
    scrollToTop()

    }
    function magento(){
      toggleMenu()
        navigate('/MagnetoDevelopers')
    scrollToTop()

    }
    function shopify(){
      toggleMenu()
        navigate('/ShopifyDevelopers')
    scrollToTop()

    }
    function drupal(){
      toggleMenu()
        navigate('/DrupalDevelopers')
    scrollToTop()

    }
    function woo(){
      toggleMenu()
        navigate('/WooCommerceDeveloper')
    scrollToTop()

    }
    function python(){
      toggleMenu()
        navigate('/PythonDevelopers')
    scrollToTop()

    }
    function golang(){
      toggleMenu()
        navigate('/GolangDevelopers')
    scrollToTop()

    }
    function solidity(){
      toggleMenu()
        navigate('/SolidityDevelopers')
    scrollToTop()

    }

    function dart(){
      toggleMenu()
        navigate('/DartDevelopers')
    scrollToTop()

    }

    function spring(){
      toggleMenu()
        navigate('/SpringBootDevelopers')
    scrollToTop()

    }
    function django(){
      toggleMenu()
        navigate('/DjangoDevelopers')
    scrollToTop()

    }
    function laravel(){
      toggleMenu()
        navigate('/LaravelDevelopers')
    scrollToTop()

    }
    function yii(){
      toggleMenu()
        navigate('/YIIDevelopers')
    scrollToTop()

    }
    function java(){
      toggleMenu()
        navigate('/JavaDevelopers')
    scrollToTop()

    }

    function iphone(){
      toggleMenu()
        navigate('/IphoneDevelopers')
    scrollToTop()

    }

    function data(){
      toggleMenu()
        navigate('/DataScientist')
    scrollToTop()

    }
    function sales(){
        navigate('/SalesforecDevelopers')
    scrollToTop()

    }
    function contact(){
      toggleMenu()
      navigate('/contactus')
    scrollToTop()

  }
    

  function contact() {
    navigate('/contactus');
    toggleMenu()

    scrollToTop()
}
function blogs() {
    navigate('/blogs');
    toggleMenu()

    scrollToTop()
}
function awards() {
    navigate('/Awards');
    toggleMenu()

    scrollToTop()
}
function career() {
    navigate('/Careers');
    toggleMenu()

    scrollToTop()
}

function  Casestudy() {
    navigate('/Casestudy');
    toggleMenu()

    scrollToTop()
}

function Research() {
    navigate('/Research');
    toggleMenu()

    scrollToTop()
}


function news() {
    navigate('/News');
    toggleMenu()

    scrollToTop()
}

function In_The_news() {
  navigate('/Companynews');
  toggleMenu()

  scrollToTop()
}

function report() {
    navigate('/Report');
    toggleMenu()

    scrollToTop()
}

function videos() {
    navigate('/Videos');
    scrollToTop()
    toggleMenu()

}

function Industry1() {
    navigate('/Industry1');
    toggleMenu()

    scrollToTop()
}

function scrollToTop() {
  window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smooth scrolling (optional)
  });
}

  return (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300, overflowX:"auto" }}
      role="presentation"
    >
        <div style={{justifyContent:"center",display:"flex",marginTop:"10px"}}>
        <img src={require("../../../assets/real.png")} height={"60px"} width={"61"} onClick={home}/>


        </div>
      <Divider />
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={toggleSolutionsOpen}>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/Pastel Colorful Marketing Analytics Infographic Instagram Post.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Solutions" />
          {solutionsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={solutionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => toggleSubDropdownOpen('demandsPlanning')}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
              <img src={require("../../../assets/solution/demandplanningout.png")} width={"50px"} height={"40px"}/>

              </ListItemIcon>
              <ListItemText primary="Demands Planning" />
              {subDropdownOpen['demandsPlanning'] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            {/* Nested Collapse for sub-dropdown */}
            <Collapse
              in={subDropdownOpen['demandsPlanning']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <img src={require("../../../assets/solution/demandplanning/forecasttech.png")}width={"50px"} height={"40px"} />
                  </ListItemIcon>
                  <ListItemText primary="ForecastTech" onClick={forecast}/>
                </ListItemButton>
              </List>
            </Collapse>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={solutionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => toggleSubDropdownOpen('supplyChain')}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
              <img src={require("../../../assets/solution/supplychainout.png")} width={"50px"} height={"40px"}/>

              </ListItemIcon>
              <ListItemText primary="Supply Chain" />
              {subDropdownOpen['supplyChain'] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            {/* Nested Collapse for sub-dropdown */}
            <Collapse
              in={subDropdownOpen['supplyChain']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/supplychain/inventarytech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="InventoryTech" onClick={inventory} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['supplyChain']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/supplychain/spacetech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="SpaceTech" onClick={spaceTech} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['supplyChain']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/supplychain/racktech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="RackTech" onClick={Racktech} />
                </ListItemButton>
              </List>
            </Collapse>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        {/* 3 */}
        <Collapse in={solutionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => toggleSubDropdownOpen('Merchandising')}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
              <img src={require("../../../assets/solution/merchandisingout.png")} width={"50px"} height={"40px"}/>

              </ListItemIcon>
              <ListItemText primary="Merchandising" />
              {subDropdownOpen['Merchandising'] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            {/* Nested Collapse for sub-dropdown */}
            <Collapse
              in={subDropdownOpen['Merchandising']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/merchandising/plantech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="PlanTech" onClick={planet} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['Merchandising']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding >
                <ListItemButton sx={{ pl: 6 }} >
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/merchandising/assortech.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="AssortTech" onClick={assortech} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['Merchandising']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/merchandising/attributetech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="AttributeTech" onClick={attribute} />
                </ListItemButton>
              </List>
            </Collapse>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        {/* 4 */}

        <Collapse in={solutionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => toggleSubDropdownOpen('Pricing')}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
              <img src={require("../../../assets/solution/PRICEANDPERMOTIONOUT.png")} width={"50px"} height={"40px"}/>

              </ListItemIcon>
              <ListItemText primary="Pricing & Promotion" />
              {subDropdownOpen['Pricing'] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            {/* Nested Collapse for sub-dropdown */}
            <Collapse
              in={subDropdownOpen['Pricing']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/priceandpermoting/price.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="PriceTech" onClick={Pricetech} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['Pricing']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/priceandpermoting/tradetech.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="TradeTech"  onClick={Tradetech}/>
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['Pricing']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/priceandpermoting/audit.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="AuditTech" onClick={audit} />
                </ListItemButton>
              </List>
            </Collapse>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        {/* 5 */}

        {/* <Collapse in={solutionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => toggleSubDropdownOpen('intelligence')}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
              <img src={require("../../../assets/solution/bujnessintelligenceOUT.png")} width={"50px"} height={"40px"}/>

              </ListItemIcon>
              <ListItemText primary="Business Intelligence" />
              {subDropdownOpen['intelligence'] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>

            <Collapse
              in={subDropdownOpen['intelligence']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/bujnessintelligence/test.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="TestTech" onClick={Testtech} />
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
              in={subDropdownOpen['intelligence']}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/solution/bujnessintelligence/monday.png")} width={"50px"} height={"40px"}/>

                  </ListItemIcon>
                  <ListItemText primary="MondayTech" onClick={monday}/>
                </ListItemButton>
              </List>
            </Collapse>

         

          </List>
        </Collapse> */}
      </List>


      {/* services */}

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={toggleServicesOpen}>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/service.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Services" />
          {ServicesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/webdev.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Web Developement" onClick={webDev}/>
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/arvr.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="AR VR" onClick={arvr} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/apppdev.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Mobile App Development" onClick={mobileApp} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <img src={require("../../../assets/services/iot.png")} width={"50px"} height={"40px"} />
                  </ListItemIcon>
                  <ListItemText primary="IOT" onClick={iot} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/dataanaylytics.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Data Analytics" onClick={dataAnalytics} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/cloudservices.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Cloud Services" onClick={cloudservices} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/devops.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Devops"onClick={devops} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/aiml.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="AI/ML" onClick={aiml} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={ServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/services/cybersecurity.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Cyber Security" onClick={cyber} />
                </ListItemButton>
              </List>
            {/* </Collapse> */}

            {/* End of sub-dropdown */}
          </List>
        </Collapse>



   

   

   
   

   

        {/* 3 */}
     

      
      </List>

      {/* Hire Developers */}
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={toggleHireDevelopers}>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/hire developers.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Hire Developers" />
          {hireDevelopers ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <img src={require("../../../assets/hiredevs/web.png")} height={"40px"} width={"40px"}  />
                  </ListItemIcon>
                  <ListItemText primary="Web Developers" onClick={webDevelopers} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/HireDevelopers/app development.png")} height={"50px"} width={"50px"}  />

                  </ListItemIcon>
                  <ListItemText primary="App Developers"onClick={appDev} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/softwaredeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Software Developers"onClick={software} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/fullstackdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Full-Stack Developers " onClick={fullStack} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/frontenddeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Frontend Develoeprs" onClick={frontend}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/backenddeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Backend Developers" onClick={BackEndDevelop}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/uiuxdeveveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="UI/UX Developers"  onClick={uiux}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/iotsolution.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="IOT Solution Architect" onClick={iotSolution} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/blockdesign.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Blockchain Developers" onClick={BlockChainDeve} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>
        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/androiddeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Android Developers" onClick={AndroidDevelop} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/iosdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="IOS Developers" onClick={ios}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/flutterdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Flutter Developers" onClick={flutter} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/reactnative developer.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="React Native Developers" onClick={reactNative} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/swiftdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Swift Developers" onClick={swift}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/ionicdevelopment.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Ionic Developers" onClick={iconic}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/kotlindeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Kotlin Developers" onClick={kotlin} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/angularjsdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Angular Developers"  onClick={angular} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/reactjsdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Reactjs Developers" onClick={react} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/nodejsdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Nodejs Developers"  onClick={node}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/vuejsdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Vue js Developers" onClick={vue}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/phpdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="PHP Developers" onClick={php}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/wordjsdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Wordpress Developers" onClick={wordpress}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/cakephpdeveloper.png")} height={"40px"} width={"40px"}  />
                    
                  </ListItemIcon>
                  <ListItemText primary="CakePhp Developers" onClick={cakephp} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/codeignitedeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Codeignitor Developers" onClick={codeignitor} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/magnetodeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Magento Developers" onClick={magento} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/shopifudeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Shoipify Developers" onClick={shopify}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/drupaldeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Drupal Developers" onClick={drupal}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/woocommercedeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="WooCommerce Developers" onClick={woo} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/pythondeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Python Developers" onClick={python}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/golangdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Golang Developers" onClick={golang} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/soliditydeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Solidity Developers" onClick={solidity}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/dart.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Dart Developers" onClick={dart}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/springbootdeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Springboot Developers" onClick={spring}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/djangodeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Django Developers" onClick={django} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/lareveldeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Laravel Developers" onClick={laravel} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/yiideveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="YII Developers" onClick={laravel}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/iphonedeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Iphone Developers" onClick={iphone}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/javadeveloper.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Java Developers" onClick={java}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/datascienctist.png")} height={"40px"} width={"40px"}  />

                  </ListItemIcon>
                  <ListItemText primary="Data Scientist" onClick={data} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={hireDevelopers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/hiredevs/sales.png")} height={"40px"} width={"40px"}  />
                    
                  </ListItemIcon>
                  <ListItemText primary="Salesforce Developers" onClick={sales}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>
</List>

{/* Company */}
<List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={toggleCompany}>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/company.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Company"  />
          {company ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>


        <Collapse in={company} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/drawerCompany/career.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Careers" onClick={career}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>



        <Collapse in={company} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/drawerCompany/innovation.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Innovation" onClick={awards}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>


        <Collapse in={company} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/drawerCompany/contactus.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Contact us" onClick={contact} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={company} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/drawerCompany/contactus.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Videos" onClick={videos} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={company} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/inthenews.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="In the news" onClick={In_The_news} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>
        </List>

        {/* Resources */}

        <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={toggleResources}>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/resources.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Resources" />
          {resources ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/trendinginsight.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Trending Insight" onClick={Industry1} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/blogs.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Blogs" onClick={blogs}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/casestudy.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Case Studies" onClick={Casestudy}/>
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>
        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/research.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Research"onClick={Research} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/inthenews.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="News Letter" onClick={news}  />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>


        <Collapse in={resources} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                  <img src={require("../../../assets/resources/reports.png")} width={"50px"} height={"40px"} />

                  </ListItemIcon>
                  <ListItemText primary="Reports" onClick={news} />
                </ListItemButton>
              </List>

            {/* End of sub-dropdown */}
          </List>
        </Collapse>

      
        </List>



        {/* contact us  */}

        <ListItemButton>
          <ListItemIcon>
          <img src={require("../../../assets/headeroption/contactus.png")} width={"50px"} height={"40px"}/>

          </ListItemIcon>
          <ListItemText primary="Contact us" onClick={contact} />
          {/* {company ? <ExpandLess /> : <ExpandMore />} */}
        </ListItemButton>



    </Box>
  );
};



function TempDrawer({ toggleMenu, menuOpen }) {

  return (
    <Drawer
      variant="temporary"
      open={menuOpen}
      onClose={toggleMenu}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: 'none',
        '@media (max-width: 1000px)': {
          display: 'block',
        },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          maxWidth: '300px',
          backgroundColor: '#091523',
        },
      }}
    >
      <ListComp  toggleMenu={toggleMenu} />
    </Drawer>
  );
}

export default TempDrawer;
