import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const DrupalDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Drupal Developers</Typography>

<Typography >Hire Drupal Developers To Built Augment Drupal Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/enwaxzihyebmlci6dz8k" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography>  Drupal is a popular open-source content management system (CMS) and web development framework. It's used for building and managing websites, ranging from personal blogs to large enterprise-level platforms. Drupal enables users to create, organize, and publish content, as well as manage the appearance and functionality of their websites.Is your website's hosting environment suitable for installing Drupal? You'll require a web server capable of running PHP and the appropriate database system, which could include options like Microsoft SQL Server, SQLite, PostgreSQL, MariaDB, MongoDB, or MySQL. If you opt to employ a Drupal developer, such as Bringmark, it's essential to have these technical prerequisites in place beforehand. This way, you can avoid any delays in ensuring your hosting environment meets the necessary criteria to support your Drupal installation effectively.</Typography>

    <Typography style={{marginTop:'40px'}}> A cornerstone of Drupal's appeal lies in its commitment to security, regularly issuing updates to fortify websites against potential threats. Notably, the platform boasts multilingual support, user role customization, and search engine optimization tools, all contributing to a holistic web management experience. As a testament to its strength, Drupal nurtures a vibrant community of developers, designers, and users who collaboratively enrich its ecosystem with insights, innovations, and support.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Why chose Drupal:</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1.  Content Management:  </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal allows you to create, edit, and manage different types of content, such as articles, images, videos, and more. You can organize content using categories, tags, and custom fields.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Customization: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal's modular architecture enables extensive customization. Developers can create custom themes for the visual design and modules for additional functionality. There's a vast repository of contributed modules available that can add various features to your site.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Scalability: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Drupal is capable of handling websites of various sizes and complexities. It's used by small businesses, NGOs, government agencies, and large enterprises alike.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>4. Security:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal has a strong focus on security. Regular security updates are released to address vulnerabilities, and the community is active in identifying and resolving potential threats.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>5. Multilingual Support:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>  Drupal supports multiple languages, making it suitable for websites with an international audience.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. User Roles and Permissions: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> You can define user roles with different permissions to manage content creation, editing, and other administrative tasks.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. SEO-Friendly: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>  Drupal provides tools and modules to help improve your site's search engine optimization (SEO) efforts.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Community and Support: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal has a large and active community of developers, designers, and users who contribute to its development, share knowledge, and provide support.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Version Control: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal allows for version control integration, making it easier for teams to collaborate on development.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. API Integration:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Drupal can integrate with various third-party services and APIs to extend functionality.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default DrupalDevelopers