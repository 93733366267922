import React from 'react';
import { Button ,useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import "./Mondaytech.css";
import "./forcastTech.css";

import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';
// import ForecastReviews from './ForecastReviews';

// data of assortech features cards
const featuresTitle = `Inspect Variations`
const featuresDescription = `Through machine learning models, MondayTech uncovers the causes behind deviations in the business and offers clever advice on addressing these concerns.`
const feauturesimage = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/j017btnkk5owxmr3cgdn"

const featuresTitle2 = `Clever Summaries`
const featuresDescription2 = `Effective insights generation that delves into the merchandising hierarchy, geographic regions, promotional strategies, and consumer segments. Enhance your Monday Morning meetings with forward-looking insights from MondayTech.`
const feauturesimage2 =  "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/rrxorvf7hutdt9udiu2u"

const featuresTitle3 = `Promo Assessment`
const featuresDescription3 = `Capability to evaluate the efficiency of promotions with sophisticated data-derived insights. Abandon speculation about what succeeded and present potent insights during your Monday Morning gatherings.`
const feauturesimage3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/bavngtdzcf5qvfttfzu2"

const featuresTitle4 = `Perfomance Check`
const featuresDescription4 = `Expedites your retail data scrutiny by enabling the monitoring of store and category performance across various facets all at once, including sales volume, customer service ratings, traffic conversion, and other vital business metrics.`
const feauturesimage4 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/iv61ve1ozbtx05kfsmkq"

// the end

// Implementation of AssortSmart takes less than 8 weeks.

const weeksTitle = `Learn`
const weeksDescription = `Ingest your present reporting bundle, encompassing unique forms your crew utilizes to analyze the company each week.`
const weeksimage = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/ovgwacwmqwukerhpahqr"

const weeksTitle2 = `Build`
const weeksDescription2 = `Put into action ML models to recognize fundamental triggers of business divergence, and include them in the MondayTech deployment. Personalize displays to conform to the method business is conventionally appraised, linked to comprehensive dive origin point units.`
const weeksimage2 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/kn9yb37luq76kafxmdsh"

const weeksTitle3 = `Monitor`
const weeksDescription3 = `Collaborate intimately with pilot users to refine ML origin point formation, display configurations, and route alternatives.`
const weeksimage3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/ujn6sfrgs0wfswhu15uh"

const weeksTitle4 = `Deploy`
const weeksDescription4 = `Launch to the full business concurrently with prevailing reporting. Many companies overlap for 2-3 months prior to phasing out traditional reporting in preference for MondayTech's sole truth foundation.`
const weeksimage4 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/idkjw6iruwla0clz5sjn"

// the end

// seconsd last card of below the page 
const title1 = `Retail`
const message1 = `Use precise ideas and hinder shifts all through the whole journey for improved consumer interaction.`
const image1 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/xoc3mj4yzvotnopi08dk"

const title2 = `CPG`
const message2 = `Secure comprehensive sales strategies through relevant data assessment, and enhance the choice-making process, commencing with allocation to promotions, and everything in the midst.`
const image2 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/cd8gvgm7rcnpxjolsbwd"

const title3 = `Hardgoods`
const message3 = `Tech BI sustained by advanced demand predictions and clever ideas.`
const image3 = "https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/q8e6omoohtastge7dvid"
// the end of cards






const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
const title = `Businesses that can gain from MondayTech`
const message = `Automatically examine data with our advanced company info system for more intelligent choices in Monday AM gatherings.`;
const containerStyle = {
  backgroundImage: 'url("https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/outerimage/lsk3mjhld6rhagrqpcgg")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed', // Optional - makes the image fixed on scroll
  backgroundPosition: 'center',
  minHeight: '100vh',
};

function Mondaytech() {
    const theme = useTheme();
    const navigate = useNavigate()
    const request =() =>{
      navigate('/contactus')
    }

  return (
    <div className='page'>
    <div className='assort_title' >
      <Grid style={containerStyle}>
        {/* Left Column */}
        <Grid  xs={12} sm={6} style={{marginTop:"70px"}}>
          <h1 style={{ fontWeight: "bold", color: '#15DFED' }}>MondayTech</h1>
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={6} style={{marginTop:"15px"}}>
          <h2 style={{ fontWeight: "bold", color: "white",flexWrap:"wrap",display:"flex" }}>"AI-fueled BI for shops, shift from responsive updates to forward-thinking wisdom for information-guided choice creation."
          </h2>
          <div style={{width:"100%"}}>
          <p style={{marginTop:"15px",flexWrap:"wrap",display:"flex"}}>
          Secure a sole origin for each your firm's critical performance factors (KPIs) for enhanced data-driven judgment-formation and watch advancement toward objectives at each grade in your company, with our top-tier AI-driven clever reporting system.
          </p>
          </div>
          <div style={{marginTop:"85px"}}>
          <Button variant="contained" color="primary" onClick={request}>Request Demo</Button>

          </div>
        </Grid>
        <div className='path'item xs={12} sm={6} style={{ textAlign: 'justify',flexWrap:"wrap" }}>
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Home</Typography>    
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>Solutions</Typography>
        <FastForwardIcon/> 
        <Typography style={{fontSize:"25px",color: '#15DFED'}}>MondayTech</Typography>
      </div>

  
      </Grid>

      <Grid style={{marginTop:"10vh"}}>
      <h2 style={{ fontWeight: "bold", color: "white" }}>MondayTech Advantage</h2>
 
      </Grid>
<Grid style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",marginBottom:"60px"}}>
      <p style={{marginTop:"15px",color:"white" }}>
      MondayTech forms Commerce Intel for sales straightforward via automatic ideas grounded in your data roots, allowing you to secure rapid perception into crucial agents impacting your company.          
      </p>
</Grid>
     

      
  

<Grid container spacing={2} className='img-container' >
        {/* For desktop view */}
        <Grid style={{display:"flex", justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/cbzzwwwehka1mcijshft" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
            <h3 >Swift implementation</h3>
              <Typography>This clever reporting software can be rolled out in under 4 weeks, providing insights at your fingertips.</Typography>
            </div>

            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Unified Data Hub</h3>
              <Typography>Exploit a consolidated view of your enterprise, with one data hub - no more disputes about data origins.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>


      {/* 2nd */}

      <Grid container spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
          <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/ihgvgsheh2t6wdak8av1" height={"300px"} width={"500px"} alt="Laptop" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div >
            <div className='laptop_container1'>
              <h3>Forward-thinking guidance</h3>
              <Typography>Enhance your Monday Morning Assembly's productivity by shifting from outdated responsive reporting to a system that spotlights variations for you and employs AI to pinpoint underlying reasons.</Typography>
            </div>
            <div className='laptop_container1'>
              <h3 style={{marginTop:"35px"}}>Initiative user interface</h3>
              <Typography>Delivers an easy drag-and-drop interface, robust data analysis capabilities, and cross-platform integrations for promoting intelligent assessment.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>   
    </div>

    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}>MondayTech Features</h1>
        <Typography>An automated tech reporting system that optimizes the potential of users by automating repeated computations, harnessing machine learning to spot trends, and providing industry leaders a competitive advantage.
</Typography>
    </div>

    <Box className='forcast_cardContainer'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle}</Typography>
            <Typography color={"white"}>{featuresDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle2}</Typography>
            <Typography color={"white"}>{featuresDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle3}</Typography>
            <Typography color={"white"}>{featuresDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={feauturesimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{featuresTitle4}</Typography>
            <Typography color={"white"}>{featuresDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>



    <div style={{textAlign:"center",marginTop:"90px"}}>
        <h1  style={{ fontWeight: "bold", color: '#15DFED'}}> Implementation of MondayTech Takes less than 4 Weeks</h1>
    </div>

    <Box className='forcast_cardContainer1'>
      

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle}</Typography>
            <Typography color={"white"}>{weeksDescription}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle2}</Typography>
            <Typography color={"white"}>{weeksDescription2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle3}</Typography>
            <Typography color={"white"}>{weeksDescription3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={weeksimage4} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{weeksTitle4}</Typography>
            <Typography color={"white"}>{weeksDescription4}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>

    <Box className='forcast_cardContainer2'>
        <div style={{width:"100%",textAlign:"center",marginBottom:"50px"}}>
            <h2>
              <span style={{ color: '#15DFED' , fontWeight:"bold"}}>BM's Tech</span> AI-driven forecast analytics helping sellers with
              </h2>
        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}> 50%</h2>
                </div>
                <p style={{color:"white"}}>Boost in the time spared </p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}} >2</h2>
                </div>
                <p style={{color:"white"}}>Weeks to spot and put into action options</p>

        </div>

        <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>15-30</h2>
                </div>
                <p style={{color:"white"}}>Mins time taken prior to each review gathering</p>
        </div>
        </Box>
{/*          <div>
            <div style={{flexDirection:"row",display:"flex",alignContent:"center",alignItems:"center"}}>
                <h1 style={{fontWeight:"bold",color: '#15DFED'}}>|</h1>
                <h2 style={{fontWeight:"bold"}}>15-30</h2>
                </div>
                <p style={{color:"white"}}></p>

      </div>*/}
    


    <Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Explore MondayTech in Action</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Discover how the "wise" MondayTech condenses vital concerns for vendors, facilitating swifter accomplishment and the capability to thoroughly investigate crucial sectors influencing corporate results, granting you the insight into the what and why behind your complete data.
                           </Typography>
            </div>
          
          </div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/n6iczogsrjo9kls2iw2f" alt="" width={"500px"} height={"400px"}/>
        </Grid>
      </Grid>
    {/* <ForecastReviews/> */}

    <Box className='forcast_cardContainer4'>
      <div  >
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          borderRadius:"150x",
          marginTop:"50px",
          backgroundColor:"black"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
         
          <Grid item xs >
            <Typography style={{fontSize:"40px",color:"white",fontWeight:"bold"}}>{title}</Typography>
            <Typography color={"white"} style={{marginTop:"150px"}}>{message}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>
      <div style={{flexDirection:"column",display:"flex"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image1} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title1}</Typography>
            <Typography color={"white"}>{message1}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image2} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title2}</Typography>
            <Typography color={"white"}>{message2}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
      </div>

      <div style={{alignContent:"center",justifyContent:"center",display:"flex",alignItems:"center"}}>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
          backgroundColor:"#122031",
          borderRadius:"150x"
        }}
      >
        <Grid container wrap="nowrap" spacing={2} flexDirection={"column"} >
          <Grid item>
            <img src={image3} width={"80px"} height={"80px"}/>
          </Grid>
          <Grid item xs>
            <Typography style={{fontSize:"30px",color:"white",fontWeight:"bold"}}>{title3}</Typography>
            <Typography color={"white"}>{message3}</Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      </div>
     
    </Box>


    

    
<Grid  spacing={2} className='img-container'>
        {/* For desktop view */}
        <Grid style={{display:"flex",justifyContent:"center"}}>
        <img src="https://res.cloudinary.com/dn3pfxep6/image/upload/f_auto,q_auto/v1/Solutions/Mondaytech/wmw9bzcdfzmnu07gwhxo" alt="" width={"500px"} height={"400px"}/>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
          <div >
            <div className='laptop_container1'>
              <h3 style={{fontWeight:"bold",fontSize:"50px"}}>Resources</h3>
              <Typography style={{fontWeight:"500",fontSize:"20px"}}>
              Gain precious insights into the freshest trends in the industry, technology, and how your contemporaries are addressing their most significant operational hurdles with AI's clever reporting answer.
                           </Typography>
            </div>
          
          </div>
        </Grid>
      </Grid> 


    </div>
  );
}

export default Mondaytech;
