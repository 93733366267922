import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const UXDesigner = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire UI/UX Designer Developers</Typography>

<Typography >Hire UI/UX Developers To Built Augment Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/nxhlenlrr59w97e98tuy" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> UI (User Interface) and UX (User Experience) are integral components of the design and usability of digital products. UI refers to the visual and interactive elements that users interact with directly, such as buttons, menus, and layouts. It involves crafting a visually appealing and intuitive interface that aligns with the brand's aesthetics and user expectations. On the other hand, UX encompasses the overall user journey and experience while interacting with a product. It involves understanding user needs, behaviors, and emotions to create a seamless and enjoyable interaction. UX design focuses on enhancing user satisfaction by ensuring that the product is easy to navigate, functional, and meets the user's goals effectively. In essence, UI and UX work together to create a cohesive and engaging digital experience. UI design deals with the aesthetics and presentation, while UX design delves into the deeper aspects of usability, accessibility, and user satisfaction. Effective UI/UX design is crucial for building products that not only look good but also provide meaningful and delightful interactions for users.</Typography>

    <Typography style={{marginTop:'40px'}}> We make certain that our clients receive a distinctive design infused with fresh features that could potentially boost productivity and returns on investment. Our designers craft design elements to ensure that your users can easily understand the purpose of each item displayed on the screen. They possess extensive expertise in constructing and enhancing customer portal websites, apps, and software programs. Additionally, our designers possess profound knowledge regarding innovative design frameworks. As a result, we are committed to delivering outputs that revolve around the preferences of our customers. We prioritize incorporating users' perspectives into our development process, aiming to enhance the usability of each product we create. Through our UI/UX design services, we offer clients a dynamic and impactful user experience that not only maximizes their returns but also advances their branding strategies.</Typography>

    <Grid className='sticky'> 

<Grid iitem xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By UI/UX Developers</Typography>

    <Typography variant='h4' marginTop={5} fontSize={22}>1. Supportive UI through Information Architecture</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> For an innovative UI/UX design approach, consider enlisting the expertise of our designers at BringMark. Our skilled UI/UX professionals leverage an understanding of users' natural behaviors to create a cohesive impact on your target audience.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Website Design Services</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> We place a strong emphasis on interactive website design solutions that contribute to the growth of your company. BringMark offers access to UI/UX specialists who can provide a user-friendly website design solution tailored to meet your company's specific demands.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Custom Design Solutions</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our dedicated UI/UX designers specialize in crafting bespoke web solutions tailored to your company's unique needs. Leveraging advanced tools and technology, we customize your product's design to align perfectly with your requirements. Choosing our UI/UX designers is the ideal choice for your project.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4. Flexible Time Zone</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our availability aligns with your country's time zone, ensuring seamless collaboration. You can engage one of our skilled designers to effectively complete tasks within your preferred time frame.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Information Architecture and Wireframing</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Engage UI/UX designers to assist you in the creation and visualization of data structures using wireframes. Our designers conduct initial assessments of workflows, data structures, and navigation, incorporating UI patterns and functionalities.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. User Testing Services</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> At BringMark, we offer adept UI/UX designers who conduct user testing sessions, enabling the collection of valuable feedback and analytical insights into your UI/UX design. This process facilitates necessary adjustments and enhancements to the design.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Cross-Platform Compatibility</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> To develop essential features and functionalities, you can enlist the services of UI/UX designers from BringMark. Our designers adhere to established UI/UX design principles to deliver platform-specific and responsive designs.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Swift App Consulting & Strategy</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our experts provide valuable insights and recommendations on the best strategies and technologies for Swift app development. We work with clients to define project goals, identify potential challenges, and outline a roadmap for successful app development.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9. Perfomance Optimization</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our experts optimize Swift apps to ensure they run smoothly and efficiently. We analyze and enhance the app's performance to provide users with a fast and responsive experience.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Upgrades and Migration</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> If you have existing apps developed using other technologies or older Swift versions, we can assist in upgrading or migrating them to the latest Swift version or iOS platform, ensuring they remain competitive and functional.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default UXDesigner