import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../commonFunctions'  
import ContactForm from '../ContactForm'


const LaravelDevelopers = () => {

    const width=useWindowSize()
  return (
 <Grid container style={{width:'80%', margin:'auto', paddingBottom:'200px',marginTop:"90px"}}>
<Grid item xsm={12} sm={6} md={6} lg={6} style={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

<Typography variant='h4' style={{fontWeight:'600',marginBottom:'20px'}} >Hire Laravel Developers</Typography>

<Typography >Hire Laravel Developers To Built Augment Laravel Web Applications

</Typography>

</Grid>

<Grid item xsm={12} sm={6} md={6} lg={6} style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
<img style={{width:'80%'}} src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Hire%20Developers/vdssfsrhibelamu4sjr7" alt="" />
</Grid>

<Grid item  style={{marginTop:'100px'}}>

    <Typography> Laravel is a renowned open-source web application framework crafted in PHP that stands out for its expressive syntax and comprehensive development environment. With Laravel, developers gain a powerful toolkit to create a diverse range of web applications, from uncomplicated websites to intricate enterprise-level systems. A hallmark of Laravel is its Eloquent ORM, facilitating seamless interaction with databases through object-oriented syntax. The framework follows the Model-View-Controller (MVC) architecture, fostering a modular approach for better code organization. Notably, Laravel incorporates the Blade templating engine, enabling efficient rendering of dynamic content within views. With its robust routing system, convenient authentication mechanisms, and support for tasks like caching and security, Laravel stands as a go-to choice for modern web development, amplifying productivity and enabling the creation of sophisticated applications.</Typography>

    <Typography style={{marginTop:'40px'}}> BringMark is the best Laravel development company to hire expert Laravel developers. Our proficient team boasts extensive expertise in various aspects, encompassing third-party integration, website migration, application maintenance, and support, as well as a firm grasp of Laravel’s Eloquent ORM. Our approach begins with a thorough analysis of your business requisites, followed by meticulous planning and execution strategies, all aimed at ensuring optimal outcomes.</Typography>

    <Grid className='sticky'> 

<Grid  item xsm={12} md={12} lg={8} xl={8}  style={{width:"100%"}}> 
    <Typography variant='h4' marginTop={10} fontSize={27}>Service We Offer By Laravel Developers:</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>1. Skilled Laravel Developers Team:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our Laravel developers possess exceptional coding skills, guaranteeing the highest standards of development and deployment. They construct web applications that are not only flawless but also exceptionally fast in performance.</Typography>

 <Typography variant='h4' marginTop={5} fontSize={22}>2. Utilization Of the Latest Laravel Vesrion: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>To infuse a contemporary look and cutting-edge features into our clients' projects, we employ the most recent Laravel framework version, capable of meeting today's diverse requirements.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>3. Structured PHP, CSS, and HTML</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>At Bringmark, we ensure the creation of robust and well-organized solutions, where PHP, CSS, and HTML are meticulously crafted.</Typography>



 <Typography variant='h4' marginTop={5} fontSize={22}>4.Proficiency in Object-Oriented Concepts: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Each project we deliver is developed using the principles of object-oriented programming, reinforcing its technical robustness.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>5. Dedication to Quality:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our commitment to delivering world-class solutions remains unwavering. We consistently prioritize top-tier development for our clients' products.</Typography>


 <Typography variant='h4' marginTop={5} fontSize={22}>6. Expertise in MVC Framework:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Engaging our dedicated Laravel developers offers an additional advantage, as they are well-versed in harnessing this modern PHP framework and its associated features and functionalities.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>7. Swift and Efficient Delivery:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> At BringMark, we dedicate ourselves to achieving this goal through the meticulous selection of technologies. This enables us to provide timely and efficient deliveries to our clients.</Typography>



 
 <Typography variant='h4' marginTop={5} fontSize={22}>8. Effective Communication:</Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}>Our approach to project management is designed to ensure client satisfaction. We offer convenient communication channels to promptly address client queries and concerns.</Typography>

  
 <Typography variant='h4' marginTop={5} fontSize={22}>9.Testing and Debugging: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Our Team of Laravel Developers Have Strong skills in unit testing, debugging, and troubleshooting to ensure the reliability and quality of Laravel applications.</Typography>


  
 <Typography variant='h4' marginTop={5} fontSize={22}>10. Security Awareness: </Typography>
 <Typography style={{color:'#E1D9D1', marginTop:'15px'}}> Laravel Developer at BringMark have deep understanding of common web security principles and practices, as well as the ability to implement security measures to protect Laravel applications from vulnerabilities.</Typography>
 </Grid>
 <div className="sticky-form-container">

<Grid className="sticky-form" style={{justifyContent:"flex-end"}}>
    <ContactForm/>
</Grid>
</div>
</Grid>
</Grid>

<Grid container style={{marginTop:'200px', justifyContent:'center'}}>


<Typography  variant={width<700?"h6":"h2"} >Leverage World-Class Talent
</Typography>
<Typography>We have a team of experts who have a pool of expertise in their respective fields. Their approach is out-of-box, dynamic, and unique in the market.

</Typography>


</Grid>

<Grid  spacing={4} marginTop={5} style={{width:'100%',flexDirection:"row",display:"flex",flexWrap:"wrap"}}> 

<Grid item xsm={12} sm={6} md={6} lg={4}   xlg={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >
    <Card style={{backgroundColor:'transparent',padding:'10px'}}>        
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-developers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Junior Developers</Typography>
    <Typography>Our Junior Developers with 1 to 2 years of experience understand the client's needs and ensure that the entire process matches requirements. They have insightful knowledge and try their best input to develop outstanding and unique development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4}   style={{boxShadow:' 0 8px 8px -4px lightblue',}}  >

    <Card style={{backgroundColor:'transparent' ,}}>     
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-designers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Senior  Developers</Typography>
    <Typography>Senior Developers having 2 to 8 years of experience are highly skilled and proficient throughout the development process. They bring the best in the development and get successful bug-free solutions.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}} >
<Card style={{backgroundColor:'transparent'}}>  
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-product-managers.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Web Developers</Typography>
    <Typography>Web Developers of Bringmark are outstanding and dedicated. We have a team of skilled web developers with several years of experience in the market. The Web Developers have a futuristic vision of web development.</Typography>
    </Card>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg" alt="" height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}}/>
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Project Managers</Typography>
    <Typography>Our project managers are well aware of how to handle and execute projects. Bringmark has expertise in IT development, and our project managers keep an eye on every minor detail in the development process with client satisfaction.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4}  xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue',}}>
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-project-managers.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>UI/UX Designers
</Typography>
    <Typography>Our web developers know the importance of a website for businesses in this competitive era. They have expertise in all the latest web technologies and deliver exceptional web design and development services as per client requirements.</Typography>
</Grid>

<Grid item xsm={12} sm={6} md={6} lg={4} xl={4} style={{boxShadow:' 0 8px 8px -4px lightblue'}} >
    <img src="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-projects.svg" alt=""height={"80px"} width={"80px"} style={{marginLeft:"20px",marginTop:"20px"}} />
    <Typography fontSize={25} color={'#E1D9D1'} marginTop={2} marginBottom={1}>Testers
</Typography>
    <Typography>Quality Assurance is one of the top aspects of any successful solution. We believe in delivering solutions with the best quality in the market, and our QA team checks every project we work on and helps us deliver bug-free solutions to our clients.</Typography>
</Grid>
</Grid>
 </Grid>
  )
}

export default LaravelDevelopers