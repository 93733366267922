import { Container, Grid, Box, Card, CardContent, Typography, Button, TextField } from '@mui/material';
import React,{ useState , useEffect} from "react"



const ContactInfo = () => {

    return (
        <>
        <div>
          {/* Other components */}
      
          
          {/* Other components */}
        </div>
        <Box display="flex" alignItems="center" justifyContent="center" marginTop ={10}mb={2}>
            {/* <Circle sx={{ fontSize: 40, color: '#F44336', marginRight: 10 }} /> */}
            <Typography variant="h4" align="center" color="orange"fontSize={20} gutterBottom>
            CONTACT INFO
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            {/* <Circle sx={{ fontSize: 40, color: '#F44336', marginRight: 10 }} /> */}
            <Typography variant="h4" align="center" fontSize={40} fontWeight="bold"gutterBottom>
            Get In Touch With Us Via Contact Form
            </Typography>
          </Box>
       
        
          </>
      );
      
      
  };


  export default ContactInfo