import React from 'react'
import ReactDOM from "react-dom/client";
// import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import LandingPage from '../LandingPage';
// import Services from '.../components/Services';
// import ContactUsPage from '.../components/contact/COntactPage';
import Footer from './Footer';
import LandingPage from './LandingPage';
import Services from '../components/Services';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from "../components/Aboutus"
import Blog from '../components/Blog';
import ContactUs from '../components/ContactUs';
import Banner from '../components/Banner';
import ContactForm from '../components/ContactForm';
import Pagenotfound from '../components/Pagenotfound';




import { Link, useNavigate } from "react-router-dom";
import WebDev from './infodevs/WebDev';
import MobileAppDev from './infodevs/MobileAppDev';
import ArVr from './infodevs/ArVr';
import DataAnalytics from './infodevs/DataAnalytics';
import CyberSecurity from './infodevs/CyberSecurity';
import CloudService from './infodevs/CloudService';
import ContactPage from '../components/contact/ContactPage';
import ContactUsPage from '../components/contact/ContactPage';
// hireDevelopers
import AngularjsDevelopers from '../components/HireDevelopers/AngularjsDevelopers';
import AndroidDevelopers from '../components/HireDevelopers/AndroidDevelopers';
import AppDevelopers from '../components/HireDevelopers/AppDevelopers';
import BackEndDevelopers from '../components/HireDevelopers/BackEndDevelopers';
import BlockChainDevelopers from '../components/HireDevelopers/BlockChainDevelopers';
import CakephpDevelopers from '../components/HireDevelopers/CakephpDevelopers';
import CodeIgniteDevelopers from '../components/HireDevelopers/CodeIgniteDevelopers';
import DartDevelopers from '../components/HireDevelopers/DartDevelopers';
import DataSciencetist from '../components/HireDevelopers/DataSciencetist';
import DjangoDevelopers from '../components/HireDevelopers/DjangoDevelopers';
import DrupalDevelopers from '../components/HireDevelopers/DrupalDevelopers';
import FlutterDevelopers from '../components/HireDevelopers/FlutterDevelopers';
import FrontEndDevelopers from '../components/HireDevelopers/FrontEndDevelopers';
import FullStackDevelopers from '../components/HireDevelopers/FullStackDevelopers';
import GolandDevelopers from '../components/HireDevelopers/GolangDevelopers';
import IconicDevelopers from '../components/HireDevelopers/IconicDevelopesrs';
import IosDevelopers from '../components/HireDevelopers/IosDevelopers';
// import IOTSolutionArchitecture from '../components/HireDevelopers/IOTSolutionArchitecture';
import IphoneDevelopers from '../components/HireDevelopers/IphoneDevelopers';
import JavaDevelopers from '../components/HireDevelopers/JavaDevelopers';
import KotlinDevelopers from '../components/HireDevelopers/KotlinDevelopers';
import LaravelDevelopers from '../components/HireDevelopers/LaravelDevelopers';
import MagnetoDevelopers from '../components/HireDevelopers/MagnetoDevelopers';
import NodejsDevelopers from '../components/HireDevelopers/NodejsDevelopers';
import PhpDevelopers from '../components/HireDevelopers/PhpDevelopers';
import PythonDevelopers from '../components/HireDevelopers/PythonDevelopers';
import ReactjsDevelopers from '../components/HireDevelopers/ReactjsDevelopers';
import ReactNativeDevelopers from '../components/HireDevelopers/ReactNativeDevelopers';
import SalesforecDevelopers from '../components/HireDevelopers/SalesforceDevelopers';
import ShopifyDevelopers from '../components/HireDevelopers/ShopifyDevelopers';
import SoftwareDevelopers from '../components/HireDevelopers/SoftwareDevelopers';
import SolidityDevelopers from '../components/HireDevelopers/SolidityDevelopers';
import SpringBootDevelopers from '../components/HireDevelopers/SpringBootDevelopers';
import SwiftDevelopers from '../components/HireDevelopers/SwiftDevelopers';
import UXDesigner from '../components/HireDevelopers/UXDesigner';
import VuejsDevelopers from '../components/HireDevelopers/VuejsDevelopers';
import WebDevelopers from '../components/HireDevelopers/WebDevelopers';
import WooCommerceDeveloper from '../components/HireDevelopers/WooCommerceDevelopers';
import WordPressDevelopers from '../components/HireDevelopers/WordPressDevelepers';
import YIIDevelopers from '../components/HireDevelopers/YIIDevelopers';
// projects

import WebProjects from './services/WebProjects';
import Appprojects from './services/Appprojects';
import Arvrprojects from './services/Arvrprojects';
import Dataanalyticsprojects from './services/Dataanalyticsprojects';
import Cloudserviceprojects from './services/Cloudserviceprojects';
import CyberCproject from './services/CyberCproject';
import Aimlprojects from './services/Aimlprojects';
import Iotprojects from './services/Iotprojects';
import Loaders from '../components/loader/Loaders';
import IOTSolutionArchitecture from '../components/HireDevelopers/IOTSolutionArchitecture';

import Navbar from '../components/navbar/Navbar';
import IOT from './infodevs/IOT';
import Devops from './infodevs/Devops';
// import Blockchain from './infodevs/Blockchain';
import AIML from './infodevs/AIML';
import Industry1 from '../components/Resources/Industry1';
import Blogs from '../components/Resources/Blogs';
import Casestudy from '../components/Resources/Casestudy';
import News from '../components/Resources/News';
import Report from '../components/Resources/Report'
import Research from '../components/Resources/Research';
import Videos from '../components/Resources/Videos';
import Careers from '../components/Company/Careers';
import Companynews from '../components/Company/Companynews';
import Awards from '../components/Company/Awards';
import Pricetech from './Solution/Pricetech';
import Racktech from './Solution/Racktech';
import Spacetech from './Solution/Spacetech';
import Testtech from './Solution/Testtech';
import Tradetech from './Solution/Tradetech';
import Gst from '../components/navbar/gst/Gst';
import ForeCarstTech from './Solution/ForeCarstTech';
import Assorttech from './Solution/Assorttech';
import Attributetech from './Solution/Attributetech';
import Inventorytech from './Solution/Inventorytech';
import Audittech from './Solution/Audittech';
import Mondaytech from './Solution/Mondaytech';
import Planetech from './Solution/Planetech';

const RoutesAll = () => {
   
  return (
   <BrowserRouter>

<Navbar/>
{/* <Gst/> */}

 
        
  <Routes>      
      <Route path="/" element={<LandingPage/>}/>
    
         <Route  path="/services" element={<Services/>} />
         <Route  path="/aboutUs" element={<AboutUs/>} />
         <Route  path="/blog" element={<Blog/>} />
         <Route  path="/contact" element={<ContactUs/>} />
        <Route  path="/web-development" element={<WebDev/>} />
         <Route  path="/arVr" element={<ArVr/>} />
         <Route  path="/cyber-security" element={<CyberSecurity/>} />
         <Route  path="/cloud-service" element={<CloudService/>} />
         <Route  path="/data-analytics" element={<DataAnalytics/>} />
         <Route  path="/danish" element={<ContactPage/>} />
         <Route  path="/mobile-development" element={<MobileAppDev/>} />
         <Route  path="/iot" element={<IOT/>} />
      <Route  path="/devops" element={<Devops/>} />
      <Route  path="/aiml" element={<AIML/>} />
      {/* <Route  path="/Assortsolutionstech" element={<Assorttech/>} /> */}
      {/* <Route  path="/services/aiml" element={<Digi/>}     /> */}
{/*  */}
         {/* projects */}
         <Route  path="/webProjects" element={<WebProjects/>} />
         <Route  path="/Appprojects" element={<Appprojects/>} />
         <Route  path="/Arvrprojects" element={<Arvrprojects/>} />
         <Route  path="/Dataanalyticsprojects" element={<Dataanalyticsprojects/>} />
         <Route  path="/Cloudserviceprojects" element={<Cloudserviceprojects/>} />
         <Route  path="/Cyberproject" element={<CyberCproject/>} />
         <Route  path="/Aimlprojects" element={<Aimlprojects/>} />
         <Route  path="/Iotprojects" element={<Iotprojects/>} />


         <Route component={Pagenotfound} /> 
         <Route  path="*" element={<Pagenotfound/>} />
         <Route  path="contactform" element={<ContactForm/>} />
         <Route  path="contactus" element={<ContactPage/>} />




         {/* hire developers routes */}
         <Route  path="/AngularjsDevelopers" element={<AngularjsDevelopers/>} />
         <Route  path="/AndroidDevelopers" element={<AndroidDevelopers/>} />
         <Route  path="/AppDevelopers" element={<AppDevelopers/>} />
         <Route  path="/BackEndDevelopers" element={<BackEndDevelopers/>} />
         <Route  path="/BlockChainDevelopers" element={<BlockChainDevelopers/>} />
         <Route  path="/CakephpDevelopers" element={<CakephpDevelopers/>} />
         <Route  path="/CodeIgniteDevelopers" element={<CodeIgniteDevelopers/>} />
         <Route  path="/DartDevelopers" element={<DartDevelopers/>} />
         <Route  path="/DataScientist" element={<DataSciencetist/>} />
         <Route  path="/WebDevelopers" element={<WebDevelopers/>} />
         <Route  path="/LaravelDevelopers" element={<LaravelDevelopers/>} />
         <Route  path="/DjangoDevelopers" element={<DjangoDevelopers/>} />
         <Route  path="/DrupalDevelopers" element={<DrupalDevelopers/>} />
         <Route  path="/FlutterDevelopers" element={<FlutterDevelopers/>} />
         <Route  path="/FrontEndDevelopers" element={<FrontEndDevelopers/>} />
         <Route  path="/FullStackDevelopers" element={<FullStackDevelopers/>} />
         <Route  path="/GolangDevelopers" element={<GolandDevelopers/>} />
         <Route  path="/IconicDevelopers" element={<IconicDevelopers/>} />
         <Route  path="/IosDevelopers" element={<IosDevelopers/>} />
         <Route  path="/IOTSolutionArchitecture" element={<IOTSolutionArchitecture/>} />        
         <Route  path="/IphoneDevelopers" element={<IphoneDevelopers/>} />
         <Route  path="/JavaDevelopers" element={<JavaDevelopers/>} />
         <Route  path="/KotlinDevelopers" element={<KotlinDevelopers/>} />
         <Route  path="/MagnetoDevelopers" element={<MagnetoDevelopers/>} />
         <Route  path="/NodejsDevelopers" element={<NodejsDevelopers/>} />
         <Route  path="/PhpDevelopers" element={<PhpDevelopers/>} />
         <Route  path="/PythonDevelopers" element={<PythonDevelopers/>} />
         <Route  path="/ReactjsDevelopers" element={<ReactjsDevelopers/>} />
         <Route  path="/ReactNativeDevelopers" element={<ReactNativeDevelopers/>} />
         <Route  path="/SalesforecDevelopers" element={<SalesforecDevelopers/>} />
         <Route  path="/ShopifyDevelopers" element={<ShopifyDevelopers/>} />
         <Route  path="/SoftwareDevelopers" element={<SoftwareDevelopers/>} />
         <Route  path="/SolidityDevelopers" element={<SolidityDevelopers/>} />
         <Route  path="/SpringBootDevelopers" element={<SpringBootDevelopers/>} />
         <Route  path="/SwiftDevelopers" element={<SwiftDevelopers/>} />
         <Route  path="/UIUXDesigner" element={<UXDesigner/>} />
         <Route  path="/VuejsDevelopers" element={<VuejsDevelopers/>} />
         <Route  path="/WooCommerceDeveloper" element={<WooCommerceDeveloper/>} />
         <Route  path="/WordPressDevelopers" element={<WordPressDevelopers/>} />
         <Route  path="/YIIDevelopers" element={<YIIDevelopers/>} />
         <Route  path="/load" element={<Loaders/>} />

{/* solutions */}
{/*  */}

         <Route  path="/ForeCarstTech" element={<ForeCarstTech/>} />
         <Route  path="/Assorttech" element={<Assorttech/>} /> 
         <Route  path="/Attributetech" element={<Attributetech/>} /> 
         <Route  path="/Audittech" element={<Audittech/>} />      
         <Route  path="/Inventorytech" element={<Inventorytech/>} />
         <Route  path="/Mondaytech" element={<Mondaytech/>} />
         <Route  path="/Planetech" element={<Planetech/>} />
                           {/* pending 4 image */}
         <Route  path="/Pricetech" element={<Pricetech/>} />
         <Route  path="/Racktech" element={<Racktech/>} />
         <Route  path="/Spacetech" element={<Spacetech/>} />

         
         <Route  path="/Testtech" element={<Testtech/>} />
         <Route  path="/Tradetech" element={<Tradetech/>} />

              <Route path="/Casestudy" element={<Casestudy />} />
              <Route path="/Research" element={<Research />} />
              <Route path="/Videos" element={<Videos />} />
              <Route path="/Companynews" element={<Companynews />} />
              <Route path="/Awards" element={<Awards />} />
              <Route path="/Careers" element={<Careers />} />
              <Route path="/News" element={<News />} />
              <Route path="/Report" element={<Report />} />
              <Route path="/Industry1" element={<Industry1 />} />
              <Route path="/Blogs" element={<Blogs />} />







      </Routes>  
      <Footer/>


     </BrowserRouter>
  )
}

export default RoutesAll