import React, { useState, useEffect } from 'react';
import { Typography, Container, Box } from "@mui/material";
import "./LandingPage.css";
import AnimatedCard from './AnimatedCard';
import IndustriesWeServe from './services/IndustriesWeServe';
import AnimatedText from './AnimatedText';
import WeOffer from '../components/WeOffer';
import WhoWeWorkWith from './services/WhoWeWorkWith';
import StepperStep from '../components/stepper/StepperStep';
import MovingCards from './card/MovingCards';
import WhyChoose from './services/WhyChoose';
import ReviewCards from '../components/Review/ReviewCards';

// Comprehensive list of countries and services
const countries = [
  "USA", "California", "Texas", "New York", "Florida", "Illinois", "Pennsylvania", "Ohio", "Georgia", "North Carolina",
    "Michigan", "New Jersey", "Virginia", "Washington", "Arizona", "Massachusetts", "Tennessee", "Indiana", "Missouri",
    "Maryland", "Wisconsin", "Colorado", "Minnesota", "South Carolina", "Alabama", "Louisiana", "Kentucky", "Oregon",
    "Oklahoma", "Connecticut", "Iowa", "Mississippi", "Arkansas", "Kansas", "Nevada", "Utah", "New Mexico", "Nebraska",
    "West Virginia", "Idaho", "Hawaii", "Maine", "New Hampshire", "Rhode Island", "Montana", "Delaware", "South Dakota",
    "North Dakota", "Vermont", "Wyoming", "Washington D.C.", "Los Angeles", "San Francisco", "San Diego", "Houston",
    "Dallas", "Austin", "New York City", "Buffalo", "Orlando", "Chicago", "Miami", "Philadelphia", "Phoenix", "San Antonio",
    "San Jose",
  
    "UK", "England", "Scotland", "Wales", "Northern Ireland", "London", "Edinburgh", "Cardiff", "Belfast", "Manchester",
    "Birmingham", "Glasgow", "Liverpool", "Bristol", "Leeds", "Sheffield", "Newcastle", "Nottingham", "Leicester",
    "Coventry", "Bradford",
  
    "India", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
    "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
    "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
    "Uttarakhand", "West Bengal", "Delhi", "Chandigarh", "Mumbai", "Delhi", "Bengaluru", "Hyderabad", "Chennai", "Kolkata",
    "Ahmedabad", "Pune", "Jaipur", "Lucknow", "Kanpur", "Nagpur", "Indore", "Thane", "Agra", "Vadodara",
  
    "Australia", "New South Wales", "Victoria", "Queensland", "South Australia", "Western Australia", "Tasmania",
    "Australian Capital Territory", "Northern Territory", "Sydney", "Melbourne", "Brisbane", "Perth", "Adelaide",
    "Hobart", "Canberra", "Darwin", "Gold Coast", "Sunshine Coast", "Cairns",
  
    "Canada", "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories",
    "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon", "Toronto", "Vancouver",
    "Montreal", "Calgary", "Edmonton", "Ottawa", "Halifax", "Victoria", "Winnipeg", "St. John's", "Regina", "Saskatoon",
  
    "Germany", "Bavaria", "Berlin", "Bremen", "Hamburg", "Hesse", "Lower Saxony", "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia", "Rhineland-Palatinate", "Saarland", "Saxony", "Saxony-Anhalt", "Schleswig-Holstein",
    "Thuringia", "Munich", "Frankfurt", "Hamburg", "Cologne", "Düsseldorf", "Stuttgart", "Leipzig", "Dresden", "Hannover",
    "Nuremberg",
  
    "France", "Île-de-France", "Auvergne-Rhône-Alpes", "Bourgogne-Franche-Comté", "Brittany", "Centre-Val de Loire", "Corsica",
    "Grand Est", "Hauts-de-France", "Normandy", "Nouvelle-Aquitaine", "Occitanie", "Pays de la Loire", "Paris", "Marseille",
    "Lyon", "Toulouse", "Nice", "Nantes", "Strasbourg", "Montpellier", "Bordeaux", "Lille", "Rennes",
  
    "Japan", "Hokkaido", "Aomori", "Iwate", "Miyagi", "Akita", "Yamagata", "Fukushima", "Ibaraki", "Tochigi", "Gunma",
    "Saitama", "Chiba", "Tokyo", "Kanagawa", "Niigata", "Toyama", "Ishikawa", "Fukui", "Yamanashi", "Nagano", "Gifu",
    "Shizuoka", "Aichi", "Mie", "Shiga", "Kyoto", "Osaka", "Hyogo", "Nara", "Wakayama", "Tottori", "Shimane", "Okayama",
    "Hiroshima", "Yamaguchi", "Tokushima", "Kagawa", "Ehime", "Kochi", "Fukuoka", "Saga", "Nagasaki", "Kumamoto", "Oita",
    "Miyazaki", "Kagoshima", "Okinawa", "Osaka", "Kyoto", "Tokyo", "Nagoya", "Kobe",
  
    "Brazil", "São Paulo", "Rio de Janeiro", "Minas Gerais", "Bahia", "Paraná", "Ceará", "Pernambuco", "Rio Grande do Sul",
    "Espírito Santo", "Goiás", "Maranhão", "Mato Grosso", "Distrito Federal", "Paraíba", "Pará", "Alagoas", "Amapá",
    "Amazonas", "Rondônia", "Roraima", "Tocantins", "Brasília", "São Paulo", "Rio de Janeiro", "Salvador", "Fortaleza",
    "Belo Horizonte", "Curitiba", "Recife", "Belém",
  
    "Italy", "Lazio", "Lombardy", "Campania", "Sicily", "Veneto", "Emilia-Romagna", "Piedmont", "Tuscany", "Apulia",
    "Calabria", "Liguria", "Marche", "Abruzzo", "Trentino-Alto Adige", "Friuli Venezia Giulia", "Umbria", "Molise",
    "Sardinia", "Valle d'Aosta", "Rome", "Milan", "Naples", "Turin", "Palermo", "Genoa", "Bologna", "Florence", "Venice",
  
    "Russia", "Moscow", "Saint Petersburg", "Novosibirsk", "Yekaterinburg", "Nizhny Novgorod", "Kazan", "Chelyabinsk",
    "Omsk", "Rostov-on-Don", "Ufa", "Volgograd", "Perm", "Krasnoyarsk", "Voronezh", "Samara", "Astrakhan", "Togliatti",
    "Ulyanovsk", "Khabarovsk", "Yaroslavl", "Irkutsk",
  
    "China", "Beijing", "Shanghai", "Tianjin", "Chongqing", "Guangzhou", "Shenzhen", "Hong Kong", "Macau", "Wuhan",
    "Chengdu", "Nanjing", "Hangzhou", "Xi'an", "Suzhou", "Qingdao", "Dalian", "Shijiazhuang", "Jinan", "Zhengzhou",
    "Changsha", "Kunming", "Harbin", "Hefei", "Fuzhou", "Xiamen", "Ningbo", "Wenzhou",
  
    "South Africa", "Gauteng", "KwaZulu-Natal", "Western Cape", "Eastern Cape", "Limpopo", "Mpumalanga", "Free State",
    "Northern Cape", "North West", "Cape Town", "Johannesburg", "Durban", "Pretoria", "Port Elizabeth",
  
    "Mexico", "Mexico City", "Jalisco", "Nuevo León", "Puebla", "Guanajuato", "Chihuahua", "Durango", "Yucatán",
    "Querétaro", "Sonora", "Veracruz", "Coahuila", "Sinaloa", "San Luis Potosí", "Zacatecas", "Tamaulipas", "Hidalgo",
    "Tabasco", "Morelos", "Campeche",
  
    "Turkey", "Istanbul", "Ankara", "Izmir", "Bursa", "Antalya", "Adana", "Konya", "Gaziantep", "Sanliurfa", "Mersin",
    "Kayseri", "Eskisehir", "Diyarbakir", "Samsun", "Trabzon", "Kocaeli", "Malatya", "Van", "Sakarya",
  
    "Egypt", "Cairo", "Alexandria", "Giza", "Shubra El Kheima", "Port Said", "Suez", "Mansoura", "Tanta", "Asyut",
    "Ismailia", "Faiyum", "Damanhur", "Zagazig", "Benha", "Qena", "Sohag", "Luxor", "Aswan", "Matruh",
  
    "United Arab Emirates", "Abu Dhabi", "Dubai", "Sharjah", "Ajman", "Umm Al-Quwain", "Fujairah", "Ras Al Khaimah",
    "Al Ain", "Khalifa City", "Jebel Ali",
  
    "Saudi Arabia", "Riyadh", "Jeddah", "Mecca", "Medina", "Dhahran", "Khobar", "Dammam", "Al Khobar", "Qassim", "Najran",
    "Abha", "Hail", "Tabuk", "Jubail", "Yanbu",
  
    "Argentina", "Buenos Aires", "Córdoba", "Rosario", "Mendoza", "La Plata", "San Miguel de Tucumán", "Mar del Plata",
    "Salta", "Santa Fe", "San Juan", "Posadas", "Resistencia", "Neuquén", "Formosa", "San Salvador de Jujuy", "Bahía Blanca",
  
    "Colombia", "Bogotá", "Medellín", "Cali", "Barranquilla", "Cartagena", "Bucaramanga", "Pereira", "Santa Marta",
    "Manizales", "Neiva", "Ibagué", "Cúcuta", "Pasto", "Tunja",
  
    "Chile", "Santiago", "Valparaíso", "Concepción", "La Serena", "Antofagasta", "Temuco", "Puerto Montt", "Rancagua",
    "Arica", "Iquique", "Coquimbo", "Osorno", "Talca",
  
    "Peru", "Lima", "Arequipa", "Trujillo", "Chiclayo", "Piura", "Iquitos", "Cajamarca", "Huancayo", "Puno", "Tacna",
    "Chimbote", "Huaraz", "Junín", "Tumbes", "Moquegua", "Cusco",
  
    "Ukraine", "Kyiv", "Kharkiv", "Odesa", "Dnipro", "Donetsk", "Zaporizhzhia", "Lviv", "Kryvyi Rih", "Mykolaiv",
    "Mariupol", "Luhansk", "Sumy", "Chernihiv", "Vinnytsia", "Zhytomyr", "Poltava",
  
    "Poland", "Warsaw", "Kraków", "Łódź", "Wrocław", "Poznań", "Gdańsk", "Szczecin", "Bydgoszcz", "Lublin", "Katowice",
    "Białystok", "Rzeszów", "Olsztyn", "Toruń", "Opole", "Legnica",
  
    "Netherlands", "Amsterdam", "Rotterdam", "The Hague", "Utrecht", "Eindhoven", "Groningen", "Maastricht", "Arnhem",
    "Nijmegen", "Haarlem", "Almere", "Dordrecht", "Leiden", "Hilversum",
  
    "Belgium", "Brussels", "Antwerp", "Ghent", "Bruges", "Liege", "Namur", "Ostend", "Leuven", "Mons", "Charleroi",
    "La Louvière", "Wavre", "Mechelen", "Hasselt",
  
    "Sweden", "Stockholm", "Gothenburg", "Malmö", "Uppsala", "Västerås", "Örebro", "Linköping", "Helsingborg", "Jönköping",
    "Norrköping", "Lund", "Karlstad", "Borås", "Sundsvall", "Trollhättan", "Halmstad",
  
    "Norway", "Oslo", "Bergen", "Stavanger", "Drammen", "Trondheim", "Kristiansand", "Ålesund", "Sandnes", "Haugesund",
    "Molde", "Larvik", "Tønsberg", "Fredrikstad",
  
    "Denmark", "Copenhagen", "Aarhus", "Odense", "Aalborg", "Esbjerg", "Randers", "Kolding", "Vejle", "Herning", "Silkeborg",
    "Horsens", "Frederiksberg",
  
    "Finland", "Helsinki", "Espoo", "Tampere", "Vantaa", "Oulu", "Jyväskylä", "Kuopio", "Pori", "Lappeenranta", "Rovaniemi",
    "Seinäjoki", "Kouvola", "Porvoo",
  
    "Austria", "Vienna", "Graz", "Linz", "Salzburg", "Innsbruck", "Klagenfurt", "Villach", "Wels", "St. Pölten", "Bregenz",
    "Leoben", "Eisenstadt",
  
    "Switzerland", "Zurich", "Geneva", "Bern", "Basel", "Lausanne", "Lucerne", "St. Moritz", "Zug", "Thun", "Neuchâtel",
    "Fribourg", "Winterthur", "Lugano", "Zurich", "Geneva", "Bern", "Basel",
  
    "Greece", "Athens", "Thessaloniki", "Patras", "Heraklion", "Larissa", "Volos", "Kavala", "Chania", "Rhodes", "Kozani",
    "Serres", "Alexandroupoli", "Drama", "Corfu", "Kalamata",
  
    "Portugal", "Lisbon", "Porto", "Braga", "Aveiro", "Coimbra", "Funchal", "Ponta Delgada", "Évora", "Viseu", "Setúbal",
    "Sintra", "Cascais", "Guimarães", "Leiria", "Bragança",
  
    "Ireland", "Dublin", "Cork", "Limerick", "Galway", "Waterford", "Drogheda", "Sligo", "Kilkenny", "Athlone", "Naas",
    "Portlaoise", "Bray", "Ennis", "Tullamore",
  
    "New Zealand", "Auckland", "Wellington", "Christchurch", "Hamilton", "Tauranga", "Dunedin", "Palmerston North",
    "Napier", "Hastings", "Invercargill", "Whangarei", "Rotorua", "New Plymouth", "Nelson",
  
    "Singapore", "Singapore",
  
    "Hong Kong", "Hong Kong",
  
    "Malaysia", "Kuala Lumpur", "Penang", "Johor Bahru", "Kota Kinabalu", "Kuching", "Ipoh", "Malacca", "Seremban",
    "Kuala Terengganu", "Alor Setar", "Shah Alam", "Putrajaya", "Miri", "Bintulu",
  
    "Philippines", "Manila", "Quezon City", "Cebu City", "Davao City", "Makati", "Pasig", "Taguig", "Zamboanga City",
    "Cagayan de Oro", "Iloilo City", "Baguio City", "Dumaguete City", "General Santos", "Batangas City", "San Fernando",
    "Lipa City",
  
    "Vietnam", "Hanoi", "Ho Chi Minh City", "Da Nang", "Hai Phong", "Nha Trang", "Can Tho", "Hue", "Bien Hoa", "Vung Tau",
    "Quy Nhon", "Hạ Long", "Rach Gia", "Sadec", "Tuy Hoa", "Hanoi", "Ho Chi Minh City", "Da Nang", "Hai Phong",
  
    "Thailand", "Bangkok", "Nonthaburi", "Nakhon Ratchasima", "Chiang Mai", "Nakhon Si Thammarat", "Khon Kaen",
    "Hatyai", "Udon Thani", "Pattaya", "Ubon Ratchathani", "Songkhla", "Surat Thani", "Lampang", "Loei", "Sakon Nakhon",
    "Chonburi", "Ayutthaya",
  
    "South Korea", "Seoul", "Busan", "Incheon", "Daegu", "Daejeon", "Gwangju", "Ulsan", "Suwon", "Jeonju", "Cheongju",
    "Jeju City", "Gumi", "Pohang", "Changwon", "Gangneung", "Jeongeup", "Mokpo",
  
    "Israel", "Tel Aviv", "Jerusalem", "Haifa", "Netanya", "Rishon LeZion", "Petah Tikva", "Ashdod", "Ashkelon", "Rehovot",
    "Hadera", "Ra'anana", "Kfar Saba", "Herzliya", "Be'er Sheva", "Nazareth", "Tiberias", "Eilat",
  
    "Japan", "Tokyo", "Osaka", "Yokohama", "Nagoya", "Sapporo", "Fukuoka", "Kobe", "Kyoto", "Hiroshima", "Sendai",
    "Nagasaki", "Kumamoto", "Okinawa", "Kanazawa", "Otsu", "Sapporo",
  
    "China", "Beijing", "Shanghai", "Guangzhou", "Shenzhen", "Chengdu", "Wuhan", "Xi'an", "Hangzhou", "Nanjing", "Chongqing",
    "Tianjin", "Dongguan", "Shijiazhuang", "Zhengzhou", "Jinan", "Qingdao", "Dalian", "Xiamen", "Fuzhou", "Changsha",
    "Shenyang", "Harbin", "Hefei", "Ningbo", "Suzhou", "Wuxi", "Yangzhou", "Nantong", "Linyi",
  
    "India", "Mumbai", "Delhi", "Bengaluru", "Hyderabad", "Chennai", "Kolkata", "Ahmedabad", "Pune", "Jaipur", "Lucknow",
    "Kanpur", "Nagpur", "Indore", "Thane", "Bhopal", "Coimbatore", "Mysuru", "Vadodara", "Rajkot", "Surat", "Patna",
    "Chandigarh", "Amritsar", "Guwahati", "Varanasi", "Aurangabad", "Jabalpur", "Bilaspur", "Raipur", "Ranchi", "Jamshedpur",
  
    "Pakistan", "Karachi", "Lahore", "Faisalabad", "Rawalpindi", "Multan", "Peshawar", "Quetta", "Islamabad", "Sialkot",
    "Bahawalpur", "Sargodha", "Gujranwala", "Mirpur", "Faisalabad", "Gujrat", "Kasur",
  
    "Sri Lanka", "Colombo", "Kandy", "Galle", "Jaffna", "Negombo", "Anuradhapura", "Matara", "Ratnapura", "Trincomalee",
    "Batticaloa", "Polonnaruwa", "Kurunegala",
  
    "Nepal", "Kathmandu", "Pokhara", "Biratnagar", "Lalitpur", "Bhairahawa", "Janakpur", "Birgunj", "Butwal", "Dhangadhi",
    "Hetauda", "Nepalgunj",
  
    "Bangladesh", "Dhaka", "Chattogram", "Khulna", "Rajshahi", "Sylhet", "Barisal", "Rangpur", "Mymensingh", "Jashore",
    "Tangail", "Comilla", "Feni", "Naogaon", "Bogra", "Patuakhali",
  
    "Maldives", "Malé", "Addu City", "Fuvahmulah", "Dhidhdhoo", "Thulusdhoo", "Raa Atoll", "Baa Atoll", "Laccadive Islands",
  
    "Myanmar", "Yangon", "Mandalay", "Naypyidaw", "Bagan", "Taunggyi", "Monywa", "Sittwe", "Kalay", "Hpa-An", "Pathein",
    "Pyay", "Maungdaw", "Khamti", "Lashio",
  
    "Laos", "Vientiane", "Luang Prabang", "Pakse", "Savannakhet", "Thakhek", "Xam Neua", "Xieng Khouang", "Khamkeut",
    "Pakse",
  
    "Cambodia", "Phnom Penh", "Siem Reap", "Battambang", "Sihanoukville", "Kampong Cham", "Kampot", "Kep", "Kandal",
    "Pailin", "Tboung Khmum",
  
    "Brunei", "Bandar Seri Begawan", "Kuala Belait", "Seria", "Tutong", "Bangar",
  
    "Timor-Leste", "Dili", "Baucau", "Viqueque", "Lautém", "Oecussi", "Aileu", "Bobonaro", "Ermera", "Manatuto",
  
    "Papua New Guinea", "Port Moresby", "Lae", "Mount Hagen", "Madang", "Wewak", "Goroka", "Rabaul", "Kokopo", "Buka",
    "Arawa", "Popondetta", "Kainantu", "Vanimo",
  
    "Solomon Islands", "Honiara", "Gizo", "Auki", "Kirakira", "Taro", "Munda", "Lata",
  
    "Fiji", "Suva", "Nadi", "Lautoka", "Labasa", "Ba", "Sigatoka", "Levuka",
  
    "Samoa", "Apia", "Vaitele", "Faleasiu", "Faleata", "Leauva’a", "Lalomanu", "Satupa’itea",
  
    "Tonga", "Nukuʻalofa", "Neiafu", "Vavaʻu", "Hihifo", "Holopeka", "Pangai",
  
    "Vanuatu", "Port Vila", "Luganville", "Norsup", "Sola", "Lakatoro", "Champagne",
  
    "Marshall Islands", "Majuro", "Ebeye", "Wotje", "Rongelap", "Ailuk", "Jabat",
  
    "Kiribati", "South Tarawa", "Betio", "Bairiki", "Buota", "Teaoraereke", "Tabontebike", "Tanaeang",
  
    "Tuvalu", "Funafuti", "Vaitupu", "Nukufetau", "Nukulaelae", "Niulakita",
  
    "Palau", "Ngerulmud", "Koror", "Melekeok", "Airai", "Ngaraard", "Ngatpang",
  
    "Nauru", "Yaren", "Denigomodu", "Boe", "Anibare", "Aiwo", "Uaboe",
  
    "Tonga", "Nukuʻalofa", "Neiafu", "Vavaʻu", "Hihifo", "Holopeka", "Pangai",
  
    "Vanuatu", "Port Vila", "Luganville", "Norsup", "Sola", "Lakatoro", "Champagne",
  
    "Marshall Islands", "Majuro", "Ebeye", "Wotje", "Rongelap", "Ailuk", "Jabat",
  
    "Kiribati", "South Tarawa", "Betio", "Bairiki", "Buota", "Teaoraereke", "Tabontebike", "Tanaeang",
  
    "Tuvalu", "Funafuti", "Vaitupu", "Nukufetau", "Nukulaelae", "Niulakita",
  
    "Palau", "Ngerulmud", "Koror", "Melekeok", "Airai", "Ngaraard", "Ngatpang",
  
    "Nauru", "Yaren", "Denigomodu", "Boe", "Anibare", "Aiwo", "Uaboe",
  
    "South Sudan", "Juba", "Malakal", "Wau", "Bor", "Yambio", "Aweil", "Renk", "Pibor", "Kapoeta",
  
    "Somalia", "Mogadishu", "Hargeisa", "Bosaso", "Kismayo", "Baidoa", "Galkayo", "Garowe", "Jowhar", "Burao", "Burco",
  
    "Eritrea", "Asmara", "Mendefera", "Keren", "Assab", "Barentu", "Teseney", "Dekemhare", "Adi Quala", "Massawa",
  
    "Djibouti", "Djibouti", "Ali Sabieh", "Tadjoura", "Obock", "Dikhil", "Arta", "Houleh", "Goubetto", "Ambouli",
  
    "Comoros", "Moroni", "Mitsamiouli", "Fomboni", "Domoni", "Sima", "Bandar", "Hani",
  
    "Mauritius", "Port Louis", "Beau Bassin-Rose Hill", "Curepipe", "Quatre Bornes", "Vacoas-Phoenix", "Triolet", "Grand Baie",
  
    "Réunion", "Saint-Denis", "Saint-Paul", "Saint-Pierre", "Saint-Leu", "La Possession", "Cilaos", "Salazie", "La Plaine-des-Palmistes",
  
    "Mayotte", "Mamoudzou", "Dzaoudzi", "Kani-Kéli", "Labattoir", "Sada", "Sambava", "Tsingoni", "Chiconi",
  
    "Swaziland", "Mbabane", "Lobamba", "Manzini", "Siteki", "Big Bend", "Hlatikulu", "Piggs Peak", "Nhlangano",
  
    "Lesotho", "Maseru", "Teyateyaneng", "Berea", "Maputsoe", "Qacha's Nek", "Quthing", "Thaba-Tseka", "Leribe", "Hlotse",
  
    "Botswana", "Gaborone", "Francistown", "Molepolole", "Maun", "Selibe Phikwe", "Palapye", "Jwaneng", "Kanye", "Ghanzi",
  
    "Namibia", "Windhoek", "Swakopmund", "Walvis Bay", "Rundu", "Oshakati", "Omaruru", "Helao Nafidi", "Luderitz", "Keetmanshoop",
  
    "Zimbabwe", "Harare", "Bulawayo", "Mutare", "Gweru", "Kwekwe", "Chitungwiza", "Masvingo", "Gokwe", "Rusape", "Chegutu",
  
    "Zambia", "Lusaka", "Kitwe", "Ndola", "Livingstone", "Chingola", "Mufulira", "Mazabuka", "Luanshya", "Chipata", "Kasama",
  
    "Malawi", "Lilongwe", "Blantyre", "Mzuzu", "Zomba", "Kasungu", "Dedza", "Mangochi", "Nkhotakota", "Salima", "Ntcheu",
  
    "Angola", "Luanda", "Lubango", "Lobito", "Kuito", "Huambo", "Uíge", "Malanje", "Cabinda", "Saurimo", "Ndalatando",
  
    "Congo-Brazzaville", "Brazzaville", "Pointe-Noire", "Dolisie", "Oyo", "Owando", "Kinkala", "Loutété", "Mossaka",
  
    "Congo-Kinshasa", "Kinshasa", "Lubumbashi", "Goma", "Kisangani", "Mbandaka", "Tshuapa", "Mbuji-Mayi", "Kananga", "Kolwezi",
  
    "Central African Republic", "Bangui", "Bambari", "Berbérati", "Kaga-Bandoro", "Ombella-M'Poko", "Paoua", "Nana-Bakassa", "Bouar", "Sibut",
  
    "Chad", "N'Djamena", "Moundou", "Sarh", "Abéché", "Kelo", "Ati", "Lac", "Mayo-Kebbi", "Logone",
  
    "South Sudan", "Juba", "Malakal", "Wau", "Bor", "Yambio", "Aweil", "Renk", "Pibor", "Kapoeta",
  
    "Sudan", "Khartoum", "Omdurman", "Port Sudan", "Nyala", "Kassala", "Juba", "El Obeid", "Wad Madani", "Dongola",
  
    "Ethiopia", "Addis Ababa", "Dire Dawa", "Mek'ele", "Adama", "Bahir Dar", "Hosaena", "Jimma", "Awasa", "Dembi Dolo",
  
    "Somalia", "Mogadishu", "Hargeisa", "Bosaso", "Kismayo", "Baidoa", "Galkayo", "Garowe", "Jowhar", "Burao", "Burco"
  ];
  
  const services = [
   "Web Development", "App Development", "Digital Marketing", "IT ", "Ads Targeting",
    "Software Development", "Cloud Computing", "Cybersecurity", "Data Analytics",
    "Blockchain Development", "AI and Machine Learning", "AR/VR Development", "Network Solutions",
    "Managed IT ", "Consulting ", "Human Resources ", "Recruitment ",
    "Project Management", "Business Process Outsourcing ", "Market Research",
    "Business Strategy Development", "Supply Chain Management", "Quality Assurance",
    "Banking", "Insurance", "Investment Management", "Accounting ", "Tax Preparation",
    "Financial Consulting", "Payment Processing", "Wealth Management", "Credit Card ",
    "Mortgage ", "General Healthcare", "Specialized Medical Care", "Dental ",
    "Mental Health ", "Telemedicine", "Pharmacy ", "Nursing and Elderly Care",
    "Medical Research", "Healthcare Consulting", "Healthcare IT ", "K-12 Education",
    "Higher Education", "Online Learning Platforms", "Tutoring ",
    "Professional Training and Development", "Educational Consulting", "Language Learning",
    "Certification Programs", "E-Learning Development", "Legal Consulting", "Corporate Law",
    "Intellectual Property Law", "Family Law", "Criminal Defense", "Estate Planning",
    "Immigration ", "Contract Law", "Dispute Resolution", "Notary ",
    "Customer Support ", "Graphic Design", "Video Production", "Photography",
    "Content Creation", "Copywriting", "Branding ", "Animation and Motion Graphics",
    "UX/UI Design", "Print Design", "Product Design", "Logistics and Shipping", "Freight ",
    "Courier ", "Public Transportation", "Taxi and Ride-Sharing", "Airport Shuttle ",
    "Vehicle Rental", "Maritime ", "Aviation ", "Hotel ",
    "Event Planning", "Tourism ", "Catering ", "Travel Agencies",
    "Resort and Spa ", "Restaurant ", "Cruise ",
    "Conference and Meeting ", "Wedding Planning", "Waste Management", "Recycling ",
    "Environmental Consulting", "Sustainability ", "Water Treatment", "Air Quality Monitoring",
    "Renewable Energy Solutions", "Environmental Impact Assessment", "Conservation ",
    "Eco-Tourism", "Residential Real Estate", "Commercial Real Estate", "Property Management",
    "Real Estate Consulting", "Real Estate Appraisal", "Mortgage Brokerage", "Real Estate Development",
    "Leasing ", "Facility Management", "Real Estate Investment", "Physical Security ",
    "Surveillance Systems", "Private Investigation", "Event Security", "Security Consulting",
    "Alarm Monitoring", "Access Control Systems", "Fire Safety ", "Emergency Response ",
    "E-Commerce ", "Retail Consulting", "Merchandising ", "Point of Sale Solutions",
    "Inventory Management", "Customer Loyalty Programs", "Store Design and Layout", "Product Sourcing",
    "Retail Analytics", "Product Design and Development", "Prototyping ", "Custom Manufacturing",
    "Mass Production", "Quality Control and Testing", "Packaging ", "Industrial Engineering",
    "Maintenance and Repair", "Manufacturing Consulting", "Telecommunications", "VoIP ",
    "Internet Service Providers (ISP)", "Mobile Network ", "Data Center ",
    "Satellite Communication", "Broadcasting ", "Media and Public Relations",
    "Corporate Communication", "Crisis Communication", "Crop Production", "Livestock Management",
    "AgriTech Solutions", "Agricultural Consulting", "Farm Management", "Irrigation ",
    "Soil Testing", "Agricultural Research", "Organic Farming", "Agricultural Machinery ",
    "Film Production", "Music Production", "Event Management", "Theater Production",
    "Game Development", "Streaming ", "Talent Management", "Advertising and Media Buying",
    "Public Relations", "Concert and Festival ", "Residential Construction", "Commercial Construction",
    "Architectural Design", "Civil Engineering", "Renovation and Remodeling", "Interior Design",
    "Construction Consulting", "Landscaping ", "Fitness and Wellness", "Personal Training",
    "Nutrition Consulting", "Mental Wellness Programs", "Yoga and Meditation", "Spa and Beauty Treatments",
    "Childcare ", "Elder Care", "Pet Care ", "Home Renovation", "Cleaning ",
    "Pest Control", "Handyman ", "Appliance Repair", "Gardening ", "Event Catering",
    "Party Planning", "Professional Organizing", "Career Counseling", "Public Speaking Training",
    "Language Translation", "Voiceover ", "Cultural Consulting", "Import/Export ",
    "Supply Chain Consulting", "Procurement ", "Customs Brokerage", "Trade Show ",
    "Medical Device Manufacturing", "Pharmaceutical Research", "Biotechnology ",
    "Veterinary ", "Sports Management", "Recreation ", "Adventure Tourism",
    "Outdoor Activities", "Historical and Cultural Tours", "Eco-Friendly Products",
    "Sustainable Fashion", "Luxury Goods", "Art and Craft ", "Collectibles and Antiques",
    "Furniture Design and Manufacturing", "Home Decor", "Artisanal Products", "Music Therapy",
    "Life Coaching", "Executive Coaching", "Business Coaching", "Personal Coaching"
  ];

const LandingPage = () => {
  const [countryIndex, setCountryIndex] = useState(0);
  const [serviceIndex, setServiceIndex] = useState(0);

  useEffect(() => {
    const changeInterval = 3000; // Time to change each service-country combination

    const interval = setInterval(() => {
      setServiceIndex((prevServiceIndex) => {
        if (prevServiceIndex === services.length - 1) {
          setCountryIndex((prevCountryIndex) => (prevCountryIndex + 1) % countries.length);
          return 0; // Reset service index after covering all services for the current country
        }
        return prevServiceIndex + 1;
      });
    }, changeInterval);

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    window.open('https://calendly.com/danishm8560/30min', '_blank');
  };

  return (
    <div className='LandingPage-container'>
      <div className="hero-section">
        <Container>
          <p className="herotext">
            Build {services[serviceIndex]} Services in <span className='highlight'>{countries[countryIndex]}</span>
          </p>
          <div className='ed'>
            <Typography className="request-button" onClick={handleButtonClick}>
              Request a Demo
            </Typography>
          </div>
        </Container>
        <hr className='hrs' />
      </div>

      <AnimatedCard />

      <Box sx={{ width: 500, maxWidth: '100%' }}>
      </Box>

      <div className='LandingPage-fourthBox'>
        <IndustriesWeServe />
        <AnimatedText />
        <WhoWeWorkWith />
        <WeOffer />
        <StepperStep />
      </div>

      <h1 style={{ marginTop: "140px", fontFamily: "Garamond", color: "rgba(255, 199, 39, 1)", fontWeight: "bold", letterSpacing: "3px" }}>
        Connect With
      </h1>

      <MovingCards
        image1={require('../assets/company logo/images (3).png')}
        image2={require('../assets/company logo/08f7b7b2-b665-493e-8ba6-c9393403b069.png')}
        image3={require('../assets/company logo/attachment_55505095-e1492449890342.avif')}
        image4={require('../assets/company logo/download.png')}
        image5={require('../assets/company logo/images (1).jpeg')}
        image6={require('../assets/company logo/images (1).png')}
        image7={require('../assets/company logo/images (2).jpeg')}
        image8={require('../assets/company logo/images (3).jpeg')}
        image9={require('../assets/company logo/images (3).png')}
        image10={require('../assets/company logo/images (4).jpeg')}
        image11={require('../assets/company logo/images (4).png')}
        image12={require('../assets/company logo/images (5).png')}
      />

      <WhyChoose />
      <ReviewCards />
    </div>
  );
};

export default LandingPage;
