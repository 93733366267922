import React, { useState, useEffect,useRef } from 'react';
import { Card, Typography, Button } from "@mui/material";
// import { useWindowSize } from '../components/commonFunctions';
import { useWindowSize } from '../../components/commonFunctions';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { keyframes } from '@emotion/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Container } from '@mui/material';
// Import slick-carousel components and CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './AIML.css'
import ProjectCard from '../../components/card/ProjectCard';
import MovingCards from '../card/MovingCards';



const images = [

 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/imageslider/xomveqwkunxwsgtg9eix",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/imageslider/rndkpbydy4ed0rjgebpo",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/imageslider/skhx9m9ygxmdyiqlviph",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/imageslider/ngx1npf1ajgqxrlaqlxh",
 "https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/imageslider/rpjsad2jil1zzzeny8kr ",

  


 

];

function AIML() {
      const { width } = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const videoRef = useRef(null);
  
    const handleVideoEnded = () => {
      // Reset the video to the beginning
      videoRef.current.currentTime = 0;
      // Play the video again
      videoRef.current.play();
    };
  
    const handleChangeTab = (index) => {
      setActiveTab(index);
    };
  
    // const videoRef = React.createRef();
  
    useEffect(() => {
      // Make the video play only once
      if (videoRef.current) {
        videoRef.current.loop = false;
      }
    }, [videoRef]);
  
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false
    };
  


  
  return (
    <div >
     {/* <Typography style={{textAlign:"center",fontWeight:"bold",fontSize:60}}>AR/VR</Typography>  */}
<div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-item-${index}`} style={{ width: '100%', height: '700px' }} />
          </div>
        ))}
      </Slider>
    </div> 

    <Typography paragraph style={{color:"white"}}>
    Experience the realm of AI/ML excellence with BringMark as your guide into the future of business transformation. Our AI/ML solutions are an embodiment of cutting-edge technology and strategic insights, designed to navigate the complexities of data and automation. By seamlessly integrating advanced algorithms, predictive models, and cognitive automation, we empower businesses to extract actionable intelligence from vast datasets. Whether it's optimizing supply chains, personalizing customer interactions, or predicting market trends, BringMark's AI/ML mastery transcends possibilities, reshaping industries and elevating businesses to new heights.
    Dive into the cutting-edge world of AI/ML through BringMark's innovative solutions, where data becomes a catalyst for transformation. Our AI/ML expertise merges machine learning algorithms, neural networks, and predictive analytics to create intelligent systems that adapt and evolve. By automating complex tasks, extracting insights, and predicting trends, we empower businesses to make informed decisions and remain agile in a dynamic market. BringMark's AI/ML mastery isn't just about technology; it's about fostering a data-driven culture that propels businesses towards sustainable growth and a competitive edge.
        </Typography>

<div>
  <MovingCards
  
image1="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/gelzhuthf8ppe2jigdng"
image2="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/de576w8byteyywxw6ydw"
image3="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/tydbtwpckv4bwiczxyix"
image4="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/jwav1gennfwoob3md0da"
image5="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/qrvpan9gniwh7zv0zk4i"
image6="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/dtduurffe4ziwsyemkff"
image7="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/yoxg6tolzrf0gztkbaja"
image8="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/rgmazd4i96uvbfi227gx"
image9="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/nqdb56qj5yqox5jzbit6"
image10="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/jkwyfjvjniasiq6hwlrs"
image11="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/arlbiftxzol8rvkhgx8p"
image12="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/marque/bln8ocaiagij27gepnfp"

  
  />
</div>

        <div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  AI/ML Transformations: Your Path to Smarter Operations with BringMark

</Typography>
<Typography  style={{color:"#545357"}}>

Venture into the world of AI/ML transformations guided by BringMark, where cutting-edge technologies fuel data-driven evolution. Our AI/ML solutions transcend boundaries, orchestrating data analytics and cognitive automation to revolutionize your operations. By seamlessly integrating predictive models, machine learning algorithms, and intelligent insights, we empower businesses to extract actionable intelligence from complex data landscapes. Whether you're in retail, finance, or manufacturing, BringMark's AI/ML mastery transforms your operations, enabling smarter decisions, optimized processes, and amplified efficiency. </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/gif/luiygmu29u68hey1xs2v" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Navigate the intricate landscape of AI/ML with BringMark's expertise that goes beyond the surface level. Our solutions delve deep into machine learning algorithms, pattern recognition, and automated decision-making. By harnessing data analytics and cognitive automation, we empower businesses to uncover insights that drive innovation and guide strategic decisions. From automating routine tasks to unveiling trends that shape industries, BringMark's AI/ML prowess isn't just about technology; it's about transforming raw data into actionable intelligence that shapes your success story.
Explore the synergy of AI/ML and business excellence with BringMark as your guide. Our AI/ML solutions bridge the gap between data and strategy, integrating predictive modeling, natural language processing, and automation into a seamless ecosystem. By unlocking insights, optimizing processes, and automating routine tasks, we enable businesses to focus on innovation and strategic endeavors. With BringMark's AI/ML mastery, you're not just leveraging technology; you're shaping your destiny through intelligent insights that drive innovation and create unparalleled customer experiences.
        </Typography>


<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/gif/uis9tjpom2num5yid1gr" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

  Elevate with AI/ML Mastery: Redefining Business Possibilities</Typography>
<Typography  style={{color:"#545357"}}>


Elevate your business to new heights with BringMark's AI/ML mastery that goes beyond the surface. Our AI/ML solutions redefine the realm of possibilities, stitching together predictive analytics, algorithmic intelligence, and automated insights. From streamlining supply chains to personalizing customer interactions, we engineer solutions that create lasting impacts. The convergence of machine learning algorithms and cognitive automation enhances decision-making, automates complex tasks, and charts a course towards unparalleled business success.

 </Typography>
</div>


</div>

<Typography paragraph style={{color:"white"}}>
Embark on an AI/ML journey that redefines business possibilities with BringMark's innovative solutions. Our AI/ML expertise integrates complex algorithms, deep learning models, and sophisticated analytics to unearth insights that transform the way you operate. By automating processes, predicting outcomes, and identifying patterns, we empower businesses to navigate uncertainties and seize opportunities. Beyond data, BringMark's AI/ML solutions encapsulate the potential to empower your workforce, streamline operations, and create experiences that resonate with your audience, ultimately leading you towards a future where innovation is the norm.
Step into the realm of AI/ML transformation with BringMark, where algorithms and automation converge to redefine possibilities. Our AI/ML solutions encompass a spectrum of capabilities – from pattern recognition and predictive analytics to cognitive automation. By orchestrating these components, we empower businesses to decode data, automate operations, and enhance decision-making. BringMark's AI/ML mastery is a testament to our commitment to innovation, as we enable businesses to foresee trends, optimize processes, and drive growth by harnessing the power of data-driven insights.
        </Typography>


<div className='LandingPage-thirdBox'>

<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>


  AI/ML Unveiled: Your Gateway to Intelligent Futures with BringMark

</Typography>
<Typography  style={{color:"#545357"}}>

Unveil the potential of AI/ML with BringMark's dynamic solutions, introducing intelligence to every facet of your business. Our AI/ML expertise propels your journey into an era where data becomes foresight and algorithms drive innovation. By fusing advanced analytics, machine learning, and predictive modeling, we transform raw data into strategic insights. From enhancing user experiences to optimizing operations, our AI/ML prowess redefines what's possible, enabling you to innovate, adapt, and thrive in a data-rich world  </Typography>
</div>
<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/gif/jhwkmvjjeryv8qqpx0c1" alt=""  style={{borderRadius:20}}/>
  
</div>

</div>

<Typography paragraph style={{color:"white"}}>
Discover the realm of AI/ML where data-driven insights converge with automation to redefine industries and reshape businesses. BringMark's AI/ML solutions aren't confined to technological frameworks – they transcend boundaries, offering a transformative approach to decision-making and operations. By blending predictive modeling, cognitive algorithms, and advanced analytics, we enable businesses to transform data into foresight, complex processes into streamlined workflows, and challenges into strategic opportunities. With BringMark, AI/ML isn't just a buzzword; it's a strategic imperative that empowers businesses to adapt, innovate, and thrive in a rapidly evolving digital landscape.
Journey into the future with BringMark's AI/ML expertise that amplifies your business's potential. Our solutions encompass predictive modeling, machine learning, and automated insights, creating a synergy that transforms data into actionable intelligence. By automating tasks, extracting insights, and uncovering patterns, we empower businesses to drive efficiency, enhance customer experiences, and make informed decisions. With BringMark, AI/ML isn't just a tool; it's a strategic enabler that reshapes businesses, fuels innovation, and propels them towards a future defined by intelligent operations and data-driven success.        </Typography>



{/* <ProjectCard/> */}
<div className='LandingPage-thirdBox'>

<div style={{marginBottom:"50px"}}>
  <img  src="https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/services/Aiml/gif/fqq8vjrukbjyj3jlcbkg" alt=""  style={{borderRadius:20}}/>
  
</div>
<div>
  {/* <Typography  variant='h5' style={{ color: "#ff7f50" }}>

  < TripOriginIcon  fontSize='small' style={{marginRight:"7px"}} /> 
    OUR WORK</Typography> */}


  <Typography variant='h3' style={{fontWeight:"bold"}}>

AI/ML Revolution: Reshaping Industries with BringMark's Ingenuity
</Typography>
<Typography  style={{color:"#545357"}}>

Step into the AI/ML revolution led by BringMark, where intelligent automation and data-driven insights reshape industries. Our AI/ML solutions extend beyond buzzwords, materializing into solutions that empower businesses to transcend limits. By harnessing predictive modeling, deep learning, and algorithmic sophistication, we empower you to decipher patterns, automate complex tasks, and foresee trends. From redefining supply chain efficiencies to crafting hyper-personalized customer experiences, BringMark's AI/ML revolution fuels innovation that sets you apart in a data-centric landscape </Typography>
</div>


</div>


      
    </div>
  )
}

export default AIML